// React and Hooks
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';

// Material UI Components
import {
    Button, CssBaseline, TextField, Grid, Box, Container, Table, TableBody,
    TableCell, TableContainer, TableHead, TableRow, Radio, RadioGroup,
    FormControlLabel, FormControl, IconButton
} from '@mui/material';

// MUI Icons
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

// Date Picker Components
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Custom Components & Styles
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';

// Redux Actions and Constants
import { allProvidedBooks, provideNewBooks, updateProvidedBooks } from '../../actions/libraryActions';
import { ACCEPT_PROVIDED_BOOK_RESET } from '../../constants/libraryConstants';
import { studentInfo } from '../../constants/commonContstants';

// Utilities and Hooks
import useAuth from '../hooks/UseAuth';
import dayjs from 'dayjs';
import LoadingButton from '@mui/lab/LoadingButton';

export default function GIVENBOOKFORM({ closeDialog, id, acceptId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const [catchID, setCatchID] = useState(id);
    const [catchID2] = useState(acceptId);
    const [isStudent, setIsStudent] = useState(false);
    const [totalBooks, settotalBooks] = useState(0);
    const formRef = useRef(null);
    const { code } = useAuth();

    // getting the value from redux according to the history info for using by default
    const { books, loading } = useSelector(state => state.allBooks);
    const { students } = useSelector(state => state.allStudent);
    const { provided_books } = useSelector(state => state.providedBooks);
    const [barcode, setBarcode] = useState('');
    const [timeoutId, setTimeoutId] = useState(null);
    const inputRef = useRef(null); // Reference to the barcode input
    const tableContainerRef = useRef(null);
    const { isSaved } = useSelector(state => state.newProvidedBooks);
    const { isAccepted } = useSelector(state => state.acceptBook);

    const [modeType, setModeType] = useState('autoMode')

    // Find receipt according to the bookNo and receiptNoTill
    const book = provided_books.find(b => b.id_no === catchID || b.id_no === catchID2);

    const defaultValues = {};

    // Populate `bookName`, `bookNo`, and `part` fields for up to 20 entries
    for (let i = 0; i < 20; i++) {
        defaultValues[`bookName${i}`] = book?.books_provided?.[i]?.[`bookName${i}`] || '';
        defaultValues[`bookNo${i}`] = book?.books_provided?.[i]?.[`bookNo${i}`] || '';
        defaultValues[`part${i}`] = book?.books_provided?.[i]?.[`part${i}`] || '';
    }

    // Add user info fields
    const userInfoFields = ['id_no', 'name', 'class_or_level', 'giving_date', 'receiving_date', 'late_fee'];
    userInfoFields.forEach((field, index) => {
        defaultValues[`userInfo${index}`] = book?.[field] || '';
    });
    const { handleSubmit, control, reset, getValues, setValue } = useForm({ defaultValues });

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (book) {
            settotalBooks(Object.keys(book.books_provided).length);
        }

        if (modeType === 'autoMode') {
            inputRef.current.focus();
        };
    }, [book, modeType])

    const handleModeType = (mode) => {
        setModeType(mode);
    };

    const handleCheck = () => {
        const data = getValues();

        // Find the maximum number of book entries dynamically by looking at the keys
        const bookKeys = Object.keys(data).filter(key => key.startsWith("bookNo"));
        const maxBooks = bookKeys.length; // Number of books

        // Loop through all book indices and create objects
        for (let i = 0; i < maxBooks; i++) {
            const book = books.find(b => b.book_no === parseInt(data[`bookNo${i}`]) && b.condition === 'in');
            if (book) {
                setValue(`bookName${i}`, book.book_name);
                setValue(`part${i}`, book.part);
            };
        }

        // for user info
        if (data.userInfo0) {
            const student = students.find(s => s.admit_no === parseInt(data.userInfo0))
            if (student) {
                setValue('userInfo1', student?.name || '')
                setValue('userInfo2', student?.choosing_class || '')
                // active save buttons
                setIsStudent(true)

                // if (modeType === 'autoMode') {
                //     inputRef.current.focus();
                // }
            } else {
                setIsStudent(false)
            }
        }
    };

    // handle clear
    const handleClear = () => {
        reset();
        setCatchID('')
        setIsStudent(false);
        settotalBooks(0)
        // if (modeType === 'autoMode') {
        //     inputRef.current.focus();
        // };
    };

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (isSaved) {
            reset();
            setCatchID('')
            setIsStudent(false);
            settotalBooks(0)
        }
        if (isAccepted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: ACCEPT_PROVIDED_BOOK_RESET })
            dispatch(allProvidedBooks(code))
        }
    }, [dispatch, t, code, enqueueSnackbar, isAccepted, isSaved, reset])


    // handleAllAccept
    const handleAllAccept = () => {
        const data = getValues();
        onSubmit(data, true);
    };

    // handle submit
    const onSubmit = (data, isAllAccepted) => {
        const result = [];
        const uniqueBookNos = [];

        // Find the maximum number of book entries dynamically by looking at the keys
        const bookKeys = Object.keys(data).filter(key => key.startsWith("bookName"));
        const maxBooks = bookKeys.length; // Number of books

        // Loop through all book indices and create objects
        let resultIndex = 0; // Track the new sequential index
        for (let i = 0; i < maxBooks; i++) {
            const bookName = data[`bookName${i}`];
            const bookNo = data[`bookNo${i}`];
            const part = data[`part${i}`];

            // Check if all three fields are defined and bookNo is unique
            if (bookName && bookNo && part && !uniqueBookNos.includes(bookNo)) {
                uniqueBookNos.push(bookNo); // Add unique bookNo to track it
                result.push({
                    [`bookName${resultIndex}`]: bookName,
                    [`bookNo${resultIndex}`]: bookNo,
                    [`part${resultIndex}`]: part,
                });
                resultIndex++; // Increment the result index for the next entry
            }
        }

        // For handling accepted books
        // Array to hold all receipt book numbers
        let recieptBookNo = [];

        // Loop through each item in books_provided
        if (book) {
            Object.values(book?.books_provided).forEach(bookEntry => {
                // Loop through each key in the current book entry to find all bookNo keys
                Object.keys(bookEntry).forEach(key => {
                    // Check if the key starts with "bookNo" to identify book number fields
                    if (key.startsWith("bookNo")) {
                        recieptBookNo.push(bookEntry[key]); // Add the book number to recieptBookNo array
                    }
                });
            });
        }

        // Find items in recieptBookNo that are not in uniqueBookNos
        const missingBookNos = recieptBookNo.filter(bookNo => !uniqueBookNos.includes(bookNo));

        if (result.length === 0 && missingBookNos.length === 0) {
            return enqueueSnackbar(t('requireText'), { variant: 'error' });
        };

        const finalData = {
            idNo: data.userInfo0,
            name: data.userInfo1,
            classOrLevel: data.userInfo2,
            givingDate: data.userInfo3 || null,
            receivingDate: data.userInfo4 || null,
            lateFee: data.userInfo5 || 0.0,
            missingBookNos: missingBookNos || [],
            allAccept: isAllAccepted,
            providedBooks: {
                ...result
            },
        }

        if (!catchID && !catchID2) {
            const duplicate = provided_books.find(b => b.id_no === parseInt(data.userInfo0))
            if (duplicate) return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });
            dispatch(provideNewBooks(finalData, code))
        } else {
            dispatch(updateProvidedBooks(finalData, code))
        }
    };

    const singleAccept = (id) => {
        removeBookByNumber(id);
    };

    // for handling barcode machine
    // Ensure input always has focus when component is mounted or updated
    const handleBarcodeChange = (event) => {
        const scannedBarcode = event.target.value;

        setBarcode(scannedBarcode);

        // Clear any existing timeout
        if (timeoutId) {
            clearTimeout(timeoutId);
        }

        // If the Enter key is pressed, prevent the form from submitting
        if (event.key === 'Enter') {
            event.preventDefault();
        }

        // Set a new timeout to log the barcode after a delay
        const newTimeoutId = setTimeout(() => {

            // checking is student id scanning or book
            const checkCode = scannedBarcode.split('-');
            if (checkCode[0] === '0.0') {
                const student = students.find(s => s.admit_no === parseInt(checkCode[1]))

                if (student) {
                    setValue('userInfo0', student?.admit_no || '')
                    setValue('userInfo1', student?.name || '')
                    setValue('userInfo2', student?.choosing_class || '')
                    // active save buttons
                    setIsStudent(true)
                    setBarcode(''); // Optionally clear the input
                }
                return
            }

            if (catchID2) { // it means user trying to accept the book
                const bookno = parseInt(scannedBarcode.split('-')[0])
                const data = getValues();
                const index = Object.keys(data).find(key => data[key] === bookno && key.startsWith('bookNo'))?.replace('bookNo', '');

                if (index) {
                    removeBookByNumber(parseInt(index));
                }
                setBarcode(''); // Optionally clear the input
                return
            }

            // if book scanning
            settotalBooks((prevTotal) => {
                const newTotal = prevTotal + 1;
                const currentBook = newTotal - 1
                const bookno = parseInt(scannedBarcode.split('-')[0])
                const partno = scannedBarcode.split('-')[1]
                const book = books.find(b => b.book_no === bookno && b.condition === 'in');

                if (book) {
                    setValue(`bookName${currentBook}`, book.book_name);
                    setValue(`bookNo${currentBook}`, bookno);
                    setValue(`part${currentBook}`, partno);
                    return newTotal;
                } else {
                    enqueueSnackbar(t('sorryToProvide'), { variant: 'error' });
                    // Decrease totalBooks count by 1 if book is not found
                    return prevTotal; // Keeps the previous totalBooks value
                }
            });
            setBarcode(''); // Optionally clear the input
        }, 1000); // Adjust the delay as needed (e.g., 1000ms = 1 second)

        setTimeoutId(newTimeoutId);
    };

    // Re-focus the input if it loses focus
    const handleBlur = () => {
        if (modeType === 'autoMode') {
            inputRef.current.focus();
        }
    };

    // Prevent the form submission on Enter globally for the barcode input
    const handleKeyDown = (event) => {
        if (event.key === 'Enter' && event.target === inputRef.current) {
            event.preventDefault();
        }
    };

    useEffect(() => {
        // Add the keydown event listener when component mounts
        window.addEventListener('keydown', handleKeyDown);

        // Cleanup on component unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
            if (timeoutId) {
                clearTimeout(timeoutId);
            }
        };
    }, [timeoutId]);


    // Scroll to the bottom whenever totalBooks increase
    useEffect(() => {
        if (tableContainerRef.current) {
            tableContainerRef.current.scrollTop = tableContainerRef.current.scrollHeight;
        }
    }, [totalBooks]); // Re-run effect whenever `totalBooks` change

    // remove book
    const removeBookByNumber = (index) => {
        if (index !== -1) {
            // Clear the row with the specified book number
            setValue(`bookName${index}`, '');
            setValue(`bookNo${index}`, '');
            setValue(`part${index}`, '');

            // Shift all rows after the removed row up by one
            for (let i = index + 1; i < totalBooks; i++) {
                setValue(`bookName${i - 1}`, getValues(`bookName${i}`));
                setValue(`bookNo${i - 1}`, getValues(`bookNo${i}`));
                setValue(`part${i - 1}`, getValues(`part${i}`));
            }

            // Clear the last row after shifting
            const lastIndex = totalBooks - 1;
            setValue(`bookName${lastIndex}`, '');
            setValue(`bookNo${lastIndex}`, '');
            setValue(`part${lastIndex}`, '');

            // Update totalBooks count
            settotalBooks(prevTotal => prevTotal - 1);

            // if (modeType === 'autoMode') {
            //     inputRef.current.focus();
            // };
        }
    };

    return (
        <Container component="main" maxWidth="sm" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'PROVIDING BOOK'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <CloseIcon fontSize='small' />
            </CustomCrossButton>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '1.5rem'
                }}
            >
                <RECEIPTHEADING text={t('givingBookForm')} department={t('library')} />
                <Box component='form' onSubmit={handleSubmit((data) => onSubmit(data, false))} ref={formRef} encType='multipart/form-data'>
                    <Grid container
                        sx={{ mt: 1.5 }}
                    >
                        <TableContainer
                            ref={modeType === 'autoMode' ? tableContainerRef : null}
                            sx={{
                                height: '235px',
                                overflowY: 'auto', // Enable vertical scrolling for the body
                                position: 'relative', // Ensures the header stays relative to the container
                            }}>
                            <Table stickyHeader aria-label="custom table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ fontSize: '1rem', width: '10%', padding: '5px' }}>
                                            {t('serialNo')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '1rem', width: '45%', padding: '5px' }}>
                                            {t('bookName')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '1rem', width: '16%', padding: '5px' }}>
                                            {t('bookNo')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '1rem', width: '16%', padding: '5px' }}>
                                            {t('part')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ fontSize: '1rem', width: '15%', padding: '5px' }}>
                                            {catchID2 ? t('accept') : t('clear')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.from({ length: catchID2 || modeType === 'autoMode' ? totalBooks : 20 }, (_, index) =>
                                        <TableRow key={`row-${index}`}>
                                            <TableCell align="center" sx={{ padding: '0px 5px 0px 5px', width: '10%' }}>{index + 1}</TableCell>
                                            <TableCell align="center" sx={{ padding: '0px 5px 0px 5px', width: '45%' }}>
                                                <Controller
                                                    name={`bookName${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            onChange={(event) => {
                                                                field.onChange(event);
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                            sx={{
                                                                '& .MuiInputBase-root': {
                                                                    padding: '5px',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '5px', width: '16%' }}>
                                                <Controller
                                                    name={`bookNo${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            type='number'
                                                            disabled={catchID2 ? true : false}
                                                            onChange={(event) => {
                                                                field.onChange(event);
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                sx: {
                                                                    '& input': {
                                                                        textAlign: 'center'
                                                                    },
                                                                },
                                                            }}
                                                            variant="standard"
                                                            sx={{
                                                                '& .MuiInputBase-root': {
                                                                    padding: '1px'
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            <TableCell align="center" sx={{ padding: '5px', width: '16%' }}>
                                                <Controller
                                                    name={`part${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            type='number'
                                                            onChange={(event) => {
                                                                field.onChange(event);
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                sx: {
                                                                    '& input': {
                                                                        textAlign: 'center'
                                                                    },
                                                                },
                                                            }}
                                                            variant="standard"
                                                            sx={{
                                                                '& .MuiInputBase-root': {
                                                                    padding: '1px',
                                                                },
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                            {!catchID2 ?
                                                <TableCell align="center" sx={{ padding: '5px', width: '15%' }}>
                                                    <IconButton aria-label="accept" size="small"
                                                        onClick={() => removeBookByNumber(index)}
                                                    >
                                                        <ClearOutlinedIcon
                                                            color='error'
                                                            fontSize="inherit"
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                                : null}
                                            {catchID2 ?
                                                <TableCell align="center" sx={{ padding: '5px', width: '11%' }}>
                                                    <IconButton aria-label="accept" size="small"
                                                        onClick={() => singleAccept(index)}
                                                    >
                                                        <CheckCircleIcon
                                                            fontSize="inherit"
                                                        />
                                                    </IconButton>
                                                </TableCell>
                                                : null}
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {
                            modeType === 'autoMode' ?
                                <TextField
                                    value={barcode}
                                    size='small'
                                    onChange={handleBarcodeChange}
                                    onBlur={handleBlur} // Re-focus on blur
                                    inputRef={inputRef} // Set the ref to manage focus
                                    fullWidth
                                    placeholder={catchID2 ? t('scanBarCodeForAccept') : t('scanBarCode')}
                                    InputProps={{
                                        sx: {
                                            alignItems: 'center',
                                            // caretColor: 'transparent', // Hide the cursor
                                            '& .MuiInputBase-input': {
                                                caretColor: 'transparent', // Ensure cursor is hidden in the input area
                                            },
                                            '& input': {
                                                height: '28px',
                                                textAlign: 'center'
                                            },
                                        },
                                        disableUnderline: true
                                    }}
                                    variant="standard"
                                />
                                : null}

                        <TableContainer sx={{ marginTop: '0.5rem', overflow: 'hidden' }}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" colSpan={2} sx={{ fontSize: '1rem', padding: '5px' }}>
                                            {t('receiverInfo')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {Array.from({ length: 3 }).map((_, rowIndex) => (
                                        <TableRow key={`row-${rowIndex}`}>
                                            {studentInfo.slice(rowIndex * 2, rowIndex * 2 + 2).map((lbl, index) => (
                                                <TableCell key={`cell-${rowIndex}-${index}`} sx={{ padding: 0 }}>
                                                    {rowIndex * 2 + index === 3 || rowIndex * 2 + index === 4 ? (
                                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                            <Controller
                                                                name={`userInfo${rowIndex * 2 + index}`}
                                                                control={control}
                                                                render={({ field }) => (
                                                                    <DatePicker
                                                                        {...field}
                                                                        value={
                                                                            field.value
                                                                                ? dayjs(field.value)
                                                                                : rowIndex * 2 + index === 3
                                                                                    ? dayjs()
                                                                                    : dayjs().add(1, 'year')
                                                                        }
                                                                        label={t(lbl)}
                                                                        format="YYYY-MM-DD"
                                                                        slotProps={{
                                                                            textField: {
                                                                                size: 'small',
                                                                                fullWidth: true,
                                                                                variant: 'filled',
                                                                                InputProps: {
                                                                                    disableUnderline: true,
                                                                                    style: { borderRadius: '0px' },
                                                                                },
                                                                            },
                                                                        }}
                                                                    />
                                                                )}
                                                            />
                                                        </LocalizationProvider>
                                                    ) : (
                                                        <Controller
                                                            name={`userInfo${rowIndex * 2 + index}`}
                                                            control={control}
                                                            render={({ field }) => (
                                                                <TextField
                                                                    {...field}
                                                                    size="small"
                                                                    fullWidth
                                                                    disabled={(catchID || catchID2) && rowIndex * 2 + index === 0}
                                                                    autoFocus={(!catchID && !catchID2) && rowIndex * 2 + index === 0}
                                                                    type={rowIndex * 2 + index === 0 || rowIndex * 2 + index === 5 ? 'number' : 'text'}
                                                                    label={lbl}
                                                                    onChange={(event) => field.onChange(event)}
                                                                    variant="filled"
                                                                    InputProps={{
                                                                        disableUnderline: true,
                                                                        style: { borderRadius: '0px' },
                                                                    }}
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item container xs={12} columnSpacing='2' mb={2} mt={2} justifyContent={'space-between'}>
                        <Grid item>
                            <FormControl component="fieldset">
                                <Controller
                                    name="modeType"
                                    control={control}
                                    defaultValue={modeType}
                                    render={({ field }) => (
                                        <RadioGroup
                                            {...field}
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            row
                                            value={modeType}
                                            onChange={(event) => {
                                                field.onChange(event);
                                                handleModeType(event.target.value);
                                            }}

                                            sx={{
                                                display: 'flex',
                                                '& .MuiSvgIcon-root': {
                                                    fontSize: 18,
                                                },
                                            }}
                                        >
                                            <FormControlLabel value="autoMode" control={<Radio />} label={t("autoMode")} />
                                            <FormControlLabel value="manualMode" control={<Radio />} label={t("manualMode")} />
                                        </RadioGroup>
                                    )}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item container xs={6} spacing={1} display={'flex'} justifyContent={'right'}>
                            {
                                catchID2 ? null :
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            onClick={handleCheck}
                                            color='secondary'
                                        >
                                            {t('check')}
                                        </Button>
                                    </Grid>
                            }

                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color='error'
                                    onClick={handleClear}
                                >
                                    {t('clear')}
                                </Button>
                            </Grid>

                            {catchID || catchID2 ?
                                <>
                                    {catchID2 ? <Grid item>
                                        <Button
                                            fullWidth
                                            variant="contained"
                                            color="success"
                                            onClick={handleAllAccept}
                                        >
                                            {t('acceptAll')}
                                        </Button>
                                    </Grid> : null}
                                    <Grid item>
                                        <Button
                                            fullWidth
                                            type="submit"
                                            variant="contained"
                                            color="primary"
                                        >
                                            {t('update')}
                                        </Button>
                                    </Grid>
                                </>
                                :
                                <Grid item>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        color="success"
                                        loading={loading ? true : false}
                                        disabled={!isStudent ? true : false}
                                        variant="contained"
                                    >
                                        <span>{t('save')}</span>
                                    </LoadingButton>
                                </Grid>
                            }
                        </Grid>
                    </Grid>

                </Box>
            </Box>
        </Container >
    );
}