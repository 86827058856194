// Step 1: Define global default column settings
const defaultColumnSettings = {
    sortable: false,
    flex: 1,
    minWidth: 120,
    disableColumnMenu: true,
};

// Step 2: Utility function to apply default settings and allow selective overrides
const useDataGridColumns = (columns) => {
    return columns.map((col) => ({ ...defaultColumnSettings, ...col }));
};

export default useDataGridColumns;