// React and related hooks
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// MUI components and icons
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, GridActionsCellItem, useGridApiContext, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { Grid, Button, ButtonGroup, Box, Menu, MenuItem, Dialog, IconButton, Chip } from '@mui/material';
import { Delete, Edit, CreditCard, Fingerprint, PinOutlined, FileDownloadOutlined } from '@mui/icons-material';

// Custom components and utilities
import CustomPagination from '../layout/Pagination';
import { MetaData } from '../utils/metaData';
import useDataGridColumns from '../utils/useDataGridColumns';
import dayjs from 'dayjs';
import ROUTINEFORM from './RoutineForm';

// Actions
import { allRoutines, deleteRoutine } from '../../actions/eduActions';
import { deleteZkUser } from '../../actions/ZKTecoActions';
import { routineListPDF } from '../../actions/pdfActions';

// Constants
import { ADD_ROUTINE_RESET } from '../../constants/eduConstants';

// Custom hooks
import useAuth from '../hooks/UseAuth';

function CustomToolbar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useAuth();
    const [currentRows, setCurrentRows] = React.useState('');
    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const excludedFields = ['__check__', 'actions', 'attendance', 'fatherName', 'address'];
        const headerNames = apiRef.current.getAllColumns()
            .filter(column => !excludedFields.includes(column.field))  // Exclude unwanted fields
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('routineManagement'),
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // handle pdf making
    const handlePdf = async (e) => {
        dispatch(routineListPDF(currentRows, code));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('routineManagement')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function ROUTINES() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { code, type } = useAuth();

    // import these info from reducers those are bound in store.js
    const { routines } = useSelector(state => state.allRoutines);
    const { success } = useSelector(state => state.newRoutine);

    const [selectedRow, setSelectedRow] = useState('');
    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allRoutines(code));
    }, [dispatch, code])

    useEffect(() => {
        if (success) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: ADD_ROUTINE_RESET })
            dispatch(allRoutines(code));
            setOpen(false);
        }
    }, [dispatch, t, enqueueSnackbar, success, code])

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, width: 80, disableColumnMenu: false, flex: 0 },
        { field: 'admitNo', headerName: t('idNo'), minWidth: 80, width: 80, sortable: true, flex: 0 },
        {
            field: 'imageUrl',
            headerName: t('picture'),
            width: 80,
            minWidth: 80,
            flex: 0,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="User"
                    style={{ width: '30px', height: '30px', borderRadius: '50%', marginTop: '2.5px' }}
                />
            ),
        },
        { field: 'name', headerName: t('name') },
        { field: 'fatherName', headerName: t('fatherName') },
        { field: 'department', headerName: t('department') },
        { field: 'class', headerName: t('class') },
        { field: 'group', headerName: t('group'), minWidth: 80, width: 80, flex: 0 },
        { field: 'mobile', headerName: t('mobile') },
        { field: 'shift', headerName: t('shift') },
        { field: 'classStart', headerName: t('classStart'), minWidth: 80, width: 80 },
        { field: 'classEnd', headerName: t('classEnd'), minWidth: 80, width: 80 },
        { field: 'classTime', headerName: t('classTime'), minWidth: 80, width: 80 },
        { field: 'address', headerName: t('address') },
        { field: 'residenceType', headerName: t('residenceType') },
        {
            field: 'attendance',
            headerName: t('attendance'),
            renderCell: (params) => (
                <Box>
                    <IconButton aria-label="delete" size="small" disabled>
                        {params.row.card ? <CreditCard fontSize="small" /> : ''}
                        {params.row.finger ? <Fingerprint fontSize="small" sx={{ marginLeft: 0.4 }} /> : ''}
                        {params.row.pin ? <PinOutlined fontSize="small" sx={{ marginLeft: 0.4 }} /> : ''}
                        {/* <Fingerprint fontSize="small" sx={{ marginLeft: 0.4 }} /> */}
                    </IconButton>
                </Box>
            ),
        },

        {
            field: 'actions',
            headerName: t('actions'),
            type: 'actions',
            width: 80,
            minWidth: 80,
            flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'education'}
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    disabled={type !== 'education'}
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('deleteRoutine')}
                    onClick={() => handleDelete(params.row)}
                    showInMenu
                />
            ],

        },

    ]);

    // Transform staff array into the desired format
    const rows = routines?.map((s, index) => {
        return {
            serial: index + 1,
            id: s.data_id,
            admitNo: s.admit_no,
            imageUrl: s.photo_url,
            name: s.name,
            fatherName: s.father_name,
            department: s.department,
            class: s.choosing_class,
            group: s.group_name,
            mobile: s.father_mobile,
            address: `${s.village}, ${s.district}`,
            residenceType: t(s.residence),
            shift: s.shift,
            classStart: s.class_start ? dayjs(s.class_start).format('h-mm A') : '',
            classEnd: s.class_end ? dayjs(s.class_end).format('h-mm A') : '',
            classTime: s.class_end && s.class_start ? `${Math.floor(dayjs(s.class_end).diff(dayjs(s.class_start), 'minute') / 60)}h ${dayjs(s.class_end).diff(dayjs(s.class_start), 'minute') % 60}m` : '',
            card: s.card_number, // only for use in params
            connectedDevices: s.connected_devices, // only for use in params
            finger: s.finger, // only for use in params
            pin: s.pin, // only for use in params
            attendance_type: s.attendance_type, // only for use in params
            class_start: s.class_start, // only for use in params
            class_end: s.class_end, // only for use in params
            devices: s.devices // only for use in params
        };
    });

    const handleEdit = (rowData) => {
        setSelectedRow(rowData);
        setOpen(true);
    };

    const handleDelete = async (rowData) => {
        if (rowData.attendance_type && rowData.attendance_type !== 'manual') {
            await dispatch(deleteZkUser({ devices: rowData.devices }, code));
        }
        await dispatch(deleteRoutine(rowData.id, code));
    };


    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'STUDENT ROUTINES'} />
            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <ROUTINEFORM closeDialog={handleClose} rowData={selectedRow} />
            </Dialog>

            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                localeText={localeText}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 }, // Default page size
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            mobile: false,
                            fatherName: false,
                            department: false,
                            address: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />,
                    pagination: CustomPagination,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
