import axios from 'axios';
import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,

    CLEAR_ERRORS,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_FAIL
} from '../constants/authConstants'

// Login
export const LoginAction = (LoginData) => async (dispatch) => {
    try {

        dispatch({ type: LOGIN_REQUEST })

        // get data from backend according this email, password
        const { data } = await axios.post(`/api/auth/login`, LoginData)

        // storing user data in payload 
        dispatch({
            type: LOGIN_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LOGIN_FAIL,
            payload: error.response.data.message
        })
    }
}

// Register user
export const registerUser = (formData, code) => async (dispatch) => {
    try {
        dispatch({ type: REGISTER_USER_REQUEST })

        // configuration for register a user 
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        await axios.post(`/api/auth/register/${code}`, formData, config)

        // storing user data in payload 
        dispatch({
            type: REGISTER_USER_SUCCESS,
            payload: 'User registered successfully'
        })

    } catch (error) {
        dispatch({
            type: REGISTER_USER_FAIL,
            payload: error
        });
    }
}

// Load currently logged in user
export const loadUser = () => async (dispatch) => {
    try {

        dispatch({ type: LOAD_USER_REQUEST })

        // geting a particuler profile data from the backend by this route
        const { data } = await axios.get(`/api/auth/me`)

        // storing user data in payload
        dispatch({
            type: LOAD_USER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: LOAD_USER_FAIL,
            payload: error.response.data.message
        })
    }
}


// Logout user
export const logout = (code) => async (dispatch) => {
    try {

        // connect to backend to work logout functionality
        await axios.get(`/api/auth/logout/${code}`)

        dispatch({
            type: LOGOUT_SUCCESS,
        })

    } catch (error) {
        dispatch({
            type: LOGOUT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Update profile
export const updateProfile = (userData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_USER_REQUEST })

        // configuration for register a user 
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        // connect with backend for profile update by userData that will be sent from frontend
        const { data } = await axios.put(`/api/auth/update/${code}`, userData, config)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_USER_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_USER_FAIL,
            payload: error.response.data.message
        })
    }
}


// // Update password
// export const updatePassword = (passwords) => async (dispatch) => {
//     try {

//         dispatch({ type: UPDATE_PASSWORD_REQUEST })

//         // configuration for update password
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         }

//         // connect with backend for password update by 'passwords' that will be sent from frontend
//         const { data } = await axios.put('/api/v1/password/update', passwords, config)

//         // showing success status if the password is updated
//         dispatch({
//             type: UPDATE_PASSWORD_SUCCESS,
//             payload: data.success
//         })

//     } catch (error) {
//         dispatch({
//             type: UPDATE_PASSWORD_FAIL,
//             payload: error.response.data.message
//         })
//     }
// }

// // Forgot password
// export const forgotPassword = (email) => async (dispatch) => {
//     try {

//         dispatch({ type: FORGOT_PASSWORD_REQUEST })

//         // configuration for forgot password
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         }

//         // geting forgot password functionality from the backend
//         const { data } = await axios.post('/api/v1/password/forgot', email, config)

//         // storing message in payload which is comes from backend
//         dispatch({
//             type: FORGOT_PASSWORD_SUCCESS,
//             payload: data.message
//         })

//     } catch (error) {
//         dispatch({
//             type: FORGOT_PASSWORD_FAIL,
//             payload: error.response.data.message
//         })
//     }
// }


// // Reset New password
// export const resetPassword = (token, passwords) => async (dispatch) => {
//     try {

//         dispatch({ type: NEW_PASSWORD_REQUEST })

//         // configuration for reset password
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         }

//         // connect with backend for password reset by 'passwords' that will be sent from frontend
//         const { data } = await axios.put(`/api/v1/password/reset/${token}`, passwords, config)

//         // showing success status if the password is reseted
//         dispatch({
//             type: NEW_PASSWORD_SUCCESS,
//             payload: data.success
//         })

//     } catch (error) {
//         dispatch({
//             type: NEW_PASSWORD_FAIL,
//             payload: error.response.data.message
//         })
//     }
// }

// // Get all users
export const allUsers = (uniqueData, code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_USERS_REQUEST })

        const { data } = await axios.post(`/api/auth/users/${code}`, uniqueData)

        // storing all users in payload
        dispatch({
            type: ALL_USERS_SUCCESS,
            payload: data.users
        })

    } catch (error) {
        dispatch({
            type: ALL_USERS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// // Update user - ADMIN
// export const updateUser = (id, userData) => async (dispatch) => {
//     try {

//         dispatch({ type: UPDATE_USER_REQUEST })

//         // configuration for update user by admin
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         }

//         // connect with backend for update user by 'userData' that will be sent from frontend
//         const { data } = await axios.put(`/api/v1/admin/user/${id}`, userData, config)

//         // showing success status if the password is reseted
//         dispatch({
//             type: UPDATE_USER_SUCCESS,
//             payload: data.success
//         })

//     } catch (error) {
//         dispatch({
//             type: UPDATE_USER_FAIL,
//             payload: error.response.data.message
//         })
//     }
// }

// // Get user details - ADMIN
// export const getUserDetails = (id) => async (dispatch) => {
//     try {

//         dispatch({ type: USER_DETAILS_REQUEST })

//         // connect with backend to get a user details
//         const { data } = await axios.get(`/api/v1/admin/user/${id}`)

//         // storing a particuler user data in payload
//         dispatch({
//             type: USER_DETAILS_SUCCESS,
//             payload: data.user
//         })

//     } catch (error) {
//         dispatch({
//             type: USER_DETAILS_FAIL,
//             payload: error.response.data.message
//         })
//     }
// }


// Delete user - ADMIN
export const deleteUser = (id, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_USER_REQUEST
        })

        // connect with backend to work delete user functionality
        const { data } = await axios.post(`/api/auth/delete/${code}`, id)

        // showing success status if the user is deleted
        dispatch({
            type: DELETE_USER_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_USER_FAIL,
            payload: error.response.data.message
        })
    }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}

