const basics = JSON.parse(localStorage.getItem('basics')) || {};
const { language } = basics;

// Function to convert Bengali or Arabic digits to english numerals
const digits = (str) => {
    const bengaliDigits = { '০': '0', '১': '1', '২': '2', '৩': '3', '৪': '4', '৫': '5', '৬': '6', '৭': '7', '৮': '8', '৯': '9' };
    const arabicDigits = { '٠': '0', '١': '1', '٢': '2', '٣': '3', '٤': '4', '٥': '5', '٦': '6', '٧': '7', '٨': '8', '٩': '9' };
    const englishDigits = { '0': '0', '1': '1', '2': '2', '3': '3', '4': '4', '5': '5', '6': '6', '7': '7', '8': '8', '9': '9' };
    const conversionMap = { ...bengaliDigits, ...arabicDigits, ...englishDigits };

    return str.split('').map(char => conversionMap[char] || char).join('');
};

// Function to fetch digits (Bengali or Arabic) from a string and convert to an Arabic numeral
export const convertDigit = (str) => {
    const combinedRegex = /[০-৯٠-٩0-9]/g; // Combined regex for Bengali and Arabic digits

    const matchedDigits = str.match(combinedRegex);

    if (matchedDigits) {
        const combinedNumber = matchedDigits.join('');
        const arabicNumber = digits(combinedNumber);
        return arabicNumber;
    } else {
        // Handle case where there are no Bengali or Arabic digits
        return null;
    }
};

export const convertToBengaliDigits = (input) => {
    const englishToBengaliDigitsMap = language === 'bn' ? {
        '0': '০',
        '1': '১',
        '2': '২',
        '3': '৩',
        '4': '৪',
        '5': '৫',
        '6': '৬',
        '7': '৭',
        '8': '৮',
        '9': '৯'
    } : language === 'ar' ? {
        '0': '٠',
        '1': '١',
        '2': '٢',
        '3': '٣',
        '4': '٤',
        '5': '٥',
        '6': '٦',
        '7': '٧',
        '8': '٨',
        '9': '٩'
    } : {
        '0': '0',
        '1': '1',
        '2': '2',
        '3': '3',
        '4': '4',
        '5': '5',
        '6': '6',
        '7': '7',
        '8': '8',
        '9': '9'
    };

    // Replace each English digit with its Bengali equivalent
    return input.toString().replace(/\d/g, (digit) => englishToBengaliDigitsMap[digit]);
};

export const numberToBanglaWords = (num) => {
    const banglaWords = [
        '', 'এক', 'দুই', 'তিন', 'চার', 'পাঁচ', 'ছয়', 'সাত', 'আট', 'নয়', 'দশ',
        'এগারো', 'বারো', 'তেরো', 'চৌদ্দ', 'পনেরো', 'ষোল', 'সতেরো', 'আঠারো', 'ঊনিশ',
        'বিশ', 'একুশ', 'বাইশ', 'তেইশ', 'চব্বিশ', 'পঁচিশ', 'ছাব্বিশ', 'সাতাশ', 'আটাশ', 'উনত্রিশ', 'ত্রিশ',
        'একত্রিশ', 'বত্রিশ', 'তেত্রিশ', 'চৌত্রিশ', 'পঁইত্রিশ', 'ছত্রিশ', 'সাঁইত্রিশ', 'আটত্রিশ', 'উনচল্লিশ',
        'চল্লিশ', 'একচল্লিশ', 'বিয়াল্লিশ', 'তেতাল্লিশ', 'চুয়াল্লিশ', 'পঁয়তাল্লিশ', 'ছেচল্লিশ', 'সাঁইচল্লিশ', 'আটচল্লিশ', 'উনপঞ্চাশ', 'পঞ্চাশ',
        'একান্ন', 'বায়ান্ন', 'তেপ্পান্ন', 'চুয়ান্ন', 'পঞ্চান্ন', 'ছাপ্পান্ন', 'সাঁইপ্পান্ন', 'আটান্ন', 'উনষাট', 'ষাট',
        'একষট্টি', 'বাষট্টি', 'তেষট্টি', 'চৌষট্টি', 'পঁয়ষট্টি', 'ছেষট্টি', 'সাঁইষট্টি', 'আটষট্টি', 'উনসত্তর', 'সত্তর',
        'একাত্তর', 'বাহাত্তর', 'তেহাত্তর', 'চুয়াত্তর', 'পঁচাত্তর', 'ছিয়াত্তর', 'সাঁইত্তর', 'আটাত্তর', 'উনআশি', 'আশি',
        'একাশি', 'বিরাশি', 'তিরাশি', 'চুরাশি', 'পঁচাশি', 'ছিয়াশি', 'সাতাশি', 'আটাশি', 'উননব্বই', 'নব্বই',
        'একানব্বই', 'বিরানব্বই', 'তিরানব্বই', 'চুরানব্বই', 'পঁচানব্বই', 'ছিয়ানব্বই', 'সাতানব্বই', 'আটানব্বই', 'নিরানব্বই'
    ];

    if (num === 0) return 'শূন্য';
    if (num < 100) return banglaWords[num];
    if (num < 1000)
        return (
            banglaWords[Math.floor(num / 100)] +
            ' শত' +
            (num % 100 === 0 ? '' : ' ' + numberToBanglaWords(num % 100))
        );
    if (num < 100000)
        return (
            numberToBanglaWords(Math.floor(num / 1000)) +
            ' হাজার' +
            (num % 1000 === 0 ? '' : ' ' + numberToBanglaWords(num % 1000))
        );
    if (num < 10000000)
        return (
            numberToBanglaWords(Math.floor(num / 100000)) +
            ' লক্ষ' +
            (num % 100000 === 0 ? '' : ' ' + numberToBanglaWords(num % 100000))
        );
    return (
        numberToBanglaWords(Math.floor(num / 10000000)) +
        ' কোটি' +
        (num % 10000000 === 0 ? '' : ' ' + numberToBanglaWords(num % 10000000))
    );
};
