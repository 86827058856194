import React, { createContext, useContext, useState } from 'react';
import UsersList from '../auth/UsersList';
import CHANGEREGARDING from '../account/ChangeRegardings';
import RECEIPTLIST from '../account/ReceiptList';
import VOUCHERLIST from '../account/VoucherList';
import CLASSLIST from '../education/ClassList';
import STUDENTLIST from '../education/StudentList';
import ADMISSIONFORM from '../education/AdmitForm';
import OFICIANTS from '../adminis/StaffList';
import RESULTMANAGEMENT from '../education/ResultManagement';
import RECEIPTLISTBOARDING from '../boarding/ReceiptList';
import VOUCHERLISTBOARDING from '../boarding/VoucherList';
import FURNITURELIST from '../adminis/FurnituerList';
import OFFICIANTREPORT from '../adminis/OfficiantReports';
import BOOKLIST from '../library/BookList';
import TOTALBOOKSTOGIVEN from '../library/TotalBooksToGiven';
import RECEIVEBOOKS from '../library/ReceiveBooks';
import DONORLIST from '../donation/DonorList';
import DONATIONRECORD from '../donation/DonationRecords';
import APPROVEDRECEIPTLIST from '../account/ApprovedBooks';
import SALARYLIST from '../account/SalaryList';
import GIVENFURNITURES from '../adminis/GivenFurnitureList';
import { SIGNSHEET } from '../education/ExamPapersForm';
import REPORTEDSTUDENTS from '../hostel/ReportedStudents';
import { HOSTELREGISTERFORM } from '../hostel/MakingRegistersForm';
import MONTHLYCOUNT from '../account/MonthlyCounts';
import { MCECOUNT, MGECOUNT, MGICOUNT, MGRCOUNT, YCECOUNT, YCRCOUNT, YGECOUNT, YGICOUNT, YGRCOUNT } from '../account/OtherCounts';
import YEARLYCOUNT from '../account/YearlyCounts';
import MONTHLYREPORT from '../account/CountingReports';
import ACCEPTMONEY from '../boarding/MoneyAcceptedList';
import STUFFATTENDANCELIST from '../attendance/OfficiantAttendance';
import STUFFDUTYLIST from '../adminis/DutyList';
import SELLINRPODUCTS from '../others/trade/SellingProdcutList';
import SOLDPRODUCTLIST from '../others/trade/SoldProductList';
import ROUTINES from '../education/Routines';
import STUDENTATTENDANCELIST from '../attendance/StudentAttendance';
import STAFFLEAVES from '../attendance/StaffLeaves';
import STUDENTLEAVES from '../attendance/StudentLeaves';
import MAIN from '../dashboard/Dashboard';
import { NEWNOTICE } from '../website/NewNotice';
import CustomizeGrading from '../education/GradingCustomize';
import { CustomSMSForm } from '../others/messages/NewSmsForm';
import { BALANCECHECK } from '../others/messages/BalanceCheck';
const ComponentContext = createContext();

export const ComponentProvider = ({ children }) => {
    const [currentComponent, setCurrentComponent] = useState(<MAIN />);
    const changeComponent = (key) => {
        // console.log(key);
        switch (key) {
            case 'dashboard':
                setCurrentComponent(<MAIN />);
                break;
            // for principal
            case 'adminis-0':
                setCurrentComponent(<OFICIANTS />);
                break;
            case 'adminis-1':
                setCurrentComponent(<STUFFDUTYLIST />);
                break;
            case 'adminis-2':
                setCurrentComponent(<STAFFLEAVES />);
                break;
            case 'adminis-3':
                setCurrentComponent(<OFFICIANTREPORT />);
                break;
            case 'adminis-4':
                setCurrentComponent(<FURNITURELIST />);
                break;
            case 'givenFurnitureWindow':
                setCurrentComponent(<GIVENFURNITURES />);
                break;
            // for hostel manager
            case 'hostelManager-0':
                setCurrentComponent(<REPORTEDSTUDENTS />);
                break;
            case 'hostelManager-1':
                setCurrentComponent(<HOSTELREGISTERFORM />);
                break;

            // for admission
            case 'admission-0':
                setCurrentComponent(<ADMISSIONFORM />);
                break;
            case 'admission-1':
                setCurrentComponent(<STUDENTLIST />);
                break;
            case 'users':
                setCurrentComponent(<UsersList />);
                break;
            // for accounts 
            case 'regardingChanging':
                setCurrentComponent(<CHANGEREGARDING />);
                break;
            case 'receipt':
                setCurrentComponent(<RECEIPTLIST />);
                break;
            case 'voucher':
                setCurrentComponent(<VOUCHERLIST />);
                break;
            case 'income-0':
                setCurrentComponent(<MONTHLYCOUNT />);
                break;
            case 'income-1':
                setCurrentComponent(<MGICOUNT />);
                break;
            case 'income-2':
                setCurrentComponent(<YEARLYCOUNT />);
                break;
            case 'income-3':
                setCurrentComponent(<YGICOUNT />);
                break;
            case 'expense-0':
                setCurrentComponent(<MCECOUNT />);
                break;
            case 'expense-1':
                setCurrentComponent(<MGECOUNT />);
                break;
            case 'expense-2':
                setCurrentComponent(<YCECOUNT />);
                break;
            case 'expense-3':
                setCurrentComponent(<YGECOUNT />);
                break;
            case 'reportAccount-0':
                setCurrentComponent(<MONTHLYREPORT />);
                break;
            case 'reportAccount-1':
                setCurrentComponent(<MGRCOUNT />);
                break;
            case 'reportAccount-2':
                setCurrentComponent(<YCRCOUNT />);
                break;
            case 'reportAccount-3':
                setCurrentComponent(<YGRCOUNT />);
                break;
            case 'salaryGiving':
                setCurrentComponent(<SALARYLIST />);
                break;
            case 'approvedReceiptBooks':
                setCurrentComponent(<APPROVEDRECEIPTLIST />);
                break;
            // boarding
            case 'boarding-0':
                setCurrentComponent(<RECEIPTLISTBOARDING />);
                break;
            case 'boarding-1':
                setCurrentComponent(<VOUCHERLISTBOARDING />);
                break;
            case 'boarding-2':
                setCurrentComponent(<ACCEPTMONEY />);
                break;
            // for education
            case 'classPanel':
                setCurrentComponent(<CLASSLIST />);
                break;
            case 'education-0':
                setCurrentComponent(<SIGNSHEET />);
                break;
            case 'education-1':
                setCurrentComponent(<RESULTMANAGEMENT />);
                break;
            case 'education-2':
                setCurrentComponent(<CustomizeGrading />);
                break;
            case 'routine':
                setCurrentComponent(<ROUTINES />);
                break;
            case 'leavesManage':
                setCurrentComponent(<STUDENTLEAVES />);
                break;
            // donation
            case 'donation-0':
                setCurrentComponent(<DONORLIST />);
                break;
            case 'donation-1':
                setCurrentComponent(<DONATIONRECORD />);
                break;
            // library
            case 'library-0':
                setCurrentComponent(<BOOKLIST />);
                break;
            case 'library-1':
                setCurrentComponent(<TOTALBOOKSTOGIVEN />);
                break;
            case 'library-2':
                setCurrentComponent(<RECEIVEBOOKS />);
                break;
            // attendance
            case 'officiants':
                setCurrentComponent(<STUFFATTENDANCELIST />);
                break;
            case 'students':
                setCurrentComponent(<STUDENTATTENDANCELIST />);
                break;
            // for sms services
            case 'messaging':
                setCurrentComponent(<CustomSMSForm />);
                break;
            case 'balanceInquery':
                setCurrentComponent(<BALANCECHECK />);
                break;
            case 'buyAndSell':
                setCurrentComponent(<SELLINRPODUCTS />);
                break;
            case 'soldProductList':
                setCurrentComponent(<SOLDPRODUCTLIST />);
                break;
            // website
            case 'newNotice':
                setCurrentComponent(<NEWNOTICE />);
                break;
            default:
                setCurrentComponent(<MAIN />);
                break;
        }
    };

    return (
        <ComponentContext.Provider value={{ currentComponent, changeComponent }}>
            {children}
        </ComponentContext.Provider>
    );
};

export const useComponent = () => useContext(ComponentContext);
