// React and Hooks
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

// Material UI Components
import {
    Grid, Button, ButtonGroup, Chip, Box, Menu, MenuItem, Dialog
} from '@mui/material';

// MUI Icons
import {
    ListAlt, Edit, CheckCircle, FileDownloadOutlined
} from '@mui/icons-material';

// MUI DataGrid Components
import {
    DataGrid, GridToolbarContainer, GridToolbarQuickFilter,
    GridActionsCellItem, useGridApiContext, gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';

// Custom Components & Utilities
import { MetaData } from '../utils/metaData';
import CustomPagination from '../layout/Pagination';
import useDataGridColumns from '../utils/useDataGridColumns';
import useAuth from '../hooks/UseAuth';

// Redux Actions
import { allProvidedBooks } from '../../actions/libraryActions';
import { providedBooksFormPDF, providedBooksListPdf } from '../../actions/pdfActions';

// Constants
import { UPDATE_PROVIDED_BOOK_RESET } from '../../constants/libraryConstants';

// Forms and Dialogs
import GIVENBOOKFORM from './GivingBookForm';

// Utilities
import dayjs from 'dayjs';

function CustomToolbar() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentRows, setCurrentRows] = React.useState('');
    const { code } = useAuth();

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('providedBookList'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePdf = () => {
        dispatch(providedBooksListPdf(currentRows, code))
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('providedBookList')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function RECEIVEBOOKS() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { code } = useAuth();
    const [selectedId, setSelectedId] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [isAccept, setIsAccept] = useState(false);

    const { provided_books, loading } = useSelector(state => state.providedBooks);
    const { isUpdated } = useSelector(state => state.providedBook);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allProvidedBooks(code))
    }, [dispatch, code])

    useEffect(() => {
        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(allProvidedBooks(code));
            dispatch({ type: UPDATE_PROVIDED_BOOK_RESET });
            setOpen(false);
        }
    }, [dispatch, code, isUpdated, enqueueSnackbar, t])

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'id', headerName: t('idNo'), minWidth: 80, sortable: true, flex: 0 },
        { field: 'name', headerName: t('receiverName') },
        { field: 'classOrLevel', headerName: t('classOrLevel') },
        { field: 'givingDate', headerName: t('givingDate') },
        { field: 'receivingDate', headerName: t('dateForAccept') },
        { field: 'lateFee', headerName: t('lateFee') },

        {
            field: 'actions',
            headerName: t('actions'),
            type: 'actions',
            minWidth: 100,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<CheckCircle />}
                    label="Accept"
                    onClick={() => handleAccept(params.row.id)}
                    sx={{ mx: 0.1 }}
                />,
                <GridActionsCellItem
                    icon={<ListAlt />}
                    label="PDFform"
                    onClick={() => handlePdfForm(params.row.id)}
                    sx={{ mx: 0.1 }}
                />,
                <GridActionsCellItem
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row.id)}
                    sx={{ mx: 0.1 }}
                />
            ],
        },

    ]);

    // Transform provided_books array into the desired format
    const rows = provided_books.map((b, index) => {
        return {
            serial: index + 1,
            id: b.id_no,
            name: b.name,
            classOrLevel: b.class_or_level,
            givingDate: `${dayjs(b.giving_date).format('YYYY-MM-DD')}`,
            receivingDate: `${dayjs(b.receiving_date).format('YYYY-MM-DD')}`,
            lateFee: b.late_fee,
        };
    });

    const handleAccept = (id) => {
        setIsAccept(true)
        setSelectedId(id)
        setOpen(true);
    }

    const handlePdfForm = (id) => {
        const books = provided_books.find(d => d.id_no === id)
        const data = {
            heading: t('providedBookList'),
            columns: [t('serialNo'), t('bookName'), t('bookNo'), t('part')],
            userInfo: { id: books.id_no, name: books.name, classOrLevel: books.class_or_level, givingDate: books.giving_date, receivingDate: books.receiving_date, lateFee: books.late_fee },
            rows: books.books_provided
        }

        dispatch(providedBooksFormPDF(data, code))
    };

    const handleEdit = (id) => {
        setIsAccept(false);
        setSelectedId(id)
        setOpen(true);
    };

    // for open the edit dialouge
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'RECEIVE BOOKS'} />

            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <GIVENBOOKFORM
                    closeDialog={handleClose}
                    {...(!isAccept ? { id: selectedId } : { acceptId: selectedId })}
                />
            </Dialog>

            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                localeText={localeText}
                loading={loading}
                sx={{
                    '& .MuiDataGrid-actionsCell': {
                        gap: '0px'
                    },

                }}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 },
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false
                        },
                    },
                }}

                slots={{
                    // toolbar: CustomToolbar,
                    toolbar: () => <CustomToolbar />,
                    pagination: CustomPagination,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
