export const NEW_REGARDING_REQUEST = 'NEW_REGARDING_REQUEST'
export const NEW_REGARDING_SUCCESS = 'NEW_REGARDING_SUCCESS'
export const NEW_REGARDING_RESET = 'NEW_REGARDING_RESET'
export const NEW_REGARDING_FAIL = 'NEW_REGARDING_FAIL'

export const ALL_REGARDING_REQUEST = 'ALL_REGARDING_REQUEST'
export const ALL_REGARDING_SUCCESS = 'ALL_REGARDING_SUCCESS'
export const ALL_REGARDING_FAIL = 'ALL_REGARDING_FAIL'

export const NEW_RECEIPT_REQUEST = 'NEW_RECEIPT_REQUEST'
export const NEW_RECEIPT_SUCCESS = 'NEW_RECEIPT_SUCCESS'
export const NEW_RECEIPT_RESET = 'NEW_RECEIPT_RESET'
export const NEW_RECEIPT_FAIL = 'NEW_RECEIPT_FAIL'

export const ALL_RECEIPT_REQUEST = 'ALL_RECEIPT_REQUEST'
export const ALL_RECEIPT_SUCCESS = 'ALL_RECEIPT_SUCCESS'
export const ALL_RECEIPT_FAIL = 'ALL_RECEIPT_FAIL'

export const UPDATE_RECEIPT_REQUEST = 'UPDATEL_RECEIPT_REQUEST'
export const UPDATE_RECEIPT_SUCCESS = 'UPDATE_RECEIPT_SUCCESS'
export const UPDATE_RECEIPT_RESET = 'UPDATE_RECEIPT_SUCCESS'
export const UPDATE_RECEIPT_FAIL = 'UPDATE_RECEIPT_FAIL'

export const DELETE_RECEIPT_REQUEST = 'DELETE_RECEIPT_REQUEST'
export const DELETE_RECEIPT_SUCCESS = 'DELETE_RECEIPT_SUCCESS'
export const DELETE_RECEIPT_RESET = 'DELETE_RECEIPT_RESET'
export const DELETE_RECEIPT_FAIL = 'DELETE_RECEIPT_FAIL'

export const COUNTING_DATA_REQUEST = 'COUNTING_DATA_REQUEST'
export const COUNTING_DATA_SUCCESS = 'COUNTING_DATA_SUCCESS'
export const COUNTING_DATA_RESET = 'COUNTING_DATA_RESET'
export const COUNTING_DATA_FAIL = 'COUNTING_DATA_FAIL'

export const SAVE_COUNTING_DATA_REQUEST = 'SAVE_COUNTING_DATA_REQUEST'
export const SAVE_COUNTING_DATA_SUCCESS = 'SAVE_COUNTING_DATA_SUCCESS'
export const SAVE_COUNTING_DATA_FAIL = 'SAVE_COUNTING_DATA_FAIL'

export const SEARCHED_COUNTING_DATA_REQUEST = 'SEARCHED_COUNTING_DATA_REQUEST'
export const SEARCHED_COUNTING_DATA_SUCCESS = 'SEARCHED_COUNTING_DATA_SUCCESS'
export const SEARCHED_COUNTING_DATA_FAIL = 'SEARCHED_COUNTING_DATA_FAIL'
export const SEARCHED_COUNTING_DATA_RESET = 'SEARCHED_COUNTING_DATA_RESET'

export const DELETE_COUNTING_DATA_REQUEST = 'DELETE_COUNTING_DATA_REQUEST'
export const DELETE_COUNTING_DATA_SUCCESS = 'DELETE_COUNTING_DATA_SUCCESS'
export const DELETE_COUNTING_DATA_FAIL = 'DELETE_COUNTING_DATA_FAIL'
export const ACTION_COUNTING_DATA_RESET = 'ACTION_COUNTING_DATA_RESET'

export const NEW_VOUCHER_REQUEST = 'NEW_VOUCHER_REQUEST'
export const NEW_VOUCHER_SUCCESS = 'NEW_VOUCHER_SUCCESS'
export const NEW_VOUCHER_FAIL = 'NEW_VOUCHER_FAIL'

export const ALL_VOUCHER_REQUEST = 'ALL_VOUCHER_REQUEST'
export const ALL_VOUCHER_SUCCESS = 'ALL_VOUCHER_SUCCESS'
export const ALL_VOUCHER_FAIL = 'ALL_VOUCHER_FAIL'

export const UPDATE_VOUCHER_REQUEST = 'UPDATEL_VOUCHER_REQUEST'
export const UPDATE_VOUCHER_SUCCESS = 'UPDATE_VOUCHER_SUCCESS'
export const UPDATE_VOUCHER_RESET = 'UPDATE_VOUCHER_SUCCESS'
export const UPDATE_VOUCHER_FAIL = 'UPDATE_VOUCHER_FAIL'

export const DELETE_VOUCHER_REQUEST = 'DELETE_VOUCHERR_REQUEST'
export const DELETE_VOUCHER_SUCCESS = 'DELETE_VOUCHERR_SUCCESS'
export const DELETE_VOUCHER_RESET = 'DELETE_VOUCHERR_RESET'
export const DELETE_VOUCHER_FAIL = 'DELETE_VOUCHERR_FAIL'

export const NEW_SALARY_REQUEST = 'NEW_SALARY_REQUEST'
export const NEW_SALARY_SUCCESS = 'NEW_SALARY_SUCCESS'
export const NEW_SALARY_FAIL = 'NEW_SALARY_FAIL'
export const NEW_SALARY_RESET = 'NEW_SALARY_RESET'

export const ALL_SALARY_REQUEST = 'ALL_SALARY_REQUEST'
export const ALL_SALARY_SUCCESS = 'ALL_SALARY_SUCCESS'
export const ALL_SALARY_FAIL = 'ALL_SALARY_FAIL'

export const UPDATE_SALARY_REQUEST = 'UPDATE_SALARY_REQUEST'
export const UPDATE_SALARY_SUCCESS = 'UPDATE_SALARY_SUCCESS'
export const UPDATE_SALARY_RESET = 'UPDATE_SALARY_RESET'
export const UPDATE_SALARY_FAIL = 'UPDATE_SALARY_FAIL'
export const DELETE_SALARY_REQUEST = 'DELETE_SALARY_REQUEST'
export const DELETE_SALARY_SUCCESS = 'DELETE_SALARY_SUCCESS'
export const DELETE_SALARY_RESET = 'DELETE_SALARY_RESET'
export const DELETE_SALARY_FAIL = 'DELETE_SALARY_FAIL'

export const NEW_RECEIPT_BOOK_REQUEST = 'NEW_RECEIPT_BOOK_REQUEST'
export const NEW_RECEIPT_BOOK_SUCCESS = 'NEW_RECEIPT_BOOK_SUCCESS'
export const NEW_RECEIPT_BOOK_FAIL = 'NEW_RECEIPT_BOOK_FAIL'
export const NEW_RECEIPT_BOOK_RESET = 'NEW_RECEIPT_BOOK_RESET'
export const ALL_RECEIPT_BOOKS_REQUEST = 'ALL_RECEIPT_BOOKS_REQUEST'
export const ALL_RECEIPT_BOOKS_SUCCESS = 'ALL_RECEIPT_BOOKS_SUCCESS'
export const ALL_RECEIPT_BOOKS_FAIL = 'ALL_RECEIPT_BOOKS_FAIL'
export const UPDATE_RECEIPT_BOOK_REQUEST = 'UPDATE_RECEIPT_BOOK_REQUEST'
export const UPDATE_RECEIPT_BOOK_SUCCESS = 'UPDATE_RECEIPT_BOOK_SUCCESS'
export const UPDATE_RECEIPT_BOOK_FAIL = 'UPDATE_RECEIPT_BOOK_FAIL'
export const DELETE_RECEIPT_BOOK_REQUEST = 'DELETE_RECEIPT_BOOK_REQUEST'
export const DELETE_RECEIPT_BOOK_SUCCESS = 'DELETE_RECEIPT_BOOK_SUCCESS'
export const DELETE_RECEIPT_BOOK_FAIL = 'DELETE_RECEIPT_BOOK_FAIL'
export const DELETE_RECEIPT_BOOK_RESET = 'DELETE_RECEIPT_BOOK_RESET'
export const UPDATE_RECEIPT_BOOK_RESET = 'UPDATE_RECEIPT_BOOK_RESET'

export const NEW_FEE_REQUEST = 'NEW_FEE_REQUEST'
export const NEW_FEE_SUCCESS = 'NEW_FEE_SUCCESS'
export const NEW_FEE_FAIL = 'NEW_FEE_FAIL'
export const NEW_FEE_RESET = 'NEW_FEE_RESET'

export const ALL_FEES_REQUEST = 'ALL_FEES_REQUEST'
export const ALL_FEES_SUCCESS = 'ALL_FEES_SUCCESS'
export const ALL_FEES_FAIL = 'ALL_FEES_FAIL'

export const APPROVED_BOOKS_REQUEST = 'APPROVED_BOOKS_REQUEST'
export const APPROVED_BOOKS_SUCCESS = 'APPROVED_BOOKS_SUCCESS'
export const APPROVED_BOOKS_FAIL = 'APPROVED_BOOKS_FAIL'

export const DELETE_FEE_REQUEST = 'DELETE_FEE_REQUEST'
export const DELETE_FEE_SUCCESS = 'DELETE_FEE_SUCCESS'
export const DELETE_FEE_FAIL = 'DELETE_FEE_FAIL'
export const DELETE_FEE_RESET = 'DELETE_FEE_RESET'

export const UPDATE_FEE_REQUEST = 'UPDATE_FEE_REQUEST'
export const UPDATE_FEE_SUCCESS = 'UPDATE_FEE_SUCCESS'
export const UPDATE_FEE_FAIL = 'UPDATE_FEE_FAIL'
export const UPDATE_FEE_RESET = 'UPDATE_FEE_RESET'

export const NEW_REPORT_REQUEST = 'NEW_REPORT_REQUEST'
export const NEW_REPORT_SUCCESS = 'NEW_REPORT_SUCCESS'
export const NEW_REPORT_FAIL = 'NEW_REPORT_FAIL'
export const NEW_REPORT_RESET = 'NEW_REPORT_RESET'

export const ALL_REPORT_REQUEST = 'ALL_REPORT_REQUEST'
export const ALL_REPORT_SUCCESS = 'ALL_REPORT_SUCCESS'
export const ALL_REPORT_FAIL = 'ALL_REPORT_FAIL'

export const REPORT_BALANCE_REQUEST = 'REPORT_BALANCE_REQUEST'
export const REPORT_BALANCE_SUCCESS = 'REPORT_BALANCE_SUCCESS'
export const REPORT_BALANCE_FAIL = 'REPORT_BALANCE_FAIL'

export const DELETE_REPORT_REQUEST = 'DELETE_REPORT_REQUEST'
export const DELETE_REPORT_SUCCESS = 'DELETE_REPORT_SUCCESS'
export const DELETE_REPORT_FAIL = 'DELETE_REPORT_FAIL'
export const DELETE_REPORT_RESET = 'DELETE_REPORT_RESET'

export const CLEAR_ERRORS = 'CLEAR_ERRORS'


// ----------------------------------------------------------------
export const getFundTypes = (t) => [
    { value: 'generalFund', label: t('generalFund') },
    { value: 'charityFund', label: t('charityFund') },
];

export const getDepartments = (t) => [
    { value: 'accounts', label: t('accounts') },
    { value: 'boarding', label: t('boarding') },
    { value: 'donation', label: t('donation') },
    { value: 'allDepartments', label: t('allDepartments') },
];