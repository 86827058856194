import axios from 'axios';
import {
    CLEAR_ERRORS,
    NEW_ACADEMY_REQUEST,
    NEW_ACADEMY_SUCCESS,
    NEW_ACADEMY_FAIL,
    ALL_ACADEMIES_REQUEST,
    ALL_ACADEMIES_SUCCESS,
    ALL_ACADEMIES_FAIL,
    DELETE_ACADEMY_REQUEST,
    DELETE_ACADEMY_SUCCESS,
    DELETE_ACADEMY_FAIL,
    NEW_EMAIL_VARIFICATION_REQUEST,
    NEW_EMAIL_VARIFICATION_SUCCESS,
    NEW_EMAIL_VARIFICATION_FAIL,
    EMAIL_VARIFY_REQUEST,
    EMAIL_VARIFY_SUCCESS,
    EMAIL_VARIFY_FAIL,
    UPDATE_ACADEMY_REQUEST,
    UPDATE_ACADEMY_SUCCESS,
    UPDATE_ACADEMY_FAIL
} from '../constants/superAdminConstants'


// add new academy 
export const newAcademy = (academicData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_ACADEMY_REQUEST })

        // passing bookData to backend by this route 
        const { data } = await axios.post(`/api/super_admin/academy/new`, academicData)

        // store data in payload 
        dispatch({
            type: NEW_ACADEMY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_ACADEMY_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all academies
export const allAcademies = () => async (dispatch) => {
    try {

        dispatch({ type: ALL_ACADEMIES_REQUEST })

        // connect with backend to get all academies
        const { data } = await axios.post('/api/super_admin/academies')

        // storing all academies in payload
        dispatch({
            type: ALL_ACADEMIES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_ACADEMIES_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

export const updateAcademy = (academyData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_ACADEMY_REQUEST })

        // configuration for register a user 
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        // connect with backend to update academy
        await axios.post(`/api/super_admin/academy/update/${code}`, academyData, config)

        // storing academy in payload
        dispatch({
            type: UPDATE_ACADEMY_SUCCESS,
            payload: 'Academy updated successfully'
        })

    } catch (error) {
        dispatch({
            type: UPDATE_ACADEMY_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Delete academy
export const deleteAcademy = (code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_ACADEMY_REQUEST
        })

        // Sending a delete request to the backend to delete the class
        const { data } = await axios.delete(`/api/super_admin/academies/delete/${code}`)

        // showing success status if the academy is deleted
        dispatch({
            type: DELETE_ACADEMY_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_ACADEMY_FAIL,
            payload: error.response.data.message
        })
    }
}

// This will used for sending varification code again
export const sendEmailVarificationCode = (email) => async (dispatch) => {
    try {

        dispatch({ type: NEW_EMAIL_VARIFICATION_REQUEST })

        // passing bookData to backend by this route 
        const { data } = await axios.post(`/api/super_admin/email/send_code/${email}`)

        // store data in payload 
        dispatch({
            type: NEW_EMAIL_VARIFICATION_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_EMAIL_VARIFICATION_FAIL,
            payload: error.response.data.message
        })
    }
}

export const emailVarify = (otp, email) => async (dispatch) => {
    try {

        dispatch({ type: EMAIL_VARIFY_REQUEST })

        // passing bookData to backend by this route 
        const { data } = await axios.post(`/api/super_admin/email/varify_code/${email}`, otp)

        // store data in payload 
        dispatch({
            type: EMAIL_VARIFY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: EMAIL_VARIFY_FAIL,
            payload: error.response.data.message
        })
    }
}

export const updateAcademyBalances = (academyData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_ACADEMY_REQUEST })

        // connect with backend to update academy
        await axios.post(`/api/super_admin/academy/update_balances/${code}`, academyData)

        // storing academy in payload
        dispatch({
            type: UPDATE_ACADEMY_SUCCESS,
            payload: 'Academy updated successfully'
        })

    } catch (error) {
        dispatch({
            type: UPDATE_ACADEMY_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}