// React and related hooks
import React, { useEffect, useState } from 'react';

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import {
    deleteCounting,
    getCountingData,
    searchCountingData,
    newCounting
} from '../../actions/accountActions';
import { countingPdf } from '../../actions/pdfActions';

// Third-party libraries
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

// MUI components and icons
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';
import {
    Grid,
    Box,
    Button,
    ButtonGroup,
    Typography,
    Menu,
    MenuItem,
    Select,
    IconButton
} from '@mui/material';
import {
    Save,
    Delete,
    ContentPasteSearch,
    ReplayCircleFilled,
    FileDownloadOutlined
} from '@mui/icons-material';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import CustomPagination from '../layout/Pagination';
import { formattedDates } from '../../constants/commonContstants';
import {
    SEARCHED_COUNTING_DATA_RESET,
    COUNTING_DATA_RESET
} from '../../constants/accountConstants';
import useAuth from '../hooks/UseAuth';

function CustomToolbar({ rowData, departType, handlers, metadata }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { type } = useAuth();
    const [currentRows, setCurrentRows] = React.useState('');
    const { countDate } = metadata;
    const { handleData, handleDate, handleRefresh, handleDelete, handleSearch } = handlers;

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };

    // fetch data from redux storage
    const { dates } = useSelector(state => state.allCountingData);
    const { searchedData, searchLoading } = useSelector((state) => state.searchedCountingData);

    // define state
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);

    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // Prepare data for PDF
        const filteredRows = gridFilteredSortedRowIdsSelector(apiRef).map((id) => apiRef.current.getRow(id));

        // Get the current visibility model
        const excludedFields = ['__check__'];
        const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel;
        const headerNames = apiRef.current.getAllColumns()
            .filter(column => !excludedFields.includes(column.field) && columnVisibilityModel[column.field] !== false)  // Exclude unwanted fields and hidden columns
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            type: departType,
            heading: `${t(departType)}, ${countDate}`,
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // for handling date drop down when saving the counting data
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handlePdf = () => {
        dispatch(countingPdf(currentRows));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button
                            aria-label="Delete icon"
                            onClick={handleDelete}
                            disabled={searchedData.length === 0 || type !== 'accountant'}
                        >
                            <Delete />
                        </Button>
                        <Button
                            aria-label="Save button"
                            onClick={handleData}
                            disabled={searchedData.length > 0 || !rowData || type !== 'accountant' ? true : false}
                        >
                            <Save />
                        </Button>
                        <Button
                            aria-label="refresh icon"
                            onClick={handleRefresh}
                            disabled={searchedData.length === 0}
                        >
                            <ReplayCircleFilled />
                        </Button>
                        <Button
                            aria-label="Print icon"
                            onClick={handleDownloadFile}
                            disabled={searchedData.length === 0}
                        >
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} flexDirection={{ sm: 'row', xs: 'column' }} alignItems={'center'} justifyContent={'center'}>
                    <Typography variant="h6">{t(departType)}।</Typography>

                    <Box display={'flex'}>
                        {rowData && searchedData.length === 0 && !searchLoading ?
                            <Select
                                value={countDate}
                                onChange={handleDate}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                size="small"
                                sx={{
                                    '.MuiSelect-select': {
                                        padding: '0 0 0 5px',
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                }}
                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            '& .MuiTypography-root': {
                                                fontSize: '1rem',
                                            }
                                        },
                                    },
                                }}
                            >
                                {Object.entries(formattedDates).map(([key, value]) => (
                                    <MenuItem key={key} value={value}>
                                        <Typography variant="h6">
                                            {value}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                            :
                            <Typography variant="h6" ml={1}>
                                {countDate}
                            </Typography>
                        }

                        {/* <Divider orientation="vertical" flexItem /> */}
                        {/* <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}> */}
                        <IconButton
                            onClick={handleClick2}
                            size="small"
                            color='primary'
                            sx={{ ml: 2 }}
                            aria-controls={open2 ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open2 ? 'true' : undefined}
                        >
                            <ContentPasteSearch />
                        </IconButton>
                        {/* </Box> */}
                        <Menu
                            anchorEl={anchorEl2}
                            id="account-menu"
                            open={open2}
                            onClose={handleClose2}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    maxHeight: 500, // Adjust the max height as needed
                                    overflow: 'auto', // Enable scrolling
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {dates && dates.map((item, index) => (
                                <MenuItem key={index} onClick={() => handleSearch(item.date)}>
                                    {item.date}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3} order={{ sm: 3, xs: 3 }} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default function MONTHLYCOUNT({ countType }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { code } = useAuth();

    // for storing basic data according to the countType
    let type;
    let fund;
    if (countType === 'mci') {
        type = 'mci';
        fund = 'charityFund';
    } else if (countType === 'mgi') {
        type = 'mgi';
        fund = 'generalFund';
    } else if (countType === 'mce') {
        type = 'mce';
        fund = 'charityFund';
    } else if (countType === 'mge') {
        type = 'mge';
        fund = 'generalFund';
    } else {
        type = 'mci';
        fund = 'charityFund';
    }

    // define state
    // fetch data from redux store
    const { countingData, dates, loading } = useSelector(state => state.allCountingData);
    const [countDate, setCountDate] = useState(Object.values(formattedDates)[0]); // for showing current month date by default
    const { searchedData, searchLoading } = useSelector((state) => state.searchedCountingData);
    const { isSavedOrDeleted, loadingAction } = useSelector((state) => state.countingDataAction);

    useEffect(() => {
        const data = {
            type: type,
            fund: fund
        };
        dispatch(getCountingData(data, code));
        dispatch({ type: SEARCHED_COUNTING_DATA_RESET })
    }, [type, fund, dispatch, code]);

    useEffect(() => {
        if (isSavedOrDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            const data = {
                type: type,
                fund: fund
            };
            dispatch(getCountingData(data, code));
            dispatch({ type: COUNTING_DATA_RESET })
            dispatch({ type: SEARCHED_COUNTING_DATA_RESET })
        }
    }, [dispatch, fund, isSavedOrDeleted, type, enqueueSnackbar, t, code]);

    // Preprocess data with numbered regarding columns and descriptive headers
    const preprocessData = (data) => {
        if (type === 'mci' || type === 'mgi') {
            const books = {};
            const uniqueRegarding = {};

            // Assign indices to each unique regarding entry
            let regardingIndex = 0;

            data.forEach((item) => {
                // const { book_no, receipt_no_from, receipt_no_till, regarding, amount, date } = item;
                const { book_no, receipt_no_till, regarding, amount, date } = item;

                // Assign an index if it's a new regarding entry
                if (!(regarding in uniqueRegarding)) {
                    uniqueRegarding[regarding] = regardingIndex++;
                }

                // Initialize book entry if not yet present
                if (!books[book_no]) {
                    books[book_no] = {
                        book_no,
                        // receipt_no_from,
                        // receipt_no_till,
                        receipt_no_tills: new Set(),  // Use a Set to store unique receipt_no_till values
                        date,
                        total: 0,
                        amounts: {}
                    };
                }
                // else {
                //     // Update receipt_no_from and receipt_no_till to track min and max values
                //     books[book_no].receipt_no_from = Math.min(books[book_no].receipt_no_from, receipt_no_from);
                //     books[book_no].receipt_no_till = Math.max(books[book_no].receipt_no_till, receipt_no_till);
                // }

                // Add receipt_no_till to the set
                books[book_no].receipt_no_tills.add(receipt_no_till);

                // Add amount to the book's total
                books[book_no].total += amount;

                // Use the regarding index as the column field
                const regardingField = uniqueRegarding[regarding];
                books[book_no].amounts[regardingField] = (books[book_no].amounts[regardingField] || 0) + amount;
            });

            // Convert books object to rows
            const rows = Object.values(books).map((book, index) => {
                const receiptNos = Array.from(book.receipt_no_tills).sort((a, b) => a - b);
                const minReceipt = receiptNos[0];
                const maxReceipt = receiptNos[receiptNos.length - 1];

                const row = {
                    id: index + 1,
                    book_no: book.book_no,
                    // Display min-max for the user
                    receipt_no: `${minReceipt}-${maxReceipt}`,
                    // receipt_no: `${book.receipt_no_from}-${book.receipt_no_till}`,
                    // receipt_no: Array.from(book.receipt_no_tills).sort((a, b) => a - b).join(','),
                    // Add a detailed_receipt_no field for logging purposes
                    detailed_receipt_no: receiptNos.join(','),
                    date: dayjs(book.date).format('YYYY-MM-DD'),
                    total: book.total,
                    ...book.amounts
                };
                return row;
            });

            // create summary row
            const summaryRow = {
                id: '\u00A0', // Non-breaking space for handling unique id error
                receipt_no: t('totalCount'),
                total: rows.reduce((sum, row) => sum + row.total, 0),
                date: t('allTotal'),
            };

            // Calculate summary for each regarding field
            Object.keys(uniqueRegarding).forEach((regarding) => {
                const regardingField = uniqueRegarding[regarding];
                summaryRow[regardingField] = rows.reduce((sum, row) => sum + (row[regardingField] || 0), 0);
            });

            const finalRows = [
                ...rows,
                summaryRow
            ];

            return { rows: finalRows, columns: uniqueRegarding };
        } else { // for mce, mge
            // Step 1: Map unique regarding to numeric fields
            const uniqueRegarding = {};
            let index = 0;

            data.forEach((voucher) => {
                Object.keys(voucher).forEach((key) => {
                    if (key.startsWith('regarding') && voucher[key] && voucher[`amount${key.slice(-1)}`]) {
                        const regardingName = voucher[key];
                        if (!(regardingName in uniqueRegarding)) {
                            uniqueRegarding[regardingName] = index.toString(); // Map regarding name to "0", "1", etc.
                            index++;
                        }
                    }
                });
            });

            // Step 2: Generate rows with numeric regarding fields
            const rows = [];
            const summary = {};

            data.forEach((voucher, rowIndex) => {
                const row = {
                    id: rowIndex + 1,
                    voucher_no: voucher.voucher_no,
                    date: dayjs(voucher.date).format('YYYY-MM-DD'),
                    total: parseFloat(voucher.total),
                };

                Object.keys(voucher).forEach((key) => {
                    if (key.startsWith('regarding') && voucher[key] && voucher[`amount${key.slice(-1)}`]) {
                        const regardingName = voucher[key];
                        const amount = parseFloat(voucher[`amount${key.slice(-1)}`]);

                        // Use numeric field mapped in uniqueRegarding
                        const field = uniqueRegarding[regardingName];
                        row[field] = (row[field] || 0) + amount;

                        // Add to summary
                        summary[field] = (summary[field] || 0) + amount;
                    }
                });

                rows.push(row);
            });

            // Step 3: Create summary row
            const summaryRow = {
                id: '\u00A0',
                voucher_no: t('totalCount'),
                date: t('allTotal'),
                total: rows.reduce((acc, row) => acc + row.total, 0),
                ...summary
            };
            rows.push(summaryRow);

            return { rows: rows.length > 0 ? rows : [], columns: uniqueRegarding };
        }
    };

    // Call the function with your data
    const { rows, columns } = preprocessData(countingData);

    // Define columns for DataGrid
    let gridColumns;
    if (type === 'mci' || type === 'mgi') {
        gridColumns = [
            { field: 'id', headerName: t('serialNo'), headerAlign: 'center', sortable: true, flex: 0, width: 80 },
            { field: 'book_no', headerName: t('bookNo'), width: 80, headerAlign: 'center', disableColumnMenu: true, flex: 0 },
            { field: 'receipt_no', headerName: t('receiptNo'), width: 80, headerAlign: 'center', disableColumnMenu: true, sortable: false },
            ...Object.entries(columns).map(([regarding, index]) => ({
                field: `${index}`,
                headerName: regarding,
                flex: 1,
                headerAlign: 'center',
                minWidth: 100,
                disableColumnMenu: true, sortable: false
            })),
            { field: 'date', headerName: t('date'), width: 100, headerAlign: 'center', disableColumnMenu: true, sortable: false },
            { field: 'total', headerName: t('total'), width: 80, headerAlign: 'center', disableColumnMenu: true, sortable: false },
        ];
    } else {
        gridColumns = [
            { field: 'id', headerName: t('serialNo'), width: 80, headerAlign: 'center', sortable: true },
            { field: 'voucher_no', headerName: t('voucherNo'), width: 80, headerAlign: 'center', disableColumnMenu: true },
            ...Object.entries(columns).map(([regarding, index]) => ({
                field: `${index}`,
                headerName: regarding,
                flex: 1,
                minWidth: 100,
                headerAlign: 'center',
                disableColumnMenu: true, sortable: false
            })),
            { field: 'date', headerName: t('date'), width: 100, headerAlign: 'center', disableColumnMenu: true, sortable: false },
            { field: 'total', headerName: t('total'), width: 80, headerAlign: 'center', disableColumnMenu: true, sortable: false },
        ]
    }

    // prepare columns to save
    const transformColumns = () => {
        return gridColumns.reduce((acc, col) => {
            acc[col.field] = col.headerName;
            return acc;
        }, {});
    };

    const saveData = () => {
        // checking duplicate
        const duplicate = dates.find(count => count.type === type && count.date === countDate)
        if (duplicate) return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });;

        const transformedColumns = transformColumns();

        const data = {
            date: countDate,
            type: type,
            fund: fund,
            columns: transformedColumns,
            rows: rows
        }

        dispatch(newCounting(data, code));
    };

    const changeDate = (e) => {
        setCountDate(e.target.value);
    };

    // prepare searched data to show on the table
    // Extract columns dynamically from headings
    const prossesSearchedData = (data) => {
        if (data.length > 0) {
            const headings = data[0].headings;

            // Define static columns in the specified order
            let staticColumns;
            if (type === 'mci' || type === 'mgi') {
                staticColumns = [
                    { field: 'id', headerName: t('serialNo'), headerAlign: 'center', sortable: true, flex: 0, width: 80 },
                    { field: 'book_no', headerName: t('bookNo'), width: 80, headerAlign: 'center', disableColumnMenu: true, flex: 0 },
                    { field: 'receipt_no', headerName: t('receiptNo'), width: 80, headerAlign: 'center', disableColumnMenu: true, sortable: false },
                ];
            } else {
                staticColumns = [
                    { field: 'id', headerName: t('serialNo'), width: 80, headerAlign: 'center', sortable: true },
                    { field: 'voucher_no', headerName: t('voucherNo'), width: 80, headerAlign: 'center', disableColumnMenu: true },
                ];
            }

            // Dynamically add numbered columns (e.g., '0', '1', '2', ...)
            const dynamicNumberedColumns = Object.keys(headings)
                .filter((key) => !isNaN(key))  // Filter keys that are numeric ('0', '1', etc.)
                .map((key) => ({
                    field: key,
                    headerName: headings[key],
                    flex: 1,
                    minWidth: 100,
                    headerClassName: 'CustomHeader',
                    headerAlign: 'center',
                    disableColumnMenu: true, sortable: false
                }));

            // Define remaining static columns for date and total
            const remainingColumns = [
                { field: 'date', headerName: t('date'), width: 100, headerAlign: 'center', disableColumnMenu: true, sortable: false },
                { field: 'total', headerName: t('total'), width: 80, headerAlign: 'center', disableColumnMenu: true, sortable: false },
            ];

            // Combine all columns in the desired order
            const scolumns = [
                ...staticColumns,
                ...dynamicNumberedColumns,
                ...remainingColumns,
            ];

            // Generate rows from counts
            const srows = data[0].counts.map((item) => ({
                id: item.id,
                ...item
            }));

            return { scolumns, srows }
        }
        return { scolumns: [], srows: [] }
    };
    const { scolumns, srows } = prossesSearchedData(searchedData)

    const refreshData = () => {
        const data = {
            type: type,
            fund: fund,
        };
        dispatch(getCountingData(data, code));
        dispatch({ type: SEARCHED_COUNTING_DATA_RESET })
    }

    // for change mui defualt localtext of row selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: (type === 'mci' || type === 'mgi') ? t('monthlyTextIncome') : t('monthlyTextExpense'),
        noResultsOverlaycibel: t('sorryNotFound'),
    };

    let currentRows;
    let currentColumns;
    if (srows.length > 0) {
        currentRows = srows
        currentColumns = scolumns
    } else if (rows.length > 1) {
        currentRows = rows
        currentColumns = gridColumns
    } else {
        currentRows = []
        currentColumns = []
    }

    const deleteData = () => {
        const bookNo = currentRows.slice(0, -1).map(row => row.book_no);
        // const receiptNo = type === 'mci' || type === 'mgi' ?
        //     currentRows.slice(0, -1).map(row => row.receipt_no.split('-')[1]) :
        //     currentRows.slice(0, -1).map(row => row.voucher_no);

        const receiptNo = type === 'mci' || type === 'mgi' ?
            currentRows.slice(0, -1).map(row => row.detailed_receipt_no) :
            currentRows.slice(0, -1).map(row => row.voucher_no);

        const data = {
            fund: fund,
            type: type,
            date: countDate,
            bookNo: bookNo,
            receiptNo: receiptNo,
        }

        dispatch(deleteCounting(data, code));
    };

    const searchData = (value) => {
        setCountDate(value)
        dispatch(searchCountingData({ type: type, date: value }, code));
    };

    // define props for passing to  the toolbar
    const toolbarProps = {
        rowData: rows.length > 1 ? true : false, // 1 for summery all times
        basicData: 'basicValues',
        departType: type,
        handlers: {
            handleData: saveData,
            handleDate: changeDate,
            handleRefresh: refreshData,
            handleDelete: deleteData,
            handleSearch: searchData
        },
        metadata: {
            countDate
        },
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'MONTHLY COUNTS'} />
            <DataGrid
                columns={currentColumns}
                rows={currentRows}
                getRowClassName={(params) => {
                    return params.indexRelativeToCurrentPage === currentRows.length - 1 ? 'summary-row' : '';
                }}
                density={'compact'}
                loading={loading || searchLoading || loadingAction}
                pageSizeOptions={[5, 10, 20, 50, 100]} // Custom rows per page options
                checkboxSelection
                scrollbarSize={0} // it's remove the empty space of toolbar
                localeText={localeText}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                sx={{
                    '& .MuiDataGrid-cell': {
                        textAlign: 'center',
                    }
                }}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 },
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar {...toolbarProps} />,
                    pagination: CustomPagination
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}