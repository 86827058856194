export const NEW_DONOR_REQUEST = 'NEW_DONOR_REQUEST'
export const NEW_DONOR_SUCCESS = 'NEW_DONOR_SUCCESS'
export const NEW_DONOR_RESET = 'NEW_DONOR_RESET'
export const NEW_DONOR_FAIL = 'NEW_DONOR_FAIL'
export const ALL_DONORS_REQUEST = 'ALL_DONORS_REQUEST'
export const ALL_DONORS_SUCCESS = 'ALL_DONORS_SUCCESS'
export const ALL_DONORS_FAIL = 'ALL_DONORS_FAIL'
export const UPDATE_DONOR_REQUEST = 'UPDATE_DONOR_REQUEST'
export const UPDATE_DONOR_FAIL = 'UPDATE_DONOR_FAIL'
export const UPDATE_DONOR_SUCCESS = 'UPDATE_DONOR_SUCCESS'
export const UPDATE_DONOR_RESET = 'UPDATE_DONOR_RESET'
export const DELETE_DONOR_REQUEST = 'DELETE_DONOR_REQUEST'
export const DELETE_DONOR_FAIL = 'DELETE_DONOR_FAIL'
export const DELETE_DONOR_SUCCESS = 'DELETE_DONOR_SUCCESS'
export const DELETE_DONOR_RESET = 'DELETE_DONOR_RESET'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const NEW_ACCEPT_DONATION_REQUEST = 'NEW_ACCEPT_DONATION_REQUEST'
export const NEW_ACCEPT_DONATION_SUCCESS = 'NEW_ACCEPT_DONATION_SUCCESS'
export const NEW_ACCEPT_DONATION_FAIL = 'NEW_ACCEPT_DONATION_FAIL'
export const NEW_ACCEPT_DONATION_RESET = 'NEW_ACCEPT_DONATION_RESET'
export const ALL_DONATIONS_REQUEST = 'ALL_DONATIONS_REQUEST'
export const ALL_DONATIONS_SUCCESS = 'ALL_DONATIONS_SUCCESS'
export const ALL_DONATIONS_FAIL = 'ALL_DONATIONS_FAIL'

export const DELETE_DONATION_REQUEST = 'DELETE_DONATION_REQUEST'
export const DELETE_DONATION_SUCCESS = 'DELETE_DONATION_SUCCESS'
export const DELETE_DONATION_FAIL = 'DELETE_DONATION_FAIL'
export const DELETE_DONATION_RESET = 'DELETE_DONATION_RESET'