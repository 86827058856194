export const NEW_ACADEMY_REQUEST = 'NEW_ACADEMY_REQUEST'
export const NEW_ACADEMY_SUCCESS = 'NEW_ACADEMY_SUCCESS'
export const NEW_ACADEMY_RESET = 'NEW_ACADEMY_RESET'
export const NEW_ACADEMY_FAIL = 'NEW_ACADEMY_FAIL'
export const ALL_ACADEMIES_REQUEST = 'ALL_ACADEMIES_REQUEST'
export const ALL_ACADEMIES_SUCCESS = 'ALL_ACADEMIES_SUCCESS'
export const ALL_ACADEMIES_FAIL = 'ALL_ACADEMIES_FAIL'

export const UPDATE_ACADEMY_REQUEST = 'UPDATE_ACADEMY_REQUEST'
export const UPDATE_ACADEMY_FAIL = 'UPDATE_ACADEMY_FAIL'
export const UPDATE_ACADEMY_SUCCESS = 'UPDATE_ACADEMY_SUCCESS'
export const UPDATE_ACADEMY_RESET = 'UPDATE_ACADEMY_RESET'

export const DELETE_ACADEMY_REQUEST = 'DELETE_ACADEMY_REQUEST'
export const DELETE_ACADEMY_FAIL = 'DELETE_ACADEMY_FAIL'
export const DELETE_ACADEMY_SUCCESS = 'DELETE_ACADEMY_SUCCESS'
export const DELETE_ACADEMY_RESET = 'DELETE_ACADEMY_RESET'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const NEW_EMAIL_VARIFICATION_REQUEST = 'NEW_EMAIL_VARIFICATION_REQUEST'
export const NEW_EMAIL_VARIFICATION_SUCCESS = 'NEW_EMAIL_VARIFICATION_SUCCESS'
export const NEW_EMAIL_VARIFICATION_FAIL = 'NEW_EMAIL_VARIFICATION_FAIL'

export const EMAIL_VARIFY_REQUEST = 'EMAIL_VARIFY_REQUEST'
export const EMAIL_VARIFY_SUCCESS = 'EMAIL_VARIFY_SUCCESS'
export const EMAIL_VARIFY_FAIL = 'EMAIL_VARIFY_FAIL'