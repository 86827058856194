// React and related hooks
import React, { useEffect, useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// MUI components and icons
import { Box, Grid, Button, Container, CssBaseline, TextField, MenuItem, Stack } from '@mui/material';
import { InputAdornment } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';
import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Utilities and hooks
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import useAuth from '../hooks/UseAuth';

// Custom components and actions
import { MetaData } from '../utils/metaData';
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { allClass } from '../../actions/eduActions';
import { admitCardtPdf, signSheetPdf } from '../../actions/pdfActions';

export const SIGNSHEET = () => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const { code } = useAuth();
    const isFirstRender = useRef(true);
    const [clickedButton, setClickedButton] = useState('');

    // useEffect is used to work these functionality in one time
    const { classes } = useSelector(state => state.allClass);
    useEffect(() => {
        if (isFirstRender) {
            isFirstRender.current = false;
            dispatch(allClass(code))
        }
    }, [dispatch, code])

    const departments = [...new Set(classes?.map(departItem => departItem.department))];
    const classnames = [...new Set(classes?.map(classItem => classItem.class_name))];

    const defaultValues = {
        examName: '',
        bookName: '',
        department: '',
        className: '',
        examDate: '',
        examTime: ''
    };

    const { handleSubmit, control } = useForm({ defaultValues });

    const handleClick = (buttonType) => {
        setClickedButton(buttonType);
    };

    const onSubmit = (data) => {
        data.examDate = dayjs(data.examDate).format('YYYY-MM-DD');
        data.examTime = dayjs(data.examTime).format('H-mm A');
        // Check which button was clicked
        if (clickedButton === 'admitCard') {
            dispatch(admitCardtPdf({ data: data }, code))
        } else if (clickedButton === 'signSheet') {
            dispatch(signSheetPdf({ data: data }, code))
        } else if (clickedButton === 'numberSheet') {
            dispatch(signSheetPdf({
                numberSheet: 'true',
                data: data
            }, code))
        }
    };

    return (
        <Container component="main" maxWidth="sm" sx={{
            borderRadius: '10px',
            paddingTop: '1.5rem',
            bgcolor: 'background.paper',
            boxShadow: '0px 0px 15px 1px #e0ecff',
            margin: 'auto'
        }}>
            <MetaData title={'EXAM PAPERS'} />
            <RECEIPTHEADING text={t('papersMakingForm')} department={t('education')} />
            <CssBaseline />
            <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' width={'100%'} marginTop={2} paddingBottom={2}>
                <Grid container>
                    {['examName', 'bookName', 'department', 'className', 'examDate', 'examTime'].map((lbl, index) => (
                        <Grid item xs={['examName', 'bookName'].includes(lbl) ? 12 : 6} key={index}>
                            {['department', 'className'].includes(lbl) ? (
                                <Controller
                                    name={lbl}
                                    control={control}
                                    render={({ field: { onChange, value = '', ...field } }) => (
                                        <TextField
                                            select
                                            value={value}
                                            onChange={(e) => onChange(e.target.value)}
                                            size="small"
                                            fullWidth
                                            sx={{ '& .MuiOutlinedInput-root': { borderRadius: 0 }, '& .css-125z06y-MuiInputBase-root-MuiOutlinedInput-root': { paddingLeft: '6px' } }}
                                            InputProps={{ startAdornment: <InputAdornment position="start">{t(lbl)}:</InputAdornment> }}
                                            {...field}
                                        >
                                            {lbl === 'department'
                                                ? departments.map((department, index) => <MenuItem key={index} value={department}>{department}</MenuItem>)
                                                : classnames.map((classname, index) => <MenuItem key={index} value={classname}>{classname}</MenuItem>)
                                            }
                                        </TextField>
                                    )}
                                />
                            ) : lbl === 'examDate' ? (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <DatePicker
                                                {...field}
                                                value={field.value ? dayjs(field.value) : null}
                                                format="YYYY-MM-DD"
                                                slotProps={{ textField: { size: 'small', fullWidth: true, required: true, InputProps: { startAdornment: <InputAdornment position="start">{t(lbl)}:</InputAdornment> } } }}
                                                sx={{ '& .MuiOutlinedInput-root': { borderRadius: 0 }, '& .css-1ajggw8-MuiInputBase-root-MuiOutlinedInput-root': { paddingLeft: '6px' } }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            ) : lbl === 'examTime' ? (
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <Controller
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TimePicker
                                                {...field}
                                                value={field.value ? dayjs(field.value) : null}
                                                slotProps={{ textField: { size: 'small', fullWidth: true, required: true, InputProps: { startAdornment: <InputAdornment position="start">{t(lbl)}:</InputAdornment> } } }}
                                                sx={{ '& .MuiOutlinedInput-root': { borderRadius: 0 }, '& .css-1ajggw8-MuiInputBase-root-MuiOutlinedInput-root': { paddingLeft: '6px' } }}
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            ) : (
                                <Controller
                                    name={lbl}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            required={['examName'].includes(lbl)}
                                            {...field}
                                            size="small"
                                            fullWidth
                                            InputProps={{
                                                style: { borderRadius: 0, paddingLeft: '6px' },
                                                startAdornment: <InputAdornment position="start">{t(lbl)}:</InputAdornment>,
                                            }}
                                        />
                                    )}
                                />
                            )}
                        </Grid>
                    ))}
                </Grid>


                <Stack direction="row" spacing={2} mt={2} justifyContent={'right'}>
                    <Button
                        variant="outlined"
                        type="submit"
                        startIcon={<FileDownloadOutlined />}
                        onClick={() => handleClick('admitCard')}
                    >
                        {t('admitCard')}
                    </Button>
                    <Button
                        variant="outlined"
                        type="submit"
                        startIcon={<FileDownloadOutlined />}
                        onClick={() => handleClick('signSheet')}
                    >
                        {t('signSheet')}
                    </Button>
                    <Button
                        variant="outlined"
                        type="submit"
                        startIcon={<FileDownloadOutlined />}
                        onClick={() => handleClick('numberSheet')}
                    >
                        {t('numberSheet')}
                    </Button>
                </Stack>

            </Box>
        </Container >
    )
}