// React and related hooks
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
    gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';
import {
    Grid,
    Button,
    ButtonGroup,
    Typography,
    Box,
    Menu,
    MenuItem,
    Select,
    IconButton
} from '@mui/material';
import {
    Save,
    Delete,
    ContentPasteSearch,
    ReplayCircleFilled,
    FileDownloadOutlined
} from '@mui/icons-material';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import CustomPagination from '../layout/Pagination';
import useAuth from '../hooks/UseAuth';

// Actions and constants
import {
    deleteCounting,
    getCountingData,
    searchCountingData,
    newCounting
} from '../../actions/accountActions';
import { countingPdf } from '../../actions/pdfActions';
import {
    COUNTING_DATA_RESET,
    SEARCHED_COUNTING_DATA_RESET
} from '../../constants/accountConstants';

// Utilities
import { formattedDatesYear } from '../../constants/commonContstants';

function CustomToolbar({ rowData, handlers, departType, metadata }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { type } = useAuth();
    const { handleData, handleDate, handleSearch, handleRefresh, handleDelete } = handlers;
    const { countDate } = metadata;
    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };

    // fetch data from redux storage
    const { dates } = useSelector(state => state.allCountingData);
    const { searchedData, searchLoading } = useSelector((state) => state.searchedCountingData);

    // define state
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const [anchorEl2, setAnchorEl2] = useState(null);
    const open2 = Boolean(anchorEl2);

    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for handling print functionality 
    // for prints option selection
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // Prepare data for PDF
        const filteredRows = gridFilteredSortedRowIdsSelector(apiRef).map((id) => apiRef.current.getRow(id));

        // Get the current visibility model
        const excludedFields = ['__check__'];
        const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel;
        const headerNames = apiRef.current.getAllColumns()
            .filter(column => !excludedFields.includes(column.field) && columnVisibilityModel[column.field] !== false)  // Exclude unwanted fields and hidden columns
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            type: departType,
            heading: `${t(departType)}, ${countDate}`,
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // for handling date drop down when saving the counting data
    const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handlePdf = () => {
        dispatch(countingPdf(currentRows));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button
                            aria-label="Print icon"
                            onClick={handleDelete}
                            disabled={searchedData.length === 0 || type !== 'accountant'}
                        >
                            <Delete />
                        </Button>
                        <Button
                            aria-label="Add icon"
                            onClick={handleData}
                            disabled={searchedData.length > 0 || !rowData || type !== 'accountant' ? true : false}
                        >
                            <Save />
                        </Button>
                        <Button
                            aria-label="refresh icon"
                            onClick={handleRefresh}
                            disabled={searchedData.length === 0}
                        >
                            <ReplayCircleFilled />
                        </Button>
                        <Button
                            aria-label="Print icon"
                            onClick={handleDownloadFile}
                            disabled={searchedData.length === 0}
                        >
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item>
                    <Box display='flex' justifyContent='center'>
                        <Typography variant="h6">{t(departType)}।</Typography>
                        {rowData && searchedData.length === 0 && !searchLoading ?
                            <Select
                                value={countDate}
                                onChange={handleDate}
                                displayEmpty
                                inputProps={{ 'aria-label': 'Without label' }}
                                size="small"
                                sx={{
                                    '.MuiSelect-select': {
                                        'padding': '0 0 0 5px',
                                        'display': 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center'
                                    },
                                    '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none'
                                    },
                                }}
                            >
                                {Object.entries(formattedDatesYear).map(([key, value]) => (
                                    <MenuItem key={key} value={value}>
                                        <Typography variant="h6">
                                            {value}
                                        </Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                            :
                            <Typography variant="h6" ml={1}>
                                {countDate}
                            </Typography>
                        }

                        {/* <Divider orientation="vertical" flexItem /> */}
                        <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                            <IconButton
                                onClick={handleClick2}
                                size="small"
                                color='primary'
                                sx={{ ml: 2 }}
                                aria-controls={open2 ? 'account-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open2 ? 'true' : undefined}
                            >
                                <ContentPasteSearch />
                            </IconButton>
                        </Box>
                        <Menu
                            anchorEl={anchorEl2}
                            id="account-menu"
                            open={open2}
                            onClose={handleClose2}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    maxHeight: 500, // Adjust the max height as needed
                                    overflow: 'auto', // Enable scrolling
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            {dates && dates.map((item, index) => (
                                <MenuItem key={index} onClick={() => handleSearch(item.date)}>
                                    {item.date}
                                </MenuItem>
                            ))}
                        </Menu>
                    </Box>
                </Grid>
                <Grid item xs={6} sm={3} order={{ sm: 3, xs: 3 }} sx={{ display: 'flex', justifyContent: 'end' }}>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default function YEARLYCOUNT({ countType }) {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const { code } = useAuth();
    // for change mui defualt localtext of row selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('yearlyText'),
        noResultsOverlaycibel: t('sorryNotFound'),
    };

    // for storing basic data according to the countType
    let monthlyType;
    let type;
    if (countType === 'yci') {
        monthlyType = 'mci';
        type = 'yci';
    } else if (countType === 'ygi') {
        monthlyType = 'mgi';
        type = 'ygi';
    } else if (countType === 'yce') {
        monthlyType = 'mce';
        type = 'yce';
    } else if (countType === 'yge') {
        monthlyType = 'mge';
        type = 'yge';
    } else {
        monthlyType = 'mci';
        type = 'yci';
    }

    // fetch data from redux store
    const { countingData, dates, loading } = useSelector(state => state.allCountingData);
    const [countDate, setCountDate] = useState(Object.values(formattedDatesYear)[0]);
    const { searchedData, searchLoading } = useSelector((state) => state.searchedCountingData);
    const { isSavedOrDeleted, loadingAction } = useSelector((state) => state.countingDataAction);

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current) { // for render only first time of component rendering
            isFirstRender.current = false;
            const data = {
                type: type,
                monthlyType: monthlyType
            };
            dispatch(getCountingData(data, code));
            dispatch({ type: SEARCHED_COUNTING_DATA_RESET })
        }
    }, [dispatch, monthlyType, type, code]);

    // useEffect is used to run this functionality whenever the state changes
    useEffect(() => {
        if (isSavedOrDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            const data = {
                type: type,
                monthlyType: monthlyType
            };
            dispatch(getCountingData(data, code));
            dispatch({ type: COUNTING_DATA_RESET })
            dispatch({ type: SEARCHED_COUNTING_DATA_RESET })
        }
    }, [dispatch, enqueueSnackbar, isSavedOrDeleted, t, type, monthlyType, code]);

    // this is for showing data by default
    // Extract unique column names dynamically
    // Define excluded fields to ignore
    const excludedFields = ['book_no', 'date', 'id', 'receipt_no', 'total', 'voucher_no'];
    // Extract unique values from numbered headings only, ignoring excluded fields
    const uniqueHeadings = Array.from(new Set(
        countingData?.flatMap(item =>
            item.headings // Check if headings exists
                ? Object.keys(item.headings)
                    .filter(key => !excludedFields.includes(key) && !isNaN(key)) // Keep only numeric keys, ignore excluded fields
                    .map(key => item.headings[key]) // Map to their values
                : [] // If headings is undefined or null, return an empty array
        )
    ));

    // Prepare rows dynamically
    const rows = countingData.map((data, index) => {
        const row = { id: index + 1, date: data.date.split('-')[0], total: 0 }; // Split the date and get the first part

        uniqueHeadings.forEach((heading, fieldIndex) => {
            // Using fieldIndex to match the column field numbering
            row[fieldIndex] = data.counts.slice(-1).reduce((sum, count) => { // slice 1 because last row is the summary row
                const key = Object.keys(data.headings).find(k => data.headings[k] === heading);
                return sum + (count[key] || 0);
            }, 0);
        });

        // Calculate the total for each row
        // row.total = Object.values(row).reduce((sum, value) => (typeof value === 'number' ? sum + value : sum), 0);
        row.total = Object.entries(row).reduce((sum, [key, value]) => ( // this method skip the id value
            typeof value === 'number' && key !== 'id' ? sum + value : sum
        ), 0);
        return row;
    });

    // Add summary row
    const summaryRow = {
        id: 'summary',
        month: t('totalCount'),
        date: t('allTotal'),
        total: rows.reduce((sum, row) => sum + row.total, 0),
    };
    uniqueHeadings.forEach((heading, fieldIndex) => {
        // Using fieldIndex to match the column field numbering
        summaryRow[fieldIndex] = rows.reduce((sum, row) => sum + (row[fieldIndex] || 0), 0);
    });
    rows.push(summaryRow);

    // Columns for DataGrid
    const columns = [
        { field: 'date', headerName: t('month'), width: 100, headerAlign: 'center' },
        ...Array.from(uniqueHeadings).map((heading, index) => ({
            field: index.toString(), // Sequential field numbers as strings
            headerName: heading,      // Original heading as headerName
            flex: 1,
            headerAlign: 'center',
            headerClassName: 'CustomHeader',
            cellClassName: 'customCell'
        })),
        // { field: 'date', headerName: t('date'), width: 100, headerAlign: 'center', cellClassName: 'customCell' },
        { field: 'total', headerName: t('total'), width: 150, headerAlign: 'center', cellClassName: 'customCell' }
    ];

    // prepare searched data to show on the table
    // Extract columns dynamically from headings
    const prossesSearchedData = (data) => {
        if (data.length > 0) {
            const headings = data[0].headings;

            // Define static columns in the specified order
            let staticColumns;
            staticColumns = [
                { field: 'date', headerName: t('month'), width: 80, headerAlign: 'center' },
            ];

            // Dynamically add numbered columns (e.g., '0', '1', '2', ...)
            const dynamicNumberedColumns = Object.keys(headings)
                .filter((key) => !isNaN(key))  // Filter keys that are numeric ('0', '1', etc.)
                .map((key) => ({
                    field: key,
                    headerName: headings[key],
                    flex: 1,
                    headerClassName: 'CustomHeader',
                    headerAlign: 'center',
                    type: 'number',
                    cellClassName: 'customCell'
                }));

            // Define remaining static columns for date and total
            const remainingColumns = [
                // { field: 'date', headerName: t('date'), width: 150, headerAlign: 'center', cellClassName: 'customCell' },
                { field: 'total', headerName: t('total'), width: 80, headerAlign: 'center', cellClassName: 'customCell', type: 'number' }
            ];

            // Combine all columns in the desired order
            const scolumns = [
                ...staticColumns,
                ...dynamicNumberedColumns,
                ...remainingColumns,
            ];

            // Generate rows from counts
            const srows = data[0].counts.map((item) => ({
                id: item.id,
                ...item
            }));

            return { scolumns, srows }
        }
        return { scolumns: [], srows: [] }
    };
    const { scolumns, srows } = prossesSearchedData(searchedData)

    const changeDate = (e) => {
        setCountDate(e.target.value);
    };

    // prepare columns to save
    const transformColumns = () => {
        return columns.reduce((acc, col) => {
            acc[col.field] = col.headerName;
            return acc;
        }, {});
    };
    const saveData = () => {
        // checking duplicate
        const duplicate = dates.find(count => count.type === type && count.date === countDate)
        if (duplicate) return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });;

        const transformedColumns = transformColumns();

        const data = {
            date: countDate,
            type: type,
            monthlyType: monthlyType,
            columns: transformedColumns,
            rows: rows
        }

        dispatch(newCounting(data, code));
    };

    const searchData = (value) => {
        setCountDate(value)
        dispatch(searchCountingData({ type: type, date: value }, code))
    };

    const refreshData = () => {
        const data = {
            type: type,
            monthlyType: monthlyType
        };
        dispatch(getCountingData(data, code));
        dispatch({ type: SEARCHED_COUNTING_DATA_RESET })
    }

    const deleteData = () => {
        const monthNames = currentRows.slice(0, -1).map(row => `${row.date}-${countDate}`);

        const data = {
            type: type,
            date: countDate,
            monthlyType: monthlyType,
            monthNames: monthNames,
        }

        dispatch(deleteCounting(data, code));
    };

    // define props for passing to  the toolbar
    const toolbarProps = {
        rowData: rows.length > 1 ? true : false, // 1 for summery all times
        departType: type,
        handlers: {
            handleData: saveData,
            handleDate: changeDate,
            handleRefresh: refreshData,
            handleDelete: deleteData,
            handleSearch: searchData
        },
        metadata: {
            countDate
        },
    };

    let currentRows;
    let currentColumns;
    if (srows.length > 0) {
        currentRows = srows
        currentColumns = scolumns
    } else if (rows.length > 1) {
        currentRows = rows
        currentColumns = columns
    } else {
        currentRows = []
        currentColumns = []
    }

    return (
        <Box className="globalShapeDesign" sx={{
            '& .customCell': {
                textAlign: 'center',
            },
        }}>
            <MetaData title={'YEARLY COUNTS'} />
            <DataGrid
                columns={currentColumns}
                rows={currentRows}
                getRowClassName={(params) => {
                    return params.indexRelativeToCurrentPage === currentRows.length - 1 ? 'summary-row' : '';
                }}
                density={'compact'} // for rowHeight
                loading={loading || searchLoading || loadingAction}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                localeText={localeText}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 },
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar {...toolbarProps} />,
                    pagination: CustomPagination
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}