import {
    NEW_CLASS_REQUEST,
    NEW_CLASS_SUCCESS,
    NEW_CLASS_FAIL,
    ALL_CLASS_REQUEST,
    ALL_CLASS_SUCCESS,
    ALL_CLASS_FAIL,
    ALL_STUDENTS_REQUEST,
    ALL_STUDENTS_SUCCESS,
    ALL_STUDENTS_FAIL,
    THE_RESULT_REQUEST,
    THE_RESULT_SUCCESS,
    THE_RESULT_FAIL,
    DELETE_CLASS_REQUEST,
    DELETE_CLASS_SUCCESS,
    DELETE_CLASS_FAIL,
    NEW_CLASS_RESET,
    NEW_STUDENT_REQUEST,
    NEW_STUDENT_SUCCESS,
    NEW_STUDENT_FAIL,
    UPDATE_STUDENT_REQUEST,
    UPDATE_STUDENT_SUCCESS,
    UPDATE_STUDENT_FAIL,
    UPDATE_STUDENT_RESET,
    NEW_RESULT_REQUEST,
    NEW_RESULT_SUCCESS,
    NEW_RESULT_FAIL,
    UPDATE_RESULT_REQUEST,
    UPDATE_RESULT_SUCCESS,
    UPDATE_RESULT_FAIL,
    UPDATE_RESULT_RESET,
    DELETE_RESULT_REQUEST,
    DELETE_RESULT_SUCCESS,
    DELETE_RESULT_FAIL,
    DELETE_RESULT_RESET,
    DELETE_STUDENT_REQUEST,
    DELETE_STUDENT_FAIL,
    DELETE_STUDENT_SUCCESS,
    DELETE_STUDENT_RESET,
    NEW_STUDENT_RESET,
    CLEAR_ERRORS,
    DELETE_CLASS_RESET,
    NEW_RESULT_RESET,
    ADD_STUDENT_ATTENDANCE_REQUEST,
    ADD_STUDENT_ATTENDANCE_SUCCESS,
    ADD_STUDENT_ATTENDANCE_FAIL,
    ADD_STUDENT_ATTENDANCE_RESET,
    ALL_STUDENT_ATTENDANCE_REQUEST,
    ALL_STUDENT_ATTENDANCE_SUCCESS,
    ALL_STUDENT_ATTENDANCE_FAIL,
    DELETE_STUDENT_ATTENDANCE_REQUEST,
    DELETE_STUDENT_ATTENDANCE_SUCCESS,
    DELETE_STUDENT_ATTENDANCE_FAIL,
    DELETE_STUDENT_ATTENDANCE_RESET,
    NEW_STUDENT_LEAVE_REQUEST,
    NEW_STUDENT_LEAVE_SUCCESS,
    NEW_STUDENT_LEAVE_RESET,
    NEW_STUDENT_LEAVE_FAIL,
    ALL_STUDENT_LEAVE_REQUEST,
    ALL_STUDENT_LEAVE_SUCCESS,
    ALL_STUDENT_LEAVE_FAIL,
    UPDATE_STUDENT_LEAVE_REQUEST,
    UPDATE_STUDENT_LEAVE_SUCCESS,
    UPDATE_STUDENT_LEAVE_FAIL,
    UPDATE_STUDENT_LEAVE_RESET,
    DELETE_STUDENT_LEAVE_REQUEST,
    DELETE_STUDENT_LEAVE_RESET,
    DELETE_STUDENT_LEAVE_SUCCESS,
    DELETE_STUDENT_LEAVE_FAIL,
    ALL_ROUTINES_REQUEST,
    ALL_ROUTINES_SUCCESS,
    ALL_ROUTINES_FAIL,
    ADD_ROUTINE_REQUEST,
    ADD_ROUTINE_SUCCESS,
    ADD_ROUTINE_FAIL,
    ADD_ROUTINE_RESET
} from '../constants/eduConstants'

// for add new class
export const newClassReducer = (state = { class: {} }, action) => {
    switch (action.type) {

        case NEW_CLASS_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_CLASS_SUCCESS:
            return {
                loading: false,
                success: action.payload,
                class: action.payload
            }

        case NEW_CLASS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_CLASS_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all classes
export const allClassReducer = (state = { classes: [] }, action) => {
    switch (action.type) {
        case ALL_CLASS_REQUEST:
            return {
                loading: true,
                classes: []
            }

        case ALL_CLASS_SUCCESS:
            return {
                loading: false,
                classes: action.payload.classes
            }

        case ALL_CLASS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for update and delete
export const classReducer = (state = {}, action) => {
    switch (action.type) {

        case DELETE_CLASS_REQUEST:
            return {
                isDeletedOrUpdated: false
            }

        case DELETE_CLASS_SUCCESS:
            return {
                isDeletedOrUpdated: true
            }

        case DELETE_CLASS_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_CLASS_RESET:
            return {
                ...state,
                isDeletedOrUpdated: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// for add new student
export const newStudentReducer = (state = { student: {} }, action) => {
    switch (action.type) {
        case NEW_STUDENT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_STUDENT_SUCCESS:
            return {
                loading: false,
                isSaved: true,
                student: action.payload
            }

        case NEW_STUDENT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_STUDENT_RESET:
            return {
                isSaved: false,
                student: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


// For all students
export const allStudentsReducer = (state = { students: [] }, action) => {
    switch (action.type) {
        case ALL_STUDENTS_REQUEST:
            return {
                loading: true,
                students: []
            }

        case ALL_STUDENTS_SUCCESS:
            return {
                loading: false,
                students: action.payload.students
            }

        case ALL_STUDENTS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}


export const studentReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_STUDENT_REQUEST:
        case DELETE_STUDENT_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_STUDENT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_STUDENT_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_STUDENT_FAIL:
        case DELETE_STUDENT_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_STUDENT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_STUDENT_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}


// for add new studnt result
export const newResultReducer = (state = { result: {} }, action) => {
    switch (action.type) {
        case NEW_RESULT_REQUEST:
            return {
                ...state,
                isSaved: false,
                loading: true
            }

        case NEW_RESULT_SUCCESS:
            return {
                loading: false,
                isSaved: true,
                result: action.payload
            }

        case NEW_RESULT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_RESULT_RESET:
            return {
                ...state,
                isSaved: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                isSaved: false,
                error: null
            }

        default:
            return state
    }
}

// For all resutls
export const getResultReducer = (state = { result: {}, resultBasics: [] }, action) => {
    switch (action.type) {
        case THE_RESULT_REQUEST:
            return {
                loadingResult: true,
                result: {},
                resultBasics: []
            }

        case THE_RESULT_SUCCESS:
            return {
                loadingResult: false,
                result: action.payload.result,
                resultBasics: action.payload.basics
            }

        case THE_RESULT_FAIL:
            return {
                loadingResult: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// For all resutls
export const actionResultReducer = (state = { result: {}, resultBasics: [] }, action) => {
    switch (action.type) {
        case UPDATE_RESULT_REQUEST:
        case DELETE_RESULT_REQUEST:
            return {
                ...state,
                isDeleted: false,
                loadingAction: true
            }

        case UPDATE_RESULT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case DELETE_RESULT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case UPDATE_RESULT_FAIL:
            return {
                ...state,
                loadingAction: false,
                isUpdated: false,
                error: action.payload
            }

        case DELETE_RESULT_FAIL:
            return {
                ...state,
                loadingAction: false,
                isDeleted: false,
                error: action.payload
            }

        case UPDATE_RESULT_RESET:
            return {
                ...state,
                loadingAction: false,
                isUpdated: false,
                error: action.payload
            }

        case DELETE_RESULT_RESET:
            return {
                ...state,
                loadingAction: false,
                isDeleted: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new student attendance
export const addStudentAttendReducer = (state = { studentAttendance: {} }, action) => {
    switch (action.type) {

        case ADD_STUDENT_ATTENDANCE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ADD_STUDENT_ATTENDANCE_SUCCESS:
            return {
                loading: false,
                success: action.payload,
                staff: action.payload
            }

        case ADD_STUDENT_ATTENDANCE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case ADD_STUDENT_ATTENDANCE_RESET:
            return {
                ...state,
                success: false
            }

        // for actions
        // case UPDATE_FURNITURE_REQUEST:
        case DELETE_STUDENT_ATTENDANCE_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        // case UPDATE_FURNITURE_FAIL:
        case DELETE_STUDENT_ATTENDANCE_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_STUDENT_ATTENDANCE_SUCCESS:
            return {
                ...state,
                loadingAction: action.payload,
                isDeleted: action.payload
            }

        case DELETE_STUDENT_ATTENDANCE_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                error: null
            }

        default:
            return state
    }
}

// All student attendance
export const allStudentAttendance = (state = { studentAttendance: [] }, action) => {
    switch (action.type) {
        case ALL_STUDENT_ATTENDANCE_REQUEST:
            return {
                loading: true,
                studentAttendance: []
            }

        case ALL_STUDENT_ATTENDANCE_SUCCESS:
            return {
                loading: false,
                studentAttendance: action.payload.student_attendance
            }

        case ALL_STUDENT_ATTENDANCE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new leave
export const newStudentLeaveReducer = (state = { studentLeave: {} }, action) => {
    switch (action.type) {

        case NEW_STUDENT_LEAVE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_STUDENT_LEAVE_SUCCESS:
            return {
                loading: false,
                success: action.payload
            }

        case NEW_STUDENT_LEAVE_RESET:
            return {
                loading: false,
                success: false
            }

        case NEW_STUDENT_LEAVE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For student leaves
export const allStudentLeaveReducer = (state = { studentLeaves: [] }, action) => {
    switch (action.type) {
        case ALL_STUDENT_LEAVE_REQUEST:
            return {
                loading: true,
                studentLeaves: []
            }

        case ALL_STUDENT_LEAVE_SUCCESS:
            return {
                loading: false,
                studentLeaves: action.payload.studentLeaves
            }

        case ALL_STUDENT_LEAVE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const studentLeaveReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_STUDENT_LEAVE_REQUEST:
        case DELETE_STUDENT_LEAVE_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_STUDENT_LEAVE_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_STUDENT_LEAVE_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_STUDENT_LEAVE_FAIL:
        case DELETE_STUDENT_LEAVE_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_STUDENT_LEAVE_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_STUDENT_LEAVE_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const allRoutinesReducer = (state = { routines: [] }, action) => {
    switch (action.type) {
        case ALL_ROUTINES_REQUEST:
            return {
                loading: true,
                routines: []
            }

        case ALL_ROUTINES_SUCCESS:
            return {
                loading: false,
                routines: action.payload.routines
            }

        case ALL_ROUTINES_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const routineReducer = (state = {}, action) => {
    switch (action.type) {

        case ADD_ROUTINE_REQUEST:
            // case DELETE_STAFF_DUTY_REQUEST:
            return {
                ...state,
                loadingRoutine: true
            }

        case ADD_ROUTINE_SUCCESS:
            // case DELETE_STAFF_DUTY_SUCCESS:
            return {
                loadingRoutine: false,
                success: action.payload
            }

        case ADD_ROUTINE_FAIL:
            return {
                ...state,
                loadingRoutine: false,
                error: action.payload
            }

        case ADD_ROUTINE_RESET:
            // case DELETE_STAFF_DUTY_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}