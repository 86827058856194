export const NEW_BOOK_REQUEST = 'NEW_BOOK_REQUEST'
export const NEW_BOOK_SUCCESS = 'NEW_BOOK_SUCCESS'
export const NEW_BOOK_RESET = 'NEW_BOOK_RESET'
export const NEW_BOOK_FAIL = 'NEW_BOOK_FAIL'
export const ALL_BOOKS_REQUEST = 'ALL_BOOKS_REQUEST'
export const ALL_BOOKS_SUCCESS = 'ALL_BOOKS_SUCCESS'
export const ALL_BOOKS_FAIL = 'ALL_BOOKS_FAIL'
export const UPDATE_BOOK_REQUEST = 'UPDATE_BOOK_REQUEST'
export const UPDATE_BOOK_FAIL = 'UPDATE_BOOK_FAIL'
export const UPDATE_BOOK_SUCCESS = 'UPDATE_BOOK_SUCCESS'
export const UPDATE_BOOK_RESET = 'UPDATE_BOOK_RESET'
export const DELETE_BOOK_REQUEST = 'DELETE_BOOK_REQUEST'
export const DELETE_BOOK_FAIL = 'DELETE_BOOK_FAIL'
export const DELETE_BOOK_SUCCESS = 'DELETE_BOOK_SUCCESS'
export const DELETE_BOOK_RESET = 'DELETE_BOOK_RESET'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const NEW_PROVIDED_BOOK_REQUEST = 'NEW_PROVIDED_BOOK_REQUEST'
export const NEW_PROVIDED_BOOK_SUCCESS = 'NEW_PROVIDED_BOOK_SUCCESS'
export const NEW_PROVIDED_BOOK_FAIL = 'NEW_PROVIDED_BOOK_FAIL'
export const NEW_PROVIDED_BOOK_RESET = 'NEW_PROVIDED_BOOK_RESET'
export const ALL_PROVIDED_BOOKS_REQUEST = 'ALL_PROVIDED_BOOKS_REQUEST'
export const ALL_PROVIDED_BOOKS_SUCCESS = 'ALL_PROVIDED_BOOKS_SUCCESS'
export const ALL_PROVIDED_BOOKS_FAIL = 'ALL_PROVIDED_BOOKS_FAIL'

export const UPDATE_PROVIDED_BOOK_REQUEST = 'UPDATE_PROVIDED_BOOK_REQUEST'
export const UPDATE_PROVIDED_BOOK_SUCCESS = 'UPDATE_PROVIDED_BOOK_SUCCESS'
export const UPDATE_PROVIDED_BOOK_FAIL = 'UPDATE_PROVIDED_BOOK_FAIL'
export const UPDATE_PROVIDED_BOOK_RESET = 'UPDATE_PROVIDED_BOOK_RESET'

export const ACCEPT_PROVIDED_BOOK_REQUEST = 'ACCEPT_PROVIDED_BOOK_REQUEST'
export const ACCEPT_PROVIDED_BOOK_SUCCESS = 'ACCEPT_PROVIDED_BOOK_SUCCESS'
export const ACCEPT_PROVIDED_BOOK_FAIL = 'ACCEPT_PROVIDED_BOOK_FAIL'
export const ACCEPT_PROVIDED_BOOK_RESET = 'ACCEPT_PROVIDED_BOOK_RESET'