// React and related hooks
import React, { useState, useRef, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    Button, CssBaseline, TextField, Grid, Box, Container
} from '@mui/material';
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';

// Custom components and utilities
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';
import useAuth from '../hooks/UseAuth';

// Actions and Constants
import { allDonors, newDonor, updateDonor } from '../../actions/donorActions';
import { NEW_DONOR_RESET } from '../../constants/donorContstants';
import { Controller, useForm } from 'react-hook-form';

export default function DONORFORM({ closeDialog, history }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const [catchID, setCatchID] = useState(history);
    const formRef = useRef(null);
    const { code } = useAuth();

    // getting the value from redux according to the history info for using by default
    const { loading } = useSelector(state => state.newDonor);
    const { loadingAction } = useSelector(state => state.donor);
    const { donors } = useSelector(state => state.allDonors);
    const { isSaved } = useSelector(state => state.newDonor);

    // Find receipt according to the bookNo and receiptNoTill
    const donor = donors?.find(d => d.member_no === history.id);

    const defaultValues = {
        // bookNo: receipt?.book_no || '',
        memberNo: donor?.member_no || '',
        donorName: donor?.donor_name || '',
        fatherName: donor?.father_name || '',
        mobile: donor?.mobile || '',
        email: donor?.email || '',
        village: donor?.village || '',
        postOffice: donor?.post_office || '',
        subDistrict: donor?.sub_district || '',
        district: donor?.district || '',
        referrerId: donor?.referrer_id || '',
        referrer: donor?.referrer_name || '',
        donationAmount: donor?.donation_amount || ''
    };
    const { handleSubmit, control, reset } = useForm({ defaultValues });

    useEffect(() => {
        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(allDonors(code));
            dispatch({ type: NEW_DONOR_RESET })
            reset();
            setCatchID('')
        }
    }, [dispatch, t, code, enqueueSnackbar, isSaved, reset])

    // handle clear
    const handleClear = () => {
        reset();
        setCatchID('')
    };

    const lbls = ['memberNo', 'donationAmount', 'donorName', 'fatherName', 'mobile', 'email', 'village', 'postOffice', 'subDistrict', 'district', 'referrerId', 'referrer'];

    // handle submit
    const onSubmit = (data) => {
        if (!catchID) {
            const duplicate = donors?.find(d => d.member_no === parseInt(data.memberNo));
            if (duplicate) {
                return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });
            } else {
                dispatch(newDonor(data, code))
            }
        } else {
            dispatch(updateDonor(data, code))
        }
    };

    return (
        <Container component="main" maxWidth="sm" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'DONOR FORM'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '1.5rem'
                }}
            >
                <RECEIPTHEADING text={t('donorForm')} department={t('donation')} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container spacing={1.2}
                        sx={{ mt: 1.5 }}
                    >
                        {lbls.map((lbl, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={['donorName', 'fatherName'].includes(lbl) ? 12 : 6}
                                key={`grid-${index}`}
                            >
                                <Controller
                                    key={`controller-${lbl}`}
                                    name={lbl}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            autoComplete="given-name"
                                            fullWidth
                                            type={['memberNo', 'referrerId', 'donationAmount'].includes(lbl) ? 'number' : 'text'}
                                            size="small"
                                            id={`id${index}`}
                                            autoFocus={lbl === 'memberNo'}
                                            // InputProps={{
                                            //     endAdornment:
                                            //         lbl === 'referrerId' ? (
                                            //             <InputAdornment position="end">
                                            //                 <IconButton size="small" sx={{
                                            //                     marginLeft: '5px'
                                            //                 }} onClick={() => checkMember(field.value)}>
                                            //                     <Search />
                                            //                 </IconButton>
                                            //             </InputAdornment>
                                            //         ) : null
                                            // }}
                                            label={t(lbl)}
                                            disabled={catchID && lbl === 'memberNo' ? true : false}
                                            required={['referrerId', 'fatherName', 'email', 'referrer'].includes(lbl) ? false : true}
                                        />
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <Grid item container xs={12} mb={2} columnSpacing={1} justifyContent={'right'}>
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color='error'
                                sx={{ mt: 2 }}
                                onClick={handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Grid>
                        {catchID ?
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    loading={loadingAction ? true : false}
                                    sx={{ mt: 2 }}
                                >
                                    <span>{t('update')}</span>
                                </LoadingButton>
                            </Grid>
                            :
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('save')}</span>
                                </LoadingButton>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}