// React and related hooks
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    Grid,
    Button,
    ButtonGroup,
    Box,
    Menu,
    MenuItem,
    Chip
} from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext, GridActionsCellItem, useGridApiRef, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { Edit, ArrowBack, CheckCircle, FileDownloadOutlined } from '@mui/icons-material';

// Custom components and styles
import CustomPagination from '../layout/Pagination';
import { MetaData } from '../utils/metaData';
import { useComponent } from '../hooks/ComponentContext';
import { GETCONFIRMATION } from '../layout/MiniComponents';
import useAuth from '../hooks/UseAuth';
import useDataGridColumns from '../utils/useDataGridColumns';

// Actions and constants
import {
    acceptFurniture,
    getGivenFurnitures
} from '../../actions/adminisActions';
import { givenFurnitureListPdf } from '../../actions/pdfActions';
import { ACCEPT_ALL_FURNITURE_RESET } from '../../constants/adminisConstants';

// Utilities
import dayjs from 'dayjs';
import { getUserTypes } from '../../constants/commonContstants';
import { convertToBengaliDigits } from '../utils/converter';

function CustomToolbar() {
    const { t } = useTranslation();
    const { changeComponent } = useComponent();
    const apiRef = useGridApiContext();
    const dispatch = useDispatch();
    const { code } = useAuth();

    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // Prepare data for PDF
        const filteredRows = gridFilteredSortedRowIdsSelector(apiRef).map((id) => apiRef.current.getRow(id));

        const excludedFields = ['provider', '__check__', 'actions', 'furnitureId', 'givingTime'];
        const headerNames = apiRef.current.getAllColumns()
            .filter(column => !excludedFields.includes(column.field))  // Exclude unwanted fields
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('listOfFurniture'),
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // open list window
    const OpenAnotherWindow = () => {
        changeComponent('adminis-4')
    };

    const handlePdf = () => {
        dispatch(givenFurnitureListPdf(currentRows, code));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} item order={{ sm: 1, xs: 2 }} display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={OpenAnotherWindow}><ArrowBack /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('givedItemsList')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function GIVENFURNITURES() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code, type } = useAuth();
    const userTypes = getUserTypes(t)
    const { enqueueSnackbar } = useSnackbar();
    const apiRef = useGridApiRef();

    const [open, setOpen] = React.useState(false);
    const [rowInfo, setRowInfo] = React.useState('');
    const [givenAmount, setGivenAmount] = React.useState('');

    const { givenFurnitures, loading } = useSelector(state => state.allGivenFurniture);
    const { isAccepted } = useSelector(state => state.furniture);
    const { furnitures } = useSelector(state => state.allFurniture);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(getGivenFurnitures(code));
        if (isAccepted) {
            setOpen(false)
            dispatch({ type: ACCEPT_ALL_FURNITURE_RESET })
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
        }
    }, [dispatch, isAccepted, enqueueSnackbar, t, code])

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const handleConfirmation = (rowData) => {
        setOpen(true)
        setRowInfo(rowData)
    };

    const handleAcceptAll = () => {
        const info = {
            id: rowInfo.furnitureId,
            amount: parseInt(rowInfo.totalGiven),
            acceptAll: true
        }
        dispatch(acceptFurniture(info, code));
    };

    const handleEdit = (rowData) => {
        const givenAmount = givenFurnitures.find(furniture => furniture.id === rowData.furnitureId);
        setGivenAmount(givenAmount.total_given)
        apiRef.current.startCellEditMode({ id: rowData.id, field: 'totalGiven' });
    }

    const handleCellEditStart = (params) => {
        const givenAmount = givenFurnitures.find(furniture => furniture.id === params.row.furnitureId);
        setGivenAmount(givenAmount.total_given)
    };

    const handleProcessRowUpdate = (newRow) => {
        // hanel not allowed amounts
        const neGivenAmount = parseInt(newRow.totalGiven);
        if (neGivenAmount === givenAmount) {
            return newRow;
        } else if (neGivenAmount <= 0) {
            enqueueSnackbar(t('notWorked'), { variant: 'error' });
            return newRow;
        }

        // process to handle new amount
        const idParts = newRow.furnitureId.split('-');
        const furniture = furnitures.find(furniture => furniture.id === idParts[idParts.length - 1]);
        const possibleAmount = (furniture.total_has - furniture.total_given) + givenAmount

        if (possibleAmount < neGivenAmount) {
            const maxNotPossibleMessage = t('maxNotPossible', { remain: possibleAmount, furniture: newRow.name });
            enqueueSnackbar(maxNotPossibleMessage, { variant: 'error' });
        } else {
            // updating furniture given amount
            const acceptAmount = givenAmount - neGivenAmount
            const info = {
                id: newRow.furnitureId,
                amount: neGivenAmount,
                acceptAmount: acceptAmount,
                acceptAll: false
            }
            dispatch(acceptFurniture(info, code));
        }
        return newRow;
    };

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('serialNo'), minWidth: 80, width: 80, disableColumnMenu: false, flex: 0 },
        { field: 'name', headerName: t('furnitureName') },
        { field: 'totalGiven', headerName: t('given'), editable: true },
        { field: 'receiverDepartment', headerName: t('receiverDepartment'), },
        { field: 'receiverResponsible', headerName: t('receiverResponsible'), },
        { field: 'givingDate', headerName: t('givingDate'), },
        { field: 'givingTime', headerName: t('time'), },
        { field: 'provider', headerName: t('provider'), },

        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            width: 80,
            minWidth: 80,
            flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'principal'}
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    disabled={type !== 'principal'}
                    icon={<CheckCircle />}
                    label="acceptAll"
                    onClick={() => handleConfirmation(params.row)}
                />
            ],
        },
        { field: 'furnitureId', headerName: t('idNo'), hideable: false },

    ]);

    // Transform givenFurnitures array into the desired format
    const rows = givenFurnitures?.map((furniture, index) => {
        // Find the matching user type
        const responsible = userTypes.find(type => type.value === furniture.provider);
        return {
            id: index + 1,
            furnitureId: furniture.id,
            name: furniture.furniture_name,
            totalGiven: `${furniture.total_given} ${t('the')}`,
            receiverDepartment: furniture.receiver_department,
            receiverResponsible: furniture.receiver_responsible,
            givingDate: `${dayjs(furniture.given_at).format('YYYY-MM-DD')} ${t('dateEng')}`,
            givingTime: dayjs(furniture.given_at).format('hh:mm A'),
            provider: responsible.label
        };
    });

    // handle confirmation
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'GIVEN FURNITURE LIST'} />
            <GETCONFIRMATION handleOpen={open} confirmationText={t('acceptFurnitureConfrim', { provider: rowInfo.receiverResponsible, amount: rowInfo ? convertToBengaliDigits(rowInfo.totalGiven) : '', furniture: rowInfo.name })} handleClose={handleClose} handleOk={handleAcceptAll} />
            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                loading={loading}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                scrollbarSize={0}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                localeText={localeText}

                isRowSelectable={() => false}
                apiRef={apiRef}
                onCellEditStart={handleCellEditStart}
                processRowUpdate={handleProcessRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                onProcessRowUpdateError={(error) => {
                    console.error("Row update failed: ", error);
                }}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 }, // Default page size
                    },

                    columns: {
                        columnVisibilityModel: {
                            furnitureId: false,
                            givingTime: false
                        },
                    },
                }}

                slots={{
                    // toolbar: CustomToolbar,
                    toolbar: () => <CustomToolbar />,
                    pagination: CustomPagination,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
