// React and related hooks
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// MUI components and icons
import { Box, Container, Grid, Paper, Typography, Avatar, InputAdornment, IconButton, TextField, Autocomplete } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LoadingButton } from '@mui/lab';
import { Close, Search } from '@mui/icons-material';

// Custom components and utilities
import { CustomCrossButton } from '../styles/style';
import { convertToBengaliDigits } from '../utils/converter';
import useAuth from '../hooks/UseAuth';

// Actions
import { allStudents, newStudentLeave, updateStudentLeave } from '../../actions/eduActions';
import { allStaff, newStaffLeave, updateStaffLeave } from '../../actions/adminisActions';

// Constants
import { leaveReasons } from '../../constants/commonContstants';

// Utility Libraries
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';
import { MetaData } from '../utils/metaData';

const LeaveForm = ({ closeDialog, selectedData, isStudentForm }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const formRef = useRef(null);
    const { code } = useAuth();
    const [rowData, setRowData] = useState(selectedData || {});
    const { students } = useSelector(state => state.allStudent);
    const { staff } = useSelector(state => state.allStaff);

    const defaultValues = {
        idNo: rowData?.idNo || '',
        leaveStart: rowData?.leaveStart || '',
        leaveEnd: rowData?.leaveEnd || '',
        leaveReason: rowData?.leaveReason || null,
        leaveType: rowData?.leaveType || null,
    };

    const { handleSubmit, control } = useForm({ defaultValues });

    useEffect(() => {
        dispatch(allStaff(code));
        dispatch(allStudents(code));
    }, [dispatch, code]);

    const onSubmit = async (data) => {
        const finalData = {
            id: selectedData ? selectedData.dataId : nanoid(),
            ...data,
        };

        if (!selectedData) {
            isStudentForm ? dispatch(newStudentLeave(finalData, code)) : dispatch(newStaffLeave(finalData, code));
        } else {
            isStudentForm ? dispatch(updateStudentLeave(finalData, code)) : dispatch(updateStaffLeave(finalData, code));
        }
    };

    const check = (idNo) => {
        const data = isStudentForm ? students?.find(s => s.admit_no === parseInt(idNo) && s.code === code)
            : staff?.find(s => s.staff_id === parseInt(idNo) && s.code === code);

        if (data) setRowData(data);
    };

    const fieldsConfig = [
        { name: 'idNo', type: 'number', isDisabled: selectedData },
        { name: 'leaveStart', type: 'date' },
        { name: 'leaveEnd', type: 'date' },
        { name: 'leaveReason', type: 'autocomplete', options: leaveReasons },
        { name: 'leaveType', type: 'autocomplete', options: [t('verbalLeave'), t('writtenLeave')] },
    ];

    return (
        <Container maxWidth={false} sx={{ borderRadius: '10px', maxWidth: '700px' }}>
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <MetaData title={'LEAVE FORM'} />
            <Grid container spacing={3} paddingTop={4} paddingBottom={4}>
                <Grid item xs={12} sm={5}>
                    <Paper sx={{ padding: 2, height: '100%' }}>
                        <Avatar alt="Profile Picture" src={rowData?.photo_url} sx={{ width: 100, height: 100, margin: '0 auto', mb: 2 }} />
                        {!selectedData ?
                            <Typography variant="h7" component="div" fontWeight="bold">
                                {t('idNo')}: {convertToBengaliDigits(isStudentForm && rowData?.admit_no ? rowData?.admit_no : rowData?.staff_id ? rowData?.staff_id : '')}
                            </Typography>
                            :
                            <Typography variant="h7" component="div" fontWeight="bold">
                                {t('idNo')}: {convertToBengaliDigits(rowData?.idNo || 0)}
                            </Typography>
                        }
                        <Typography variant="h7" component="div" fontWeight="bold">
                            {t('name')}: {rowData?.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {isStudentForm
                                ? `${t('class')}: ${rowData?.choosing_class || ''}`
                                : `${t('designation')}: ${rowData?.designation || ''}`}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {t('address')}: {rowData?.village || rowData?.address ? (!selectedData ? `${rowData?.village}, ${rowData?.district}` : rowData?.address) : ''}
                        </Typography>

                        <Typography variant="body1" color="textSecondary">
                            {t('mobile')}: {isStudentForm ? convertToBengaliDigits(rowData?.father_mobile || '') : convertToBengaliDigits(rowData?.mobile || '')}
                        </Typography>
                    </Paper>
                </Grid>

                <Grid item xs={12} sm={7}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography sx={{ fontSize: '1.2rem' }} gutterBottom>
                            {t('leavesManage')} ({isStudentForm ? t('student') : t('staff')})
                        </Typography>
                        <Box component="form" onSubmit={handleSubmit(onSubmit)} ref={formRef}>
                            <Grid container spacing={1.5}>
                                {fieldsConfig.map((field, index) => (
                                    <Grid item xs={field.name === 'leaveStart' || field.name === 'leaveEnd' ? 6 : 12} key={field.name}>
                                        {field.type === 'date' ? (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Controller
                                                    name={field.name}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            {...field}
                                                            value={field.value ? dayjs(field.value) : null}
                                                            slotProps={{
                                                                textField: {
                                                                    size: 'small', fullWidth: true, required: true,
                                                                    label: t(field.name)
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>
                                        ) : field.type === 'autocomplete' ? (
                                            <Controller
                                                name={field.name}
                                                control={control}
                                                render={({ field: { onChange, value = '', ...fieldProps } }) => (
                                                    <Autocomplete
                                                        options={field.options}
                                                        value={value}
                                                        onInputChange={(event, newInputValue) => onChange(newInputValue)}
                                                        renderInput={(params) => <TextField {...params} size="small" label={t(field.name)} required />}
                                                        {...fieldProps}
                                                    />
                                                )}
                                            />
                                        ) : (
                                            <Controller
                                                name={field.name}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        label={t(field.name)}
                                                        fullWidth
                                                        autoFocus={field.name === 'idNo'}
                                                        disabled={field.isDisabled}
                                                        InputProps={{
                                                            endAdornment: field.name === 'idNo' && (
                                                                <InputAdornment position="end">
                                                                    <IconButton size='small' onClick={() => check(field.value)}>
                                                                        <Search />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            ),
                                                        }}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>
                                ))}
                            </Grid>

                            <LoadingButton type='submit' variant="contained" disabled={!rowData.id} color="success" sx={{ mt: 2 }}>
                                {!selectedData ? t('save') : t('update')}
                            </LoadingButton>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
};

// Export two components for Student and General usage
export const LeaveFormStudent = (props) => <LeaveForm {...props} isStudentForm={true} />;
export const LeaveFormGeneral = (props) => <LeaveForm {...props} isStudentForm={false} />;