// React and hooks
import React, { useEffect, useState } from 'react';
// import { useTranslation } from 'react-i18next';

// MUI components and icons
import { useTheme } from '@mui/material/styles';
import { Typography, Box, Divider, Grid, Card, CardMedia, Button, TextField, Dialog, DialogContent, DialogTitle, CircularProgress, Chip, Avatar } from '@mui/material';
import { CustomCrossButton } from '../styles/style';
import { Close } from '@mui/icons-material';
import { t } from 'i18next'
// Custom utilities
import useAuth from '../hooks/UseAuth';
import { convertToBengaliDigits } from '../utils/converter';

export const HEADINGS = ({ photoView, onSendData }) => {
    // const { t } = useTranslation();

    const [photoPreview, setPhotoPreview] = useState('');

    useEffect(() => {
        if (photoView) {
            setPhotoPreview(photoView);
        }
    }, [photoView]);


    return (
        <Grid container columnSpacing={2}>
            <Grid item xs={2}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <CardMedia
                    component="img"
                    height="60"
                    width="60"
                    sx={{
                        objectFit: 'contain'
                    }}
                    image='/images/logo.png'
                    alt='logo'
                />
            </Grid>

            <Grid item xs={7}>
                <Box
                    display="flex"
                    flexDirection={'column'}
                    sx={{
                        alignItems: 'left',
                        mt: 1,
                    }}>
                    <Typography variant="h6">
                        {t('academicName')}
                    </Typography>
                    <Typography variant="h7">
                        {t('fullAddress')}
                    </Typography>
                    <Typography variant="h6">
                        {t('addStuffHeading')}
                    </Typography>
                    <Divider sx={{
                        width: '100%',
                        borderColor: 'black'
                    }} />
                </Box>
            </Grid>
            <Grid item xs={3}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    mt: 1
                }}
            >
                <Button
                    component="label"
                    sx={{
                        marginTop: 'auto',
                        padding: '0px'
                    }}
                >
                    <Card variant="outlined">
                        <CardMedia
                            component="img"
                            height="70"
                            width="70"
                            image={photoPreview}
                            alt={t('picture')}
                        />
                    </Card>

                    <input
                        type="file"
                        hidden
                        onChange={(e) => {
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                onSendData(reader.result)
                                setPhotoPreview(reader.result)
                            };
                            if (file) {
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                </Button>
            </Grid>
        </Grid>
    )
}

export const CUSTOMLOADING = () => {
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 'auto', height: '100%' }}>
            <CircularProgress />
        </Box>
    )
}

export const RECEIPTHEADING = ({ text, department }) => {
    const theme = useTheme();
    const todayDate = new Date().toISOString().split('T')[0];

    const {
        academicName,
        academicAddress,
        academicMobile,
        englishName,
        englishAddress,
        logo,
        calligraphy
    } = useAuth();

    return (
        <Grid container
            display={"flex"}
            alignItems={'center'}
            justifyContent={'center'}
        >
            <Grid item xs={12}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                }}
            >
                {calligraphy ?
                    <CardMedia
                        component="img"
                        height="30"
                        src={calligraphy}
                        alt='Calligraphy design will be here'
                        sx={{
                            objectFit: 'contain',
                            maxWidth: '100%',
                            filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none', // Invert colors for dark mode
                        }}
                    />
                    : ''}
            </Grid>

            <Grid item xs={12} sm={5} marginRight={'0.6rem'}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    textAlign: { xs: 'center', sm: 'right' }
                }}
            >
                <Typography variant="subtitle2">{academicName}</Typography>
                <Typography variant="overline"
                    sx={{ lineHeight: '8px' }}
                >{academicAddress}</Typography>
                <Typography variant="overline"
                    sx={{ lineHeight: '20px' }}
                >{convertToBengaliDigits(academicMobile || '')}</Typography>
            </Grid>
            <Grid item xs={1}
                sx={{
                    display: { xs: 'none', sm: 'flex' },
                    justifyContent: 'center',
                }}
            >
                <Avatar
                    alt="Logo"
                    src={logo}
                    sx={{
                        width: '55px',
                        height: '55px',
                        objectFit: 'contain',
                    }}
                />
            </Grid>
            <Grid item xs={5} marginLeft={'0.6rem'}
                sx={{
                    display: { xs: 'none', sm: 'flex' },
                    flexDirection: 'column',
                    justifyContent: 'right'
                }}
            >
                <Typography variant="overline"
                    sx={{
                        fontFamily: 'Times New Roman',
                        fontSize: '10px',
                        lineHeight: '20px',
                    }}
                >
                    {englishName || 'ENGLISH ACADEMIC NAME WILL BE HERE'}
                </Typography>

                <Typography variant="overline"
                    className='rcptHdngTxtEn'
                    sx={{
                        lineHeight: '10px',
                        fontFamily: 'Times New Roman',
                        fontSize: '9px'
                    }}
                >{englishAddress || 'ENGLISH ADDRESS WILL BE HERE'}
                </Typography>

                <Typography variant="overline"
                    className='rcptHdngTxtEn'
                    sx={{
                        lineHeight: '22px',
                        fontFamily: 'Times New Roman',
                    }}
                >{academicMobile}</Typography>
            </Grid>
            <Divider
                sx={{
                    borderColor: 'black',
                    width: '100%',
                    my: '3px'
                }}
            />
            <Grid
                item
                xs={12}
                display="flex"
                justifyContent={'center'}
                marginTop={1}
                sx={{
                    marginTop: '0px'
                }}
            >
                <Grid item xs={4}>
                    <Chip label={`${t('department')}: ${department}`} />
                </Grid>
                <Grid item xs={4}>
                    <Chip

                        sx={{
                            margin: 'auto',
                            display: 'flex',
                            justifyContent: 'center',
                            borderRadius: '50px',
                            textAlign: 'center',
                            padding: '0px 10px 0px 10px',
                            fontSize: '1.1rem',
                        }}
                        label={text} />
                </Grid>
                <Grid item xs={4}
                    sx={{
                        display: 'flex',
                        justifyContent: 'right',
                    }}
                >
                    <Chip label={`${t('date')}: ${todayDate}`} />
                </Grid>
            </Grid>
        </Grid >
    )
}

export const FORMHEADING = ({ onSendData, photoView, text }) => {
    const theme = useTheme();

    const [photoPreview, setPhotoPreview] = useState('');

    const {
        academicName,
        academicAddress,
        logo,
        calligraphy
    } = useAuth();


    useEffect(() => {
        if (photoView) {
            setPhotoPreview(photoView);
        }
    }, [photoView]);

    return (
        <Grid container
            display="flex"
            columnSpacing={1}
            mt={1}
        >
            <Grid item xs={12} sm={2}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <CardMedia
                    component="img"
                    height="60"
                    width="60"
                    sx={{
                        objectFit: 'contain'
                    }}
                    image={logo || ''}
                    alt='Logo'
                />
            </Grid>

            <Grid item xs={12} sm={8}
                sx={{
                    justifyContent: 'center',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    mt: 2,
                }}
            >
                {calligraphy ?
                    <CardMedia
                        component="img"
                        height="30"
                        src={calligraphy}
                        alt='Calligraphy design will be here'
                        sx={{
                            objectFit: 'contain',
                            maxWidth: '100%',
                            filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none', // Invert colors for dark mode
                        }}
                    />
                    : ''}

                <Typography variant="h7">{academicName || ''}</Typography>
                <Typography variant="body2">{academicAddress || ''}</Typography>

                <Chip sx={{
                    fontSize: '1.2rem',
                    paddingTop: '5px',
                    paddingBottom: '2px',
                    marginBottom: '5px',
                }} label={t(text)} />
            </Grid>

            <Grid item xs={12} sm={2}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Button
                    component="label"
                >
                    <CardMedia
                        component="img"
                        height="70"
                        width="70"
                        image={photoPreview}
                        alt={t('picture')}
                        border='1px'
                    />

                    <input
                        type="file"
                        hidden
                        onChange={(e) => {
                            const file = e.target.files[0];
                            const reader = new FileReader();
                            reader.onload = () => {
                                onSendData(file)
                                setPhotoPreview(reader.result)
                            };
                            if (file) {
                                reader.readAsDataURL(file);
                            }
                        }}
                    />
                </Button>
            </Grid>
        </Grid >
    )
}

export const GETCODE = ({ handleOpen, handleClose, handleSign, headingText, password, setPassword }) => {
    const handlePasswordChange = (event) => {
        setPassword(event.target.value);
    };

    return (
        <React.Fragment>
            <Dialog
                open={handleOpen}
            >
                <CustomCrossButton
                    onClick={handleClose}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                >
                    <Close fontSize='small' />
                </CustomCrossButton>
                <Typography mt={1} variant='h7' sx={{
                    padding: '1.5rem 1.5rem 0.5rem 1.5rem'
                }}>{headingText}</Typography>
                <form onSubmit={handleSign}>
                    <Box padding={'0.5rem 1.5rem 0.5rem 1.5rem'}>
                        <TextField
                            autoFocus
                            required
                            size='small'
                            id="name"
                            name="password"
                            type="password"
                            value={password}
                            onChange={handlePasswordChange}
                            fullWidth
                        />
                        <Box display='flex' justifyContent='right' marginTop={2} marginBottom={1}>
                            <Button
                                type='submit'
                                variant="contained"
                                color="success"
                            >
                                {t('ok')}
                            </Button>
                        </Box>
                    </Box>
                </form>
            </Dialog>
        </React.Fragment >
    );
}

// confirmation dialog
export const GETCONFIRMATION = ({ handleOpen, handleClose, confirmationText, handleOk }) => {
    return (
        <>
            <Dialog
                open={handleOpen}
                onClose={handleClose}>
                <DialogTitle>{confirmationText}</DialogTitle>
                <DialogContent>
                    <Box display='flex' justifyContent='right' marginTop={1}>
                        <Button
                            onClick={handleClose}
                            variant="outlined"
                        >{t('no')}</Button>
                        <Button
                            type='submit'
                            variant="outlined"
                            onClick={handleOk}
                            sx={{
                                ml: 1
                            }}
                        >
                            {t('yes')}
                        </Button>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
}

export const LoadingScreen = () => {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                minHeight: '100vh',
                backgroundColor: 'background.default',
                color: 'text.primary',
                p: 3
            }}
        >
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', margin: 'auto', height: '100%' }}>
                <CircularProgress color="success" size="3rem" />
                <Typography mt={1}>
                    Please wait a moment. This may take a little longer.
                </Typography>
            </Box>
        </Box>
    );
};