import { useSelector } from 'react-redux';
import LeaveTable from './LeaveTable';
import { LeaveFormGeneral } from './LeaveForm';
// Constants and Actions Imports
import { allStaffLeaves, deleteStaffLeave } from '../../actions/adminisActions';
import { DELETE_STAFF_LEAVE_RESET, NEW_STAFF_LEAVE_RESET, UPDATE_STAFF_LEAVE_RESET } from '../../constants/adminisConstants';

export default function STAFFLEAVES() {
    const staffLeaves = useSelector(state => state.allStaffLeaves.staffLeaves);
    return (
        <LeaveTable
            isStudent={false}
            fetchDataAction={allStaffLeaves}
            deleteAction={deleteStaffLeave}
            resetActions={[NEW_STAFF_LEAVE_RESET, UPDATE_STAFF_LEAVE_RESET, DELETE_STAFF_LEAVE_RESET]}
            leavesData={staffLeaves}
            leaveForm={LeaveFormGeneral}
        />
    );
}