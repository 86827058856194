// React and related hooks
import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    Button,
    TextField,
    Grid,
    Box,
    Container,
    MenuItem,
    FormControl,
    Divider,
    Card,
    CardMedia,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    InputAdornment
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';

// Custom components and utilities
import { GETCODE, RECEIPTHEADING } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';
import useAuth from '../hooks/UseAuth';

// Actions and constants
import { newVoucher, updateVoucher } from '../../actions/accountActions';
import { getFundTypes } from '../../constants/accountConstants';
import { numberToBanglaWords } from '../utils/converter';

// Utilities
import { nanoid } from 'nanoid';
import { Controller, useForm } from 'react-hook-form';

export default function VOUCHERFORM({ closeDialog, history, department }) {
    const { t } = useTranslation();
    const fundTypes = getFundTypes(t);
    const dispatch = useDispatch();
    const { code, sign_url } = useAuth();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const [catchID, setCatchID] = useState(history);
    const formRef = useRef(null);
    const [receiverSignPreveiw, setReceiverSignPreveiw] = useState('')
    // if user will not select a image then default image will show and set 
    const [principalSignPreview, setPrincipalSignPreview] = useState('')

    // getting the value from redux according to the history info for using by default
    const { vouchers } = useSelector(state => state.allVoucher);
    const { users } = useSelector(state => state.allUsers);
    const { loading, isSaved } = useSelector(state => state.newVoucher);
    const { regardings } = useSelector((state) => state.allRegarding);
    const [selectedFundType, setSelectedFundType] = useState('');

    // Find voucher according to the fundName and voucherNo
    const id = history?.id || ''; // Safely accessing fundName and voucherNo
    const voucherNo = history?.voucherNo || '';
    const voucher = vouchers.find(voucher =>
        voucher.id === id && voucher.voucher_no === voucherNo && voucher.code === code
    );

    const defaultValues = {
        id: voucher?.id || nanoid(10),
        fund: voucher?.fund || '',
        voucherNo: voucher?.voucher_no || '',
        name: voucher?.name || '',
        regarding1: voucher?.regarding1 || '',
        amount1: voucher?.amount1 || '',
        regarding2: voucher?.regarding2 || '',
        amount2: voucher?.amount2 || '',
        regarding3: voucher?.regarding3 || '',
        amount3: voucher?.amount3 || '',
        regarding4: voucher?.regarding4 || '',
        amount4: voucher?.amount4 || '',
        regarding5: voucher?.regarding5 || '',
        amount5: voucher?.amount5 || '',
        regarding6: voucher?.regarding6 || '',
        amount6: voucher?.amount6 || '',
        regarding7: voucher?.regarding7 || '',
        amount7: voucher?.amount7 || '',
        regarding8: voucher?.regarding8 || '',
        amount8: voucher?.amount8 || '',
        regarding9: voucher?.regarding9 || '',
        amount9: voucher?.amount9 || '',
        regarding10: voucher?.regarding10 || '',
        amount10: voucher?.amount10 || '',
        inWords: voucher?.in_words || '',
        total: voucher?.total || '',
    };

    const { handleSubmit, control, reset, setValue, watch, getValues } = useForm({ defaultValues });

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (sign_url) {
            setReceiverSignPreveiw(sign_url)
        }
    }, [dispatch, sign_url, t])

    // handle clear
    const handleClear = () => {
        const emptyValues = {
            id: nanoid(10)
        };
        reset(emptyValues);
        setPrincipalSignPreview('')
        setCatchID('')
    };

    // handle submit
    const onSubmit = (data) => {

        const duplicate = vouchers.find(v => v.fund === data.fund && v.voucher_no === data.voucherNo)
        if (duplicate && !catchID) return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });;

        // Function to filter out empty values
        const filterEmptyValues = (data) => {
            return Object.fromEntries(
                Object.entries(data).filter(([key, value]) => value !== '')
            );
        };

        // Filter out empty values from the data
        const cleanedData = filterEmptyValues(data);

        if (catchID) {
            if (principalSignPreview) {
                dispatch(updateVoucher({ ...cleanedData, principalSign: principalSignPreview }, code))
            } else {
                setGetCodeOpen(true);
            }
        } else {
            dispatch(newVoucher({
                ...cleanedData,
                department: department,
                receiverSign: sign_url,
                principalSign: principalSignPreview
            }, code))
        }
    };

    // for controling getcode window
    const [password, setPassword] = useState('');
    const [openGetCode, setGetCodeOpen] = React.useState(false);
    const handleOpenGetCode = () => {
        setGetCodeOpen(true);
    };

    const handleCloseGetCode = () => {
        setGetCodeOpen(false);
    };

    // handle principal sign by the code
    const handleUserSign = async (e) => {
        e.preventDefault();
        const userType = users.find(user => user.password === password);
        if (userType && userType.type === 'principal') {
            setPrincipalSignPreview(userType.sign_url)
            setGetCodeOpen(false);
        } else {
            enqueueSnackbar(t('authorRequired'), { variant: 'error' });
        }
    };

    // Memoize mci_regardings and mgi_regardings
    const mce_regardings = useMemo(() => regardings?.mce_regardings ?? [], [regardings]);
    const mge_regardings = useMemo(() => regardings?.mge_regardings ?? [], [regardings]);

    // Function to parse regarding JSON strings into arrays
    const parseRegardings = (regarding) => {
        try {
            return JSON.parse(regarding); // Parse the JSON string into an array
        } catch (error) {
            console.error('Error parsing regarding:', error);
            return []; // Return an empty array in case of an error
        }
    };

    const [voucherRegardings, setVoucherRegardings] = useState([
        ...mce_regardings?.flatMap((option) =>
            parseRegardings(option.regarding).map((value) => ({
                value: value,
                label: value,
            }))
        ),
        ...mge_regardings?.flatMap((option) =>
            parseRegardings(option.regarding).map((value) => ({
                value: value,
                label: value,
            }))
        )
    ]);

    // Effect to update voucherregardings based on selectedFundType
    useEffect(() => {
        if (isSaved) {
            const emptyValues = {
                id: nanoid(10),
                fund: getValues('fund')
            };
            reset(emptyValues);
        }
        if (selectedFundType === 'charityFund' && !catchID) {
            setVoucherRegardings(
                mce_regardings.flatMap((option) =>
                    parseRegardings(option.regarding).map((value) => ({
                        value: value,
                        label: value,
                    }))
                )
            );

            // find max voucher no
            const maxVoucherNo = vouchers?.length
                ? vouchers.reduce((max, voucher) => {
                    return voucher.fund === 'charityFund' && voucher.voucher_no > max ? voucher.voucher_no : max;
                }, -Infinity)
                : 0;

            // Adjust maxVoucherNo if it's -Infinity
            const newVoucherNo = maxVoucherNo === -Infinity ? 1 : parseInt(maxVoucherNo) + 1;
            setValue('voucherNo', newVoucherNo);

        } else if (selectedFundType === 'generalFund' && !catchID) {
            setVoucherRegardings(
                mge_regardings.flatMap((option) =>
                    parseRegardings(option.regarding).map((value) => ({
                        value: value,
                        label: value,
                    }))
                )
            );

            // find max voucher no
            const maxVoucherNo = vouchers?.length
                ? vouchers.reduce((max, voucher) => {
                    return voucher.fund === 'generalFund' && voucher.voucher_no > max ? voucher.voucher_no : max;
                }, -Infinity)
                : 0;
            // Adjust maxVoucherNo if it's -Infinity
            const newVoucherNo = maxVoucherNo === -Infinity ? 1 : parseInt(maxVoucherNo) + 1;
            setValue('voucherNo', newVoucherNo);
        }
    }, [selectedFundType, mce_regardings, mge_regardings, t, vouchers, setValue, catchID, isSaved, dispatch, reset, enqueueSnackbar, getValues]);

    // const handleFundChange = (e) => {
    //     // const changedFund = e.target.value;
    //     // setValue('fund', changedFund);
    //     setSelectedFundType(e.target.value);
    // };

    const regardingsNames = ['regarding1', 'regarding2', 'regarding3', 'regarding4', 'regarding5', 'regarding6', 'regarding7', 'regarding8', 'regarding9', 'regarding10']
    const amountsNames = ['amount1', 'amount2', 'amount3', 'amount4', 'amount5', 'amount6', 'amount7', 'amount8', 'amount9', 'amount10']
    const basicLbls = ['fund', 'voucherNo', 'name'];

    // Watch for changes
    const valuesToWatch = [
        'amount1',
        'amount2',
        'amount3',
        'amount4',
        'amount5',
        'amount6',
        'amount7',
        'amount8',
        'amount9',
        'amount10'
    ].reduce((acc, label) => ({ ...acc, [label]: watch(label) }), {});

    useEffect(() => {
        const {
            amount1,
            amount2,
            amount3,
            amount4,
            amount5,
            amount6,
            amount7,
            amount8,
            amount9,
            amount10
        } = valuesToWatch;

        const totalSalary = [
            amount1,
            amount2,
            amount3,
            amount4,
            amount5,
            amount6,
            amount7,
            amount8,
            amount9,
            amount10].reduce((sum, val) => sum + (parseFloat(val) || 0), 0);
        setValue('total', totalSalary);
        setValue('inWords', totalSalary > 0 ? `${numberToBanglaWords(totalSalary)} ${t('money')} ${t('just')}` : '');

    }, [dispatch, valuesToWatch, setValue, t])

    return (
        <Container component="main" maxWidth="sm" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'VOUCHER'} />
            <GETCODE
                headingText={t('principalCode')}
                handleSign={handleUserSign}
                handleOpen={openGetCode}
                handleClose={handleCloseGetCode}
                password={password}
                setPassword={setPassword}
            />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '1.5rem'
                }}
            >
                <CustomCrossButton
                    onClick={closeDialog}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                >
                    <Close fontSize='small' />
                </CustomCrossButton>
                <RECEIPTHEADING text={t('voucher')} department={t(department)} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' sx={{
                    width: '100%',
                }}>
                    {/* for handling id */}
                    <Controller
                        name={'id'}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                sx={{
                                    display: 'none'
                                }}
                            />
                        )}
                    />

                    <Grid container>
                        {basicLbls.map((name, index) => (
                            <Grid
                                item
                                mt={1.5}
                                mb={1}
                                xs={{ fund: 4, voucherNo: 3 }[name] || 5}
                                key={name}
                            >
                                <Controller
                                    name={name}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            fullWidth
                                            size="small"
                                            autoComplete="given-name"
                                            label={t(name === 'fund' ? 'fund' : name)}
                                            required={index === 0}
                                            type={name === 'voucherNo' ? 'number' : 'text'}
                                            select={name === 'fund'}
                                            InputProps={{ style: { borderRadius: 0, height: '35px' } }}
                                            InputLabelProps={{
                                                style: {
                                                    lineHeight: '1em',
                                                },
                                            }}
                                            value={field.value}
                                            onChange={name === 'fund' ? (e) => {
                                                field.onChange(e);
                                                setSelectedFundType(e.target.value);
                                            } : field.onChange}
                                        >
                                            {name === 'fund' && fundTypes.map(({ value, label }) => (
                                                <MenuItem key={value} value={value} sx={{ textAlign: 'left' }}>
                                                    {label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>

                    <TableContainer>
                        <Table sx={{ border: '1px solid #ecc' }}>
                            <TableHead>
                                <TableRow>
                                    {['serialNo', 'regarding', 'amount'].map((header, idx) => (
                                        <TableCell
                                            key={header}
                                            align="center"
                                            sx={{
                                                borderRight: idx < 2 ? '1px solid #ecc' : 'none',
                                                fontSize: '1rem',
                                                width: ['5%', '50%', '19%'][idx],
                                                padding: '2.5px 5px 2.5px 5px'
                                            }}
                                        >
                                            {t(header)}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {regardingsNames.map((name, index) => (
                                    <TableRow key={index}>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: 0 }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="left" sx={{ borderRight: '1px solid #ecc', padding: '2.5px 5px 2.5px 5px' }}>
                                            <Controller
                                                name={name}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        size="small"
                                                        select
                                                        variant="standard"
                                                        InputProps={{ disableUnderline: true }}
                                                        required={index === 0}
                                                    >
                                                        {voucherRegardings.map(({ value, label }, idx) => (
                                                            <MenuItem key={idx} value={value} sx={{ textAlign: 'left' }}>
                                                                {label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', padding: '2.5px 5px 2.5px 5px' }}>
                                            <Controller
                                                name={amountsNames[index]}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        fullWidth
                                                        size="small"
                                                        variant="standard"
                                                        type="number"
                                                        InputProps={{
                                                            disableUnderline: true,
                                                            sx: { '& input': { textAlign: 'center' } },
                                                        }}
                                                        required={index === 0}
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <TableRow>
                                    <TableCell colSpan={2} sx={{ borderRight: '1px solid #ecc', padding: '2.5px 5px 2.5px 5px' }}>
                                        <TextField
                                            fullWidth
                                            size="small"
                                            variant="standard"
                                            defaultValue={t('allTotal')}
                                            InputProps={{
                                                readOnly: true,
                                                disableUnderline: true,
                                                sx: { '& input': { textAlign: 'right' } },
                                            }}
                                        />
                                    </TableCell>
                                    <TableCell sx={{ padding: '2.5px 5px 2.5px 5px' }}>
                                        <Controller
                                            name="total"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    size="small"
                                                    variant="standard"
                                                    type="number"
                                                    InputProps={{
                                                        disableUnderline: true,
                                                        sx: { '& input': { textAlign: 'center' } },
                                                        readOnly: true
                                                    }}
                                                    required
                                                />
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell colSpan={3} sx={{ padding: '2.5px 5px 2.5px 5px' }}>
                                        <Controller
                                            name="inWords"
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    size="small"
                                                    variant="standard"
                                                    InputProps={{
                                                        startAdornment: <InputAdornment position="start">{t('inWords')}:</InputAdornment>,
                                                        disableUnderline: true,
                                                        sx: { '& input': { textAlign: 'left' } },
                                                    }}
                                                    required
                                                />
                                            )}
                                        />
                                    </TableCell>
                                </TableRow>

                            </TableBody>
                        </Table>
                    </TableContainer>
                    {/* </Grid> */}
                    <Grid item container xs={12} columnSpacing='4' mt={0.5}>
                        <Grid item xs={5} display='flex' justifyContent={'center'}>
                            <FormControl>
                                {receiverSignPreveiw ?
                                    <Card elevation={0}>
                                        <CardMedia
                                            component="img"
                                            height="40"
                                            image={receiverSignPreveiw}
                                            alt="receiverSignPreveiw"
                                        />
                                    </Card>
                                    :
                                    <Button>
                                        {t('receiverSign')}
                                    </Button>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={2} display='flex' justifyContent={'center'}>
                            <Divider orientation="vertical" />
                        </Grid>
                        <Grid item xs={5} display='flex' justifyContent={'center'}>
                            <FormControl>
                                {principalSignPreview ?
                                    <Button component='label' sx={{ padding: 0 }}>
                                        <Card elevation={0}
                                            onClick={handleOpenGetCode}
                                        >
                                            <CardMedia
                                                component="img"
                                                height="40"
                                                image={principalSignPreview}
                                                alt="principalSignPreview"
                                            />
                                        </Card>
                                    </Button>
                                    :
                                    <Button
                                        onClick={handleOpenGetCode}
                                    >
                                        {t('principalSign')}
                                    </Button>
                                }
                            </FormControl>
                        </Grid>

                        <Grid container item xs={12} spacing={1} mt={0.5} mb={1.5} justifyContent={'right'}>
                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={handleClear}
                                    color='error'
                                >
                                    {t('clear')}
                                </Button>
                            </Grid>
                            {catchID ?
                                <Grid item>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="success"
                                    >
                                        {t('update')}
                                    </Button>
                                </Grid>
                                :
                                <Grid item>

                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        color="success"
                                        loading={loading ? true : false}
                                        // disabled={type !== 'accountant' ? true : false}
                                        variant="contained"
                                    >
                                        <span>{t('save')}</span>
                                    </LoadingButton>
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}