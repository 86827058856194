import React, { useState } from 'react';
// Material UI Components
import { Avatar, CssBaseline, TextField, InputLabel, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// Utilities
import { MetaData } from '../utils/metaData';
import REGISTEREDACADEMIES from './RegisteredAcademies';

export default function SUPERADMINLOGIN() {

    const [isSuperAdmin, setIsSuperAdmin] = useState(false);

    // define handleSubmit function
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);

        const email = data.get('email')
        const password = data.get('password')
        const code = data.get('code')

        if (email === 'maktummishok5050@gmail.com' && password === 'mirkat@1996' && code === '514266') {
            setIsSuperAdmin(true)
        }
    }

    return (
        <>
            {!isSuperAdmin ?
                <Box sx={{
                    alignItems: 'center',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    width: '100%'
                }}>
                    <CssBaseline enableColorScheme />
                    <MetaData title={'Login'} />
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            padding: '2rem',
                            width: '400px',
                            borderRadius: '8px',
                            boxShadow: '0px 0px 15px 1px #e0ecff',
                        }}
                    >
                        <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5">
                            Super admin login
                        </Typography>
                        <Box component="form" width={'100%'} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <InputLabel>
                                Email
                            </InputLabel>
                            <TextField
                                required
                                fullWidth
                                id="email"
                                name="email"
                                autoComplete="email"
                                autoFocus
                                size='small'
                                placeholder="academic@email.com"
                            />
                            <InputLabel sx={{ marginTop: '18px' }}>
                                Password
                            </InputLabel>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                type="password"
                                id="password"
                                size='small'
                                placeholder="••••••"
                            />
                            <InputLabel sx={{ marginTop: '18px' }}>
                                Code
                            </InputLabel>
                            <TextField
                                required
                                fullWidth
                                name="code"
                                type="password"
                                id="code"
                                size='small'
                                placeholder="••••••"
                            />
                            <LoadingButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Login
                            </LoadingButton>
                        </Box>
                    </Box>
                    {/* <Copyright /> */}
                </Box>
                :
                <REGISTEREDACADEMIES />
            }
        </>

    );
}
