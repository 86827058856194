import { useSelector } from 'react-redux';
import { useMemo } from 'react';

const useAuth = () => {
    const user = useSelector(state => state.auth.user);

    // Optional: Memoize the returned values to prevent recalculating if 'user' hasn't changed
    return useMemo(() => ({
        code: user?.code || '',
        email: user?.email || '',
        type: user?.type || '',
        firstName: user?.first_name || '',
        lastName: user?.last_name || '',
        avatar: user?.avatar_url || '',
        sign_url: user?.sign_url || '',
        current_user_name: user ? `${user.first_name} ${user.last_name}` : '',
        academicName: user?.name,
        academicAddress: user?.address,
        englishName: user?.english_name,
        englishAddress: user?.english_address,
        academicMobile: user?.mobile,
        academyType: user?.academy_type,
        numberOfStudents: user?.number_of_students,
        country: user?.country,
        bill: user?.bill,
        logo: user?.logo_url,
        calligraphy: user?.calligraphy_url,
        academyPhoto: user?.academy_photo_url,
        smsBalance: user?.sms_balance,
        activation: user?.activation,
        expiryDate: user?.expiry_date
    }), [user]);
};

export default useAuth;