// React and related hooks
import React from 'react';
import { useTranslation } from 'react-i18next';

// MUI components and icons
import { Grid, Button, Typography, Container } from '@mui/material';
import { DataGrid, GridColumnMenu, GridToolbarContainer, useGridApiContext } from '@mui/x-data-grid';
import { Close, FileDownloadOutlined } from '@mui/icons-material';

// Custom components and utilities
import CustomPagination from '../layout/Pagination';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';
import { convertToBengaliDigits } from '../utils/converter';

// Utility Libraries
import dayjs from 'dayjs';

function CustomToolbar({ info }) {
    const { t } = useTranslation();

    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    return (
        <GridToolbarContainer>
            <Grid container alignItems='center' sx={{ padding: '0px 0px 3px 0px' }} justifyContent={'center'}>
                <Button onClick={handleExportCsv}>
                    <FileDownloadOutlined fontSize='small' />
                </Button>
                <Typography
                    variant="body1"
                    id="tableTitle"
                    component="div"
                    color='primary'
                    display='flex'
                    justifyContent='center'
                >
                    {info.name} {t('ofLogs')}, {t('date')}: {convertToBengaliDigits(dayjs(info.date).format('YYYY-MM-DD'))}
                </Typography>
            </Grid>
        </GridToolbarContainer >
    );
}

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuFilterItem: null,
            }}
        />
    );
}


export default function OFFICIANTLOGS({ rowData, closeDialog }) {
    const { t } = useTranslation();

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = [
        { field: 'id', headerName: t('serialNo'), headerClassName: 'CustomHeader' },
        { field: 'checkIn', headerName: t('checkIn'), minWidth: 150 },
        { field: 'checkOut', headerName: t('checkOut'), minWidth: 150 },
        { field: 'duration', headerName: t('duration'), minWidth: 150 },

    ];

    // Transform staffs array into the desired format
    const rows = rowData.logs.map((log, index) => {
        return {
            id: index + 1,
            checkIn: dayjs(log.check_in).format('h:mm A'),
            checkOut: log.check_out ? dayjs(log.check_out).format('h:mm A') : '',
            duration: log.check_out && log.check_in ? `${Math.floor(dayjs(log.check_out).diff(dayjs(log.check_in), 'minute') / 60)}h ${dayjs(log.check_out).diff(dayjs(log.check_in), 'minute') % 60}m` : '',
        };
    });

    return (
        <Container component="main" sx={{
            '& .CustomHeader': {
                backgroundColor: 'whitesmoke',
            },
        }}>
            <MetaData title={'OFFICIANTS LOGS'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <Grid container>
                <Grid item xs={12} mt={3} mb={3} sx={{
                    height: '500px'
                }}>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        density={'compact'}
                        pageSizeOptions={[5, 10, 20, 50, 99]}
                        scrollbarSize={0}
                        localeText={localeText}
                        sx={{
                            background: 'white',
                            '& .MuiDataGrid-footerContainer': {
                                height: '40px',
                                minHeight: '40px',
                                borderRadius: '3px',
                                overflow: 'hidden',
                                backgroundColor: '#FFF7FC',
                            }
                        }}

                        initialState={{
                            pagination: {
                                paginationModel: { pageSize: 99 }, // Default page size
                            },

                            columns: {
                                columnVisibilityModel: {
                                    __check__: false, // for disabled checkbox selection
                                    nid: false,
                                    salaryAmount: false
                                },
                            },
                        }}

                        slots={{
                            toolbar: () => <CustomToolbar info={rowData} />,
                            columnMenu: CustomColumnMenu,
                            pagination: CustomPagination,
                        }}

                        slotProps={{
                            toolbar: {
                                showQuickFilter: true,
                            },
                        }}
                    />
                </Grid>
            </Grid>
        </Container>
    );
}
