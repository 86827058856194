import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Material UI Components
import { Avatar, CssBaseline, TextField, InputLabel, Grid, Box, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

// Actions and Constants
import { LoginAction } from '../../actions/authActions';

// Utilities
import { MetaData } from '../utils/metaData';

function Copyright(props) {
    return (
        <Typography variant="body2" sx={{ position: 'fixed', bottom: '0' }} color="text.secondary" align="center" {...props}>
            © {new Date().getFullYear()} TANZIM ACADEMY. All rights reserved.
        </Typography>
    );
}

export default function LOGIN() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { error, isAuthenticated, user } = useSelector(state => state.auth);

    // //useEffect is used to work these functionality in one time
    // useEffect(() => {
    //     if (isLogged) {
    //         const basics = JSON.parse(localStorage.getItem('basics'));

    //         const currentSettings = {
    //             academyType: basics?.academyType || user.academy_type || 'school',
    //             language: basics?.language || 'en',
    //             themeMode: basics?.themeMode || 'light'
    //         };
    //         console.log(currentSettings);

    //         localStorage.setItem('basics', JSON.stringify(currentSettings));

    //         dispatch({ type: LOGIN_RESET })
    //     }

    //     if (error) {
    //         enqueueSnackbar(t(error), { variant: 'error' });
    //         // enqueueSnackbar(t('invalidEmailOrPassword'), { variant: 'error' });
    //         dispatch(clearErrors());
    //     }
    // }, [dispatch, error, t, enqueueSnackbar, navigate, isLogged, user?.academy_type])

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t(error), { variant: 'error' });
            // enqueueSnackbar(t('invalidEmailOrPassword'), { variant: 'error' });
            return
        }

        if (isAuthenticated && user?.code) {
            const code = user?.code;
            if (code) {
                // store basics to the localstore
                const basics = JSON.parse(localStorage.getItem('basics'));
                const currentSettings = {
                    academyType: basics?.academyType || user.academy_type || 'school',
                    language: basics?.language || 'en',
                    themeMode: basics?.themeMode || 'light'
                };

                localStorage.setItem('basics', JSON.stringify(currentSettings));

                // set cookie to use in the backend
                document.cookie = user.academy_type !== 'madrasa' && basics?.language === 'bn' ? `lang=bn_g; path=/` : `lang=${basics?.language || 'en'}; path=/`;

                // redirect to the software
                navigate(`/${code}`);
            }
        }
    }, [isAuthenticated, user, navigate, error, enqueueSnackbar, t]);

    // define handleSubmit function
    const handleSubmit = (e) => {
        e.preventDefault();
        const data = new FormData(e.currentTarget);
        // Dispatch the login action
        dispatch(LoginAction({
            email: data.get('email'),
            password: data.get('password')
        }))
    }

    return (
        <Box sx={{
            alignItems: 'center',
            height: '100vh',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%'
        }}>
            <CssBaseline enableColorScheme />
            <MetaData title={'LOGIN'} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '2rem',
                    width: '400px',
                    borderRadius: '8px',
                    boxShadow: '0px 0px 15px 1px #e0ecff',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {t('login')}
                </Typography>
                <Box component="form" width={'100%'} onSubmit={handleSubmit} sx={{ mt: 1 }}>
                    <InputLabel>
                        {t('academicEmail')}
                    </InputLabel>
                    <TextField
                        required
                        fullWidth
                        id="email"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        size='small'
                        placeholder="academic@email.com"
                    />
                    <InputLabel sx={{ marginTop: '18px' }}>
                        {t('password')}
                    </InputLabel>
                    <TextField
                        required
                        fullWidth
                        name="password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        size='small'
                        placeholder="••••••"
                    />
                    <LoadingButton
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        {t('login')}
                    </LoadingButton>
                    <Grid container mt={1}>
                        <Grid item textAlign={'center'}>
                            {/* <Link href="#" variant="body2">
                                {t('forgotPassword')}
                            </Link> */}
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Copyright />
        </Box>
    );
}
