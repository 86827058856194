// React and Hooks
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useAuth from '../../hooks/UseAuth';
import { useTranslation } from 'react-i18next';
import { useComponent } from '../../hooks/ComponentContext';

// Material UI Components
import { Grid, Button, ButtonGroup, Chip, Box, Menu, MenuItem } from '@mui/material';
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, GridActionsCellItem, useGridApiRef, useGridApiContext, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';

// Material UI Icons
import EditIcon from '@mui/icons-material/Edit';
import ArrowBack from '@mui/icons-material/ArrowBack';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';

// Custom Components and Utilities
import CustomPagination from '../../layout/Pagination';
import { MetaData } from '../../utils/metaData';
import { GETCONFIRMATION } from '../../layout/MiniComponents';
import { convertToBengaliDigits } from '../../utils/converter';
import useDataGridColumns from '../../utils/useDataGridColumns';

// Redux Actions
import { acceptProduct, getSoldProducts } from '../../../actions/othersActions';
import { soldProductListPdf } from '../../../actions/pdfActions';

// Constants
import { getUserTypes } from '../../../constants/commonContstants';
import { ACCEPT_ALL_PRODUCT_RESET } from '../../../constants/othersConstants';

// Day.js
import dayjs from 'dayjs';

function CustomToolbar() {
    const { t } = useTranslation();
    const { changeComponent } = useComponent();
    const [currentRows, setCurrentRows] = React.useState('');
    const dispatch = useDispatch();

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== 'id' && column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('soldProductList'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // open list window
    const OpenSellingProductWindow = () => {
        changeComponent('buyAndSell')
    };

    const handlePdf = () => {
        dispatch(soldProductListPdf(currentRows));
    };


    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={OpenSellingProductWindow}><ArrowBack /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlinedIcon />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('soldProductList')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function SOLDPRODUCTLIST() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const userTypes = getUserTypes(t)
    const apiRef = useGridApiRef();
    const { code } = useAuth();

    const { soldProducts, loading } = useSelector(state => state.allSoldProduct);
    const { products } = useSelector(state => state.allProduct);
    const { isAccepted } = useSelector(state => state.product);

    const [open, setOpen] = React.useState(false);
    const [soldAmount, setSoldAmount] = React.useState('');
    const [rowInfo, setRowInfo] = React.useState('');

    useEffect(() => {
        dispatch(getSoldProducts(code))
        if (isAccepted) {
            setOpen(false)
            dispatch({ type: ACCEPT_ALL_PRODUCT_RESET })
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
        }
    }, [dispatch, isAccepted, t, enqueueSnackbar, code])

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), width: 80, minWidth: 80, flex: 0 },
        { field: 'id', headerName: t('dataId') },
        { field: 'sellerName', headerName: t('sellerName') },
        { field: 'productName', headerName: t('productName') },
        { field: 'buyingPrice', headerName: t('buyingPrice'), width: 80, minWidth: 80, flex: 0 },
        { field: 'sellingPrice', headerName: t('sellingPrice'), width: 80, minWidth: 80, flex: 0 },
        { field: 'sellingAmount', headerName: t('sellingAmount'), width: 100, minWidth: 100, flex: 0, editable: true },
        { field: 'totalSellingPrice', headerName: t('totalSellingPrice'), width: 110, minWidth: 80, flex: 0 },
        { field: 'benifitOrLoss', headerName: t('benifitsOrLoss'), width: 80, minWidth: 80, flex: 0 },
        { field: 'responsible', headerName: t('responsible'), width: 80, minWidth: 80, flex: 0 },
        { field: 'date', headerName: t('date'), width: 100, minWidth: 100, flex: 0 },

        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            width: 80, minWidth: 80, flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<EditIcon />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<CheckCircleIcon />}
                    label="acceptAll"
                    onClick={() => handleConfirmation(params.row)}
                />
            ],
        },

    ]);

    // Transform sold products array into the desired format
    const rows = soldProducts?.map((p, index) => {
        const responsible = userTypes.find(type => type.value === p.responsible);
        return {
            serial: index + 1,
            id: p.id,
            sellerName: p.seller_name,
            productName: p.product_name,
            buyingPrice: `${p.per_product_buying_price} ${t('moneySymbol')}`,
            sellingPrice: `${p.per_product_selling_price} ${t('moneySymbol')}`,
            sellingAmount: `${p.total_sold} ${p.unit}`,
            totalSellingPrice: `${p.per_product_selling_price * p.total_sold} ${t('moneySymbol')}`,
            benifitOrLoss: `${(p.per_product_selling_price * p.total_sold) - (p.total_sold * p.per_product_buying_price)} ${t('moneySymbol')}`,
            date: dayjs(p.created_at).format('YYYY-MM-DD'),
            responsible: responsible.label
        };
    });

    // for confirmation to accept all the products
    const handleConfirmation = (rowData) => {
        setOpen(true)
        setRowInfo(rowData)
    };

    const handleAcceptAll = () => {
        const info = {
            id: rowInfo.id,
            newSoldAmount: parseInt(rowInfo.sellingAmount),
            acceptingAll: true
        }
        dispatch(acceptProduct(info, code))
    };

    const handleEdit = (rowData) => {
        const sold = soldProducts.find(p => p.id === rowData.id);
        setSoldAmount(sold.total_sold)
        apiRef.current.startCellEditMode({ id: rowData.id, field: 'sellingAmount' });
    }

    const handleCellEditStart = (params) => {
        const amount = soldProducts.find(p => p.id === params.row.id);
        setSoldAmount(amount.total_sold)
    };

    const handleProcessRowUpdate = (newRow) => {
        // handle not allowed amounts
        const newSoldAmount = parseInt(newRow.sellingAmount);
        if (newSoldAmount === soldAmount) {
            return newRow;
        } else if (newSoldAmount <= 0) {
            enqueueSnackbar(t('notWorked'), { variant: 'error' });
            return newRow;
        }

        // process to handle new amount
        const idParts = newRow.id.split('|');
        const product = products.find(p => p.id === idParts[idParts.length - 1]);

        const possibleAmount = (product.total_has - product.total_sold) + soldAmount

        if (possibleAmount < newSoldAmount) {
            const maxNotPossibleMessage = t('maxNotPossible', { remain: possibleAmount, furniture: newRow.product_name });
            enqueueSnackbar(maxNotPossibleMessage, { variant: 'error' });
        } else {
            // updating product given amount
            const acceptingAmount = soldAmount - newSoldAmount

            const info = {
                id: newRow.id,
                newSoldAmount: newSoldAmount,
                acceptingAmount: acceptingAmount,
                acceptingAll: false
            }
            dispatch(acceptProduct(info, code))
        }
        return newRow;
    };

    // handle confirmation
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'SOLD PRODUCT LIST'} />

            <GETCONFIRMATION handleOpen={open} confirmationText={t('acceptFurnitureConfrim', { provider: rowInfo.sellerName, amount: convertToBengaliDigits(rowInfo ? rowInfo.sellingAmount : ''), furniture: rowInfo.productName })} handleClose={handleClose} handleOk={handleAcceptAll} />

            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                loading={loading}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                localeText={localeText}

                isRowSelectable={() => false}
                apiRef={apiRef}
                onCellEditStart={handleCellEditStart}
                processRowUpdate={handleProcessRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                onProcessRowUpdateError={(error) => {
                    console.error("Row update failed: ", error);
                }}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 },
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            id: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar />,
                    pagination: CustomPagination,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
