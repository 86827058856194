// React and hooks
import React, { useEffect, useRef, useState } from 'react';

// MUI components and icons
import { Box, Grid, Button, TextField, Chip, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import { t } from 'i18next'
import { useForm, Controller } from 'react-hook-form';
// Custom utilities
import useAuth from '../../hooks/UseAuth';
import { useDispatch, useSelector } from 'react-redux';
import { getSettings, sendSMS, updateSettings } from '../../../actions/othersActions';
import { allClass } from '../../../actions/eduActions';
import { useSnackbar } from 'notistack';
import { SEND_SMS_RESET } from '../../../constants/othersConstants';
import { loadUser } from '../../../actions/authActions';
import { MetaData } from '../../utils/metaData';

export const CustomSMSForm = () => {
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { code, smsBalance } = useAuth();
    const [selectedReceiver, setSelectedReceiver] = useState('');

    const { classes } = useSelector(state => state.allClass);
    const { allSettings } = useSelector(state => state.settings);
    const { success, loading } = useSelector(state => state.messages);
    const classnames = [...new Set(classes?.map(classItem => classItem.class_name))];

    const defaultValues = {
        receiver: '',
        className: '',
        mobile: '',
        message: ''
    };

    const { handleSubmit, control, watch, reset, getValues, setValue } = useForm({ defaultValues });
    const [typedLetters, setTypedLetters] = useState(0);
    const [remaining, setRemaining] = useState(0);
    const [messageCount, setMessageCount] = useState(1);

    useEffect(() => {
        dispatch(allClass(code))
        dispatch(getSettings(code))
    }, [dispatch, code]);

    useEffect(() => {
        if (success) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(loadUser(code))
            dispatch({ type: SEND_SMS_RESET })
        }
    }, [dispatch, success, enqueueSnackbar, code]);

    // Utility function to check if the message contains non-language letters
    const containsNonLanguageLetter = (text) => {
        const nonLanguageRegex = /[^A-Za-z0-9\s]/; // Regex to detect non-English, non-digit, and special characters
        return nonLanguageRegex.test(text);
    };

    // Watch the `message` field dynamically
    const message = watch("message");

    // Effect to calculate the letter count, remaining letters, and total messages
    useEffect(() => {
        const totalLetters = message.length;

        // Determine batch size based on content type
        const batchSize = containsNonLanguageLetter(message) ? 60 : 150;

        setTypedLetters(totalLetters);

        // Remaining letters for the current batch size
        const newRemaining = batchSize - (totalLetters % batchSize || batchSize);
        message.length > 0 ? setRemaining(newRemaining) : setRemaining(batchSize);

        // Total message count (including partially filled one)
        setMessageCount(Math.ceil(totalLetters / batchSize) || 1);
    }, [message]);

    const receivers = [
        { label: t('allTeachers'), value: 'teachers' },
        { label: `${t('allForPerson')} ${t('students')}`, value: 'students' },
        { label: `${t('specific')} ${t('class')}`, value: 'class' },
        { label: t('custom'), value: 'custom' },
    ];

    const onSubmit = (data) => {
        const balance = ((parseFloat(smsBalance) * 100) / 45).toFixed(2);
        if (balance < messageCount) {
            return enqueueSnackbar(t('balanceFinishde'), { variant: 'info' });
        }

        dispatch(sendSMS({ ...data, remainBalance: smsBalance - parseFloat(messageCount * 0.45) }, code))
        reset();
    };

    const handleSaveingDraft = () => {
        const finalData = {
            messages: [
                ...(allSettings.draft_messages || []),
                getValues('message'),
            ],
        };
        dispatch(updateSettings(finalData, code))
        enqueueSnackbar(t('successMessage'), { variant: 'success' });
    };

    const handleDeleteDraft = () => {
        const finalData = {
            messages: allSettings.draft_messages.filter(message => message !== getValues('message'))
        }
        dispatch(updateSettings(finalData, code))
        enqueueSnackbar(t('successMessage'), { variant: 'success' });
        reset();
    };

    return (
        <Box
            component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'
            sx={{
                p: 4,
                borderRadius: 2,
                maxWidth: 600,
                mx: "auto",
                margin: "auto",
                bgcolor: 'background.paper',
                boxShadow: '0px 0px 15px 0.5px #e0ecff',
            }}
        >
            <MetaData title={'SMS SENDING'} />
            <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12} textAlign="center">
                    <Chip
                        sx={{ fontSize: '1.1rem' }}
                        label={t('newMessageForm')}
                    />
                </Grid>

                {/* Receiver Selection */}
                <Grid item xs={5}>
                    <Controller
                        name="receiver"
                        control={control}
                        render={({ field: { onChange, value = '', ...field } }) => (
                            <TextField
                                select
                                value={value}
                                label={t('receiver')}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                    setSelectedReceiver(e.target.value);
                                }}
                                size="small"
                                fullWidth
                                {...field}
                            >
                                {receivers?.map(({ value, label }, index) => (
                                    <MenuItem key={index} value={value}>
                                        {label}
                                    </MenuItem>
                                ))}
                            </TextField>
                        )}
                    />
                </Grid>

                {/* Dynamic Input Based on Selected Receiver */}
                <Grid item xs={selectedReceiver === 'student' || selectedReceiver === 'teacher' ? 12 : 7}>
                    {selectedReceiver === 'class' && (
                        <Controller
                            name="className"
                            control={control}
                            render={({ field: { onChange, value = '', ...field } }) => (
                                <TextField
                                    select
                                    value={value}
                                    label={t('className')}
                                    onChange={(e) => onChange(e.target.value)}
                                    size="small"
                                    fullWidth
                                    {...field}
                                >
                                    {classnames?.map((class_name) => (
                                        <MenuItem key={class_name} value={class_name}>
                                            {class_name}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            )}
                        />
                    )}

                    {(selectedReceiver === 'custom') && (
                        <Controller
                            name="mobile"
                            control={control}
                            render={({ field: { onChange, value = '', ...field } }) => (
                                <TextField
                                    value={value}
                                    placeholder='017.................., 018..................'
                                    label={`${t('mobile')} ${t('number')}`}
                                    onChange={(e) => onChange(e.target.value)}
                                    size="small"
                                    fullWidth
                                    {...field}
                                />
                            )}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Controller
                        name="draft"
                        control={control}
                        render={({ field: { onChange, value = '', ...field } }) => (
                            <TextField
                                select
                                value={value}  // Make sure this value is initialized and corresponds to one of the messages
                                label={t('drafts')}
                                onChange={(e) => {
                                    onChange(e.target.value);
                                    setValue('message', e.target.value);
                                }}
                                size="small"
                                fullWidth
                                {...field}
                            >
                                {allSettings.draft_messages && allSettings.draft_messages.length > 0 ? (
                                    allSettings.draft_messages.map((message, index) => (
                                        <MenuItem key={index} value={message}>
                                            {message}
                                        </MenuItem>
                                    ))
                                ) : (
                                    <MenuItem value="" disabled>
                                        {t('no_messages')}
                                    </MenuItem>
                                )}
                            </TextField>
                        )}
                    />
                </Grid>

                {/* Message Input */}
                <Grid item xs={12}>
                    <Controller
                        name="message"
                        control={control}
                        render={({ field: { onChange, value = '', ...field } }) => (
                            <TextField
                                value={value}
                                placeholder={t('typeHereYourMsg')}
                                onChange={(e) => onChange(e.target.value)}
                                multiline
                                rows={4}
                                fullWidth
                                {...field}
                            />
                        )}
                    />
                </Grid>

                {/* Submit Button */}
                <Grid container item xs={12} textAlign="center" alignItems={'center'}>
                    <Grid item xs={12} textAlign={'left'}>
                        <Button
                            disableElevation
                            variant="contained"
                            color="error"
                            size='small'
                            onClick={handleDeleteDraft}
                            sx={{ marginRight: 1 }}
                        >
                            {t('draft')} {t('delete')}
                        </Button>
                        <Button
                            disableElevation
                            variant="contained"
                            color="primary"
                            size='small'
                            onClick={handleSaveingDraft}
                            sx={{ marginRight: 1 }}
                        >
                            {t('draft')} {t('save')}
                        </Button>
                    </Grid>
                    <Grid item xs={12} display={'flex'} mt={2} justifyContent={'space-between'}>
                        <Grid textAlign={'left'}>
                            <Chip size="small" sx={{ marginRight: 1 }} color="primary" label={`লেটারস ${typedLetters} টি`} />
                            <Chip size="small" sx={{ marginRight: 1 }} color="success" label={`বাকি ${remaining} টি`} />
                            <Chip size="small" sx={{ marginRight: 1 }} color="secondary" label={`মেসেজ ${messageCount} টি`} />
                        </Grid>
                        <LoadingButton
                            // disableElevation
                            variant="contained"
                            color="success"
                            type='submit'
                            size='small'
                            loading={loading}
                            onClick={handleSubmit}
                            endIcon={< SendIcon />}
                        >
                            {t('send')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};