import { useSelector } from 'react-redux';
import LeaveTable from './LeaveTable';
import { allStudentLeaves, deleteStudentLeave } from '../../actions/eduActions';
import { DELETE_STUDENT_LEAVE_RESET, NEW_STUDENT_LEAVE_RESET, UPDATE_STUDENT_LEAVE_RESET } from '../../constants/eduConstants';
import { LeaveFormStudent } from './LeaveForm';

export default function STUDENTLEAVES() {
    const studentLeaves = useSelector(state => state.allStudentLeaves.studentLeaves);
    return (
        <LeaveTable
            isStudent={true}
            fetchDataAction={allStudentLeaves}
            deleteAction={deleteStudentLeave}
            resetActions={[NEW_STUDENT_LEAVE_RESET, UPDATE_STUDENT_LEAVE_RESET, DELETE_STUDENT_LEAVE_RESET]}
            leavesData={studentLeaves}
            leaveForm={LeaveFormStudent}
        />
    );
}