// React and related hooks
import React, { useState, useEffect, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

// MUI components and icons
import {
    TextField, Grid, Box, Container, MenuItem, Chip, Button
} from '@mui/material';
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

// Custom components and utilities
import { FORMHEADING } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';

// Actions and Constants
import { newStudent, allClass } from '../../actions/eduActions';
import { nanoid } from 'nanoid';
import { NEW_STUDENT_RESET } from '../../constants/eduConstants';

export default function ONLINEADMISSIONFORM({ closeDialog, academicInfo }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [photoView] = useState('');
    const [photo, setPhoto] = useState('');
    const formRef = useRef(null);

    // getting the value from redux
    const { classes } = useSelector(state => state.allClass);
    const { isSaved } = useSelector(state => state.newStudent);

    // Find student according to the form no
    const departments = [...new Set(classes?.map(depart => depart.department))];
    const classnames = [...new Set(classes?.map(classItem => classItem.class_name))];

    const defaultValues = {
        id: nanoid(),
        code: academicInfo.code,
        newOld: '',
        department: '',
        residenceType: '',
        admitNo: '',
        name: '',
        fatherName: '',
        village: '',
        postOffice: '',
        subDistrict: '',
        district: '',
        birthDate: '',
        age: '',
        fatherMobile: '',
        fatherJob: '',
        guardian2: '',
        preAcademy: '',
        preClass: '',
        choosingClass: '',
    };

    const { handleSubmit, control } = useForm({ defaultValues });

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allClass(academicInfo.code))
    }, [dispatch, academicInfo.code])

    useEffect(() => {
        if (isSaved) {
            dispatch({ type: NEW_STUDENT_RESET })
            setOpen3(true);
        }
    }, [dispatch, isSaved])

    // First, ensure departments array is defined and includes student.department if necessary
    let updatedDepartments = departments ? [...departments] : [];

    const basicValues = [
        ['new', 'old'],
        updatedDepartments,
        ['residential', 'nonResidential']
    ];

    const lbls = ['newOld', 'department', 'residenceType'];
    const lbls1 = ['name', 'fatherName', 'village', 'postOffice', 'subDistrict', 'district', 'birthDate', 'age', 'fatherMobile', 'fatherJob', 'guardian2', 'preAcademy', 'preClass', 'choosingClass'];

    // handle submit
    const onSubmit = (data) => {
        const finalData = {
            isOnline: true,
            ...data,
            photo: photo
        }
        dispatch(newStudent(finalData, academicInfo.code));
    };

    // handle profile
    const handlephoto = (data) => {
        setPhoto(data);
    };

    // handle success admission
    const [open3, setOpen3] = React.useState(false);

    const handleClose3 = () => {
        closeDialog();
        setOpen3(false);
    };

    return (
        <Box className="globalShapeDesign" paddingRight={'5px'}>
            <MetaData title={'ADMISSION FORM'} />

            <Dialog
                open={open3}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {t('congratulations')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {t('submissionStatus')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant='contained' color='success' onClick={handleClose3} autoFocus>
                        {t('ok')}
                    </Button>
                </DialogActions>
            </Dialog>

            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <Container className='customGridClass' maxWidth={'md'} sx={{ marginTop: '5px', marginBottom: '5px', borderRadius: '10px' }}>
                <Box sx={{
                    width: '100%'
                }}>
                    <FORMHEADING onSendData={handlephoto} photoView={photoView} text={'admissionForm'} academicInfo={academicInfo} />
                </Box>
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container sx={{
                        height: {
                            xs: 'auto',
                            sm: '480px'
                        },
                        padding: '3px',
                        overflow: 'auto',

                    }}>
                        <Grid container spacing={1.5} paddingBottom={0.2} paddingTop={2}>
                            {lbls.map((lbl, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    key={`grid-${index}`}
                                >
                                    <Controller
                                        name={`id`}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                sx={{
                                                    display: 'none'
                                                }}
                                            />
                                        )}
                                    />

                                    {lbl === 'newOld' || lbl === 'department' || lbl === 'residenceType' ?
                                        <Controller
                                            key={`controller-${index}`}
                                            name={lbl}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="given-name"
                                                    fullWidth
                                                    size="small"
                                                    id={`idno${index}`}
                                                    select
                                                    label={lbl === 'newOld' ? t('newOld') : lbl === 'department' ? t('department') : t('residenceType')}
                                                    required
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: '0px'
                                                        }
                                                    }}
                                                >
                                                    {basicValues[lbl === 'newOld' ? 0 : lbl === 'department' ? 1 : 2].map((option, index) => (
                                                        <MenuItem key={`${option}-${index}`} value={option}>
                                                            {t(option)}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                        :
                                        <Controller
                                            key={`controller-${index}`}
                                            name={lbl}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    type='number'
                                                    fullWidth
                                                    size="small"
                                                    id={`idno${index}`}
                                                    label={t(lbl)}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: '0px'
                                                        }
                                                    }}
                                                />
                                            )}
                                        />
                                    }
                                </Grid>
                            ))}

                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent={'center'}
                                mt={1}
                            >
                                <Chip sx={{
                                    fontSize: '1rem',
                                }} label={t('studentAddress')} />
                            </Grid>
                            {lbls1.map((lbl, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={6}
                                    key={`grid2-${index}`}
                                >
                                    {/* <Controller
                                        key={`controller-${index}`}
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                autoComplete="given-name"
                                                fullWidth
                                                required
                                                type={lbl === 'age' ? 'number' : 'text'}
                                                size="small"
                                                id={`id${index}`}
                                                label={t(lbl)}
                                                InputProps={{
                                                    style: {
                                                        borderRadius: '0px'
                                                    }
                                                }}
                                            />
                                        )}
                                    /> */}
                                    <Controller
                                        key={`controller-${index}`}
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            lbl === 'choosingClass' ? (
                                                <TextField
                                                    {...field}
                                                    select // Enables the TextField to render a dropdown
                                                    fullWidth
                                                    size="small"
                                                    id={`idno${index}`}
                                                    label={t(lbl)}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: '0px',
                                                        },
                                                    }}
                                                >
                                                    {classnames.map((option, idx) => (
                                                        <MenuItem key={`${option}-${idx}`} value={option}>
                                                            {t(option)}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            ) : (
                                                <TextField
                                                    {...field}
                                                    type={lbl === 'age' ? 'number' : 'text'}
                                                    fullWidth
                                                    size="small"
                                                    id={`idno${index}`}
                                                    label={t(lbl)}
                                                    InputProps={{
                                                        style: {
                                                            borderRadius: '0px',
                                                        },
                                                    }}
                                                />
                                            )
                                        )}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid container columnSpacing={{ xs: 0.5, sm: 1 }} mb={1} mt={1} justifyContent={'flex-end'}>
                        <Grid item>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                color="success"
                                variant="contained"
                            >
                                <span>{t('submit')}</span>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box >
    );
}
