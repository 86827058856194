// React and Related Imports
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

// Material-UI Imports
import { Button, CssBaseline, TextField, Grid, Box, Container, Chip, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CloseIcon from '@mui/icons-material/Close';

// Date Adapter Import

// Constants and Actions Imports
import { newStaff, updateStaff } from '../../actions/adminisActions';

// Custom Component Imports
import { FORMHEADING } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';
import { nanoid } from 'nanoid';
import useAuth from '../hooks/UseAuth';
import { NEW_STAFF_RESET } from '../../constants/adminisConstants';
import { allClass } from '../../actions/eduActions';

export default function STAFFREGISTERFORM({ closeDialog, staffId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [catchID, setCatchID] = useState(staffId);
    const [photoView, setPhotoView] = useState('');
    const [photo, setPhoto] = useState('');
    const formRef = useRef(null);
    const { code } = useAuth();
    const { staff } = useSelector(state => state.allStaff);
    const { success, loading } = useSelector(state => state.newStaff)

    // Find staff according to the form no
    const s = staff?.find(staff => staff.id === catchID);
    const { classes } = useSelector(state => state.allClass);

    useEffect(() => {
        dispatch(allClass(code))
    }, [dispatch, code])

    // Find student according to the form no
    const departments = [...new Set(classes?.map(depart => depart.department))];

    const defaultValues = {
        code: code,
        staffName: s?.name || '',
        fatherName: s?.father_name || '',
        designation: s?.designation || '',
        birthDate: s?.birth_date || '',
        mobile: s?.mobile || '',
        nid: s?.nid || '',
        village: s?.village || '',
        postOffice: s?.post_office || '',
        subDistrict: s?.sub_district || '',
        district: s?.district || '',
        qualification: s?.qualification || '',
        preExperience: s?.pre_experience || '',
        termsAndCondition: s?.terms || '',
        dutyList: s?.duty_list || '',
        salaryAmount: s?.salary || '',
        boardingFee: s?.boarding_fee || '',
        residenceType: s?.residence_type || '',
        candidateType: s?.candidate_type || '',
        hireDate: s?.hire_date || '',
        idNo: s?.staff_id || '',
        department: s?.department || ''
    };

    const { handleSubmit, control, reset } = useForm({ defaultValues });

    // First, ensure departments array is defined and includes student.department if necessary
    let updatedDepartments = departments ? [...departments] : [];

    if (s?.department && !updatedDepartments.includes(s.department)) {
        updatedDepartments.push(s.department);
    }

    useEffect(() => {
        if (s?.photo_url) {
            setPhoto(s.photo_url)
            setPhotoView(s.photo_url)
        }
    }, [dispatch, s?.photo_url, catchID, s])

    // handle clear
    const handleClear = () => {
        const emptyValues = {
            staffName: '',
            fatherName: '',
            designation: '',
            birthDate: '',
            mobile: '',
            nid: '',
            village: '',
            postOffice: '',
            subDistrict: '',
            district: '',
            qualification: '',
            preExperience: '',
            termsAndCondition: '',
            dutyList: '',
            salaryAmount: '',
            residenceType: '',
            candidateType: '',
            hireDate: '',
            idNo: '',
        };
        reset(emptyValues);
        setPhoto('')
        setPhotoView('https://')
        setCatchID('')
    };

    useEffect(() => {
        if (success) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_STAFF_RESET })
            handleClear();
        }
    })

    const lbls5 = ['department', 'residenceType', 'candidateType'];
    const lbls = ['staffName', 'fatherName', 'designation', 'birthDate', 'mobile', 'nid', 'village', 'postOffice', 'subDistrict', 'district'];
    const lbls3 = ['qualification', 'preExperience'];
    const lbls4 = ['termsAndCondition', 'dutyList', 'salaryAmount', 'boardingFee', 'idNo', 'hireDate'];

    // handle submit
    const onSubmit = (data) => {
        if (data.mobile.length > 30) {
            return enqueueSnackbar(t('checkMobile'), { variant: 'error' });
        }

        const finalData = {
            id: !catchID ? nanoid() : s.id,
            ...data,
            avatar: photo
        }

        if (!catchID) {
            dispatch(newStaff(finalData, code));
        } else {
            dispatch(updateStaff(finalData, code));
        }
    };

    // handle profile
    const handlephoto = (data) => {
        setPhoto(data);
    };

    return (
        <Container maxWidth={false} sx={{
            borderRadius: '10px',
            maxWidth: '700px',
        }}>
            <MetaData title={'STAFF REGISTER'} />
            <CssBaseline />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <CloseIcon fontSize='small' />
            </CustomCrossButton>
            <Box sx={{
                boxShadow: '0px 3px 2px -2px rgba(0, 0, 0, 0.2)',
            }}>
                <FORMHEADING onSendData={handlephoto} photoView={photoView} text={'officiantForm'} />
            </Box>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container
                        sx={{
                            '&::-webkit-scrollbar-track': {
                                marginTop: '18px',
                            },
                            overflow: 'auto',
                            position: 'relative',
                            height: {
                                xs: 'auto',
                                sm: '480px',
                                marginTop: '5px'
                            },
                            padding: '3px',
                        }}
                    >
                        <Grid container item xs={12} mt={1} spacing={1.2}>
                            {lbls.map((lbl, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    md={6}
                                    key={`grid-${index}`}
                                >
                                    {lbl !== 'birthDate' ?
                                        <Controller
                                            key={`controller-${lbl}`}
                                            name={lbl}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="given-name"
                                                    fullWidth
                                                    required
                                                    size="small"
                                                    id={`id${index}`}
                                                    label={t(lbl)}
                                                />
                                            )}
                                        />
                                        :

                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Controller
                                                key={`controller-${lbl}`}
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        value={field.value ? dayjs(field.value) : null}
                                                        label={t(lbl)}
                                                        format="YYYY-MM-DD"
                                                        slotProps={{ textField: { size: 'small', fullWidth: true, required: true } }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    }
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container item xs={12} spacing={1.2}>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent={'center'}
                                mt={2.5}
                            >
                                <Chip sx={{
                                    fontSize: '1rem',
                                }} label={t('qualificationAndPreExp')} />
                            </Grid>
                            {lbls3.map((lbl, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={`grid-${index}`}
                                >
                                    <Controller
                                        key={`controller-${lbl}`}
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                autoComplete="given-name"
                                                fullWidth
                                                multiline
                                                size="small"
                                                id={`id${index}`}
                                                label={t(lbl)}
                                            />
                                        )}
                                    />
                                </Grid>
                            ))}
                        </Grid>

                        <Grid container item xs={12} spacing={1.2}>
                            <Grid
                                item
                                xs={12}
                                display="flex"
                                justifyContent={'center'}
                                mt={2.5}
                            >
                                <Chip sx={{
                                    fontSize: '1rem',
                                }} label={t('forOffice')} />
                            </Grid>
                            {lbls5.map((lbl, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    sm={4}
                                    key={`grid-${index}`}
                                >
                                    <Controller
                                        name={lbl}
                                        control={control}
                                        render={({ field: { onChange, value = '', ...field } }) => (
                                            <TextField
                                                select
                                                value={value}
                                                label={t(lbl)}
                                                onChange={(e) => onChange(e.target.value)}
                                                size="small"
                                                fullWidth
                                                {...field}
                                            >
                                                {lbl === 'department'
                                                    ? updatedDepartments?.map((department) => (
                                                        <MenuItem key={department} value={department}>
                                                            {department}
                                                        </MenuItem>
                                                    ))
                                                    : lbl === 'residenceType'
                                                        ? ['residential', 'nonResidential'].map((role) => (
                                                            <MenuItem key={role} value={role}>
                                                                {t(role)}
                                                            </MenuItem>
                                                        ))
                                                        : ['teacher', 'worker'].map((candidate) => (
                                                            <MenuItem key={candidate} value={candidate}>
                                                                {t(candidate)}
                                                            </MenuItem>
                                                        ))}
                                            </TextField>
                                        )}
                                    />
                                </Grid>
                            ))}
                            {lbls4.map((lbl, index) => (
                                <Grid
                                    item
                                    xs={lbl === 'termsAndCondition' || lbl === 'dutyList' ? 12 : 6}
                                    key={`grid-${index}`}
                                >
                                    {lbl === 'hireDate' ? (
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Controller
                                                key={`controller-${lbl}`}
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        value={field.value ? dayjs(field.value) : null}
                                                        label={t(lbl)}
                                                        format="YYYY-MM-DD"
                                                        slotProps={{ textField: { size: 'small', fullWidth: true } }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                    ) : (
                                        <Controller
                                            key={`controller-${lbl}`}
                                            name={lbl}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="given-name"
                                                    fullWidth
                                                    multiline={lbl === 'termsAndCondition' || lbl === 'dutyList'}
                                                    type={lbl === 'salaryAmount' || lbl === 'idNo' || lbl === 'boardingFee' ? 'number' : 'text'}
                                                    size="small"
                                                    id={`id${index}`}
                                                    label={t(lbl)}
                                                />
                                            )}
                                        />
                                    )}
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid xs={12} mb={1.8} item spacing={1.2} container sx={{ display: 'flex' }} justifyContent={'right'}>
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color='error'
                                sx={{ mt: 2 }}
                                onClick={handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Grid>
                        {catchID ?
                            <Grid item xs={2}>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    variant="contained"
                                >
                                    <span>{t('update')}</span>
                                </LoadingButton>
                            </Grid>
                            :
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('save')}</span>
                                </LoadingButton>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}