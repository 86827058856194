import React from 'react';
import MONTHLYCOUNT from './MonthlyCounts';
import YEARLYCOUNT from './YearlyCounts';
import MONTHLYREPORT from './CountingReports';

const createMonthlyCountComponent = (countType) => () => <MONTHLYCOUNT countType={countType} />;
const createYearlyCountComponent = (countType) => () => <YEARLYCOUNT countType={countType} />;
const createMonthlyReportComponent = (countType) => () => <MONTHLYREPORT countType={countType} />;

export const MCECOUNT = createMonthlyCountComponent('mce'); // monthly charity expense
export const MGICOUNT = createMonthlyCountComponent('mgi'); // monthly general income
export const MGECOUNT = createMonthlyCountComponent('mge'); // monthly general expense

export const MGRCOUNT = createMonthlyReportComponent('mgr'); // monthly general report

export const YCECOUNT = createYearlyCountComponent('yce'); // yearly charity expense
export const YCRCOUNT = createMonthlyReportComponent('ycr');  // yearly charity report

export const YGICOUNT = createYearlyCountComponent('ygi'); // yearly general income
export const YGECOUNT = createYearlyCountComponent('yge'); // yearly general expense

export const YGRCOUNT = createMonthlyReportComponent('ygr'); // yearly general report