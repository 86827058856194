// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';
import store from './store';
import i18n from './components/hooks/i18n';

const basics = JSON.parse(localStorage.getItem('basics')) || {};
const language = basics.language || 'en';

// Set the `dir` attribute on the `<html>` tag
document.documentElement.setAttribute('dir', language === 'ar' ? 'rtl' : 'ltr');

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Provider store={store}>
    <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
  </Provider>
);
