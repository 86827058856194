import {
    CLEAR_ERRORS,
    ZK_TECO_CONNECTION_FAIL,
    ZK_TECO_CONNECTION_REQUEST,
    ZK_TECO_CONNECTION_SUCCESS,
    ZK_TECO_CONNECTION_RESET,
    ZK_TECO_SAVE_REQUEST,
    ZK_TECO_SAVE_SUCCESS,
    ZK_TECO_SAVE_FAIL,
    ZK_TECO_SAVE_RESET,
    ZK_TECO_LOGS_REQUEST,
    ZK_TECO_LOGS_SUCCESS,
    ZK_TECO_LOGS_FAIL,
    CLEAR_LOGS,
    ZK_TECO_NEW_USER_REQUEST,
    ZK_TECO_NEW_USER_FAIL,
    ZK_TECO_NEW_USER_RESET,
    ZK_TECO_NEW_USER_SUCCESS,
    ZK_TECO_DEVICE_DELETE_REQUEST,
    ZK_TECO_DEVICE_DELETE_SUCCESS,
    ZK_TECO_DEVICE_DELETE_FAIL,
    ZK_TECO_DEVICE_DELETE_RESET,
    // ZK_TECO_DELETE_USER_REQUEST,
    // ZK_TECO_DELETE_USER_SUCCESS,
    // ZK_TECO_DELETE_USER_FAIL,
    // ZK_TECO_DELETE_USER_RESET
} from '../constants/zktecoConstants'

export const connectTheDevice = (state = {}, action) => {
    switch (action.type) {
        case ZK_TECO_CONNECTION_REQUEST:
            return {
                connectionLoading: true,
            }

        case ZK_TECO_CONNECTION_SUCCESS:
            return {
                connectionLoading: false,
                connected: action.payload
            }

        case ZK_TECO_CONNECTION_RESET:
            return {
                connectionLoading: false,
                connected: false
            }

        case ZK_TECO_CONNECTION_FAIL:
            return {
                connectionLoading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                connected: false,
                error: null
            }

        default:
            return state;
    }
}

// for add new device
export const newDeviceReducer = (state = {}, action) => {
    switch (action.type) {
        case ZK_TECO_SAVE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ZK_TECO_SAVE_SUCCESS:
            return {
                loading: false,
                isSaved: action.payload
            }

        case ZK_TECO_SAVE_FAIL:
            return {
                ...state,
                isSaved: false,
                error: action.payload
            }

        case ZK_TECO_SAVE_RESET:
            return {
                ...state,
                isSaved: false,
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// for add new user
export const zkNewUserReducer = (state = { connectedDevice: [] }, action) => {
    switch (action.type) {
        case ZK_TECO_NEW_USER_REQUEST:
            return {
                ...state,
                loading: true,
                connectedDevice: [],
                error: null
            };

        case ZK_TECO_NEW_USER_SUCCESS:
            return {
                loading: false,
                connectedDevice: action.payload,
                error: null
            };

        case ZK_TECO_NEW_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case ZK_TECO_NEW_USER_RESET:
            return {
                ...state,
                loading: false,
                connectedDevice: []
            };

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            };

        default:
            return state;
    }
};

export const allLogsReducers = (state = { logs: [] }, action) => {
    switch (action.type) {
        case ZK_TECO_LOGS_REQUEST:
            return { ...state, loadingLogs: true, logs: [] };

        case ZK_TECO_LOGS_SUCCESS:
            return { loadingLogs: false, logs: action.payload };

        case ZK_TECO_LOGS_FAIL:
            return { ...state, error: action.payload };

        case CLEAR_LOGS:
            return { ...state, loadingLogs: false, logs: [] };

        case CLEAR_ERRORS:
            return { ...state, loadingLogs: false, error: null };

        default:
            return state;
    }
};

export const zkDeviceDeleteReducers = (state = {}, action) => {
    switch (action.type) {
        case ZK_TECO_DEVICE_DELETE_REQUEST:
            return {
                ...state,
                loading: true
            };

        case ZK_TECO_DEVICE_DELETE_SUCCESS:
            return {
                loading: false,
                isDeleted: action.payload
            };

        case ZK_TECO_DEVICE_DELETE_FAIL:
            return {
                ...state,
                error: action.payload
            };

        case ZK_TECO_DEVICE_DELETE_RESET:
            return {
                ...state,
                isDeleted: null
            };

        case CLEAR_ERRORS:
            return { ...state, error: null };

        default:
            return state;
    }
};

// for update zkteco user
// export const zkTecoUserReducer = (state = {}, action) => {
//     switch (action.type) {
//         case ZK_TECO_DELETE_USER_REQUEST:
//             return {
//                 ...state,
//                 zkLoading: true,
//                 isZkDeleted: null,
//                 zkActionError: null
//             };

//         case ZK_TECO_DELETE_USER_SUCCESS:
//             return {
//                 zkLoading: false,
//                 isZkDeleted: true,
//                 zkActionError: null
//             };

//         case ZK_TECO_DELETE_USER_FAIL:
//             return {
//                 ...state,
//                 zkLoading: false,
//                 zkActionError: action.payload
//             };

//         case ZK_TECO_DELETE_USER_RESET:
//             return {
//                 ...state,
//                 isZkDeleted: null,
//                 zkLoading: false
//             };

//         case CLEAR_ERRORS:
//             return {
//                 ...state,
//                 error: null // clear any error
//             };

//         default:
//             return state;
//     }
// };