// React and hooks
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// MUI components and icons
import { Button, TextField, Grid, Box, Container, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Autocomplete, IconButton, InputAdornment } from '@mui/material';
import { Search, Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { CustomCrossButton } from '../styles/style';
import useAuth from '../hooks/UseAuth';

// Actions and constants
import { newStudentReport, updateStudentReport } from '../../actions/hostelActions';
import { getReportStages, getReportTypes, reportOptionsCommon, reportOptionsFinal } from '../../constants/commonContstants';
import dayjs from 'dayjs';

const NEWREPORTFORM = ({ closeDialog, rowData }) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const formRef = useRef(null);
    const reportTypes = getReportTypes(t)
    const reportStages = getReportStages(t)
    const { code, current_user_name } = useAuth();

    const [reportOptions, setReportOptions] = useState(reportOptionsCommon);
    const [reportHeading, setReportHeading] = useState('');
    const [finalOptions, setFinalOptions] = useState(false);
    const [catchData, setCatchData] = useState(rowData);

    // useEffect is used to work these functionality in one time
    const { students } = useSelector(state => state.allStudent);
    const { loading } = useSelector(state => state.newStudentReport);
    const { loadingAction } = useSelector(state => state.studentReport);

    useEffect(() => {
        if (reportHeading) {
            const reportLabel = reportTypes.find(type => type.label === reportHeading);
            if (reportLabel?.value === 'ThirdTermExam') {
                setReportOptions(reportOptionsFinal)
                setFinalOptions(true)
            } else {
                setReportOptions(reportOptionsCommon)
                setFinalOptions(false)
            }
        }
    }, [reportHeading, reportTypes])

    const lbls = ['admitNo', 'reportType', 'studentName', 'department', 'className', 'date'];

    const defaultValues = {
        id: rowData?.id || '',
        admitNo: rowData?.admit_no || '',
        studentName: rowData?.student_name || '',
        department: rowData?.department || '',
        className: rowData?.class_name || '',
        reportType: rowData?.report_type || '',
        date: rowData?.date || '',
        reportStage: rowData?.report_stage || '',
        // Set default values for heading fields
        ...reportOptions.reduce((acc, lbl, index) => {
            acc[`heading${index}`] = rowData ? rowData[`heading${index}`] || lbl : lbl;
            return acc;
        }, {}),

        number0: rowData[`number${0}`] || '',
        number1: rowData[`number${1}`] || '',
        number2: rowData[`number${2}`] || '',
        number3: rowData[`number${3}`] || '',
        number4: rowData[`number${4}`] || '',
        number5: rowData[`number${5}`] || '',
        number6: rowData[`number${6}`] || '',
        number7: rowData[`number${7}`] || '',
        number8: rowData[`number${8}`] || '',
        number9: rowData[`number${9}`] || '',
        comment: rowData?.comment || '',
    };

    const { handleSubmit, control, reset, setValue } = useForm({ defaultValues });

    const check = (admitNo) => {
        const student = students.find(student => student.admit_no === parseInt(admitNo));
        if (student) {
            setValue('studentName', student.name);
            setValue('department', student.department);
            setValue('className', student.admitted_class);
            setValue('id', student.id);
        } else {
            return enqueueSnackbar(t('sorryNotFound'), { variant: 'error' });
        }
    };

    const onSubmit = (data) => {
        if (!catchData) {
            dispatch(newStudentReport({ provider: current_user_name, ...data }, code))
            clear();
        } else {
            dispatch(updateStudentReport({ provider: current_user_name, ...data }, code))
        }
    };

    const clear = () => {
        const defaultValues = {
            id: '',
            admitNo: '',
            studentName: '',
            department: '',
            className: '',
            reportType: '',
            date: '',
            reportStage: '',
            // Set default values for heading fields
            ...reportOptions.reduce((acc, lbl, index) => {
                acc[`heading${index}`] = lbl;
                return acc;
            }, {}),

            number0: '',
            number1: '',
            number2: '',
            number3: '',
            number4: '',
            number5: '',
            number6: '',
            number7: '',
            number8: '',
            number9: '',
            comment: ''
        };
        reset(defaultValues)
        setCatchData('')
    };

    return (
        <Container maxWidth="md" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'REPORT FORM'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '1.5rem'
                }}
            >
                <RECEIPTHEADING text={t('reportForm')} department={t('hostelManager')} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container mt={1.8} rowSpacing={1.2} columnSpacing={1}>
                        <Controller
                            name={'id'}
                            control={control}
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    sx={{
                                        display: 'none'
                                    }}
                                />
                            )}
                        />

                        {lbls.map((lbl, index) => (
                            <Grid
                                item
                                xs={12}
                                md={6}
                                key={`grid-${index}`}
                            >
                                {lbl === 'reportType' ?
                                    <Controller
                                        key={lbl}
                                        name={lbl}
                                        control={control}
                                        render={({ field: { onChange, value, ...field } }) => (
                                            <Autocomplete
                                                freeSolo
                                                options={[...reportTypes]}
                                                fullWidth
                                                value={value}
                                                onInputChange={(event, newInputValue) => {
                                                    onChange(newInputValue);
                                                    setReportHeading(newInputValue)
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        label={t(lbl)}
                                                        size="small"
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': { borderRadius: 0 }
                                                        }}
                                                    />
                                                )}
                                                {...field}
                                            />
                                        )}
                                    />
                                    : lbl === 'date' ?
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <Controller
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <DatePicker
                                                        {...field}
                                                        value={field.value ? dayjs(field.value) : null}
                                                        format="YYYY-MM-DD"
                                                        slotProps={{ textField: { size: 'small', fullWidth: true, required: true, InputProps: { startAdornment: <InputAdornment position="start">{t(lbl)}:</InputAdornment> } } }}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': { borderRadius: 0 },
                                                        }}
                                                    />
                                                )}
                                            />
                                        </LocalizationProvider>
                                        :
                                        <Controller
                                            key={`controller-${index}`} // Ensure keys are unique
                                            name={lbl}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="given-name"
                                                    required
                                                    fullWidth
                                                    size="small"
                                                    sx={{
                                                        '& .MuiOutlinedInput-root': { borderRadius: 0 }
                                                    }}
                                                    variant='outlined'
                                                    label={t(lbl)}
                                                    autoFocus={lbl === 'admitNo'}
                                                    InputProps={{
                                                        ...(lbl === 'admitNo' && {
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    <IconButton edge="end" onClick={() => check(field.value)}>
                                                                        <Search />
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            )
                                                        }),
                                                    }}
                                                />
                                            )}
                                        />
                                }
                            </Grid>
                        ))}

                        <TableContainer sx={{ marginTop: 3, marginLeft: '8px' }}>
                            <Table aria-label="custom table" sx={{ border: '1px solid #ecc' }}>
                                <TableHead>
                                    <TableRow>
                                        {reportOptions.map((lbl, index) => (
                                            <TableCell key={index} align="center" sx={{ fontSize: '1rem', width: '5%', padding: '5px' }}>
                                                <Controller
                                                    name={`heading${index}`}
                                                    control={control}
                                                    defaultValue={lbl || ''}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            multiline
                                                            onChange={(event) => {
                                                                field.onChange(event);
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                inputProps: {
                                                                    style: { textAlign: 'center' }
                                                                },
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    <TableRow key={'number-box'}>
                                        {Array.from({ length: reportOptions.length }, (_, index) =>
                                            <TableCell key={`box${index}`} align="center" sx={{ padding: '5px' }}>
                                                <Controller
                                                    name={`number${index}`}
                                                    control={control}
                                                    defaultValue={''}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            required
                                                            type={finalOptions && index < reportOptions.length - 2 ? 'number' : !finalOptions ? 'number' : ''}
                                                            onChange={(event) => {
                                                                field.onChange(event);
                                                            }}
                                                            InputProps={{
                                                                disableUnderline: true,
                                                                inputProps: {
                                                                    style: { textAlign: 'center' }
                                                                },
                                                            }}
                                                            variant="standard"
                                                            placeholder={finalOptions && index < reportOptions.length - 4 ? '100' : !finalOptions ? '100' : ''}
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        )}
                                    </TableRow>

                                    <TableRow key={'comment-box'}>
                                        <TableCell key={`comment_box`} colSpan={reportOptions.length - 2} align="center" sx={{ padding: '5px' }}>
                                            <Controller
                                                name={`comment`}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        fullWidth
                                                        label={`${t('comment')}:`}
                                                        onChange={(event) => {
                                                            field.onChange(event);
                                                        }}
                                                        InputProps={{
                                                            disableUnderline: true
                                                        }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell key={`report-stage`} colSpan={2} sx={{ padding: '5px' }}>
                                            <Controller
                                                key={'reportStage'} // Ensure keys are unique
                                                name={'reportStage'}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        autoComplete="given-name"
                                                        fullWidth
                                                        size="small"
                                                        required
                                                        variant='standard'
                                                        label={t('reportStage')}
                                                        InputProps={{
                                                            disableUnderline: true
                                                        }}
                                                        select
                                                    >
                                                        {reportStages.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>

                                </TableBody>
                            </Table>
                            <p >
                                {t('provider')}: {rowData ? rowData.provider : current_user_name}
                            </p>
                        </TableContainer>

                        <Grid container columnSpacing={{ xs: 0.5, sm: 1 }} mb={2} justifyContent={'flex-end'}>
                            <Grid item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color='error'
                                    onClick={clear}
                                >
                                    {t('clear')}
                                </Button>
                            </Grid>
                            {catchData ?
                                <Grid item>
                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        color="success"
                                        loading={loadingAction ? true : false}
                                        variant="contained"
                                    >
                                        <span>{t('update')}</span>
                                    </LoadingButton>
                                </Grid>
                                :
                                <Grid item>

                                    <LoadingButton
                                        fullWidth
                                        type="submit"
                                        color="success"
                                        loading={loading ? true : false}
                                        // disabled={user.user_type !== t('acntskpr')}
                                        variant="contained"
                                    >
                                        <span>{t('save')}</span>
                                    </LoadingButton>
                                </Grid>
                            }
                        </Grid>

                    </Grid>
                </Box>
            </Box>
        </Container>
    )
}

export default NEWREPORTFORM