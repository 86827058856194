// React and Related Imports
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useForm, Controller } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

// Material-UI Imports
import { CssBaseline, TextField, Grid, Box, Container, Chip, MenuItem } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import CloseIcon from '@mui/icons-material/Close';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';
import { connectAttendanceDevice, saveAttendanceDevice } from '../../actions/ZKTecoActions';
import { CLEAR_ERRORS, getDeviceUsers, ZK_TECO_CONNECTION_RESET, ZK_TECO_SAVE_RESET } from '../../constants/zktecoConstants';
import useAuth from '../hooks/UseAuth';

export default function NEWDEVICEFORM({ closeDialog, staffId }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const deviceUsers = getDeviceUsers(t);
    const { enqueueSnackbar } = useSnackbar();
    const formRef = useRef(null);
    const { code } = useAuth();

    // getting the value from redux according to the history info for using by default
    const { connected, error, connectionLoading } = useSelector(state => state.zkConnection);
    const { isSaved, loading } = useSelector(state => state.newDevice);
    const devices = useSelector(state => state.auth.devices);

    const defaultValues = {
        deviceName: '',
        deviceUser: '',
        ipAddress: '',
        port: ''
    };

    const { handleSubmit, control, getValues, reset } = useForm({ defaultValues });

    useEffect(() => {
        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            // dispatch(loadSettings(code))
            dispatch({ type: ZK_TECO_SAVE_RESET })
            reset();
        }
    }, [dispatch, code, isSaved, enqueueSnackbar, t, reset])

    useEffect(() => {
        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
            // enqueueSnackbar(t('checkConnectionText'), { variant: 'error' });
            dispatch({ type: CLEAR_ERRORS })
        }

        if (connected) {
            enqueueSnackbar(t('connectStatus'), { variant: 'success' });
            dispatch({ type: ZK_TECO_CONNECTION_RESET })
        }
    })

    const lbls = ['deviceName', 'deviceUser', 'ipAddress', 'port'];

    // handle submit
    const onSubmit = (data) => {
        const duplicate = devices.find(d => d.ip_address === data.ipAddress);
        if (duplicate) {
            return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });
        };
        dispatch(saveAttendanceDevice(data, code));
    };

    // handle profile
    const checkConnection = () => {
        const data = getValues();
        dispatch(connectAttendanceDevice(data, code));
    };

    return (
        <Container maxWidth={false} sx={{
            borderRadius: '10px',
            maxWidth: '400px',
        }}>
            <MetaData title={'NEW DEVICE FORM'} />
            <CssBaseline />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <CloseIcon fontSize='small' />
            </CustomCrossButton>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    paddingTop: '1.5rem'
                }}
            >
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container justifyContent={'center'}>
                        <Grid item xs={8}>
                            <Chip

                                sx={{
                                    margin: 'auto',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    borderRadius: '50px',
                                    textAlign: 'center',
                                    padding: '0px 10px 0px 10px',
                                    fontSize: '1.1rem',
                                }}
                                label={t('addNewDevice')} />
                        </Grid>
                        <Grid container item mt={1} spacing={1.2}>
                            {lbls.map((lbl, index) => (
                                <Grid
                                    item
                                    xs={12}
                                    key={`grid-${index}`}
                                >
                                    {lbl === 'deviceUser' ? <Controller
                                        key={`controller-${lbl}`} // Ensure keys are unique
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                autoComplete="given-name"
                                                fullWidth
                                                size="small"
                                                id={`id${index}`}
                                                select
                                                label={t(lbl)}
                                                onChange={(e) => {
                                                    field.onChange(e);
                                                    // handleAttendanceTypeChange(e);
                                                }}
                                            >
                                                {deviceUsers.map((option) => (
                                                    <MenuItem key={option.value} value={option.value}>
                                                        {option.label}
                                                    </MenuItem>
                                                ))}
                                            </TextField>
                                        )}
                                    />
                                        :
                                        <Controller
                                            key={`controller-${lbl}`}
                                            name={lbl}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    fullWidth
                                                    autoComplete="given-name"
                                                    required
                                                    size="small"
                                                    id={`id${index}`}
                                                    label={t(lbl)}
                                                />
                                            )}
                                        />
                                    }
                                </Grid>
                            ))}
                        </Grid>
                    </Grid>
                    <Grid xs={12} mb={1.8} mt={1} spacing={1.2} item container sx={{ display: 'flex' }} justifyContent={'right'}>
                        <Grid item>
                            <LoadingButton
                                fullWidth
                                color="primary"
                                variant="contained"
                                loading={connectionLoading}
                                onClick={checkConnection}
                            >
                                <span>{t('checkConnection')}</span>
                            </LoadingButton>
                        </Grid>
                        <Grid item>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                color="success"
                                loading={loading ? true : false}
                                variant="contained"
                            >
                                <span>{t('save')}</span>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}