import axios from 'axios';
import {
    CLEAR_ERRORS,
    NEW_BOOK_REQUEST,
    NEW_BOOK_SUCCESS,
    NEW_BOOK_FAIL,
    ALL_BOOKS_REQUEST,
    ALL_BOOKS_SUCCESS,
    ALL_BOOKS_FAIL,
    UPDATE_BOOK_REQUEST,
    UPDATE_BOOK_FAIL,
    UPDATE_BOOK_SUCCESS,
    DELETE_BOOK_REQUEST,
    DELETE_BOOK_FAIL,
    DELETE_BOOK_SUCCESS,
    NEW_PROVIDED_BOOK_REQUEST,
    NEW_PROVIDED_BOOK_SUCCESS,
    NEW_PROVIDED_BOOK_FAIL,
    ALL_PROVIDED_BOOKS_REQUEST,
    ALL_PROVIDED_BOOKS_SUCCESS,
    ALL_PROVIDED_BOOKS_FAIL,
    UPDATE_PROVIDED_BOOK_REQUEST,
    UPDATE_PROVIDED_BOOK_SUCCESS,
    UPDATE_PROVIDED_BOOK_FAIL,
    ACCEPT_PROVIDED_BOOK_REQUEST,
    ACCEPT_PROVIDED_BOOK_SUCCESS,
    ACCEPT_PROVIDED_BOOK_FAIL
} from '../constants/libraryConstants'


// add new book 
export const newBook = (bookData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_BOOK_REQUEST })

        // passing bookData to backend by this route 
        const { data } = await axios.post(`/api/library/book/new/${code}`, bookData)

        // store data in payload 
        dispatch({
            type: NEW_BOOK_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_BOOK_FAIL,
            payload: error.response.data.message
        })
    }
}


// Get all books
export const allBooks = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_BOOKS_REQUEST })

        // connect with backend to get all books
        const { data } = await axios.post(`/api/library/books/${code}`)

        // storing all books in payload
        dispatch({
            type: ALL_BOOKS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_BOOKS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update book data
export const updateBook = (bookData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_BOOK_REQUEST })

        // connect with backend for book report update
        const { data } = await axios.put(`/api/library/book/update/${code}`, bookData)

        // showing success status if the book is updated
        dispatch({
            type: UPDATE_BOOK_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_BOOK_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete book
export const deleteBook = (info, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_BOOK_REQUEST
        })

        // Sending a delete request to the backend to delete the class
        const { data } = await axios.post(`/api/library/book/delete/${code}`, info)

        // showing success status if the book is deleted
        dispatch({
            type: DELETE_BOOK_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_BOOK_FAIL,
            payload: error.response.data.message
        })
    }
}

// new provided books
export const provideNewBooks = (booksData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_PROVIDED_BOOK_REQUEST })

        // passing bookData to backend by this route 
        const { data } = await axios.post(`/api/library/book/provide/${code}`, booksData)

        // store data in payload 
        dispatch({
            type: NEW_PROVIDED_BOOK_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_PROVIDED_BOOK_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all provided books
export const allProvidedBooks = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_PROVIDED_BOOKS_REQUEST })

        // connect with backend to get all books
        const { data } = await axios.post(`/api/library/book/provided_books/${code}`)

        // storing all provided books in payload
        dispatch({
            type: ALL_PROVIDED_BOOKS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_PROVIDED_BOOKS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// update provided books
export const updateProvidedBooks = (booksData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_PROVIDED_BOOK_REQUEST })

        // passing bookData to backend by this route 
        const { data } = await axios.post(`/api/library/book/update/${code}`, booksData)

        // store data in payload 
        dispatch({
            type: UPDATE_PROVIDED_BOOK_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_PROVIDED_BOOK_FAIL,
            payload: error.response.data.message
        })
    }
}

// accept book success
export const acceptBook = (bookData, code) => async (dispatch) => {
    try {

        dispatch({ type: ACCEPT_PROVIDED_BOOK_REQUEST })

        // passing bookData to backend by this route 
        const { data } = await axios.post(`/api/library/book/accept/${code}`, bookData)

        // store data in payload 
        dispatch({
            type: ACCEPT_PROVIDED_BOOK_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ACCEPT_PROVIDED_BOOK_FAIL,
            payload: error.response.data.message
        })
    }
}


// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}