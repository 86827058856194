// React and related hooks
import React, { useEffect, useState, useRef, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';

// MUI components and icons
import { Box, Button, Grid, ButtonGroup, Typography, TextField, Autocomplete, Dialog, Chip } from '@mui/material';
import { SaveOutlined, Delete, Edit, ListAlt, Refresh as RefreshIcon, FileDownloadOutlined, ManageSearch, Close, AddCircle } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// DataGrid and related components
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, GridActionsCellItem, useGridApiContext, gridFilteredSortedRowIdsSelector, gridVisibleColumnFieldsSelector } from '@mui/x-data-grid';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import CustomPagination from '../layout/Pagination';
import { convertToBengaliDigits } from '../utils/converter';
import { markSheetPdf, resultPdf } from '../../actions/pdfActions';
import { exmnames } from '../../constants/commonContstants';
import { allStudents, allClass, newResult, getResult, deleteResult, updateResult } from '../../actions/eduActions';
import { DELETE_RESULT_RESET, NEW_RESULT_RESET, UPDATE_RESULT_RESET } from '../../constants/eduConstants';
import { getPastTenYears } from '../utils/commonUtils';
import useAuth from '../hooks/UseAuth';
import useDataGridColumns from '../utils/useDataGridColumns';
import { nanoid } from 'nanoid';
import { CustomCrossButton } from '../styles/style';
import { getSettings } from '../../actions/othersActions';

function QuickSearchToolbar() {
    return (
        <Box
            sx={{
                p: 0.5,
                pb: 0,
                margin: 'auto'
            }}
        >
            <GridToolbarQuickFilter />
        </Box>
    );
}

function CustomToolbar({ handleOpen, saveData, currentBasic, handleRefresh, handleMode, tableMode, handleSelectionChange, handleDelete }) {
    const { t } = useTranslation();
    const apiRef = useGridApiContext();
    const dispatch = useDispatch();
    const { code, type } = useAuth();
    const [currentRows, setCurrentRows] = React.useState('');

    const { resultBasics } = useSelector(state => state.result);
    const { allSettings } = useSelector(state => state.settings);

    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound')
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [anchorEl1, setAnchorEl1] = React.useState(null);
    const open1 = Boolean(anchorEl1);

    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // Get all visible column fields
        const visibleColumnFields = gridVisibleColumnFieldsSelector(apiRef);

        // Filter visible columns to exclude specific ones like '__check__' and 'actions'
        const filteredColumnFields = apiRef.current
            .getAllColumns()
            .filter((column) => column.field !== '__check__' && column.field !== 'actions')
            .map((column) => column.field)
            .filter((field) => visibleColumnFields.includes(field)); // Ensure only visible columns are included

        // Map visible and filtered column fields to header names
        const headerNames = filteredColumnFields.map((field) => {
            const column = apiRef.current.getColumn(field);
            return column.headerName || column.field; // Use headerName if available, fallback to field
        });

        // Get filtered and sorted row IDs
        const filteredRowIds = gridFilteredSortedRowIdsSelector(apiRef);

        // Map row data to include only fields corresponding to filteredColumnFields
        const filteredRows = filteredRowIds.map((id) => {
            const row = apiRef.current.getRow(id);
            const filteredRow = filteredColumnFields.reduce((acc, field) => {
                acc[field] = row[field];
                return acc;
            }, {});
            return filteredRow;
        });

        // Prepare the data object
        const data = {
            system: allSettings.result_system,
            grades: allSettings.grades,
            heading: currentBasic,
            columns: headerNames, // Filtered and visible columns
            rows: filteredRows,   // Filtered rows corresponding to visible columns
        };

        // Save the prepared data
        setCurrentRows(data);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick1 = (event) => {
        setAnchorEl1(event.currentTarget);
    };

    const handleClose1 = () => {
        setAnchorEl1(null);
    };

    const columns = useDataGridColumns([
        {
            field: 'id', headerName: t('idNo'), minWidth: 80, width: 80, headerAlign: 'center',
            disableColumnMenu: false,
            flex: 0,
            renderCell: (params) => (
                <div style={{ textAlign: 'center', width: '100%' }}>{params.value}</div>
            )
        },
        { field: 'examName', headerName: t('examName'), minWidth: 200 },
        { field: 'className', headerName: `${t('className')}` },
        { field: 'perSubNum', headerName: t('numberPerSubject') },
        { field: 'year', headerName: t('year') }
    ]);

    // Transform basics array into the desired format
    const rows = resultBasics?.map((basic, index) => {
        return {
            id: basic.id,
            examName: basic.exam_name,
            className: basic.class_name,
            perSubNum: basic.number_per_subject,
            year: basic.year
        };
    });

    const handlePdf = () => {
        dispatch(resultPdf(currentRows, code));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button
                            aria-label="Delete icon"
                            onClick={handleDelete}
                            color='error'
                            disabled={!currentBasic || tableMode !== 'showMode' || type !== 'education' ? true : false}
                        >
                            <Delete />
                        </Button>

                        <Button disabled={type !== 'education'} aria-label="Add icon" onClick={handleOpen}><AddCircle /></Button>

                        <Button
                            aria-label="Save icon"
                            onClick={saveData}
                            disabled={tableMode === 'showMode' || tableMode === 'previewMode' || type !== 'education'}
                        >
                            <SaveOutlined />
                        </Button>

                        <Button aria-label="Download file icon"
                            onClick={handleDownloadFile}
                            disabled={!currentBasic || tableMode !== 'showMode'}
                        >
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>

                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'} mt={0.2}>
                    <Chip
                        label={
                            currentBasic
                                ? tableMode === 'editMode'
                                    ? t('updatingResult')
                                    : tableMode === 'addMode'
                                        ? `${currentBasic.selectedClass} ${t('resultAddingClass')}`
                                        : t('resultShowing')
                                : t('resultManagement')
                        }
                        sx={{
                            fontSize: '1rem',
                        }}
                    />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>

            <Grid container alignItems='center' sx={{ padding: '0px 0px 3px 0px' }}>
                {currentBasic.examYear ?
                    <Grid xs={12} item display={'flex'} justifyContent={'center'}>
                        <Typography
                            variant="h6"
                            id="tableTitle"
                            component="div"
                            color='success'
                            display='flex'
                            justifyContent='center'
                        >
                            {`${currentBasic.selectedExamName}, ${t('class')}: ${currentBasic.selectedClass}, ${t('year')}: ${convertToBengaliDigits(currentBasic.examYear)} ${t('dateEng')}`}
                        </Typography>
                        <ButtonGroup size='small' variant="text" aria-label="Basic button group" sx={{ marginLeft: 1 }}>
                            <Button color="secondary" aria-label="edit icon"
                                onClick={tableMode === 'showMode' || tableMode === 'previewMode' ? handleMode : handleRefresh}
                                sx={{
                                    marginLeft: '10px',
                                }}
                            >
                                {tableMode === 'showMode' || tableMode === 'previewMode' ? <Edit fontSize='small' /> : <RefreshIcon />}
                            </Button>
                            <Button
                                onClick={handleClick1}
                                size="small"
                                aria-controls={open1 ? 'resultsListTable' : undefined}
                                aria-haspopup="true"
                                aria-expanded={open1 ? 'true' : undefined}
                            >
                                <ManageSearch />
                            </Button>

                        </ButtonGroup>

                        <Menu
                            anchorEl={anchorEl1}
                            id="resultsListTable"
                            open={open1}
                            onClose={handleClose1}
                            // onClick={handleClose1}
                            PaperProps={{
                                elevation: 0,
                                sx: {
                                    overflow: 'visible',
                                    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                    mt: 1.5,
                                    '& .MuiAvatar-root': {
                                        width: 32,
                                        height: 32,
                                        ml: -0.5,
                                        mr: 1,
                                    },
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        position: 'absolute',
                                        top: 0,
                                        right: 14,
                                        width: 10,
                                        height: 10,
                                        bgcolor: 'background.paper',
                                        transform: 'translateY(-50%) rotate(45deg)',
                                        zIndex: 0,
                                    },
                                },
                            }}
                            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                        >
                            <Box sx={{
                                height: 400, width: 600, paddingLeft: '8px', paddingRight: '8px'
                            }}>

                                <DataGrid
                                    rows={rows}
                                    columns={columns}
                                    // disableSelectionOnClick
                                    localeText={localeText}
                                    density={'compact'}
                                    scrollbarSize={0}
                                    showCellVerticalBorder={true}
                                    showColumnVerticalBorder={true}

                                    disableColumnMenu
                                    disableColumnSorting
                                    hideFooterPagination
                                    hideFooterSelectedRowCount

                                    onRowSelectionModelChange={(id) => {
                                        handleSelectionChange(id);
                                    }}

                                    sx={{
                                        '& .MuiDataGrid-footerContainer': {
                                            display: 'none'
                                        }
                                    }}

                                    slots={{
                                        toolbar: QuickSearchToolbar
                                    }}


                                    slotProps={{
                                        toolbar: {
                                            showQuickFilter: true,
                                        },
                                    }}

                                    initialState={{
                                        columns: {
                                            columnVisibilityModel: {
                                                __check__: false,
                                                id: false,
                                            },
                                        },
                                    }}
                                />
                            </Box>
                        </Menu>
                    </Grid>
                    :
                    ''
                }
            </Grid>
        </GridToolbarContainer>
    );
}

const RESULTMANAGEMENT = () => {
    const { t } = useTranslation();
    const { code } = useAuth();
    const [openDialouge, setOpenDialouge] = useState(false);
    const [searchClass, setSearchClass] = useState('');
    const [examInfo, setExamInfo] = useState('');
    const [tableMode, setTableMode] = useState('showMode');
    const [marksheet, setMarksheet] = useState('');
    const [tableData, setTableData] = useState('');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const formRef = useRef(null);
    const isFirstRender = useRef(true);
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const pastTenYears = getPastTenYears();

    // get redux state
    const { classes } = useSelector(state => state.allClass);
    const classnames = [...new Set(classes?.map(classItem => classItem.class_name))];
    const { students, loading } = useSelector(state => state.allStudent);
    const { result, loadingResult } = useSelector(state => state.result);
    const { isSaved } = useSelector(state => state.newResult);
    const { isUpdated, isDeleted } = useSelector(state => state.resultActions);
    const { allSettings } = useSelector(state => state.settings);

    const [booksData, setBooksData] = useState([]);

    useEffect(() => {
        dispatch(getSettings(code))
    }, [dispatch, code])

    // find subjects for making reuslt
    useEffect(() => {
        if (classes?.length > 0) {
            const particularClass = classes.find(classItem => classItem.class_name === searchClass);
            const books = particularClass
                ? Object.keys(particularClass)
                    .filter(key => key.startsWith('book') && particularClass[key] !== null)
                    .map(key => particularClass[key])
                : [];
            setBooksData(books);
        }
    }, [classes, searchClass]);

    const handleMarksheet = useCallback((rowData) => {
        setMarksheet(rowData)
    }, []);

    // for showing result by default
    useEffect(() => {
        if (tableMode === 'showMode' && tableData) {

            const { id, class_name, exam_name, number_per_subject, year, headings, results } = tableData;

            // Prepare columns using the headings
            const scolumns = [
                {
                    field: 'id',
                    headerName: t('serialNo'),
                    width: 80,
                    minWidth: 80,
                    flex: 0,
                    sortable: false,
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div style={{ textAlign: 'center', width: '100%' }}>{params.value}</div>
                    )
                },

                ...Object.keys(headings)
                    .filter(key => !['total', 'average_gpa', 'grade', 'rank', 'status'].includes(key)) // Exclude specific keys
                    .map((key) => ({
                        field: key,
                        headerName: headings[key], // Display the value of the heading
                        editable: true,
                        flex: 1,
                        minWidth: key === '0' ? 80 : 130,
                        disableColumnMenu: true,
                        headerAlign: 'center',
                        renderCell: (params) => (
                            <div style={{ textAlign: key === '1' ? 'left' : 'center', width: '100%' }}>
                                {params.value}
                            </div>
                        ),
                    })),

                // Special columns (total, average, level, rank)
                ...['total', 'average_gpa', 'grade', 'rank', 'status'].map((key) => ({
                    field: key,
                    headerName: headings[key], // Display the value of the heading
                    width: 90,
                    editable: true,
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div style={{ textAlign: 'center', width: '100%' }}>
                            {key === 'level' ? t(params.value) : params.value}
                        </div>
                    ),
                })),

                {
                    field: 'actions',
                    headerName: t('markSheet'),
                    type: 'actions',
                    width: 80,
                    getActions: (params) => [
                        <GridActionsCellItem
                            icon={<ListAlt />}
                            label="marksheet"
                            onClick={() => handleMarksheet(params.row)}
                        />,
                    ],
                }
            ];

            // Prepare rows using the results
            const srows = results.map((result, index) => ({
                id: index + 1, // Ensure each row has a unique ID
                ...result // Spread the result data into the row
            }));

            if (srows.length > 0) {
                setColumns(scolumns);
                setRows(srows);

                setExamInfo({
                    id: id,
                    selectedClass: class_name,
                    selectedExamName: exam_name,
                    numberPerSubject: number_per_subject,
                    examYear: year
                })
            }
        }
    }, [t, handleMarksheet, tableData, tableMode]);

    // when updated the results
    useEffect(() => {
        const handleAction = (actionType) => {
            dispatch({ type: actionType });
            // setTableMode('showMode');
            dispatch(getResult(code));
        };

        if (isSaved) handleAction(NEW_RESULT_RESET);
        if (isUpdated) handleAction(UPDATE_RESULT_RESET);
        if (isDeleted) handleAction(DELETE_RESULT_RESET);

    }, [dispatch, code, isSaved, result, isUpdated, isDeleted]);


    const { handleSubmit, control } = useForm('');

    // dipatch some data at the first time
    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            dispatch(allStudents(code));
            dispatch(allClass(code));
            dispatch(getResult(code))
        }
    }, [dispatch, code, result]);

    // display the results
    useEffect(() => {
        if (result?.results) {
            setTableMode('showMode')
            setTableData(result)
        } else {
            setColumns([])
            setRows([])
            setExamInfo('')
        }
    }, [dispatch, code, result]);

    // for making marksheet
    useEffect(() => {
        if (marksheet) {
            const student = students?.find(s => s.admit_no === marksheet[0])
            const studentData = {
                student_name: student?.name,
                fatherName: student?.father_name,
                department: student?.department,
                village: student?.village,
                post_office: student?.post_office,
                sub_district: student?.sub_district,
                district: student?.district,
                residence: student?.residence
            };

            const data = {
                system: allSettings.result_system,
                grades: allSettings.grades,
                heading: examInfo,
                studentInfo: studentData,
                columns: columns
                    .filter(column => column.field !== 'actions')
                    .map(column => column.headerName),
                row: marksheet
            };

            dispatch(markSheetPdf(data, code));
            setMarksheet('')
        }
    }, [dispatch, examInfo, marksheet, columns, code, students, allSettings.grades, allSettings.result_system]);

    const handleClickDialouge = () => {
        setOpenDialouge(true);
    };

    const handleCloseDialouge = () => {
        setOpenDialouge(false);
    };

    const onSubmit = (data) => {
        dispatch(allStudents(code, { className: data.selectedClass }));
        setExamInfo(data);
        setTableMode('addMode')
        setOpenDialouge(false);
    };

    const handleSave = () => {
        if (!examInfo) return enqueueSnackbar(t('requireText'), { variant: 'error' });

        // Retrieve all cell values
        const headerValues = tableMode === 'editMode'
            ? columns.slice(1, -6).map(column => column.headerName) // skip serial no and conclusions
            : columns.slice(1).map(column => column.headerName); // skip serial no

        const rowsWithTotals = rows?.map(row => {
            const rowValues = [];

            // Get the maximum value of the `to` field
            const maxTo = allSettings.grades.reduce((max, current) => {
                return Math.max(max, parseFloat(current.to));
            }, -Infinity);

            // GPA calculation based on score
            const calculateGPA = (score) => {
                // Normalize score to a maxTo scale
                const normalizedScore = (score / 100) * maxTo;

                const gpaRange = allSettings.grades.find(({ from, to }) => {
                    const numericFrom = parseFloat(from);
                    const numericTo = parseFloat(to);
                    // Match normalized score to the GPA range
                    return normalizedScore >= numericFrom && normalizedScore <= numericTo;
                });
                return gpaRange ? parseFloat(gpaRange.to) : 0.0; // Default to 0.0 if no match
            };

            const relevantColumns = tableMode === 'editMode' ? columns.slice(1, -6) : columns.slice(1);
            let total = 0;
            let count = 0;
            let totalGPA = 0;  // Initialize total GPA for each student
            relevantColumns.forEach((column, index) => {
                const cellValue = row[column.field];
                rowValues.push(cellValue);

                // Calculate total and GPA for columns after the first three
                if (index >= 2) { // Skip admit no and names
                    const score = parseInt(cellValue || 0, 10); // Handle null/undefined with a fallback
                    total += score;
                    totalGPA += calculateGPA(score); // Add GPA for each score
                    count++;
                }
            });

            // Calculate average
            const average = (total / count).toFixed(2); // toFixed for getting two digit after dot
            // Calculate average GPA
            const averageGPA = (totalGPA / count).toFixed(2); // toFixed for two decimal places

            // Level calculation
            const length = tableMode === 'editMode'
                ? columns.slice(3, -6).length // Skip the first three and last four columns in editing mode
                : columns.slice(3).length;    // Skip the first three columns when not in editing mode

            const number = examInfo.numberPerSubject; // change number per subject to the English digit
            // Function to calculate achieved marks for a given percentage range
            const calculateAchieved = (percentage) => number * (percentage / 100) * length;
            // Determine the grade grade
            const level = allSettings.result_system === 'parcentageSystem' ? allSettings.grades.find(({ from, to }) => {
                const achievedFrom = calculateAchieved(Number(from));
                const achievedTo = calculateAchieved(Number(to));
                return total >= achievedFrom && total <= achievedTo;
            }) : allSettings.grades.find(row =>
                parseFloat(averageGPA) <= parseFloat(row.to) &&
                parseFloat(averageGPA) >= parseFloat(row.from)
            );;

            // Add total, average, grade, and level to the row
            return {
                ...rowValues,
                total,
                average_gpa: allSettings.result_system === 'parcentageSystem' ? average : averageGPA, // set 'averageGPA' by checking conditions
                grade: level.grade,
                status: level.status
            };
        });

        // Sort rows by total in descending order for handling level
        const sortedRows = rowsWithTotals.sort((a, b) => b.total - a.total);

        // Assign ranks
        let currentRank = 1;
        let previousTotal = null;
        const rankMap = {};
        const rankedRows = sortedRows.map((row, index) => {
            let rank;
            // Check if current row has a different total and is not "fail"
            if (row.total !== previousTotal) {
                // Assign rank names for first, second, and third
                if (currentRank === 1) rank = t('first');
                else if (currentRank === 2) rank = t('second');
                else if (currentRank === 3) rank = t('third');
                else rank = '';

                // Update previousTotal and map the rank
                previousTotal = row.total;
                rankMap[row.total] = rank;
            } else {
                // Use the same rank for the same total if it already exists
                rank = rankMap[row.total];
            }

            // Increment the rank for the next item
            currentRank++;
            return {
                ...row,
                rank: rank
            };
        });

        // Final headers with GPA and grade
        const headings = {
            ...headerValues.reduce((acc, value, index) => {
                acc[index] = value;
                return acc;
            }, {}),
            total: t('justTotal'),
            average_gpa: allSettings.result_system === 'parcentageSystem' ? t('percentage') : t('gpa'),
            grade: t('grade'),
            rank: t('rank'),
            status: t('status')
        };

        const finalData = {
            id: tableMode === 'addMode' ? nanoid() : examInfo.id, // add new id for new result
            className: examInfo.selectedClass,
            examName: examInfo.selectedExamName,
            numberPerSubject: examInfo.numberPerSubject,
            year: examInfo.examYear,
            headings: headings,
            results: rankedRows
        }

        if (rankedRows.length > 0 && tableMode === 'addMode') {
            if (result?.results) {
                const findByYearAndExamName = (data, year, examName, className) =>
                    data.year === year && data.exam_name === examName && data.class_name === className ? data : null;
                const duplicate = findByYearAndExamName(result, parseInt(examInfo.examYear), examInfo.selectedExamName, examInfo.selectedClass);

                if (duplicate) {
                    return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });
                }
            }

            dispatch(newResult(finalData, code))

        } else if (rankedRows.length > 0 && tableMode === 'editMode') {
            dispatch(updateResult(finalData, code))
        }
    };

    useEffect(() => {
        if (students?.length > 0 && tableMode === 'addMode') {
            const columns = [
                { field: 'id', headerName: t('serialNo'), width: 80 },
                { field: 'admitno', headerName: t('admitNo'), width: 80 },
                { field: 'name', headerName: t('name'), minWidth: 150, flex: 1 },
                ...booksData?.map((book, index) => ({
                    field: `book${index}`,
                    headerName: book,
                    flex: 1,
                    type: 'number',
                    editable: true,
                    headerAlign: 'center',
                    renderCell: (params) => (
                        <div style={{ textAlign: 'center', width: '100%' }}>{params.value}</div>
                    ),
                })),
            ];
            setColumns(columns);

            // preparing rows according to the student data
            const tableRows = students?.map((student, index) => ({
                id: index + 1,
                admitno: student.admit_no,
                name: student.name,
                ...booksData.reduce((acc, book, bookIndex) => {
                    acc[`book${bookIndex}`] = "";
                    return acc;
                }, {}),
            }));
            setRows(tableRows)
        }
        //  else if (result?.results && tableMode === 'showMode') { // this is for showing results by default and when mode will change
        //     setTableData(result);
        // } else if (tableMode === 'showMode') {
        //     setColumns([])
        //     setRows([])
        //     setExamInfo('')
        // }
    }, [dispatch, booksData, students, result, t, tableMode, loading]);

    // making dialogs combobox
    const renderAutocomplete = (name, label, defaultValue, options, isNumber = false, onInputChange = null) => (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue || ''}
            render={({ field: { onChange, value, ...field } }) => (
                <Autocomplete
                    freeSolo
                    options={options}
                    fullWidth
                    size="small"
                    value={value}
                    onInputChange={(event, newInputValue) => {
                        onChange(newInputValue);
                        if (onInputChange) onInputChange(newInputValue);
                    }}
                    getOptionLabel={(option) => option.toString()} // Ensure options are converted to strings
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={label}
                            type={isNumber ? 'number' : 'text'}
                        />
                    )}
                    {...field}
                />
            )}
        />
    );

    // for change mui defualt localtext of row selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const processRowUpdate = (newRow, oldRow) => {
        const updatedRows = rows?.map((row) => (row.id === oldRow.id ? newRow : row));
        setRows(updatedRows);
        return newRow;
    };

    const handleEditMode = () => {
        setTableMode('editMode');
    };

    // handle columns visibility according to the editMode and showMode
    const [columnVisibilityModel, setColumnVisibilityModel] = useState({ __check__: false });

    useEffect(() => {
        if (tableMode === 'editMode') {
            if (columns.length > 5) {
                const visibilityModel = { __check__: false };
                for (let i = columns.length - 6; i < columns.length; i++) {
                    visibilityModel[columns[i].field] = false;
                }
                setColumnVisibilityModel(visibilityModel);
            }
        } else if (tableMode === 'showMode' || tableMode === 'previewMode') {
            setColumnVisibilityModel({ __check__: false })
        }
    }, [dispatch, columns, tableMode, examInfo]);

    const handleRefresh = () => {
        setTableMode('showMode')
        dispatch(getResult(code))
    };

    const handleSearch = (id) => {
        dispatch(getResult(code, { id: id[0] }))
    };

    const handleResultDelete = () => {
        dispatch(deleteResult({ id: tableData.id }, code));
    };

    return (
        <Box className="globalShapeDesign" sx={{ width: '100%' }}>
            <MetaData title={'RESULT MANAGEMENT'} />
            <Dialog
                open={openDialouge}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="xs"
                PaperProps={{
                    style: {
                        overflow: 'visible'
                    }
                }}
            >
                <CustomCrossButton
                    onClick={handleCloseDialouge}
                    disableElevation
                    disableRipple
                    disableFocusRipple
                >
                    <Close fontSize='small' />
                </CustomCrossButton>
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container display={'flex'} padding={3} paddingTop={4} justifyContent={'center'} spacing={1.5}>
                        <Grid item xs={12}>
                            {renderAutocomplete('selectedClass', t('className'), examInfo.selectedClass, classnames, false, setSearchClass)}
                        </Grid>
                        <Grid item xs={12}>
                            {renderAutocomplete('selectedExamName', t('examName'), examInfo.selectedExamName, exmnames)}
                        </Grid>
                        <Grid item xs={6}>
                            {renderAutocomplete('numberPerSubject', t('numberPerSubject'), examInfo.numberPerSubject, [20, 50, 100, 200], true)}
                        </Grid>
                        <Grid item xs={6}>
                            {renderAutocomplete('examYear', t('year'), examInfo.examYear, pastTenYears, true)}
                        </Grid>

                        <Grid item xs={12} justifyContent='right' display={'flex'} mt={1}>
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="primary"
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('newTable')}</span>
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
            <DataGrid
                rows={rows}
                columns={columns}
                editMode="cell"
                processRowUpdate={processRowUpdate}
                experimentalFeatures={{ newEditingApi: true }}
                density={'compact'}
                loading={loadingResult}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                localeText={localeText}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityChange={(newModel) => setColumnVisibilityModel(newModel)}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 }, // Default page size
                    },
                    columns: {
                        columnVisibilityModel: {
                            __check__: false
                        },
                    },
                }}
                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleClickDialouge} saveData={handleSave} currentBasic={examInfo} handleRefresh={handleRefresh} handleMode={handleEditMode} tableMode={tableMode} handleSelectionChange={handleSearch} handleDelete={handleResultDelete} />,
                    pagination: CustomPagination
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>

    )
}

export default RESULTMANAGEMENT;