// React and related hooks
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// MUI components and icons
import { Box, Grid, Button, Container, TextField, Stack, Chip } from '@mui/material';
// Utilities and hooks
import { useForm, Controller } from 'react-hook-form';
import useAuth from '../hooks/UseAuth';

// Custom components and actions
import { MetaData } from '../utils/metaData';
import { getSettings, updateSettings } from '../../actions/othersActions';
import { enqueueSnackbar } from 'notistack';

export const NEWNOTICE = () => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const { code } = useAuth();
    const isFirstRender = useRef(true);

    const { allSettings } = useSelector(state => state.settings);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (isFirstRender) {
            isFirstRender.current = false;
            dispatch(getSettings(code))
        }
    }, [dispatch, code])

    const defaultValues = {
        notice_text: allSettings?.notice_text || '',
    };

    const { handleSubmit, control, reset } = useForm({ defaultValues });

    const onSubmit = (data) => {
        dispatch(updateSettings({ settings: data }, code))
        enqueueSnackbar(t('successMessage'), { variant: 'success' });
        reset();
    };

    return (
        <Container component="main" maxWidth='md' sx={{
            borderRadius: '10px',
            bgcolor: 'background.paper',
            boxShadow: '0px 0px 15px 1px #e0ecff',
            margin: 'auto'
        }}>
            <MetaData title={'NOTICE FORM'} />
            <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' width={'100%'} marginTop={2} paddingBottom={2}>
                <Grid container justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                        marginBottom: 1
                    }} label={`${t('new')} ${t('notice')}`} />
                    <Controller
                        name={'notice_text'}
                        control={control}
                        render={({ field }) => (
                            <TextField
                                {...field}
                                multiline
                                minRows={7}
                                fullWidth
                                autoFocus
                                InputProps={{
                                    sx: {
                                        resize: "vertical",
                                        overflow: "auto",
                                    },
                                }}
                            />
                        )}
                    />
                </Grid>

                <Stack direction="row" spacing={1} mt={2} justifyContent={'right'}>
                    <Button
                        variant="outlined"
                        type="submit"
                    >
                        {t('delete')}
                    </Button>
                    <Button
                        variant="outlined"
                        type="submit"
                    >
                        {t('save')}
                    </Button>
                </Stack>

            </Box>
        </Container >
    )
}