// React and related hooks
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import { DataGrid, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext, GridActionsCellItem, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import { Grid, Button, ButtonGroup, Box, Menu, MenuItem, Dialog, Chip } from '@mui/material';
import { Delete, AddCircle, Edit, CheckCircle, Cancel, FileDownloadOutlined } from '@mui/icons-material';

// Custom components and utilities
import CustomPagination from '../layout/Pagination';
import ADMISSIONFORM from './AdmitForm';
import { MetaData } from '../utils/metaData';
import useDataGridColumns from '../utils/useDataGridColumns';
import dayjs from 'dayjs';

// Actions
import { allStudents, deleteStudent } from '../../actions/eduActions';
import { studentIdCards, studentListPdf } from '../../actions/pdfActions';

// Constants
import { getReportStages } from '../../constants/commonContstants';
import { DELETE_STUDENT_RESET } from '../../constants/eduConstants';

// Custom hooks
import useAuth from '../hooks/UseAuth';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentRows, setCurrentRows] = React.useState('');
    const { code } = useAuth();

    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== 'id' && column.field !== 'formNo' && column.field !== 'reportStage' && column.field !== 'condition' && column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('studentList'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePdf = () => {
        dispatch(studentListPdf(currentRows, code));
    };

    // handle Id cards making
    const handleIdCards = async (e) => {
        const data = {
            info: [t('academicName'), t('fullAddress'), t('idNo'), t('class'), t('name'), t('fatherName'), t('mobile'), t('address')],
            rows: currentRows.rows
        }
        dispatch(studentIdCards(data, code));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button aria-label="Add icon" onClick={handleOpen}><AddCircle /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                        <MenuItem onClick={handleIdCards}>{t('studnetIdCards')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('studentList')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default function STUDENTLIST() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { code, type } = useAuth();

    const reportStages = getReportStages(t)

    // fetch data from redux store
    const { students, loading } = useSelector(state => state.allStudent);
    const { isDeleted } = useSelector(state => state.student);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allStudents(code));
    }, [dispatch, code])

    useEffect(() => {
        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_STUDENT_RESET })
            dispatch(allStudents(code));
        }
    }, [dispatch, t, isDeleted, enqueueSnackbar, code])

    // handle edit
    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);
    const [studentIDNo, setStudentIDNo] = React.useState(null);

    const handleOpen = () => {
        setOpen(true);
        setStudentIDNo('');
    };

    const handleEdit = (id) => {
        setStudentIDNo(id);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (id) => {
        dispatch(deleteStudent({ id: id }, code))
    }

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('dataId') },
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'formNo', headerName: t('formNo') },
        {
            field: 'imageUrl',
            headerName: t('picture'),
            minWidth: 65,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="User"
                    style={{ width: '30px', height: '30px', borderRadius: '50%', marginTop: '2.8px' }}
                />
            ),
        },
        { field: 'admitNo', headerName: t('admitNo'), minWidth: 80, sortable: true, flex: 0 },
        { field: 'name', headerName: t('name') },
        { field: 'fatherName', headerName: t('fatherName') },
        { field: 'department', headerName: t('department') },
        { field: 'class', headerName: t('class') },
        { field: 'address', headerName: t('address') },
        { field: 'mobile', headerName: t('mobile') },
        { field: 'residenceType', headerName: t('residenceType') },
        { field: 'reportStage', headerName: t('reportStage') },
        {
            field: 'condition',
            headerName: t('status'),
            minWidth: 80,
            renderCell: (params) =>
                params.value === 'out' ? <GridActionsCellItem
                    icon={<Cancel />}
                    color="error"
                    label="not admitted"
                /> :
                    <GridActionsCellItem
                        icon={<CheckCircle />}
                        label="admitted"
                        color="success"
                    />
        },

        { field: 'date', headerName: t('date') },

        {
            field: 'actions',
            headerName: t('actions'),
            type: 'actions',
            minWidth: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row.id)}
                />,
                <GridActionsCellItem
                    disabled={type !== 'education' && type !== 'principal'}
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.id)}
                    showInMenu
                />,
            ],
        },
    ]);

    // Transform furnitures array into the desired format
    const rows = students?.map((s, index) => {
        return {
            id: s.id,
            serial: index + 1,
            formNo: s.form_no,
            imageUrl: s.photo_url,
            admitNo: s.admit_no,
            name: s.name,
            fatherName: s.father_name,
            department: s.department,
            class: s.admitted_class,
            address: `${s.village}, ${s.sub_district}, ${s.district}`,
            mobile: s.father_mobile,
            residenceType: t(s.residence),
            reportStage: t(s.report_stage),
            condition: s.condition, // Store condition directly
            date: dayjs(s.date).format('YYYY-MM-DD')
        };
    });

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    // Define the getRowId function to use 'serialNo' as the unique id for each row
    return (
        <Box className="globalShapeDesign">
            <MetaData title={'STUDENT LIST'} />
            <Dialog
                open={open}
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <ADMISSIONFORM id={studentIDNo} closeDialog={handleClose} />
            </Dialog>

            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                loading={loading ? true : false}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0} // it's remove the empty space of toolbar
                localeText={localeText}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}

                getRowClassName={(params) => {
                    const stage = reportStages.find(stage => stage.label === params.row.reportStage);
                    const reportStage = stage ? stage.value : undefined;
                    return reportStage === 'intermediate' ? 'warning-row' :
                        reportStage === 'difficult' ? 'danger-row' : '';
                }}

                sx={{
                    '& .warning-row': {
                        backgroundColor: '#FFEAEB'
                    },
                    '& .danger-row': {
                        backgroundColor: '#eaeaea',
                    },
                }}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 }, // Default page size
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            fathername: false,
                            condition: false,
                            date: false,
                            id: false,
                            reportStage: false,
                        },
                    },
                }}

                slots={{
                    // toolbar: CustomToolbar,
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />,
                    pagination: CustomPagination
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
