import axios from 'axios';
import {
    NEW_STUDENT_REPORT_REQUEST,
    NEW_STUDENT_REPORT_SUCCESS,
    NEW_STUDENT_REPORT_FAIL,
    ALL_STUDENT_REPORT_REQUEST,
    ALL_STUDENT_REPORT_SUCCESS,
    ALL_STUDENT_REPORT_FAIL,
    DELETE_STUDENT_REPORT_REQUEST,
    DELETE_STUDENT_REPORT_SUCCESS,
    DELETE_STUDENT_REPORT_FAIL,
    UPDATE_STUDENT_REPORT_REQUEST,
    UPDATE_STUDENT_REPORT_SUCCESS,
    UPDATE_STUDENT_REPORT_FAIL,
    CLEAR_ERRORS
} from '../constants/hostelConstants'

// add new student report
export const newStudentReport = (reportData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_STUDENT_REPORT_REQUEST })

        // passing studentData to backend by this route 
        const { data } = await axios.post(`/api/hostel/report/new/${code}`, reportData)

        // store data in payload 
        dispatch({
            type: NEW_STUDENT_REPORT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_STUDENT_REPORT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Get all student reports
export const allStudentReports = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_STUDENT_REPORT_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/hostel/student_reports/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_STUDENT_REPORT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_STUDENT_REPORT_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Delete student report
export const deleteStudentReport = (studentInfo, code) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_STUDENT_REPORT_REQUEST })

        const { data } = await axios.post(`/api/hostel/report/delete/${code}`, studentInfo)

        // showing success status if the profile is updated
        dispatch({
            type: DELETE_STUDENT_REPORT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DELETE_STUDENT_REPORT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update student result
export const updateStudentReport = (rowData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_STUDENT_REPORT_REQUEST })

        // connect with backend for staff report update
        const { data } = await axios.put(`/api/hostel/report/update/${code}`, rowData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_STUDENT_REPORT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_STUDENT_REPORT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}