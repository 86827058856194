import * as React from 'react';
import Toolbar from '@mui/material/Toolbar';
import {
    Grid, Box, Button, AppBar
} from '@mui/material';
import HeroSection from './others/landing/HeroSection';
import FeaturesSection from './others/landing/FeaturesSection';
import { MetaData } from './utils/metaData';

// const drawerWidth = 240;
export default function LANDINGPAGE() {
    // const [mobileOpen, setMobileOpen] = React.useState(false);

    // const handleDrawerToggle = () => {
    //     setMobileOpen((prevState) => !prevState);
    // };

    // const navItems = ['officiants', 'online_admission'];
    // const drawer = (
    //     <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
    //         <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
    //             <Typography>
    //                 TANZIM
    //             </Typography>
    //             <Typography variant='caption' marginLeft={'0.5px'}>
    //                 ACADEMY
    //             </Typography>
    //         </Box>
    //         <Divider />
    //         <List>
    //             {navItems.map((item) => (
    //                 <ListItem key={item} disablePadding>
    //                     <ListItemButton sx={{ textAlign: 'left' }}>
    //                         <ListItemText primary={t(item)} />
    //                     </ListItemButton>
    //                 </ListItem>
    //             ))}
    //         </List>
    //     </Box>
    // );

    return (
        <Box>
            <MetaData title={'HOME'} />
            <Box>
                <AppBar
                    elevation={0}
                    position="sticky"
                    sx={{ backgroundColor: '#6a11cb', padding: '5px' }}
                >
                    <Toolbar sx={{ justifyContent: 'center' }}>
                        <Grid container maxWidth={'md'}>
                            <Grid item xs={6} display={'flex'}>
                                {/* <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 1, display: { sm: 'none' } }}
                                >
                                    <MenuIcon />
                                </IconButton> */}
                                <Box
                                    sx={{
                                        // animation: `${bounce} 4s infinite`,
                                        borderRadius: 2,
                                        overflow: 'hidden',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        margin: 'left'
                                    }}
                                >
                                    <img
                                        src="/logo.png"
                                        alt="Laptop Mockup"
                                        style={{
                                            width: '80px',
                                            height: '100%',
                                            objectFit: 'contain', // Ensures the image scales proportionally
                                        }}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={6} textAlign={'right'}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        background: '#6a11cb',
                                        border: '2px solid white',
                                        borderRadius: 50,
                                        color: 'inherit'
                                    }}
                                    disableElevation
                                    component="a" // Makes the button render as an <a> element
                                    href="/login" // Link to the Login page
                                >
                                    Login
                                </Button>
                                <Button
                                    variant="contained"
                                    sx={{
                                        ml: 1,
                                        color: 'inherit',
                                        background: '#6a11cb',
                                        border: '2px solid yellow',
                                        borderRadius: 50,
                                    }}
                                    disableElevation
                                    component="a" // Makes the button render as an <a> element
                                    href="/new_academy" // Link to the Sign Up page
                                >
                                    Sign Up
                                </Button>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {/* <Box>
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Box> */}
                    <Box component={'main'} width={'100%'}>
                        <Box sx={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        >
                            <HeroSection />
                        </Box>
                        {/* <Box sx={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        >
                            <PricingSection />
                        </Box> */}
                        <Box sx={{
                            textAlign: "center",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        >
                            <FeaturesSection />
                        </Box>
                    </Box>
                </Box>
            </Box>

            <Box color='inherit' elevation={0} sx={{ padding: 1, marginTop: 4 }}>
                <Toolbar variant='dense' sx={{ justifyContent: 'center' }}>
                    © {new Date().getFullYear()} TANZIMIT. All rights reserved.
                </Toolbar>
            </Box>
        </Box>
    );
}