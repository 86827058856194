// React and Hooks
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// Material UI Components
import {
    Grid, Button, ButtonGroup, Typography, Box, Menu, MenuItem, Dialog
} from '@mui/material';

// Material UI Icons
import { Delete, AddCircle, Edit, FileDownloadOutlined } from '@mui/icons-material';

// Custom Components and Actions
import { DataGrid, GridColumnMenu, GridToolbarContainer, GridToolbarQuickFilter, GridActionsCellItem, useGridApiContext, gridFilteredSortedRowIdsSelector } from '@mui/x-data-grid';
import CustomPagination from '../layout/Pagination';
import { MetaData } from '../utils/metaData';
import { barcodePDF, bookListPdf } from '../../actions/pdfActions';

// Redux Actions and Constants
import { allAcademies, deleteAcademy } from '../../actions/superAdminActions';
import UPDATEACADEMYFORM from './AcademyUpdateForm';
import { DELETE_ACADEMY_RESET, UPDATE_ACADEMY_RESET } from '../../constants/superAdminConstants';
import useDataGridColumns from '../utils/useDataGridColumns';
import dayjs from 'dayjs';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const [currentRows, setCurrentRows] = React.useState('');

    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('bookList'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePdf = () => {
        dispatch(bookListPdf(currentRows))
    };

    const handleBarcodePDF = () => {
        dispatch(barcodePDF(currentRows));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={3} item>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={handleOpen}><AddCircle /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                        <MenuItem onClick={handleBarcodePDF}>{t('barcodeFile')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={6} item>
                    <Typography
                        variant="h6"
                        display='flex'
                        justifyContent='center'
                    >
                        {t('registeredAcademies')}
                    </Typography>
                </Grid>
                <Grid xs={3} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

function CustomColumnMenu(props) {
    return (
        <GridColumnMenu
            {...props}
            slots={{
                columnMenuFilterItem: null,
            }}
        />
    );
}

export default function REGISTEREDACADEMIES() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [academyInfo, setAcademyInfo] = useState(null);

    const { academies, loading } = useSelector(state => state.allAcademies);
    const { isDeleted, isUpdated } = useSelector(state => state.academy);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allAcademies())
    }, [dispatch])

    useEffect(() => {
        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_ACADEMY_RESET })
            setOpen(false)
            dispatch(allAcademies());
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(allAcademies());
            dispatch({ type: DELETE_ACADEMY_RESET })
        }
    }, [dispatch, t, enqueueSnackbar, isDeleted, isUpdated])

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), minWidth: 50, flex: 0 },
        { field: 'id', headerName: t('code') },
        { field: 'name', headerName: t('name') },
        { field: 'address', headerName: t('address') },
        { field: 'type', headerName: t('type'), minWidth: 50, flex: 0 },
        { field: 'numberOfStudents', headerName: t('numberOfStudents'), minWidth: 50, flex: 0 },
        { field: 'mobile', headerName: t('mobile'), minWidth: 50, flex: 0 },
        { field: 'email', headerName: t('email') },
        { field: 'payment', headerName: t('payment'), minWidth: 30, flex: 0 },
        { field: 'balance', headerName: t('balance'), minWidth: 30, flex: 0 },
        { field: 'validity', headerName: t('validity'), minWidth: 30, flex: 0 },
        { field: 'expiryDate', headerName: t('expiryDate') },
        {
            field: 'activation',
            headerName: t('activation'),
            headerClassName: 'CustomHeader',
            minWidth: 50, flex: 0
        },
        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            minWidth: 50, flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.id)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform academies array into the desired format
    const rows = academies?.map((a, index) => {
        const isDateValid = dayjs().isBefore(dayjs(a.expiry_date));

        return {
            serial: index + 1,
            id: a.code,
            name: a.name,
            address: a.address,
            type: t(a.academy_type),
            numberOfStudents: a.number_of_students,
            mobile: a.mobile,
            email: a.email,
            payment: a.payment,
            balance: a.sms_balance,
            validity: isDateValid,
            expiryDate: a.expiry_date,
            activation: a.activation
        };
    });

    const handleEdit = (rowData) => {
        setAcademyInfo(rowData)
        setOpen(true);
    };

    const handleDelete = (code) => {
        dispatch(deleteAcademy(code));
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box sx={{ height: '100vh', width: '100%' }}>
            <MetaData title={'ACADEMIC LIST'} />
            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
                onClose={handleClose}
            >
                <UPDATEACADEMYFORM academy={academyInfo} />
            </Dialog>

            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                localeText={localeText}
                loading={loading}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 },
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />,
                    columnMenu: CustomColumnMenu,
                    pagination: CustomPagination,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box >
    );
}
