// Retrieve the data
const basics = JSON.parse(localStorage.getItem('basics')) || {};
const { language } = basics;

const getFontFamily = (lang) => {
    switch (lang) {
        case 'bn':
            return 'SolaimanLipi, sans-serif';
        case 'ar':
            return 'Roboto Arabic, Noto Sans Arabic, sans-serif';
        default:
            return 'Calibri, sans-serif';
    }
};

export const fontFamily = getFontFamily(language);

