
import React, { useEffect, useState } from "react";
import { Checkbox, Button, Typography, Grid, Paper, FormControlLabel, Box } from "@mui/material";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { getSettings, updateSettings } from "../../actions/othersActions";
import useAuth from "../hooks/UseAuth";
import { enqueueSnackbar } from "notistack";
import { CopyAll } from "@mui/icons-material";
import CheckIcon from '@mui/icons-material/Check';

export default function USERWEBSETTINGS() {
    const dispatch = useDispatch();
    const { code } = useAuth();
    const basics = JSON.parse(localStorage.getItem('basics')) || {};
    const { language } = basics;

    const { allSettings } = useSelector(state => state.settings);

    useEffect(() => {
        dispatch(getSettings(code))
    }, [dispatch, code])

    // Initialize state with the first element of allSettings or fallback to default values
    const [settings, setSettings] = useState({
        notice: allSettings?.notice ?? false,
        result: allSettings?.result ?? false,
        officiants: allSettings?.officiants ?? false,
        online_admission: allSettings?.online_admission ?? false
    });

    // Effect to update settings when allSettings changes
    useEffect(() => {
        if (allSettings) {
            setSettings({
                notice: allSettings?.notice ?? false,
                result: allSettings?.result ?? false,
                officiants: allSettings?.officiants ?? false,
                online_admission: allSettings?.online_admission ?? false
            });
        }
    }, [allSettings]);


    const handleToggle = (key) => {
        setSettings((prevSettings) => ({
            ...prevSettings,
            [key]: !prevSettings[key],
        }));
    };

    const handleUpdate = () => {
        dispatch(updateSettings({ settings: settings }, code))
        enqueueSnackbar(t('successMessage'), { variant: 'success' });
    };

    // For copy url link
    const [copied, setCopied] = React.useState(false);
    const url = `https://tanzimit.com/${code}/wb/${language}`;

    const handleCopy = () => {
        navigator.clipboard.writeText(url).then(() => {
            setCopied(true);
        }).catch(err => {
            console.error("Error copying text: ", err);
        });
    };

    return (
        <>
            {/* Header Section */}
            <Box display="flex" sx={{ width: "40%" }} alignItems="center" justifyContent="space-between" mb={1}>
                <Typography sx={{ fontWeight: "bold" }}>
                    {t("website")}
                </Typography>
            </Box>

            {/* Settings Panel */}
            <Box component={Paper} sx={{ width: "40%", padding: "1rem" }}>
                <Box sx={{ mt: 2 }}>
                    <Grid container spacing={2}>
                        {Object.keys(settings).map((key) => (
                            <Grid item xs={12} key={key}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={settings[key]}
                                            onChange={() => handleToggle(key)}
                                            color="primary"
                                        />
                                    }
                                    label={t(key)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Box sx={{ mt: 3, textAlign: "center" }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleUpdate}
                            sx={{ px: 4 }}
                            // fullWidth
                            disableElevation
                        >
                            {t('update')}
                        </Button>
                    </Box>
                </Box>
            </Box>

            <Box
                component={Paper}
                sx={{
                    width: '100%',
                    maxWidth: 500,
                    padding: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    textAlign: 'center',
                    marginTop: 5
                }}
            >
                {/* URL Display */}
                <Typography
                    sx={{
                        marginBottom: 2,
                        wordBreak: 'break-word',
                        color: 'text.primary',
                    }}
                >
                    {url}
                </Typography>

                {/* Copy Button */}
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={copied ? <CheckIcon /> : <CopyAll />}
                    onClick={handleCopy}
                    disableElevation
                    sx={{
                        fontWeight: 600,
                    }}
                >
                    {`${t('website')} ${t('link')}`}
                </Button>
            </Box>
        </>
    );
}
