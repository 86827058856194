// React and Hooks
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import useAuth from '../../hooks/UseAuth';

// Material UI Components
import {
    CssBaseline, TextField, Grid, Box, Container, Table,
    TableBody, TableCell, TableContainer, TableHead, TableRow, InputAdornment
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// MUI Icons
import CloseIcon from '@mui/icons-material/Close';

// Custom Components and Utilities
import { RECEIPTHEADING } from '../../layout/MiniComponents';
import { MetaData } from '../../utils/metaData';
import { CustomCrossButton } from '../../styles/style';

// Redux Actions
import { newSellingProduct } from '../../../actions/othersActions';

// Other Utilities
import { nanoid } from 'nanoid';

export default function SELLINGPRODUCTFORM({ closeDialog, id, selectedItem }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [catchID] = useState(id);
    const formRef = useRef(null);
    const { code, type } = useAuth();

    const [unit, setUnit] = useState({});

    // getting the value from redux according to the history info for using by default
    const { loading } = useSelector(state => state.newFurniture);

    // Transform selectedItem into defaultValues
    const defaultValues = selectedItem.reduce((acc, item, index) => {
        acc[`id${index}`] = item.id || '';
        acc[`productName${index}`] = item.productName || '';
        acc[`remain${index}`] = parseInt(item.remain.match(/\d+/)[0], 10) || '';
        acc[`sellingAmount${index}`] = '';
        acc[`sold${index}`] = parseInt(item.sold.match(/\d+/)[0], 10) || '';
        acc[`buyingPrice${index}`] = parseInt(item.buyingPrice) || '';
        acc[`sellingPrice${index}`] = parseInt(item.sellingPrice) || '';
        acc[`money${index}`] = parseInt(item.money) || '';
        return acc;
    }, {});

    // Use useEffect to update the unit state once defaultValues are computed
    useEffect(() => {
        const newUnits = selectedItem.reduce((unitAcc, item, index) => {
            unitAcc[`unit${index}`] = item.totalHas.replace(/\d+/g, '').trim();
            return unitAcc;
        }, {});

        setUnit(prevState => ({
            ...prevState,
            ...newUnits
        }));
    }, [selectedItem]); // Runs whenever selectedItem changes

    const finalDefaultValues = {
        ...defaultValues,
        sellerName: '',
        mobile: '',
        allTotal: ''
    };

    const { handleSubmit, control, setValue, watch } = useForm({ defaultValues: finalDefaultValues });

    // handle submit
    const onSubmit = (data) => {
        // Function to get the length of keys with a specific prefix
        const getTotalSoldCount = (data, prefix) => {
            return Object.keys(data).filter(key => key.startsWith(prefix)).length;
        };
        const totalGivingItems = getTotalSoldCount(data, 'productName');

        let items = [];
        for (let i = 0; i < totalGivingItems; i++) {
            const idKey = `id${i}`;
            const productName = `productName${i}`;
            const sold = `sold${i}`;
            const reaminKey = `remain${i}`;
            const sellingAmountKey = `sellingAmount${i}`;
            const unitKey = unit[`unit${i}`];
            const buyingPriceKey = `buyingPrice${i}`;
            const sellingPriceKey = `sellingPrice${i}`;
            const moneyKey = `money${i}`;

            const remain = parseInt(data[reaminKey]) || 0;
            const sellingAmount = parseInt(data[sellingAmountKey]) || 0;
            const buyingPrice = parseInt(data[buyingPriceKey]) || 0;
            const sellingPrice = parseInt(data[sellingPriceKey]) || 0;

            if (sellingAmount > remain) {
                const maxNotPossibleMessage = t('maxNotPossible', { remain: remain, furniture: data[productName] });
                return enqueueSnackbar(maxNotPossibleMessage, { variant: 'error' });
            } else if (data[moneyKey] !== 0) {
                items.push([`${nanoid(10)}|${data[idKey]}`, data[productName], unitKey, data[sold], sellingAmount, buyingPrice, sellingPrice]);
                id++;
            }
        }

        dispatch(newSellingProduct({ data: items, seller: data.sellerName, mobile: data.mobile, allTotal: data.allToal, responsible: type }, code));

    };

    // for calculating money 
    // Watch for changes in all sellingAmount and sellingPrice fields
    const allSellingAmounts = watch(Object.keys(defaultValues).filter(key => key.startsWith('sellingAmount')));
    const allSellingPrices = watch(Object.keys(defaultValues).filter(key => key.startsWith('sellingPrice')));

    // Calculate money outside of the map function
    useEffect(() => {
        let totalMoneyValue = 0; // Initialize total money

        selectedItem.forEach((_, index) => {
            const sellingAmount = allSellingAmounts[index] || 0;
            const sellingPrice = allSellingPrices[index] || 0;
            const moneyValue = sellingAmount * sellingPrice;

            // Add to total money value
            totalMoneyValue += moneyValue;

            // Set the money value for each index
            setValue(`money${index}`, moneyValue);
        });

        // Set the total money value
        setValue('allTotal', totalMoneyValue);

    }, [allSellingAmounts, allSellingPrices, setValue, selectedItem]);


    return (
        <Container component="main" sx={{
            background: 'white',
            borderRadius: '10px',
            paddingTop: '1.5rem',
            '& .CustomHeader': {
                backgroundColor: 'whitesmoke',
            }
        }}>
            <MetaData title={'SELLING PRODUCT FORM'} />
            <RECEIPTHEADING text={t('productSellingForm')} department={t('buyAndSell')} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <CloseIcon fontSize='small' />
            </CustomCrossButton>
            <CssBaseline />
            <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' width={'100%'} marginTop={2}>
                <Grid container
                    sx={{
                        overflow: 'auto',
                        position: 'relative',
                        '&::-webkit-scrollbar-track': {
                            marginTop: '10px'
                        }
                    }}
                >
                    <TableContainer>
                        <Table aria-label="custom table" sx={{ border: '1px solid #ecc' }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', fontSize: '1rem', width: '4%', padding: '5px' }}>
                                        {t('serialNo1')}
                                    </TableCell>
                                    <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', fontSize: '1rem', width: '18%', padding: '5px' }}>
                                        {t('productName')}
                                    </TableCell>
                                    <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', fontSize: '1rem', width: '10%', padding: '5px' }}>
                                        {t('remain')}
                                    </TableCell>
                                    <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', width: '10%', fontSize: '1rem', padding: '5px' }}>
                                        {t('sellingAmount')}
                                    </TableCell>
                                    <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', width: '10%', fontSize: '1rem', padding: '5px' }}>
                                        {t('buyingPrice')} ({t('unit')})
                                    </TableCell>
                                    <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', width: '10%', fontSize: '1rem', padding: '5px' }}>
                                        {t('sellingPrice')} ({t('unit')})
                                    </TableCell>
                                    <TableCell align="center" sx={{ borderRight: '1px solid #ecc', background: 'whitesmoke', width: '10%', fontSize: '1rem', padding: '5px' }}>
                                        {t('total')}
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {selectedItem.map((lbl, index) => (
                                    <TableRow key={`item-${index}`}>
                                        <TableCell sx={{ display: 'none' }}>
                                            <TextField
                                                name={`id${index + 1}`}
                                                type="number"
                                            />
                                        </TableCell>
                                        <TableCell sx={{ display: 'none' }}>
                                            <TextField
                                                name={`sold${index + 1}`}
                                                type="number"
                                            />
                                        </TableCell>

                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '0px' }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="left" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '5px' }}>
                                            <Controller
                                                name={`productName${index}`}
                                                control={control}
                                                defaultValue={lbl?.name || ''}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        fullWidth
                                                        InputProps={{
                                                            sx: { '& input': { textAlign: 'left' } },
                                                            disableUnderline: true,
                                                            readOnly: true
                                                        }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="left" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '5px' }}>
                                            <Controller
                                                name={`remain${index}`}
                                                control={control}
                                                defaultValue={lbl?.remains || ''}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        type="number"
                                                        fullWidth
                                                        InputProps={{
                                                            endAdornment: (
                                                                <InputAdornment position="end">
                                                                    {unit[`unit${index}`] || t('unit')} {/* Use the correct unit from state */}
                                                                </InputAdornment>
                                                            ),
                                                            sx: { alignItems: 'center', '& input': { textAlign: 'center' } },
                                                            disableUnderline: true
                                                        }}
                                                        inputProps={{
                                                            readOnly: true,  // Correct way to set input as read-only
                                                        }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', padding: '5px' }}>
                                            <Controller
                                                name={`sellingAmount${index}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        fullWidth
                                                        type="number"
                                                        required
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                {unit[`unit${index}`] || t('unit')} {/* Use the correct unit from state */}
                                                            </InputAdornment>,
                                                            sx: { alignItems: 'center', '& input': { textAlign: 'center' } },
                                                            disableUnderline: true
                                                        }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', padding: '5px' }}>
                                            <Controller
                                                name={`buyingPrice${index}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        fullWidth
                                                        type="number"
                                                        required
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                {t('moneySymbol')}
                                                            </InputAdornment>,
                                                            sx: { alignItems: 'center', '& input': { textAlign: 'center' } },
                                                            disableUnderline: true
                                                        }}
                                                        inputProps={{
                                                            readOnly: true
                                                        }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', padding: '5px' }}>
                                            <Controller
                                                name={`sellingPrice${index}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        fullWidth
                                                        type="number"
                                                        required
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                {t('moneySymbol')}
                                                            </InputAdornment>,
                                                            sx: { alignItems: 'center', '& input': { textAlign: 'center' } },
                                                            disableUnderline: true
                                                        }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', padding: '5px' }}>
                                            <Controller
                                                name={`money${index}`}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        fullWidth
                                                        type="number"
                                                        required
                                                        InputProps={{
                                                            endAdornment: <InputAdornment position="end">
                                                                {t('moneySymbol')}
                                                            </InputAdornment>,
                                                            sx: { '& input': { textAlign: 'right' } },
                                                            disableUnderline: true
                                                        }}
                                                        inputProps={{
                                                            readOnly: true
                                                        }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}

                                <TableRow key={`buyer-row`}>
                                    {['sellerName', 'mobile', 'allTotal'].map((lbl, index) => (
                                        <TableCell key={`seller-cell-${index}`} colSpan={lbl === 'sellerName' ? 3 : 2} sx={{ fontSize: '1rem', padding: '5px', borderRight: '1px solid #ecc' }}>
                                            <Controller
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        fullWidth
                                                        required
                                                        type={lbl === 'sellerName' ? 'text' : 'number'}
                                                        InputProps={{
                                                            startAdornment: <InputAdornment position="start">
                                                                {t(lbl)}:
                                                            </InputAdornment>,
                                                            endAdornment: <InputAdornment position="end">
                                                                {lbl === 'allTotal' ? t('moneySymbol') : ''}
                                                            </InputAdornment>,
                                                            sx: { '& input': { textAlign: lbl === 'allTotal' ? 'right' : 'left' } },
                                                            disableUnderline: true
                                                        }}
                                                        inputProps={{
                                                            readOnly: lbl === 'allTotal' ? true : false
                                                        }}
                                                        variant="standard"
                                                    />
                                                )}
                                            />
                                        </TableCell>
                                    ))}
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <Grid item container columnSpacing='4' marginBottom={2} justifyContent='right'>
                    {catchID ?
                        <Grid item>
                            <LoadingButton
                                fullWidth
                                type="submit"
                                color="success"
                                sx={{ mt: 2 }}
                                loading={loading ? true : false}
                                variant="contained"
                            >
                                <span>{t('update')}</span>
                            </LoadingButton>
                        </Grid>
                        :
                        <Grid item>

                            <LoadingButton
                                fullWidth
                                type="submit"
                                color="success"
                                sx={{ mt: 2 }}
                                loading={loading ? true : false}
                                // disabled={user.user_type !== t('acntskpr')}
                                variant="contained"
                            >
                                <span>{t('save')}</span>
                            </LoadingButton>
                        </Grid>
                    }
                </Grid>

            </Box>
        </Container >
    );
}