import {
    NEW_BOOK_REQUEST,
    NEW_BOOK_SUCCESS,
    NEW_BOOK_FAIL,
    NEW_BOOK_RESET,
    ALL_BOOKS_REQUEST,
    ALL_BOOKS_SUCCESS,
    ALL_BOOKS_FAIL,
    UPDATE_BOOK_REQUEST,
    UPDATE_BOOK_FAIL,
    UPDATE_BOOK_SUCCESS,
    DELETE_BOOK_REQUEST,
    DELETE_BOOK_FAIL,
    DELETE_BOOK_SUCCESS,
    UPDATE_BOOK_RESET,
    DELETE_BOOK_RESET,
    NEW_PROVIDED_BOOK_REQUEST,
    NEW_PROVIDED_BOOK_SUCCESS,
    NEW_PROVIDED_BOOK_FAIL,
    ALL_PROVIDED_BOOKS_REQUEST,
    ALL_PROVIDED_BOOKS_SUCCESS,
    ALL_PROVIDED_BOOKS_FAIL,
    NEW_PROVIDED_BOOK_RESET,
    UPDATE_PROVIDED_BOOK_REQUEST,
    UPDATE_PROVIDED_BOOK_SUCCESS,
    UPDATE_PROVIDED_BOOK_FAIL,
    UPDATE_PROVIDED_BOOK_RESET,
    ACCEPT_PROVIDED_BOOK_REQUEST,
    ACCEPT_PROVIDED_BOOK_SUCCESS,
    ACCEPT_PROVIDED_BOOK_FAIL,
    ACCEPT_PROVIDED_BOOK_RESET,
    CLEAR_ERRORS
} from '../constants/libraryConstants'

// for add new book
export const newBookReducer = (state = { book: {} }, action) => {
    switch (action.type) {
        case NEW_BOOK_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_BOOK_SUCCESS:
            return {
                loading: false,
                isSaved: true,
                book: action.payload
            }

        case NEW_BOOK_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_BOOK_RESET:
            return {
                isSaved: false,
                book: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all books
export const allBooksReducer = (state = { books: [] }, action) => {
    switch (action.type) {
        case ALL_BOOKS_REQUEST:
            return {
                loading: true,
                books: []
            }

        case ALL_BOOKS_SUCCESS:
            return {
                loading: false,
                books: action.payload.books
            }

        case ALL_BOOKS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const bookReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_BOOK_REQUEST:
        case DELETE_BOOK_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_BOOK_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_BOOK_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_BOOK_FAIL:
        case DELETE_BOOK_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_BOOK_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_BOOK_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new provided book
export const newProvidedBookReducer = (state = { provided_book: {} }, action) => {
    switch (action.type) {
        case NEW_PROVIDED_BOOK_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_PROVIDED_BOOK_SUCCESS:
            return {
                loading: false,
                isSaved: true,
                provided_book: action.payload
            }

        case NEW_PROVIDED_BOOK_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_PROVIDED_BOOK_RESET:
            return {
                isSaved: false,
                provided_book: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all provided books
export const allProvidedBooksReducer = (state = { provided_books: [] }, action) => {
    switch (action.type) {
        case ALL_PROVIDED_BOOKS_REQUEST:
            return {
                loading: true,
                provided_books: []
            }

        case ALL_PROVIDED_BOOKS_SUCCESS:
            return {
                loading: false,
                provided_books: action.payload.provided_books
            }

        case ALL_PROVIDED_BOOKS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// provided book reducer
export const providedBookReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_PROVIDED_BOOK_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_PROVIDED_BOOK_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_PROVIDED_BOOK_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case UPDATE_PROVIDED_BOOK_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// accept provided books
export const acceptProvidedBookReducer = (state = { provided_book: {} }, action) => {
    switch (action.type) {
        case ACCEPT_PROVIDED_BOOK_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ACCEPT_PROVIDED_BOOK_SUCCESS:
            return {
                loading: false,
                isAccepted: true
            }

        case ACCEPT_PROVIDED_BOOK_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case ACCEPT_PROVIDED_BOOK_RESET:
            return {
                isSaved: false,
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}