export const ZK_TECO_CONNECTION_REQUEST = 'ZK_TECO_CONNECTION_REQUEST';
export const ZK_TECO_CONNECTION_SUCCESS = 'ZK_TECO_CONNECTION_SUCCESS';
export const ZK_TECO_CONNECTION_FAIL = 'ZK_TECO_CONNECTION_FAIL';
export const ZK_TECO_CONNECTION_RESET = 'ZK_TECO_CONNECTION_RESET';

export const ZK_TECO_SAVE_REQUEST = 'ZK_TECO_SAVE_REQUEST';
export const ZK_TECO_SAVE_SUCCESS = 'ZK_TECO_SAVE_SUCCESS';
export const ZK_TECO_SAVE_FAIL = 'ZK_TECO_SAVE_FAIL';
export const ZK_TECO_SAVE_RESET = 'ZK_TECO_SAVE_RESET';

export const ZK_TECO_NEW_USER_REQUEST = 'ZK_TECO_NEW_USER_REQUEST';
export const ZK_TECO_NEW_USER_SUCCESS = 'ZK_TECO_NEW_USER_SUCCESS';
export const ZK_TECO_NEW_USER_FAIL = 'ZK_TECO_NEW_USER_FAIL';
export const ZK_TECO_NEW_USER_RESET = 'ZK_TECO_NEW_USER_RESET';

export const ZK_TECO_LOGS_REQUEST = 'ZK_TECO_LOGS_REQUEST';
export const ZK_TECO_LOGS_SUCCESS = 'ZK_TECO_LOGS_SUCCESS';
export const ZK_TECO_LOGS_FAIL = 'ZK_TECO_LOGS_FAIL';
export const CLEAR_LOGS = 'CLEAR_LOGS';

export const ZK_TECO_USERS_REQUEST = 'ZK_TECO_USERS_REQUEST';
export const ZK_TECO_USERS_SUCCESS = 'ZK_TECO_USERS_SUCCESS';
export const ZK_TECO_USERS_FAIL = 'ZK_TECO_USERS_FAIL';

export const ZK_TECO_DELETE_USER_REQUEST = 'ZK_TECO_DELETE_USER_REQUEST'
export const ZK_TECO_DELETE_USER_SUCCESS = 'ZK_TECO_DELETE_USER_SUCCESS'
export const ZK_TECO_DELETE_USER_FAIL = 'ZK_TECO_DELETE_USER_FAIL'
export const ZK_TECO_DELETE_USER_RESET = 'ZK_TECO_DELETE_USER_RESET'

export const ZK_TECO_DEVICE_DELETE_REQUEST = 'ZK_TECO_DEVICE_DELETE_REQUEST'
export const ZK_TECO_DEVICE_DELETE_SUCCESS = 'ZK_TECO_DEVICE_DELETE_SUCCESS'
export const ZK_TECO_DEVICE_DELETE_FAIL = 'ZK_TECO_DEVICE_DELETE_FAIL'
export const ZK_TECO_DEVICE_DELETE_RESET = 'ZK_TECO_DEVICE_DELETE_RESET'

export const getDeviceUsers = (t) => [
    { label: t('student'), value: 'Student' },
    { label: t('officiant'), value: 'Officiant' },
    { label: `${t('student')}/${t('officiant')}`, value: 'All' },
];

export const CLEAR_ERRORS = 'CLEAR_ERRORS';