export const NEW_CLASS_REQUEST = 'NEW_CLASS_REQUEST'
export const NEW_CLASS_SUCCESS = 'NEW_CLASS_SUCCESS'
export const NEW_CLASS_RESET = 'NEW_CLASS_RESET'
export const NEW_CLASS_FAIL = 'NEW_CLASS_FAIL'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const ALL_CLASS_REQUEST = 'ALL_CLASS_REQUEST'
export const ALL_CLASS_SUCCESS = 'ALL_CLASS_SUCCESS'
export const ALL_CLASS_RESET = 'ALL_CLASS_RESET'
export const ALL_CLASS_FAIL = 'ALL_CLASS_FAIL'

export const ALL_STUDENTS_REQUEST = 'ALL_STUDENTS_REQUEST'
export const ALL_STUDENTS_SUCCESS = 'ALL_STUDENTS_SUCCESS'
export const ALL_STUDENTS_FAIL = 'ALL_STUDENTS_FAIL'

export const DELETE_CLASS_REQUEST = 'DELETE_CLASS_REQUEST'
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS'
export const DELETE_CLASS_RESET = 'DELETE_CLASS_RESET'
export const DELETE_CLASS_FAIL = 'DELETE_CLASS_FAIL'

export const NEW_STUDENT_REQUEST = 'NEW_STUDENT_REQUEST'
export const NEW_STUDENT_SUCCESS = 'NEW_STUDENT_SUCCESS'
export const NEW_STUDENT_FAIL = 'NEW_STUDENT_FAIL'
export const NEW_STUDENT_RESET = 'NEW_STUDENT_RESET'

export const UPDATE_STUDENT_REQUEST = 'UPDATE_STUDENT_REQUEST'
export const UPDATE_STUDENT_SUCCESS = 'UPDATE_STUDENT_SUCCESS'
export const UPDATE_STUDENT_FAIL = 'UPDATE_STUDENT_FAIL'
export const UPDATE_STUDENT_RESET = 'UPDATE_STUDENT_RESET'

export const DELETE_STUDENT_REQUEST = 'DELETE_STUDENT_REQUEST'
export const DELETE_STUDENT_SUCCESS = 'DELETE_STUDENT_SUCCESS'
export const DELETE_STUDENT_FAIL = 'DELETE_STUDENT_FAIL'
export const DELETE_STUDENT_RESET = 'DELETE_STUDENT_RESET'

export const NEW_RESULT_REQUEST = 'NEW_RESULT_REQUEST'
export const NEW_RESULT_SUCCESS = 'NEW_RESULT_SUCCESS'
export const NEW_RESULT_FAIL = 'NEW_RESULT_FAIL'
export const NEW_RESULT_RESET = 'NEW_RESULT_RESET'

export const THE_RESULT_REQUEST = 'THE_RESULT_REQUEST'
export const THE_RESULT_SUCCESS = 'THE_RESULT_SUCCESS'
export const THE_RESULT_FAIL = 'THE_RESULT_FAIL'

export const UPDATE_RESULT_REQUEST = 'UPDATE_RESULT_REQUEST'
export const UPDATE_RESULT_SUCCESS = 'UPDATE_RESULT_SUCCESS'
export const UPDATE_RESULT_FAIL = 'UPDATE_RESULT_FAIL'
export const UPDATE_RESULT_RESET = 'UPDATE_RESULT_RESET'

export const DELETE_RESULT_REQUEST = 'DELETE_RESULT_REQUEST'
export const DELETE_RESULT_SUCCESS = 'DELETE_RESULT_SUCCESS'
export const DELETE_RESULT_FAIL = 'DELETE_RESULT_FAIL'
export const DELETE_RESULT_RESET = 'DELETE_RESULT_RESET'

export const ADD_STUDENT_ATTENDANCE_REQUEST = 'ADD_STUDENT_ATTENDANCE_REQUEST'
export const ADD_STUDENT_ATTENDANCE_SUCCESS = 'ADD_STUDENT_ATTENDANCE_SUCCESS'
export const ADD_STUDENT_ATTENDANCE_FAIL = 'ADD_STUDENT_ATTENDANCE_FAIL'
export const ADD_STUDENT_ATTENDANCE_RESET = 'ADD_STUDENT_ATTENDANCE_RESET'
export const ALL_STUDENT_ATTENDANCE_REQUEST = 'ALL_STUDENT_ATTENDANCE_REQUEST'
export const ALL_STUDENT_ATTENDANCE_SUCCESS = 'ALL_STUDENT_ATTENDANCE_SUCCESS'
export const ALL_STUDENT_ATTENDANCE_FAIL = 'ALL_STUDENT_ATTENDANCE_FAIL'
export const DELETE_STUDENT_ATTENDANCE_REQUEST = 'DELETE_STUDENT_ATTENDANCE_REQUEST'
export const DELETE_STUDENT_ATTENDANCE_SUCCESS = 'DELETE_STUDENT_ATTENDANCE_SUCCESS'
export const DELETE_STUDENT_ATTENDANCE_FAIL = 'DELETE_STUDENT_ATTENDANCE_FAIL'
export const DELETE_STUDENT_ATTENDANCE_RESET = 'DELETE_STUDENT_ATTENDANCE_RESET'

// constants
export const NEW_STUDENT_LEAVE_REQUEST = 'NEW_STUDENT_LEAVE_REQUEST'
export const NEW_STUDENT_LEAVE_SUCCESS = 'NEW_STUDENT_LEAVE_SUCCESS'
export const NEW_STUDENT_LEAVE_FAIL = 'NEW_STUDENT_LEAVE_FAIL'
export const NEW_STUDENT_LEAVE_RESET = 'NEW_STUDENT_LEAVE_RESET'

export const ALL_STUDENT_LEAVE_REQUEST = 'ALL_STUDENT_LEAVE_REQUEST'
export const ALL_STUDENT_LEAVE_SUCCESS = 'ALL_STUDENT_LEAVE_SUCCESS'
export const ALL_STUDENT_LEAVE_FAIL = 'ALL_STUDENT_LEAVE_FAIL'

export const UPDATE_STUDENT_LEAVE_REQUEST = 'UPDATE_STUDENT_LEAVE_REQUEST'
export const UPDATE_STUDENT_LEAVE_SUCCESS = 'UPDATE_STUDENT_LEAVE_SUCCESS'
export const UPDATE_STUDENT_LEAVE_FAIL = 'UPDATE_STUDENT_LEAVE_FAIL'

export const DELETE_STUDENT_LEAVE_REQUEST = 'DELETE_STUDENT_LEAVE_REQUEST'
export const DELETE_STUDENT_LEAVE_SUCCESS = 'DELETE_STUDENT_LEAVE_SUCCESS'
export const DELETE_STUDENT_LEAVE_FAIL = 'DELETE_STUDENT_LEAVE_FAIL'
export const UPDATE_STUDENT_LEAVE_RESET = 'UPDATE_STUDENT_LEAVE_RESET'
export const DELETE_STUDENT_LEAVE_RESET = 'DELETE_STUDENT_LEAVE_RESET'

export const ALL_ROUTINES_REQUEST = 'ALL_ROUTINES_REQUEST'
export const ALL_ROUTINES_SUCCESS = 'ALL_ROUTINES_SUCCESS'
export const ALL_ROUTINES_FAIL = 'ALL_ROUTINES_FAIL'

export const ADD_ROUTINE_REQUEST = 'ADD_ROUTINE_REQUEST'
export const ADD_ROUTINE_SUCCESS = 'ADD_ROUTINE_SUCCESS'
export const ADD_ROUTINE_FAIL = 'ADD_ROUTINE_FAIL'
export const ADD_ROUTINE_RESET = 'ADD_ROUTINE_RESET'

export const DELETE_ROUTINE_REQUEST = 'DELETE_ROUTINE_REQUEST'
export const DELETE_ROUTINE_SUCCESS = 'DELETE_ROUTINE_SUCCESS'
export const DELETE_ROUTINE_FAIL = 'DELETE_ROUTINE_FAIL'
export const DELETE_ROUTINE_RESET = 'DELETE_ROUTINE_RESET'
