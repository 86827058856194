// React and related hooks
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    Grid,
    Button,
    ButtonGroup,
    Chip,
    Box,
    Menu,
    MenuItem,
    Dialog
} from '@mui/material';
import {
    Delete,
    AddCircle,
    Edit,
    FileDownloadOutlined
} from '@mui/icons-material';

// Custom components and utilities
import CustomPagination from '../layout/Pagination';
import SALARYFORM from './SalaryForm';
import { MetaData } from '../utils/metaData';
import useAuth from '../hooks/UseAuth';
import useDataGridColumns from '../utils/useDataGridColumns';

// Actions and constants
import {
    allSalaries,
    deleteSalary
} from '../../actions/accountActions';
import {
    DELETE_SALARY_RESET,
    NEW_SALARY_RESET,
    UPDATE_SALARY_RESET
} from '../../constants/accountConstants';
import {
    salaryListPdf,
    salarySheetPdf,
    salaryStatementPdf
} from '../../actions/pdfActions';
import { officiantDetailsText, salaryCutted } from '../../constants/commonContstants';
import { DataGrid, GridActionsCellItem, gridFilteredSortedRowIdsSelector, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const { type } = useAuth();
    const apiRef = useGridApiContext();
    const dispatch = useDispatch();
    const [currentRows, setCurrentRows] = React.useState('');
    const { salaries } = useSelector(state => state.salaries);
    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };

    // for csv export using MUI API
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // Prepare data for PDF
        const visibleRowIds = gridFilteredSortedRowIdsSelector(apiRef); // Get visible row IDs
        const visibleSalaries = salaries.filter(salary => visibleRowIds.includes(salary.id)); // Get salaries corresponding to visible rows

        // Get the current visibility model
        const excludedFields = ['__check__', 'actions', 'provider'];
        const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel;
        const headerNames = apiRef.current.getAllColumns()
            .filter(column => !excludedFields.includes(column.field) && columnVisibilityModel[column.field] !== false)  // Exclude unwanted fields and hidden columns
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const sheetColumns = [
            t('serialNo'),
            ...officiantDetailsText,
            ...salaryCutted,
            t('totalGiven'),
            t('date')
        ];
        sheetColumns.splice(16, 0, t('incrementAmount'));

        const data = {
            heading: t('salaryRecords'),
            columns: headerNames,
            sheetColumns: sheetColumns,
            rows: visibleSalaries
        }
        setCurrentRows(data)
    };

    const handleSalarySheet = () => {
        dispatch(salarySheetPdf(currentRows));
    };

    const handleStatement = () => {
        dispatch(salaryStatementPdf(currentRows));
    };

    const handlePdf = () => {
        dispatch(salaryListPdf(currentRows));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button disabled={type !== 'accountant'} onClick={handleOpen}><AddCircle />
                        </Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handleSalarySheet}>{t('salarySheet')}</MenuItem>
                        <MenuItem onClick={handleStatement}>{t('statement')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>

                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('salaryRecords')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function SALARYLIST() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { code, type } = useAuth();

    const { salaries, loading } = useSelector(state => state.salaries);
    const { isUpdated, isDeleted } = useSelector(state => state.salary);
    const { success } = useSelector(state => state.newSalary);

    const [selectID, setSelectedID] = React.useState(null);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allSalaries(code));

        if (success) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_SALARY_RESET })
        }

        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_SALARY_RESET });
            setOpen(false);
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_SALARY_RESET });
        }
    }, [dispatch, success, isUpdated, isDeleted, t, enqueueSnackbar, code]);

    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlaycibel: t('sorryNotFound'),
    };

    const handleSingleStatement = (id) => {
        const sheetColumns = [
            t('serialNo'),
            ...officiantDetailsText,
            ...salaryCutted,
            t('totalGiven'),
            t('date')
        ];
        sheetColumns.splice(16, 0, t('incrementAmount'));

        const row = salaries.find(s => s.id === id);

        const data = {
            heading: t('salaryRecords'),
            sheetColumns: sheetColumns,
            rows: [row]
        }
        dispatch(salaryStatementPdf(data, code))
    };

    const handleYearlyStatement = (rowData) => {
        const sheetColumns = [
            t('serialNo'),
            ...officiantDetailsText,
            ...salaryCutted,
            t('totalGiven'),
            t('date')
        ];
        sheetColumns.splice(16, 0, t('incrementAmount'));

        const rows = salaries.filter(s => s.employee_id === rowData.idNo && s.date.endsWith(rowData.date.split('-')[1]));

        const data = {
            heading: t('salaryRecords'),
            sheetColumns: sheetColumns,
            rows: rows
        }
        dispatch(salaryStatementPdf(data))
    };

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'id', headerName: t('dataId'), minWidth: 80, sortable: true, flex: 0 },
        { field: 'idNo', headerName: t('idNo') },
        { field: 'name', headerName: t('name') },
        { field: 'level', headerName: t('designation') },
        { field: 'totalSalary', headerName: t('totalSalary') },
        { field: 'totalCutted', headerName: t('totalCutted') },
        { field: 'totalGiven', headerName: t('totalGiven') },
        { field: 'date', headerName: t('date') },
        {
            field: 'provider',
            headerName: t('provider'),
            headerClassName: 'CustomHeader',
            minWidth: 110,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="Provider sign"
                    style={{ width: '60px', height: '30px', marginTop: '2.5px' }}
                />
            ),
        },

        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            minWidth: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'accountant'}
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row.id)}
                />,
                <GridActionsCellItem
                    icon={<FileDownloadOutlined />}
                    label={`${t('statement')}`}
                    onClick={() => handleSingleStatement(params.row.id)}
                    showInMenu
                />,
                <GridActionsCellItem
                    icon={<FileDownloadOutlined />}
                    label={`${t('statement')} -${params.row.date.split('-')[1]}`}
                    onClick={() => handleYearlyStatement(params.row)}
                    showInMenu
                />,
                <GridActionsCellItem
                    sx={{
                        borderTop: '1px solid #ecc'
                    }}
                    disabled={type !== 'accountant'}
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.id)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform salaries array into the desired format
    const rows = salaries.map((salary, index) => {
        return {
            serial: index + 1,
            id: salary.id,
            idNo: salary.employee_id,
            name: salary.name,
            level: salary.designation,
            date: salary.date,
            totalSalary: salary.total_salary,
            totalCutted: salary.total_deductions,
            totalGiven: salary.total_given,
            provider: salary.provider_sign
        };
    });

    const handleEdit = (id) => {
        setSelectedID(id);
        setOpen(true);
    };

    const handleDelete = (id) => {
        const data = {
            id: id
        }
        dispatch(deleteSalary(data, code));
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
        setSelectedID('')
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'SALARY LIST'} />

            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <SALARYFORM closeDialog={handleClose} id={selectID} />
            </Dialog>

            <DataGrid
                rows={rows}
                columns={columns}
                loading={loading ? true : false}
                density={'compact'}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                localeText={localeText}
                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 },
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            id: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />,
                    pagination: CustomPagination,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
