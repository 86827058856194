// React and related hooks
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

// MUI components and icons
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog, Button, Typography } from '@mui/material';
import { Delete, Add } from '@mui/icons-material';

// Custom components and styles
import NEWDEVICEFORM from './NewDeviceForm';
import useAuth from '../hooks/UseAuth';

// Actions
import { deleteAttendanceDevice } from '../../actions/ZKTecoActions';
import { loadUser } from '../../actions/authActions';

// Constants
import { getDeviceUsers, ZK_TECO_DEVICE_DELETE_RESET } from '../../constants/zktecoConstants';
import { MetaData } from '../utils/metaData';

export default function ATTENDANCEDEVICES() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const deviceUsers = getDeviceUsers(t);
    const devices = useSelector(state => state.auth.devices);
    const { isDeleted } = useSelector(state => state.deleteZkDevice);
    const { code, type } = useAuth();

    const [open, setOpen] = React.useState(false);

    useEffect(() => {
        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: ZK_TECO_DEVICE_DELETE_RESET });
            dispatch(loadUser())
        }
    }, [dispatch, enqueueSnackbar, t, isDeleted]);

    const handleDelete = (ipAddress) => {
        const data = {
            ip: ipAddress
        }
        dispatch(deleteAttendanceDevice(data, code));
    };

    const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);
    const handleClose = (ipAddress) => {
        setOpen(false);
        dispatch(loadUser())
    };

    // Transform settings to table data
    const rows = devices?.map((s, index) => {
        const user = deviceUsers.find(u => u.value === s.device_user);
        const userLabel = user ? user.label : s.device_user;

        return {
            id: index + 1,
            deviceName: s.device_name,
            ipAddress: s.ip_address,
            port: s.port,
            deviceUser: userLabel
        };
    });

    const commonTabHeadingStyles = {
        borderRight: '1px solid #ecc',
        fontSize: '1rem',
        padding: '10px'
    };
    const commonTabRowsStyles = {
        borderRight: '1px solid #ecc',
        fontSize: '1rem',
        padding: '10px'
    };

    return (
        <>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {t('connectedDevices')}
                </Typography>
                <Button disabled={type !== 'admin'} startIcon={<Add />} onClick={handleOpen}>
                    {t('newDevice')}
                </Button>
            </Box>
            <MetaData title={'DEVICES'} />
            <Box>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center" sx={{ ...commonTabHeadingStyles }}>{t('serialNo')}</TableCell>
                                <TableCell align="center" sx={{ ...commonTabHeadingStyles }}>{t('deviceName')}</TableCell>
                                <TableCell align="center" sx={{ ...commonTabHeadingStyles }}>{t('ipAddress')}</TableCell>
                                <TableCell align="center" sx={{ ...commonTabHeadingStyles }}>{t('port')}</TableCell>
                                <TableCell align="center" sx={{ ...commonTabHeadingStyles }}>{t('deviceUser')}</TableCell>
                                <TableCell align="center" sx={{ ...commonTabHeadingStyles }}>{t('actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows?.map((row) => (
                                <TableRow key={row.id}>
                                    <TableCell align="center" sx={{ ...commonTabRowsStyles }}>{row.id}</TableCell>
                                    <TableCell sx={{ ...commonTabRowsStyles }}>{row.deviceName}</TableCell>
                                    <TableCell align="center" sx={{ ...commonTabRowsStyles }}>{row.ipAddress}</TableCell>
                                    <TableCell align="center" sx={{ ...commonTabRowsStyles }}>{row.port}</TableCell>
                                    <TableCell sx={{ ...commonTabRowsStyles }}>{row.deviceUser}</TableCell>
                                    <TableCell align="center" sx={{ borderRight: '1px solid #ecc', padding: '10px' }}>
                                        <IconButton disabled={type !== 'admin'} size='small' onClick={() => handleDelete(row.ipAddress)} sx={{ color: 'red' }}>
                                            <Delete fontSize='small' />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog open={open} onClose={handleClose}>
                    <NEWDEVICEFORM closeDialog={handleClose} />
                </Dialog>
            </Box>
        </>
    );
}