import React from 'react'
import { Grid, Box, CardMedia, Typography, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { convertToBengaliDigits } from '../utils/converter';

const TopPadHeading = ({ academicInfo }) => {
    const theme = useTheme();
    return (
        <Box
            sx={{
                justifyContent: 'center',
                padding: 1
            }}
        >
            <Grid container
                display={"flex"}
                alignItems={'center'}
                justifyContent={'center'}
            >
                <Grid item xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: 'center',
                    }}
                >
                    {academicInfo?.calligraphy_url ?
                        <CardMedia
                            component="img"
                            height="45"
                            src={academicInfo?.calligraphy_url}
                            alt='Calligraphy design will be here'
                            sx={{
                                objectFit: 'contain',
                                maxWidth: '100%',
                                filter: theme.palette.mode === 'dark' ? 'invert(1)' : 'none', // Invert colors for dark mode
                            }}
                        />
                        : ''}
                </Grid>
                <Grid item sm={12} md={5} marginRight={'0.6rem'}
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        textAlign: { xs: 'center', md: 'right' }
                    }}
                >
                    <Typography variant="h5">{academicInfo?.name}</Typography>
                    <Typography variant="h6"
                        sx={{ lineHeight: '25px' }}
                    >{academicInfo?.address}</Typography>
                    <Typography variant="h6"
                        sx={{ lineHeight: '20px' }}
                    >{convertToBengaliDigits(academicInfo?.mobile || '')}</Typography>
                </Grid>
                <Grid item xs={1}
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        justifyContent: 'center',
                    }}
                >
                    <Avatar
                        alt="Logo"
                        src={academicInfo?.logo_url}
                        sx={{
                            width: '75px',
                            height: '75px',
                            objectFit: 'contain',
                        }}
                    />
                </Grid>
                <Grid item xs={5} marginLeft={'0.6rem'}
                    sx={{
                        display: { xs: 'none', md: 'flex' },
                        flexDirection: 'column',
                        justifyContent: 'right'
                    }}
                >
                    <Typography variant="h6"
                        sx={{
                            fontSize: '1.2rem',
                            lineHeight: '25px',
                            textTransform: 'uppercase',
                            // fontFamily: 'Segoe UI, Roboto, Helvetica Neue, Arial, sans-serif',
                            fontFamily: 'Times New Roman',
                            fontWeight: 380,
                        }}
                    >
                        {academicInfo?.english_name || 'ENGLISH ACADEMIC NAME WILL BE HERE'}
                    </Typography>

                    <Typography variant="h6"
                        sx={{
                            lineHeight: '25px',
                            fontFamily: 'Times New Roman',
                            textTransform: 'uppercase',
                            fontSize: '1.1rem',
                            fontWeight: 380,
                        }}
                    >{academicInfo?.english_address || 'ENGLISH ADDRESS WILL BE HERE'}
                    </Typography>

                    <Typography variant="h6"
                        sx={{
                            lineHeight: '25px',
                            fontFamily: 'Times New Roman',
                            fontSize: '1.1rem',
                            fontWeight: 380,
                        }}
                    >{academicInfo?.mobile}</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export default TopPadHeading