import {
    CLEAR_ERRORS,
    NEW_REGARDING_REQUEST,
    NEW_REGARDING_SUCCESS,
    NEW_REGARDING_FAIL,
    NEW_REGARDING_RESET,
    ALL_REGARDING_REQUEST,
    ALL_REGARDING_SUCCESS,
    ALL_REGARDING_FAIL,
    ALL_RECEIPT_REQUEST,
    ALL_RECEIPT_SUCCESS,
    ALL_RECEIPT_FAIL,
    ALL_VOUCHER_REQUEST,
    ALL_VOUCHER_SUCCESS,
    ALL_VOUCHER_FAIL,
    NEW_RECEIPT_REQUEST,
    NEW_RECEIPT_SUCCESS,
    NEW_RECEIPT_RESET,
    NEW_RECEIPT_FAIL,
    UPDATE_RECEIPT_REQUEST,
    UPDATE_RECEIPT_SUCCESS,
    UPDATE_RECEIPT_RESET,
    UPDATE_RECEIPT_FAIL,
    UPDATE_VOUCHER_REQUEST,
    UPDATE_VOUCHER_SUCCESS,
    UPDATE_VOUCHER_RESET,
    UPDATE_VOUCHER_FAIL,
    DELETE_COUNTING_DATA_REQUEST,
    DELETE_COUNTING_DATA_SUCCESS,
    DELETE_RECEIPT_REQUEST,
    DELETE_RECEIPT_SUCCESS,
    DELETE_RECEIPT_RESET,
    DELETE_RECEIPT_FAIL,
    DELETE_VOUCHER_REQUEST,
    DELETE_VOUCHER_SUCCESS,
    DELETE_VOUCHER_RESET,
    DELETE_VOUCHER_FAIL,
    DELETE_COUNTING_DATA_FAIL,
    COUNTING_DATA_REQUEST,
    COUNTING_DATA_SUCCESS,
    COUNTING_DATA_RESET,
    COUNTING_DATA_FAIL,
    SEARCHED_COUNTING_DATA_REQUEST,
    SEARCHED_COUNTING_DATA_SUCCESS,
    SEARCHED_COUNTING_DATA_FAIL,
    SEARCHED_COUNTING_DATA_RESET,
    SAVE_COUNTING_DATA_REQUEST,
    SAVE_COUNTING_DATA_SUCCESS,
    NEW_VOUCHER_REQUEST,
    NEW_VOUCHER_SUCCESS,
    NEW_VOUCHER_FAIL,
    NEW_SALARY_REQUEST,
    NEW_SALARY_SUCCESS,
    NEW_SALARY_FAIL,
    NEW_SALARY_RESET,
    ALL_SALARY_REQUEST,
    ALL_SALARY_SUCCESS,
    ALL_SALARY_FAIL,
    UPDATE_SALARY_REQUEST,
    UPDATE_SALARY_SUCCESS,
    UPDATE_SALARY_RESET,
    UPDATE_SALARY_FAIL,
    DELETE_SALARY_REQUEST,
    DELETE_SALARY_RESET,
    DELETE_SALARY_SUCCESS,
    DELETE_SALARY_FAIL,
    NEW_RECEIPT_BOOK_REQUEST,
    NEW_RECEIPT_BOOK_SUCCESS,
    NEW_RECEIPT_BOOK_FAIL,
    NEW_RECEIPT_BOOK_RESET,
    ALL_RECEIPT_BOOKS_REQUEST,
    ALL_RECEIPT_BOOKS_SUCCESS,
    ALL_RECEIPT_BOOKS_FAIL,
    UPDATE_RECEIPT_BOOK_REQUEST,
    UPDATE_RECEIPT_BOOK_SUCCESS,
    UPDATE_RECEIPT_BOOK_FAIL,
    DELETE_RECEIPT_BOOK_REQUEST,
    DELETE_RECEIPT_BOOK_SUCCESS,
    DELETE_RECEIPT_BOOK_FAIL,
    DELETE_RECEIPT_BOOK_RESET,
    UPDATE_RECEIPT_BOOK_RESET,
    NEW_FEE_REQUEST,
    NEW_FEE_SUCCESS,
    NEW_FEE_FAIL,
    ALL_FEES_REQUEST,
    ALL_FEES_SUCCESS,
    ALL_FEES_FAIL,
    APPROVED_BOOKS_REQUEST,
    APPROVED_BOOKS_SUCCESS,
    APPROVED_BOOKS_FAIL,
    DELETE_FEE_REQUEST,
    DELETE_FEE_SUCCESS,
    DELETE_FEE_FAIL,
    DELETE_FEE_RESET,
    UPDATE_FEE_REQUEST,
    UPDATE_FEE_SUCCESS,
    UPDATE_FEE_FAIL,
    UPDATE_FEE_RESET,
    NEW_FEE_RESET,
    NEW_REPORT_REQUEST,
    NEW_REPORT_SUCCESS,
    NEW_REPORT_FAIL,
    NEW_REPORT_RESET,
    DELETE_REPORT_REQUEST,
    DELETE_REPORT_SUCCESS,
    DELETE_REPORT_FAIL,
    REPORT_BALANCE_REQUEST,
    REPORT_BALANCE_SUCCESS,
    REPORT_BALANCE_FAIL,
    DELETE_REPORT_RESET


} from '../constants/accountConstants'

// for add new regarding
export const newRegardingReducer = (state = { regarding: {} }, action) => {
    switch (action.type) {

        case NEW_REGARDING_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_REGARDING_SUCCESS:
            return {
                loading: false,
                success: action.payload,
                regarding: action.payload
            }

        case NEW_REGARDING_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_REGARDING_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all regardings
export const allRegardingReducer = (state = { regardings: [] }, action) => {
    switch (action.type) {
        case ALL_REGARDING_REQUEST:
            return {
                regardingLoading: true,
                regardings: []
            }

        case ALL_REGARDING_SUCCESS:
            return {
                regardingLoading: false,
                regardings: action.payload
            }

        case ALL_REGARDING_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new receipt
export const newReceiptReducer = (state = { receipt: {} }, action) => {
    switch (action.type) {

        case NEW_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_RECEIPT_SUCCESS:
            return {
                loading: false,
                isSaved: true,
                receipt: action.payload
            }

        case NEW_RECEIPT_RESET:
            return {
                ...state,
                isSaved: false
            }

        case NEW_RECEIPT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all receipts
export const allReciptReducer = (state = { receipts: [] }, action) => {
    switch (action.type) {
        case ALL_RECEIPT_REQUEST:
            return {
                loading: true,
                receipts: []
            }

        case ALL_RECEIPT_SUCCESS:
            return {
                loading: false,
                receipts: action.payload
            }

        case ALL_RECEIPT_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for update and delete
export const receiptReducer = (state = {}, action) => {
    switch (action.type) {

        case DELETE_RECEIPT_REQUEST:
        case UPDATE_RECEIPT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_RECEIPT_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }

        case UPDATE_RECEIPT_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }


        case DELETE_RECEIPT_FAIL:
        case UPDATE_RECEIPT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_RECEIPT_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case UPDATE_RECEIPT_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For getting mla, msa, mlb, mlb data
export const getCountingDataReducer = (state = { countingData: [] }, action) => {
    switch (action.type) {
        case COUNTING_DATA_REQUEST:
            return {
                loading: true,
                countingData: []
            }

        case COUNTING_DATA_SUCCESS:
            return {
                loading: false,
                countingData: action.payload.results,
                dates: action.payload.dates,
                searched: action.payload.searched,
                preBalance: action.payload.preBalance
            }

        // case COUNTING_DATA_RESET:
        //     return {
        //         ...state,
        //         countingData: []
        //     }

        case COUNTING_DATA_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// For single counting data
export const searchCountingDataReducer = (state = { searchedData: [] }, action) => {
    switch (action.type) {
        case SEARCHED_COUNTING_DATA_REQUEST:
            return {
                searchLoading: true,
                searchedData: []
            }

        case SEARCHED_COUNTING_DATA_SUCCESS:
            return {
                searchLoading: false,
                searchedData: action.payload.results
            }

        case SEARCHED_COUNTING_DATA_FAIL:
            return {
                searchLoading: false,
                error: action.payload
            }

        case SEARCHED_COUNTING_DATA_RESET:
            return {
                searchLoading: false,
                searchedData: []
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for counting data actions
export const countingDataActionReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_COUNTING_DATA_REQUEST:
        case SAVE_COUNTING_DATA_REQUEST:
            return {
                ...state,
                loadingAction: true,
            }

        case DELETE_COUNTING_DATA_SUCCESS:
        case SAVE_COUNTING_DATA_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isSavedOrDeleted: action.payload
            }

        case COUNTING_DATA_RESET:
            return {
                ...state,
                isSavedOrDeleted: false
            }

        case DELETE_COUNTING_DATA_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// for add new voucher
export const newVoucherReducer = (state = { voucher: {} }, action) => {
    switch (action.type) {

        case NEW_VOUCHER_REQUEST:
            return {
                ...state,
                loading: true,
                isSaved: false
            }

        case NEW_VOUCHER_SUCCESS:
            return {
                loading: false,
                isSaved: true,
                voucher: action.payload
            }

        case NEW_VOUCHER_FAIL:
            return {
                ...state,
                isSaved: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all vouchers
export const allVoucherReducer = (state = { vouchers: [] }, action) => {
    switch (action.type) {
        case ALL_VOUCHER_REQUEST:
            return {
                loading: true,
                vouchers: []
            }

        case ALL_VOUCHER_SUCCESS:
            return {
                loading: false,
                vouchers: action.payload
            }

        case ALL_VOUCHER_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for update and delete
export const voucherReducer = (state = {}, action) => {
    switch (action.type) {

        case DELETE_VOUCHER_REQUEST:
        case UPDATE_VOUCHER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_VOUCHER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }

        case UPDATE_VOUCHER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }

        case DELETE_VOUCHER_FAIL:
        case UPDATE_VOUCHER_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_VOUCHER_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case UPDATE_VOUCHER_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// for add new salary
export const newSalaryReducer = (state = { salary: {} }, action) => {
    switch (action.type) {

        case NEW_SALARY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_SALARY_SUCCESS:
            return {
                loading: false,
                success: action.payload,
                salary: action.payload
            }

        case NEW_SALARY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_SALARY_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all salary
export const allSalaryReducer = (state = { salaries: [] }, action) => {
    switch (action.type) {
        case ALL_SALARY_REQUEST:
            return {
                loading: true,
                salaries: []
            }

        case ALL_SALARY_SUCCESS:
            return {
                loading: false,
                salaries: action.payload
            }

        case ALL_SALARY_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for update and delete salary
export const salaryReducer = (state = {}, action) => {
    switch (action.type) {

        case DELETE_SALARY_REQUEST:
        case UPDATE_SALARY_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_SALARY_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }

        case UPDATE_SALARY_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }

        case DELETE_SALARY_FAIL:
        case UPDATE_SALARY_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_SALARY_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case UPDATE_SALARY_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// for add new approved book
export const newApprovedBookReducer = (state = { approved_book: {} }, action) => {
    switch (action.type) {

        case NEW_RECEIPT_BOOK_REQUEST:
            return {
                ...state,
                loading: true,
                isSaved: false
            }

        case NEW_RECEIPT_BOOK_SUCCESS:
            return {
                loading: false,
                isSaved: true,
                approved_book: action.payload
            }

        case NEW_RECEIPT_BOOK_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_RECEIPT_BOOK_RESET:
            return {
                ...state,
                isSaved: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all approved_books
export const allApprovedBooksReducer = (state = { all_approved_books: [] }, action) => {
    switch (action.type) {
        case ALL_RECEIPT_BOOKS_REQUEST:
            return {
                loading: true,
                all_approved_books: []
            }

        case ALL_RECEIPT_BOOKS_SUCCESS:
            return {
                loading: false,
                all_approved_books: action.payload
            }

        case ALL_RECEIPT_BOOKS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// For approved_books
export const approvedBooksReducer = (state = { approved_books: [] }, action) => {
    switch (action.type) {
        case APPROVED_BOOKS_REQUEST:
            return {
                loading: true,
                approved_books: []
            }

        case APPROVED_BOOKS_SUCCESS:
            return {
                loading: false,
                approved_books: action.payload
            }

        case APPROVED_BOOKS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for update and delete
export const approvedBookReducer = (state = {}, action) => {
    switch (action.type) {

        case DELETE_RECEIPT_BOOK_REQUEST:
        case UPDATE_RECEIPT_BOOK_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_RECEIPT_BOOK_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }

        case UPDATE_RECEIPT_BOOK_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }

        case DELETE_RECEIPT_BOOK_FAIL:
        case UPDATE_RECEIPT_BOOK_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_RECEIPT_BOOK_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case UPDATE_RECEIPT_BOOK_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// for add new fee
export const newFeeReducer = (state = { fee: {} }, action) => {
    switch (action.type) {

        case NEW_FEE_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case NEW_FEE_SUCCESS:
            return {
                loading: false,
                isSavedFee: true,
                fee: action.payload
            }

        case NEW_FEE_RESET:
            return {
                ...state,
                isSavedFee: false
            }

        case NEW_FEE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all fees
export const allFeesReducer = (state = { fees: [] }, action) => {
    switch (action.type) {
        case ALL_FEES_REQUEST:
            return {
                loading: true,
                fees: []
            }

        case ALL_FEES_SUCCESS:
            return {
                loading: false,
                fees: action.payload
            }

        case ALL_FEES_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for update and delete
export const feeReducer = (state = {}, action) => {
    switch (action.type) {

        case DELETE_FEE_REQUEST:
        case UPDATE_FEE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case DELETE_FEE_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: action.payload
            }

        case UPDATE_FEE_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: action.payload
            }

        case DELETE_FEE_FAIL:
        case UPDATE_FEE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_FEE_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case UPDATE_FEE_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// for add new report
export const newReportReducer = (state = { report: {} }, action) => {
    switch (action.type) {

        case NEW_REPORT_REQUEST:
            return {
                ...state,
                loading: true,
                isSaved: false,
            }

        case NEW_REPORT_SUCCESS:
            return {
                loading: false,
                isSaved: action.payload,
            }

        case NEW_REPORT_FAIL:
            return {
                ...state,
                isSaved: true,
                error: action.payload
            }

        case NEW_REPORT_RESET:
            return {
                ...state,
                isSaved: false,
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For geting report balance
export const reportBalanceReducer = (state = { balances: [] }, action) => {
    switch (action.type) {
        case REPORT_BALANCE_REQUEST:
            return {
                balances: []
            }

        case REPORT_BALANCE_SUCCESS:
            return {
                balances: action.payload
            }

        case REPORT_BALANCE_FAIL:
            return {
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for delete counting report
export const reportReducer = (state = {}, action) => {
    switch (action.type) {

        case DELETE_REPORT_REQUEST:
            return {
                ...state,
            }

        case DELETE_REPORT_SUCCESS:
            return {
                ...state,
                isDeleted: action.payload
            }

        case DELETE_REPORT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case DELETE_REPORT_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}
