// React and related hooks
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

// MUI components and icons
import { Box, Grid, Button, Container, TextField, InputAdornment, MenuItem, Stack } from '@mui/material';
import { FileDownloadOutlined } from '@mui/icons-material';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { useForm, Controller } from 'react-hook-form';

// Actions
import { allClass } from '../../actions/eduActions';
import { hostelAttnRegisterPdf, hostelReportRegisterPdf } from '../../actions/pdfActions';

// Constants
import { getReportTypes, reportOptionsCommon, reportOptionsFinal } from '../../constants/commonContstants';

// Custom hooks
import useAuth from '../hooks/UseAuth';

export const HOSTELREGISTERFORM = () => {
    const { t } = useTranslation();
    const formRef = useRef(null);
    const dispatch = useDispatch();
    const isFirstRender = useRef(true);
    const { code } = useAuth();
    const [clickedButton, setClickedButton] = useState('');
    const reportTypes = getReportTypes(t)

    // useEffect is used to work these functionality in one time
    const { classes } = useSelector(state => state.allClass);
    useEffect(() => {
        if (isFirstRender) {
            isFirstRender.current = false;
            dispatch(allClass(code))
        }
    }, [dispatch, code])

    const departments = classes?.map(depart => depart.department);
    const classnames = [...new Set(classes?.map(classItem => classItem.class_name))];

    const defaultValues = {
        attnRegisterHdr: t('hostelAttnRegister'),
        reportRegisterHdr: t('hostelReportRegister'),
        reportType: '',
        department: '',
        className: '',
        emptyRows: 10,
        fontSize: 14
    };

    const { handleSubmit, control } = useForm({ defaultValues });

    const handleClick = (buttonType) => {
        setClickedButton(buttonType);
    };

    const onSubmit = (data) => {
        // Check which button was clicked
        if (clickedButton === 'attendanceRegister') {
            const monthNames = [
                t('January'),
                t('February'),
                t('March'),
                t('April'),
                t('May'),
                t('June'),
                t('July'),
                t('August'),
                t('September'),
                t('October'),
                t('November'),
                t('December'),
            ]
            dispatch(hostelAttnRegisterPdf(code, { data: data, monthNames: monthNames },))
        } else if (clickedButton === 'reportRegister') {

            // define report type column text
            let reportHeadings;
            if (data.reportType === 'ThirdTermExam') {
                reportHeadings = reportOptionsFinal;
            } else {
                reportHeadings = reportOptionsCommon;
            }

            const finalHeading = [
                t('serialNo'),
                t('admitNo'),
                t('studentName'),
                ...reportHeadings,
                t('comment')
            ]

            // find report type label text
            const type = reportTypes.find(report => report.value === data.reportType);
            data.reportType = type.label;

            dispatch(hostelReportRegisterPdf(code, { headings: finalHeading, data: data }))
        }
    };

    return (
        <Container component="main" maxWidth="sm" sx={{
            borderRadius: '10px',
            paddingTop: '1.5rem',
            bgcolor: 'background.paper',
            boxShadow: '0px 0px 15px 1px #e0ecff',
            margin: 'auto'
        }}>
            <MetaData title={'REGISTERS FORM'} />

            <RECEIPTHEADING text={t('registerMakingForm')} department={t('hostelManager')} />
            <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' width={'100%'} marginTop={2} paddingBottom={2}>
                <Grid container spacing={0.2}>
                    {['attnRegisterHdr', 'reportRegisterHdr', 'reportType', 'department', 'className', 'emptyRows', 'fontSize'].map((lbl, index) => (
                        <Grid item xs={['department', 'className', 'emptyRows', 'fontSize'].includes(lbl) ? 6 : 12} key={index}>
                            {['department', 'className'].includes(lbl) ? (
                                <Controller
                                    name={lbl}
                                    control={control}
                                    render={({ field: { onChange, value = '', ...field } }) => (
                                        <TextField
                                            select
                                            value={value}
                                            onChange={(e) => onChange(e.target.value)}
                                            size="small"
                                            fullWidth
                                            sx={{ '& .MuiOutlinedInput-root': { borderRadius: 0 }, '& .css-125z06y-MuiInputBase-root-MuiOutlinedInput-root': { paddingLeft: '6px' } }}
                                            InputProps={{ startAdornment: <InputAdornment position="start">{t(lbl)}:</InputAdornment> }}
                                            {...field}
                                        >
                                            {lbl === 'department'
                                                ? departments.map((department, index) => <MenuItem key={index} value={department}>{department}</MenuItem>)
                                                : classnames.map((classname, index) => <MenuItem key={index} value={classname}>{classname}</MenuItem>)
                                            }
                                        </TextField>
                                    )}
                                />
                            ) : lbl === 'reportType' ?
                                <Controller
                                    key={'reportType'}
                                    name={'reportType'}
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            autoComplete="given-name"
                                            fullWidth
                                            size="small"
                                            required
                                            sx={{ '& .MuiOutlinedInput-root': { borderRadius: 0 }, '& .css-125z06y-MuiInputBase-root-MuiOutlinedInput-root': { paddingLeft: '6px' } }}
                                            InputProps={{ startAdornment: <InputAdornment position="start">{t(lbl)}:</InputAdornment> }}
                                            select
                                        >
                                            {reportTypes.map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                />
                                : (
                                    <Controller
                                        name={lbl}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                required={['attnRegisterHdr', 'reportRegisterHdr'].includes(lbl)}
                                                {...field}
                                                size="small"
                                                type={['emptyRows', 'fontSize'].includes(lbl) ? 'number' : 'text'}
                                                fullWidth
                                                InputProps={{
                                                    style: { borderRadius: 0, paddingLeft: '6px' },
                                                    startAdornment: <InputAdornment position="start">{t(lbl)}:</InputAdornment>,
                                                }}
                                            />
                                        )}
                                    />
                                )}
                        </Grid>
                    ))}
                </Grid>


                <Stack direction="row" spacing={2} mt={2} justifyContent={'right'}>
                    <Button
                        variant="outlined"
                        type="submit"
                        startIcon={<FileDownloadOutlined />}
                        onClick={() => handleClick('attendanceRegister')}
                    >
                        {t('attendanceRegister')}
                    </Button>
                    <Button
                        variant="outlined"
                        type="submit"
                        startIcon={<FileDownloadOutlined />}
                        onClick={() => handleClick('reportRegister')}
                    >
                        {t('reportRegister')}
                    </Button>
                </Stack>

            </Box>
        </Container >
    )
}