import axios from 'axios';
import {
    CLEAR_ERRORS,
    ZK_TECO_CONNECTION_REQUEST,
    ZK_TECO_CONNECTION_SUCCESS,
    ZK_TECO_CONNECTION_FAIL,
    ZK_TECO_SAVE_REQUEST,
    ZK_TECO_SAVE_SUCCESS,
    ZK_TECO_SAVE_FAIL,
    ZK_TECO_LOGS_REQUEST,
    ZK_TECO_LOGS_SUCCESS,
    ZK_TECO_LOGS_FAIL,
    CLEAR_LOGS,
    ZK_TECO_NEW_USER_REQUEST,
    ZK_TECO_NEW_USER_SUCCESS,
    ZK_TECO_NEW_USER_FAIL,
    ZK_TECO_USERS_REQUEST,
    ZK_TECO_USERS_SUCCESS,
    ZK_TECO_USERS_FAIL,
    ZK_TECO_DELETE_USER_REQUEST,
    ZK_TECO_DELETE_USER_SUCCESS,
    ZK_TECO_DELETE_USER_FAIL,
    ZK_TECO_DEVICE_DELETE_REQUEST,
    ZK_TECO_DEVICE_DELETE_SUCCESS,
    ZK_TECO_DEVICE_DELETE_FAIL
} from '../constants/zktecoConstants'

const axiosInstance = axios.create({
    baseURL: process.env.NODE_ENV === 'production'
        ? 'https://tanzimit.com'
        : 'http://localhost:4000',
});

// connect to the device
export const connectAttendanceDevice = (deviceData, code) => async (dispatch) => {
    try {
        dispatch({ type: ZK_TECO_CONNECTION_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        // Send device data to backend
        const { data } = await axiosInstance.post(`/api/zkteco/connect_device/${code}`, deviceData, config);

        dispatch({
            type: ZK_TECO_CONNECTION_SUCCESS,
            payload: data.message
        });

    } catch (error) {
        dispatch({
            type: ZK_TECO_CONNECTION_FAIL,
            payload: error.response?.data?.error || 'Connection failed',
        });
    }
};

// save new device data to backend
export const saveAttendanceDevice = (deviceData, code) => async (dispatch) => {
    try {
        dispatch({ type: ZK_TECO_SAVE_REQUEST });

        // Send device data to backend
        const { data } = await axios.post(`/api/zkdevice/save_device/${code}`, deviceData);

        dispatch({
            type: ZK_TECO_SAVE_SUCCESS,
            payload: data.message
        });

    } catch (error) {
        dispatch({
            type: ZK_TECO_SAVE_FAIL,
            payload: error.response?.data?.error || 'Connection failed',
        });
    }
};

// save new device data to backend
export const deleteAttendanceDevice = (deviceData, code) => async (dispatch) => {
    try {
        dispatch({ type: ZK_TECO_DEVICE_DELETE_REQUEST });

        // Send device data to backend
        const { data } = await axios.post(`/api/zkdevice/delete_device/${code}`, deviceData);

        dispatch({
            type: ZK_TECO_DEVICE_DELETE_SUCCESS,
            payload: data.message
        });

    } catch (error) {
        dispatch({
            type: ZK_TECO_DEVICE_DELETE_FAIL,
            payload: error.response?.data?.error || 'Connection failed',
        });
    }
};

export const getZkTecoLogs = (code, deviceConfigs) => async (dispatch) => {
    try {
        dispatch({ type: ZK_TECO_LOGS_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post(`/api/zkteco/get_logs/${code}`, deviceConfigs, config);
        dispatch({ type: ZK_TECO_LOGS_SUCCESS, payload: data });

    } catch (error) {
        dispatch({
            type: ZK_TECO_LOGS_FAIL,
            payload: error.response?.data?.error || 'Logs fetching failed',
        });
    }
};

export const clearLogs = () => ({ type: CLEAR_LOGS });


export const addZkNewUser = (finalData, code) => async (dispatch) => {
    try {
        dispatch({ type: ZK_TECO_NEW_USER_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post(`/api/zkteco/add_user/${code}`, finalData, config);
        dispatch({
            type: ZK_TECO_NEW_USER_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ZK_TECO_NEW_USER_FAIL,
            payload: error.response?.data?.error || 'Add user failed',
        });
    }
};

export const getZkUsers = (devicesData, code) => async (dispatch) => {
    try {
        dispatch({ type: ZK_TECO_USERS_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post(`/api/zkteco/get_zk_users/${code}`, devicesData, config);
        dispatch({
            type: ZK_TECO_USERS_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ZK_TECO_USERS_FAIL,
            payload: error.response?.data?.error || 'Add user failed',
        });
    }
};

export const deleteZkUser = (useInfo, code) => async (dispatch) => {
    try {
        dispatch({ type: ZK_TECO_DELETE_USER_REQUEST });

        const config = {
            headers: {
                'Content-Type': 'application/json',
            },
        };

        const { data } = await axios.post(`/api/zkteco/delete_user/${code}`, useInfo, config);

        dispatch({
            type: ZK_TECO_DELETE_USER_SUCCESS,
            payload: data
        });

    } catch (error) {
        dispatch({
            type: ZK_TECO_DELETE_USER_FAIL,
            payload: error.response?.data?.error || 'Update user failed',
        });
    }
};

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}