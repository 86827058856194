// Material UI Components
import { Tabs, Tab, Box, Typography } from '@mui/material';

// Material UI Icons
import PeopleIcon from '@mui/icons-material/People';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';
import LanguageIcon from '@mui/icons-material/Language';
import DomainVerificationIcon from '@mui/icons-material/DomainVerification';

// Custom Components and Actions
import UsersList from '../auth/UsersList';
import LANGUAGESETTINGS from './Lnaguages';
import REGISTERNEWACADEMY from '../superAdmin/AcademyForm';
import ATTENDANCEDEVICES from '../attendance/AttendanceDevices';

// Translation
import { useTranslation } from 'react-i18next';

import React, { useState } from "react";
import { IconButton, Drawer } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import USERWEBSETTINGS from './WebSettings';

const ResponsiveSettings = () => {
    const { t } = useTranslation();
    const [selectedTab, setSelectedTab] = useState(0);
    const [isDrawerOpen, setDrawerOpen] = useState(false);

    const handleTabChange = (event, newValue) => {
        toggleDrawer()
        setSelectedTab(newValue);
    };

    const toggleDrawer = () => {
        setDrawerOpen(!isDrawerOpen);
    };

    const menuItems = [
        { label: t('academicInfo'), icon: <PeopleIcon fontSize="12px" style={{ margin: 8 }} /> },
        { label: t('users'), icon: <PeopleIcon fontSize="12px" style={{ margin: 8 }} /> },
        { label: t('connectedDevices'), icon: <DeviceHubIcon fontSize="12px" style={{ margin: 8 }} /> },
        { label: t('languages'), icon: <LanguageIcon fontSize="12px" style={{ margin: 8 }} /> },
        { label: t('customization'), icon: <DomainVerificationIcon fontSize="12px" style={{ margin: 8 }} /> },
        // { label: t('activation'), icon: <RunCircleOutlinedIcon fontSize="12px" style={{ margin: 8 }} /> },
        // { label: t('format'), icon: <DeleteForeverOutlinedIcon fontSize="12px" style={{ margin: 8 }} /> },
        // { label: t('aboutSoftware'), icon: <InfoOutlinedIcon fontSize="12px" style={{ margin: 8 }} /> },
    ];

    return (
        <>
            <Box sx={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                zIndex: 1300
            }}
                backgroundColor='whitesmoke'
            >
                <IconButton
                    sx={{
                        display: { xs: isDrawerOpen ? 'none' : 'flex', sm: "none" }, // Show only on small screens
                    }}
                    onClick={toggleDrawer}
                >
                    <MenuIcon /> <Typography marginLeft={1}>{t('settings')}</Typography>
                </IconButton>
            </Box>
            <Box sx={{ display: "flex", height: "97vh" }} mt={2}>
                {/* Drawer Button */}

                {/* Drawer for Small Screens */}
                <Drawer
                    anchor="left"
                    open={isDrawerOpen}
                    onClose={toggleDrawer}
                    sx={{
                        display: { xs: "block", sm: "none" }, // Drawer visible only for small screens
                    }}
                >
                    <Box
                        sx={{
                            width: 250,
                            padding: "1rem",
                        }}
                    >
                        <Typography
                            variant="h6"
                            sx={{
                                marginTop: '5px',
                                borderBottom: '1px solid #ccc',
                                textAlign: "left", // Align heading to the left
                            }}
                        >
                            {t('settings')}
                        </Typography>
                        <Tabs
                            orientation="vertical"
                            value={selectedTab}
                            onChange={handleTabChange}
                        >
                            {menuItems.map((tab, index) => (
                                <Tab
                                    key={index}
                                    sx={{ alignItems: 'baseline', padding: '5px' }}
                                    label={
                                        <Box
                                            sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "flex-start",
                                            }}
                                        >
                                            {tab.icon}
                                            {tab.label}
                                        </Box>
                                    }
                                />
                            ))}
                        </Tabs>
                    </Box>
                </Drawer>

                {/* Sidebar for Larger Screens */}
                <Box
                    sx={{
                        display: { xs: "none", sm: "block" }, // Sidebar only for larger screens
                        borderRight: 1,
                        borderColor: "divider",
                        width: "20%",
                        padding: "1.5rem",
                    }}
                >
                    <Typography
                        variant="h6"
                        sx={{
                            marginTop: '5px',
                            borderBottom: '1px solid #ccc',
                            textAlign: "left", // Align heading to the left
                        }}
                    >
                        {t('settings')}
                    </Typography>
                    <Tabs
                        orientation="vertical"
                        value={selectedTab}
                        onChange={handleTabChange}
                        sx={{
                            marginTop: '10px'
                        }}
                    >
                        {menuItems.map((tab, index) => (
                            <Tab
                                key={index}
                                sx={{ alignItems: 'baseline', padding: '5px' }}
                                label={
                                    <Box
                                        sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "flex-start",
                                        }}
                                    >
                                        {tab.icon}
                                        {tab.label}
                                    </Box>
                                }
                            />
                        ))}
                    </Tabs>
                </Box>

                {/* Main Content */}
                <Box
                    sx={{
                        width: { xs: "100%", sm: "80%" }, // Full width on small screens
                        padding: "2rem",
                        overflow: "auto",
                    }}
                    mt={1}
                >
                    {selectedTab === 0 && <Box>
                        <REGISTERNEWACADEMY isUpdating={true} />
                    </Box>}
                    {selectedTab === 1 && <UsersList />}
                    {selectedTab === 2 && <ATTENDANCEDEVICES />}
                    {selectedTab === 3 && <LANGUAGESETTINGS />}
                    {selectedTab === 4 && <USERWEBSETTINGS />}
                    {/* {selectedTab === 5 && <ActivationStatus />}
                    {selectedTab === 6 && <Typography>This section is developing</Typography>}
                    {selectedTab === 7 && <Typography>This section is developing</Typography>} */}
                </Box>
            </Box>
        </>
    );
};

export default ResponsiveSettings;
