import React from "react";
import {
    Box,
    Container,
    Typography,
    Grid,
    Card,
    CardContent,
    Avatar,
} from "@mui/material";
import BusinessIcon from "@mui/icons-material/Business";
import SchoolIcon from "@mui/icons-material/School";
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PeopleIcon from "@mui/icons-material/People";
import PrintIcon from "@mui/icons-material/Print";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import BarChartIcon from "@mui/icons-material/BarChart";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant"; // New icon

const features = [
    {
        title: 'Institute Information',
        description: 'Efficiently configure your institute\'s details, such as logo, name, and tagline, to ensure they appear consistently across all printable documents and reports.',
        icon: <BusinessIcon fontSize="large" />,
        color: "#4caf50",
    },
    {
        title: 'Class Management',
        description: "Our software simplifies class management, covering all aspects from student enrollment to subject assignment, course planning, and grade tracking.",
        icon: <SchoolIcon fontSize="large" />,
        color: "#4caf50",
    },
    {
        title: 'Exam Management',
        description: 'A comprehensive solution for managing exams, including exam creation, result tracking, detailed reports, and result card generation.',
        icon: <AssignmentIcon fontSize="large" />,
        color: "#4caf50",
    },
    {
        title: 'Financial Management',
        description: "Easily manage financial operations including income, expenses, and staff salaries with our intuitive software solution.",
        icon: <AccountBalanceIcon fontSize="large" />,
        color: "#ff9800",
    },
    {
        title: "Attendance System",
        description: "The software includes a robust online attendance management system for both students and staff, ensuring efficient tracking and reporting.",
        icon: <PeopleIcon fontSize="large" />,
        color: "#2196f3",
    },
    {
        title: "Printable Reports",
        description: "You can easily print all essential reports and documents, including admission letters, fee slips, salary slips, job letters, result cards, and more.",
        icon: <PrintIcon fontSize="large" />,
        color: "#ff9800",
    },
    {
        title: "Staff Management",
        description: "Manage staff information, roles, and schedules effectively through a streamlined interface.",
        icon: <SupervisorAccountIcon fontSize="large" />,
        color: "#f44336",
    },
    {
        title: "Analytics & Reporting",
        description: "Gain valuable insights with detailed analytics and custom reporting tools for data-driven decision making.",
        icon: <BarChartIcon fontSize="large" />,
        color: "#673ab7",
    },
    {
        title: "Notifications & Alerts", // New feature
        description: "Stay informed with real-time notifications and alerts for important events such as exam schedules, attendance updates, and system alerts.",
        icon: <NotificationImportantIcon fontSize="large" />,
        color: "#9c27b0",
    },
];

const FeaturesSection = () => {
    return (
        <Box sx={{ py: 5, px: 2 }}>
            <Container maxWidth="lg">
                <Typography
                    variant="h4"
                    component="h2"
                    align="center"
                    sx={{ mb: 1 }}
                >
                    Features of our educational management system
                </Typography>
                <Typography
                    variant="body1"
                    component="h2"
                    align="center"
                    sx={{ mb: 4 }}
                >
                    This is just a preview, there are many more features available
                </Typography>
                <Grid container spacing={4}>
                    {features.map((feature, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                            <Card
                                sx={{
                                    height: "100%",
                                    boxShadow: 3,
                                    transition: "transform 0.3s ease",
                                    "&:hover": {
                                        transform: "translateY(-5px)",
                                    },
                                }}
                            >
                                <CardContent>
                                    <Avatar
                                        sx={{
                                            bgcolor: feature.color,
                                            width: 56,
                                            height: 56,
                                            mb: 2,
                                            mx: "auto",
                                        }}
                                    >
                                        {feature.icon}
                                    </Avatar>
                                    <Typography
                                        variant="h6"
                                        align="center"
                                        sx={{ fontWeight: "bold", mb: 1 }}
                                    >
                                        {feature.title}
                                    </Typography>
                                    <Typography
                                        variant="body2"
                                        align="center"
                                    >
                                        {feature.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </Box>
    );
};

export default FeaturesSection;
