import {
    CLEAR_ERRORS,
    NEW_STAFF_REQUEST,
    NEW_STAFF_SUCCESS,
    NEW_STAFF_FAIL,
    ALL_STAFF_REQUEST,
    ALL_STAFF_SUCCESS,
    ALL_STAFF_FAIL,
    UPDATE_STAFF_REQUEST,
    UPDATE_STAFF_SUCCESS,
    UPDATE_STAFF_RESET,
    UPDATE_STAFF_FAIL,
    DELETE_STAFF_SUCCESS,
    DELETE_STAFF_FAIL,
    NEW_STAFF_RESET,
    DELETE_STAFF_RESET,
    DELETE_STAFF_REQUEST,
    NEW_STAFF_REPORT_REQUEST,
    NEW_STAFF_REPORT_SUCCESS,
    NEW_STAFF_REPORT_FAIL,
    ALL_STAFF_REPORT_REQUEST,
    ALL_STAFF_REPORT_SUCCESS,
    ALL_STAFF_REPORT_FAIL,
    UPDATE_STAFF_REPORT_REQUEST,
    UPDATE_STAFF_REPORT_SUCCESS,
    UPDATE_STAFF_REPORT_RESET,
    UPDATE_STAFF_REPORT_FAIL,
    DELETE_STAFF_REPORT_SUCCESS,
    DELETE_STAFF_REPORT_FAIL,
    NEW_STAFF_REPORT_RESET,
    DELETE_STAFF_REPORT_RESET,
    DELETE_STAFF_REPORT_REQUEST,
    NEW_FURNITURE_REQUEST,
    NEW_FURNITURE_SUCCESS,
    NEW_FURNITURE_FAIL,
    NEW_FURNITURE_RESET,
    ALL_FURNITURE_REQUEST,
    ALL_FURNITURE_SUCCESS,
    ALL_FURNITURE_FAIL,
    UPDATE_FURNITURE_REQUEST,
    UPDATE_FURNITURE_SUCCESS,
    UPDATE_FURNITURE_RESET,
    UPDATE_FURNITURE_FAIL,
    DELETE_FURNITURE_REQUEST,
    DELETE_FURNITURE_SUCCESS,
    DELETE_FURNITURE_RESET,
    DELETE_FURNITURE_FAIL,
    NEW_GIVEN_FURNITURE_REQUEST,
    NEW_GIVEN_FURNITURE_SUCCESS,
    NEW_GIVEN_FURNITURE_FAIL,
    NEW_GIVEN_FURNITURE_RESET,
    ALL_GIVEN_FURNITURE_REQUEST,
    ALL_GIVEN_FURNITURE_SUCCESS,
    ALL_GIVEN_FURNITURE_FAIL,
    UPDATE_GIVEN_FURNITURE_REQUEST,
    UPDATE_GIVEN_FURNITURE_SUCCESS,
    UPDATE_GIVEN_FURNITURE_RESET,
    UPDATE_GIVEN_FURNITURE_FAIL,
    DELETE_GIVEN_FURNITURE_REQUEST,
    DELETE_GIVEN_FURNITURE_SUCCESS,
    DELETE_GIVEN_FURNITURE_RESET,
    DELETE_GIVEN_FURNITURE_FAIL,
    ACCEPT_ALL_FURNITURE_REQUEST,
    ACCEPT_ALL_FURNITURE_SUCCESS,
    ACCEPT_ALL_FURNITURE_RESET,
    ACCEPT_ALL_FURNITURE_FAIL,
    ADD_OFFICIANT_ATTENDANCE_REQUEST,
    ADD_OFFICIANT_ATTENDANCE_SUCCESS,
    ADD_OFFICIANT_ATTENDANCE_FAIL,
    ADD_OFFICIANT_ATTENDANCE_RESET,
    ALL_OFFICIANT_ATTENDANCE_REQUEST,
    ALL_OFFICIANT_ATTENDANCE_SUCCESS,
    ALL_OFFICIANT_ATTENDANCE_FAIL,
    DELETE_OFFICIANT_ATTENDANCE_REQUEST,
    DELETE_OFFICIANT_ATTENDANCE_FAIL,
    DELETE_OFFICIANT_ATTENDANCE_SUCCESS,
    DELETE_OFFICIANT_ATTENDANCE_RESET,
    NEW_STAFF_LEAVE_REQUEST,
    NEW_STAFF_LEAVE_SUCCESS,
    NEW_STAFF_LEAVE_FAIL,
    NEW_STAFF_LEAVE_RESET,
    ALL_STAFF_LEAVE_REQUEST,
    ALL_STAFF_LEAVE_SUCCESS,
    ALL_STAFF_LEAVE_FAIL,
    UPDATE_STAFF_LEAVE_REQUEST,
    UPDATE_STAFF_LEAVE_SUCCESS,
    UPDATE_STAFF_LEAVE_FAIL,
    DELETE_STAFF_LEAVE_REQUEST,
    DELETE_STAFF_LEAVE_SUCCESS,
    UPDATE_STAFF_LEAVE_RESET,
    DELETE_STAFF_LEAVE_RESET,
    DELETE_STAFF_LEAVE_FAIL,
    ALL_DUTIES_REQUEST,
    ALL_DUTIES_SUCCESS,
    ALL_DUTIES_FAIL,
    ADD_STAFF_DUTY_REQUEST,
    ADD_STAFF_DUTY_SUCCESS,
    // ADD_STAFF_DUTY_FAIL,
    ADD_STAFF_DUTY_RESET,
    DELETE_STAFF_DUTY_REQUEST,
    DELETE_STAFF_DUTY_SUCCESS,
    DELETE_STAFF_DUTY_FAIL,
    DELETE_STAFF_DUTY_RESET
} from '../constants/adminisConstants'

// for add new staff
export const newStaffReducer = (state = { staff: {} }, action) => {
    switch (action.type) {

        case NEW_STAFF_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_STAFF_SUCCESS:
            return {
                loading: false,
                success: action.payload,
                staff: action.payload
            }

        case NEW_STAFF_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_STAFF_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all staff
export const allStaffReducer = (state = { staff: [] }, action) => {
    switch (action.type) {
        case ALL_STAFF_REQUEST:
            return {
                loading: true,
                staff: []
            }

        case ALL_STAFF_SUCCESS:
            return {
                loading: false,
                staff: action.payload.staff
            }

        case ALL_STAFF_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const staffReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_STAFF_REQUEST:
        case DELETE_STAFF_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_STAFF_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_STAFF_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_STAFF_FAIL:
        case DELETE_STAFF_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_STAFF_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_STAFF_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new staff report
export const newStaffReportReducer = (state = { staffReport: {} }, action) => {
    switch (action.type) {

        case NEW_STAFF_REPORT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_STAFF_REPORT_SUCCESS:
            return {
                loading: false,
                success: action.payload,
                staffReport: action.payload
            }

        case NEW_STAFF_REPORT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_STAFF_REPORT_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all staff reports
export const allStaffReportReducer = (state = { staffReports: [] }, action) => {
    switch (action.type) {
        case ALL_STAFF_REPORT_REQUEST:
            return {
                loading: true,
                staffReports: []
            }

        case ALL_STAFF_REPORT_SUCCESS:
            return {
                loading: false,
                staffReports: action.payload.staffReports
            }

        case ALL_STAFF_REPORT_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const staffReportReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_STAFF_REPORT_REQUEST:
        case DELETE_STAFF_REPORT_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_STAFF_REPORT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_STAFF_REPORT_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_STAFF_REPORT_FAIL:
        case DELETE_STAFF_REPORT_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_STAFF_REPORT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_STAFF_REPORT_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new furniture
export const newFurnitureReducer = (state = { furniture: {} }, action) => {
    switch (action.type) {

        case NEW_FURNITURE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_FURNITURE_SUCCESS:
            return {
                loading: false,
                success: action.payload,
                furniture: action.payload
            }

        case NEW_FURNITURE_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case NEW_FURNITURE_RESET:
            return {
                ...state,
                success: false
            }

        // for actions
        case UPDATE_FURNITURE_REQUEST:
        case DELETE_FURNITURE_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_FURNITURE_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: action.payload
            }

        case UPDATE_FURNITURE_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_FURNITURE_FAIL:
        case DELETE_FURNITURE_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_FURNITURE_SUCCESS:
            return {
                ...state,
                loadingAction: action.payload,
                isDeleted: action.payload
            }

        case DELETE_FURNITURE_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all furnitures
export const allFurnitureReducer = (state = { furnitures: [] }, action) => {
    switch (action.type) {
        case ALL_FURNITURE_REQUEST:
            return {
                loading: true,
                furnitures: []
            }

        case ALL_FURNITURE_SUCCESS:
            return {
                loading: false,
                furnitures: action.payload.furnitures
            }

        case ALL_FURNITURE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new given_furniture
export const newGivenFurnitureReducer = (state = { given_furniture: {} }, action) => {
    switch (action.type) {

        case NEW_GIVEN_FURNITURE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_GIVEN_FURNITURE_SUCCESS:
            return {
                loading: false,
                givingSuccess: action.payload,
                given_furniture: action.payload
            }

        case NEW_GIVEN_FURNITURE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_GIVEN_FURNITURE_RESET:
            return {
                ...state,
                givingSuccess: false
            }

        // for actions
        case UPDATE_GIVEN_FURNITURE_REQUEST:
        case DELETE_GIVEN_FURNITURE_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_GIVEN_FURNITURE_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: action.payload
            }

        case UPDATE_GIVEN_FURNITURE_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_GIVEN_FURNITURE_FAIL:
        case DELETE_GIVEN_FURNITURE_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_GIVEN_FURNITURE_SUCCESS:
            return {
                ...state,
                loadingAction: action.payload,
                isDeleted: action.payload
            }

        case DELETE_GIVEN_FURNITURE_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all given_furnitures
export const allGivenFurnitureReducer = (state = { givenFurnitures: [] }, action) => {
    switch (action.type) {
        case ALL_GIVEN_FURNITURE_REQUEST:
            return {
                loading: true,
                givenFurnitures: []
            }

        case ALL_GIVEN_FURNITURE_SUCCESS:
            return {
                loading: false,
                givenFurnitures: action.payload.givenFurnitures
            }

        case ALL_GIVEN_FURNITURE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// furniture actions
export const furnitureReducer = (state = {}, action) => {
    switch (action.type) {
        case ACCEPT_ALL_FURNITURE_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case ACCEPT_ALL_FURNITURE_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isAccepted: true
            }

        case ACCEPT_ALL_FURNITURE_RESET:
            return {
                ...state,
                isAccepted: false
            }

        case ACCEPT_ALL_FURNITURE_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new officiant attendance
export const addOfficiantAttendReducer = (state = { officiantAttend: {} }, action) => {
    switch (action.type) {

        case ADD_OFFICIANT_ATTENDANCE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case ADD_OFFICIANT_ATTENDANCE_SUCCESS:
            return {
                loading: false,
                success: action.payload,
                staff: action.payload
            }

        case ADD_OFFICIANT_ATTENDANCE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case ADD_OFFICIANT_ATTENDANCE_RESET:
            return {
                ...state,
                success: false
            }

        // for actions
        // case UPDATE_FURNITURE_REQUEST:
        case DELETE_OFFICIANT_ATTENDANCE_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        // case UPDATE_FURNITURE_SUCCESS:
        //     return {
        //         ...state,
        //         loadingAction: false,
        //         isUpdated: action.payload
        //     }

        // case UPDATE_FURNITURE_RESET:
        //     return {
        //         ...state,
        //         isUpdated: false
        //     }

        // case UPDATE_FURNITURE_FAIL:
        case DELETE_OFFICIANT_ATTENDANCE_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_OFFICIANT_ATTENDANCE_SUCCESS:
            return {
                ...state,
                loadingAction: action.payload,
                isDeleted: action.payload
            }

        case DELETE_OFFICIANT_ATTENDANCE_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// All officiant attendance
export const allOfficiantAttendance = (state = { officiantAttendance: [] }, action) => {
    switch (action.type) {
        case ALL_OFFICIANT_ATTENDANCE_REQUEST:
            return {
                loading: true,
                officiantAttendance: []
            }

        case ALL_OFFICIANT_ATTENDANCE_SUCCESS:
            return {
                loading: false,
                officiantAttendance: action.payload.officiant_attendance
            }

        case ALL_OFFICIANT_ATTENDANCE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                error: null
            }

        default:
            return state;
    }
}

// for add new leave
export const newStaffLeaveReducer = (state = { staffLeave: {} }, action) => {
    switch (action.type) {

        case NEW_STAFF_LEAVE_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_STAFF_LEAVE_SUCCESS:
            return {
                loading: false,
                success: action.payload
            }

        case NEW_STAFF_LEAVE_RESET:
            return {
                loading: false,
                success: false
            }

        case NEW_STAFF_LEAVE_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For staff leaves
export const allStaffLeaveReducer = (state = { staffLeaves: [] }, action) => {
    switch (action.type) {
        case ALL_STAFF_LEAVE_REQUEST:
            return {
                loading: true,
                staffLeaves: []
            }

        case ALL_STAFF_LEAVE_SUCCESS:
            return {
                loading: false,
                staffLeaves: action.payload.staffLeaves
            }

        case ALL_STAFF_LEAVE_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const staffLeaveReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_STAFF_LEAVE_REQUEST:
        case DELETE_STAFF_LEAVE_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_STAFF_LEAVE_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_STAFF_LEAVE_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_STAFF_LEAVE_FAIL:
        case DELETE_STAFF_LEAVE_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_STAFF_LEAVE_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_STAFF_LEAVE_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// For staff duties
export const allDutiesReducer = (state = { staffDuties: [] }, action) => {
    switch (action.type) {
        case ALL_DUTIES_REQUEST:
            return {
                loading: true,
                staffDuties: []
            }

        case ALL_DUTIES_SUCCESS:
            return {
                loading: false,
                staffDuties: action.payload.staffDuties
            }

        case ALL_DUTIES_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new staff duty
export const dutyReducer = (state = {}, action) => {
    switch (action.type) {

        case ADD_STAFF_DUTY_REQUEST:
        case DELETE_STAFF_DUTY_REQUEST:
            return {
                ...state,
                loadingDuty: true
            }

        case ADD_STAFF_DUTY_SUCCESS:
        case DELETE_STAFF_DUTY_SUCCESS:
            return {
                loadingDuty: false,
                success: action.payload
            }

        case DELETE_STAFF_DUTY_FAIL:
            return {
                ...state,
                loadingDuty: false,
                error: action.payload
            }

        case ADD_STAFF_DUTY_RESET:
        case DELETE_STAFF_DUTY_RESET:
            return {
                ...state,
                success: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}