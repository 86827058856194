import React from 'react'
// Material UI Components
import { DialogContent, DialogTitle, Typography, Container, Grid } from '@mui/material';
import { MetaData } from '../utils/metaData';

const TermsAndConditions = () => {
    return (
        <Container>
            <MetaData title={'TERMS AND CONDITIONS'} />
            <Grid container justifyContent={'center'}>
                <Grid item xs={8}>
                    <DialogTitle>Terms and Conditions</DialogTitle>
                    <DialogContent dividers>
                        <Typography variant="body1" paragraph>
                            By accessing or using TANZIMACADEMY, you agree to comply with and be bound by these Terms and Conditions. If you do not agree, you should not use the Software.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            1. License
                        </Typography>
                        <Typography variant="body2" paragraph>
                            We grant you a limited, non-transferable license to use the Software for its intended educational purpose, subject to compliance with these Terms.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            2. User Obligations
                        </Typography>
                        <Typography variant="body2" paragraph>
                            You agree to: <br />
                            - Use the Software in accordance with all applicable laws. <br />
                            - Keep your login credentials confidential. <br />
                            - Provide accurate and up-to-date information.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            3. Prohibited Activities
                        </Typography>
                        <Typography variant="body2" paragraph>
                            You may not: <br />
                            - Violate any intellectual property rights or use the Software for unauthorized purposes. <br />
                            - Disrupt or damage the Software or related systems.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            4. Privacy and Data Protection
                        </Typography>
                        <Typography variant="body2" paragraph>
                            By using the Software, you consent to the collection and use of personal data as outlined in our Privacy Policy.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            5. Fees and Payment
                        </Typography>
                        <Typography variant="body2" paragraph>
                            You agree to pay any applicable fees for the use of the Software, as detailed in the subscription agreement.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            6. Termination
                        </Typography>
                        <Typography variant="body2" paragraph>
                            We may suspend or terminate your access if you breach these Terms. Upon termination, you must stop using the Software and delete all copies.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            7. Limitation of Liability
                        </Typography>
                        <Typography variant="body2" paragraph>
                            We are not liable for any indirect, incidental, or consequential damages arising from your use of the Software. Our liability is limited to the fees you’ve paid for the Software in the past 12 months.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            8. Indemnification
                        </Typography>
                        <Typography variant="body2" paragraph>
                            You agree to indemnify us against any claims arising from your use of the Software.
                        </Typography>
                        <Typography variant="h6" paragraph>
                            9. Governing Law
                        </Typography>
                        <Typography variant="body2" paragraph>
                            These Terms are governed by the laws of [Insert Jurisdiction].
                        </Typography>
                        <Typography variant="h6" paragraph>
                            10. Modifications
                        </Typography>
                        <Typography variant="body2" paragraph>
                            We may update these Terms at any time. Continued use of the Software after changes constitutes acceptance of the updated Terms.
                        </Typography>
                    </DialogContent>
                </Grid>
            </Grid>
        </Container>
    )
}

export default TermsAndConditions