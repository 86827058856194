import {
    NEW_STUDENT_REPORT_REQUEST,
    NEW_STUDENT_REPORT_SUCCESS,
    NEW_STUDENT_REPORT_FAIL,
    ALL_STUDENT_REPORT_REQUEST,
    ALL_STUDENT_REPORT_SUCCESS,
    ALL_STUDENT_REPORT_FAIL,
    DELETE_STUDENT_REPORT_REQUEST,
    DELETE_STUDENT_REPORT_SUCCESS,
    DELETE_STUDENT_REPORT_FAIL,
    DELETE_STUDENT_REPORT_RESET,
    UPDATE_STUDENT_REPORT_REQUEST,
    UPDATE_STUDENT_REPORT_SUCCESS,
    UPDATE_STUDENT_REPORT_RESET,
    UPDATE_STUDENT_REPORT_FAIL,
    CLEAR_ERRORS,
    NEW_STUDENT_REPORT_RESET
} from '../constants/hostelConstants'

// for add new student
export const newStudentReportReducer = (state = { studentReport: {} }, action) => {
    switch (action.type) {
        case NEW_STUDENT_REPORT_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_STUDENT_REPORT_SUCCESS:
            return {
                loading: false,
                isSaved: true,
                studentReport: action.payload
            }

        case NEW_STUDENT_REPORT_RESET:
            return {
                loading: false,
                isSaved: false
            }

        case NEW_STUDENT_REPORT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}


// For all student reports
export const allStudentReportsReducer = (state = { studentReports: [] }, action) => {
    switch (action.type) {
        case ALL_STUDENT_REPORT_REQUEST:
            return {
                loading: true,
                studentReports: []
            }

        case ALL_STUDENT_REPORT_SUCCESS:
            return {
                loading: false,
                studentReports: action.payload.student_reports
            }

        case ALL_STUDENT_REPORT_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}


export const studentReportReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_STUDENT_REPORT_REQUEST:
        case DELETE_STUDENT_REPORT_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_STUDENT_REPORT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_STUDENT_REPORT_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_STUDENT_REPORT_FAIL:
        case DELETE_STUDENT_REPORT_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_STUDENT_REPORT_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_STUDENT_REPORT_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}