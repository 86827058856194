import axios from 'axios';
import {
    NEW_CLASS_REQUEST,
    NEW_CLASS_SUCCESS,
    NEW_CLASS_FAIL,
    ALL_CLASS_REQUEST,
    ALL_CLASS_SUCCESS,
    ALL_CLASS_FAIL,
    NEW_STUDENT_REQUEST,
    NEW_STUDENT_SUCCESS,
    NEW_STUDENT_FAIL,
    ALL_STUDENTS_REQUEST,
    ALL_STUDENTS_SUCCESS,
    ALL_STUDENTS_FAIL,
    UPDATE_STUDENT_REQUEST,
    UPDATE_STUDENT_FAIL,
    UPDATE_STUDENT_SUCCESS,
    DELETE_STUDENT_REQUEST,
    DELETE_STUDENT_FAIL,
    DELETE_STUDENT_SUCCESS,
    THE_RESULT_REQUEST,
    THE_RESULT_SUCCESS,
    THE_RESULT_FAIL,
    DELETE_CLASS_REQUEST,
    DELETE_CLASS_SUCCESS,
    DELETE_CLASS_FAIL,
    CLEAR_ERRORS,
    NEW_RESULT_REQUEST,
    NEW_RESULT_SUCCESS,
    NEW_RESULT_FAIL,
    UPDATE_RESULT_REQUEST,
    UPDATE_RESULT_SUCCESS,
    UPDATE_RESULT_FAIL,
    DELETE_RESULT_REQUEST,
    DELETE_RESULT_SUCCESS,
    DELETE_RESULT_FAIL,
    ADD_STUDENT_ATTENDANCE_REQUEST,
    ADD_STUDENT_ATTENDANCE_SUCCESS,
    ADD_STUDENT_ATTENDANCE_FAIL,
    ALL_STUDENT_ATTENDANCE_REQUEST,
    ALL_STUDENT_ATTENDANCE_SUCCESS,
    ALL_STUDENT_ATTENDANCE_FAIL,
    DELETE_STUDENT_ATTENDANCE_REQUEST,
    DELETE_STUDENT_ATTENDANCE_SUCCESS,
    DELETE_STUDENT_ATTENDANCE_FAIL,
    NEW_STUDENT_LEAVE_REQUEST,
    NEW_STUDENT_LEAVE_SUCCESS,
    NEW_STUDENT_LEAVE_FAIL,
    ALL_STUDENT_LEAVE_REQUEST,
    ALL_STUDENT_LEAVE_SUCCESS,
    ALL_STUDENT_LEAVE_FAIL,
    UPDATE_STUDENT_LEAVE_REQUEST,
    UPDATE_STUDENT_LEAVE_SUCCESS,
    UPDATE_STUDENT_LEAVE_FAIL,
    DELETE_STUDENT_LEAVE_REQUEST,
    DELETE_STUDENT_LEAVE_SUCCESS,
    DELETE_STUDENT_LEAVE_FAIL,
    ALL_ROUTINES_REQUEST,
    ALL_ROUTINES_SUCCESS,
    ALL_ROUTINES_FAIL,
    ADD_ROUTINE_REQUEST,
    ADD_ROUTINE_SUCCESS,
    ADD_ROUTINE_FAIL,
    DELETE_ROUTINE_REQUEST,
    DELETE_ROUTINE_SUCCESS,
    DELETE_ROUTINE_FAIL
} from '../constants/eduConstants'


// add new class 
export const newClass = (classData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_CLASS_REQUEST })

        // passing CLASSData to backend by this route 
        const { data } = await axios.post(`/api/education/class/new/${code}`, classData)

        // store data in payload 
        dispatch({
            type: NEW_CLASS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_CLASS_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all classes
export const allClass = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_CLASS_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/education/classes/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_CLASS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_CLASS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Delete class
export const deleteClass = (classData, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_CLASS_REQUEST
        })

        // Sending a delete request to the backend to delete the class
        const { data } = await axios.post(`/api/education/class/delete/${code}`, classData)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_CLASS_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_CLASS_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new student 
export const newStudent = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_STUDENT_REQUEST })

        // configuration for register a user 
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        // passing studentData to backend by this route 
        const { data } = await axios.post(`/api/education/student/new/${code}`, formData, config)

        // store data in payload 
        dispatch({
            type: NEW_STUDENT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_STUDENT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Get all students
export const allStudents = (code, className) => async (dispatch) => {
    try {

        dispatch({ type: ALL_STUDENTS_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/education/students/${code}`, className)

        // storing all users in payload
        dispatch({
            type: ALL_STUDENTS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_STUDENTS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update studnet data
export const updateStudent = (studentData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_STUDENT_REQUEST })

        // configuration for register a user 
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        // connect with backend for staff report update
        const { data } = await axios.put(`/api/education/student/update/${code}`, studentData, config)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_STUDENT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_STUDENT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete student
export const deleteStudent = (studentData, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_STUDENT_REQUEST
        })

        // Sending a delete request to the backend to delete the class
        const { data } = await axios.post(`/api/education/student/delete/${code}`, studentData)

        // showing success status if the student is deleted
        dispatch({
            type: DELETE_STUDENT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_STUDENT_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new result 
export const newResult = (resultData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_RESULT_REQUEST })

        // passing resultData to backend by this route 
        const { data } = await axios.post(`/api/education/result/new/${code}`, resultData)

        // store data in payload 
        dispatch({
            type: NEW_RESULT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_RESULT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get result
export const getResult = (code, resultData) => async (dispatch) => {
    try {

        dispatch({ type: THE_RESULT_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/education/result/${code}`, resultData)

        // storing all users in payload
        dispatch({
            type: THE_RESULT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: THE_RESULT_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update student result
export const updateResult = (resultData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_RESULT_REQUEST })

        // connect with backend for staff report update
        const { data } = await axios.put(`/api/education/result/update/${code}`, resultData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_RESULT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_RESULT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete student result
export const deleteResult = (resultInfo, code) => async (dispatch) => {
    try {

        dispatch({ type: DELETE_RESULT_REQUEST })

        // connect with backend for staff report update
        const { data } = await axios.post(`/api/education/result/delete/${code}`, resultInfo)

        // showing success status if the profile is updated
        dispatch({
            type: DELETE_RESULT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DELETE_RESULT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Add class routine
export const addClassRoutine = (routineData, code) => async (dispatch) => {
    try {

        dispatch({ type: ADD_ROUTINE_REQUEST })

        // connect with backend for add staff duty
        const { data } = await axios.put(`/api/education/routine/add/${code}`, routineData)

        // showing success status if the profile is updated
        dispatch({
            type: ADD_ROUTINE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_ROUTINE_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new student attendance
export const addStudentAttendance = (code, logData) => async (dispatch) => {
    try {

        dispatch({ type: ADD_STUDENT_ATTENDANCE_REQUEST })

        // passing _STAFF_REPORT_Data to backend by this route 
        const { data } = await axios.post(`/api/education/student/add_attendance/${code}`, logData)

        // store data in payload 
        dispatch({
            type: ADD_STUDENT_ATTENDANCE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_STUDENT_ATTENDANCE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all officiant attendance
export const getAllStudentAttendance = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_STUDENT_ATTENDANCE_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/education/student/all_attendance/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_STUDENT_ATTENDANCE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_STUDENT_ATTENDANCE_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Delete Student Attendance
export const deleteStudentAttendance = (info, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_STUDENT_ATTENDANCE_REQUEST
        })

        // Sending a delete request to the backend to delete the furniture
        const { data } = await axios.post(`/api/education/student/delete_attendance//${code}`, info)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_STUDENT_ATTENDANCE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_STUDENT_ATTENDANCE_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new student leave
export const newStudentLeave = (formData, code) => async (dispatch) => {

    try {

        dispatch({ type: NEW_STUDENT_LEAVE_REQUEST })

        // passing studentData to backend by this route 
        const { data } = await axios.post(`/api/education/student/leave/new/${code}`, formData)

        // store data in payload 
        dispatch({
            type: NEW_STUDENT_LEAVE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_STUDENT_LEAVE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const allStudentLeaves = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_STUDENT_LEAVE_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/education/student/leaves/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_STUDENT_LEAVE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_STUDENT_LEAVE_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

export const updateStudentLeave = (studentData, code) => async (dispatch) => {

    try {

        dispatch({ type: UPDATE_STUDENT_LEAVE_REQUEST })

        // connect with backend for student update
        const { data } = await axios.post(`/api/education/student/leave/update/${code}`, studentData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_STUDENT_LEAVE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_STUDENT_LEAVE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteStudentLeave = (id, code) => async (dispatch) => { // id is nid which is sent from studentlsit
    try {
        dispatch({
            type: DELETE_STUDENT_LEAVE_REQUEST
        })

        // Sending a delete request to the backend to delete the student
        const { data } = await axios.post(`/api/education/student/leave/delete/${code}`, id)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_STUDENT_LEAVE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DELETE_STUDENT_LEAVE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all routines
export const allRoutines = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_ROUTINES_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/education/routines/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_ROUTINES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_ROUTINES_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Delete staff routine
export const deleteRoutine = (id, code) => async (dispatch) => { // id is nid which is sent from stafflsit

    try {
        dispatch({
            type: DELETE_ROUTINE_REQUEST
        })

        // Sending a delete request to the backend to delete the staff
        const { data } = await axios.post(`/api/education/students/delete_routine/${code}/${id}`)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_ROUTINE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_ROUTINE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}