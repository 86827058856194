// React and related hooks
import React, { useState, useRef, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    Avatar,
    Box,
    Button,
    Grid,
    Paper,
    TextField,
    Typography,
    Container,
    MenuItem,
    CircularProgress
} from '@mui/material';
import {
    LoadingButton
} from '@mui/lab';
import {
    Close,
    Fingerprint
} from '@mui/icons-material';
import { green } from '@mui/material/colors';

// MUI DatePicker components
import {
    LocalizationProvider
} from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

// Custom components and utilities
import { CustomCrossButton } from '../styles/style';
import { convertToBengaliDigits } from '../utils/converter';
import useAuth from '../hooks/UseAuth';

// Actions and constants
import { addStaffDuty } from '../../actions/adminisActions';
import { addZkNewUser } from '../../actions/ZKTecoActions';
import { getAttendanceTypes, getZktecoUserTypes } from '../../constants/adminisConstants';
import { ZK_TECO_NEW_USER_RESET } from '../../constants/zktecoConstants';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { MetaData } from '../utils/metaData';

function DUTYFORM({ closeDialog, rowData }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const attendanceTypes = getAttendanceTypes(t);
    const userTypes = getZktecoUserTypes(t);
    const formRef = useRef(null);
    const { code } = useAuth();

    const devices = useSelector(state => state.auth.devices);
    const { loading, connectedDevice } = useSelector(state => state.zkNewUser);
    const { loadingDuty } = useSelector(state => state.duty);

    const defaultDeviceIPs = rowData?.devices
        ? rowData.devices.map(d => d.split(':'))  // Extract into a 2D array
        : [];
    // Transform the structure to the desired format
    const formattedDeviceIPs = defaultDeviceIPs.map(device => `${device[1]}:${device[2]}`);

    const defaultValues = {
        attendanceMethod: rowData?.attendance_type || 'cardNumber',
        cardNumber: rowData?.card || '',
        dutyStart: rowData?.duty_start || '',
        dutyEnd: rowData?.duty_end || '',
        residenceType: rowData?.residenceType || '',
        pin: rowData?.pin || '',
        shift: rowData?.shift || '',
        teamName: rowData?.teamName || '',
        privilege: rowData?.privilege || 'general',
        deviceIPs: formattedDeviceIPs || ''

    };
    const { handleSubmit, control, getValues } = useForm({ defaultValues });
    const [attendanceType, setAttendanceType] = useState([])

    const handleAttendanceTypeChange = (e) => {
        if (e.target.value === 'all') {
            setAttendanceType(['cardNumber', 'pin'])
        } else if (e.target.value === 'manual' || e.target.value === 'finger') {
            setAttendanceType([])
        } else {
            setAttendanceType([e.target.value])
        }
    };

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (rowData && rowData.attendance_type) {
            if (rowData.attendance_type === 'all') {
                setAttendanceType(['cardNumber', 'pin']);
            } else if (rowData.attendance_type === 'manual') {
                setAttendanceType([]);
            } else {
                setAttendanceType([rowData.attendance_type]);
            }
        } else {
            // Handle case when rowData or attendance_type is null
            setAttendanceType(['cardNumber']);
        }
    }, [rowData]);

    useEffect(() => {
        if (connectedDevice.length > 0) {
            const data = getValues()
            data.deviceIPs = connectedDevice; // replace with connected devices
            const isUpdate = rowData.dutyStart ? true : false;
            dispatch(addStaffDuty({ idNo: rowData.id, ...data, isUpdate: isUpdate }, code));
            dispatch({ type: ZK_TECO_NEW_USER_RESET })
        }
    }, [connectedDevice.length, getValues, rowData.dutyStart, code, dispatch, rowData.id, connectedDevice]);

    // handle submit
    // handle submit
    const onSubmit = async (data) => {
        // Extract the IPs from data.deviceIPs
        const selectedIPs = data.deviceIPs.map(ipWithPort => ipWithPort.split(':')[0]);
        // Filter devices based on the extracted IPs
        const newDevices = devices.filter(device => selectedIPs.includes(device.ip_address));

        const userData = {
            userType: 'Officiant',
            isUpdate: rowData.attendance_type && rowData.attendance_type !== "manual" ? true : false,
            idNo: rowData.idNo,
            privilege: data.privilege,
            finger: data.attendanceMethod === 'all' || data.attendanceMethod === 'finger' ? 'finger' : '',
            cardNumber: data.cardNumber,
            pin: data.pin
        }

        const finalData = [
            newDevices,
            userData,
            rowData?.devices
        ]

        try {
            if (data.attendanceMethod !== 'manual') {
                // register on ZKTeco machine
                dispatch(addZkNewUser(finalData, code));
            } else {
                const isUpdate = rowData.dutyStart ? true : false;
                dispatch(addStaffDuty({ idNo: rowData.id, ...data, isUpdate: isUpdate }, code));
            }

        } catch (err) {
            enqueueSnackbar(t('zktecoOffMeesage'), { variant: 'error' });
        }
    }

    return (
        <Container maxWidth={false} sx={{
            borderRadius: '10px',
            maxWidth: '700px',
        }}>
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <MetaData title={'DUTY FORM'} />
            {/* Sidebar */}
            <Grid container spacing={3} paddingTop={4} paddingBottom={4}>
                <Grid item xs={12} sm={5}>
                    <Paper sx={{ padding: 2, height: '100%' }}>
                        <Avatar
                            alt="Profile Picture"
                            src={rowData?.imageUrl} // Replace with your avatar image path
                            sx={{ width: 100, height: 100, margin: '0 auto', mb: 2, border: '1px solid #ccc' }}
                        />
                        {/* User Info Section */}
                        <Typography variant="h7" component="div" fontWeight="bold">
                            {t('idNo')}: {convertToBengaliDigits(rowData?.idNo || '')}
                        </Typography>
                        <Typography variant="h7" component="div" fontWeight="bold">
                            {t('name')}: {rowData?.name}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {t('designation')}: {rowData?.designation}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {t('father')}: {rowData?.fatherName}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {rowData?.address}
                        </Typography>
                        <Typography variant="body1" color="textSecondary">
                            {t('mobile')}: {convertToBengaliDigits(rowData?.mobile || '')}
                        </Typography>
                    </Paper>
                </Grid>

                {/* Account Settings Form */}
                <Grid item xs={12} sm={7}>
                    <Paper sx={{ padding: 2 }}>
                        <Typography variant="h6" gutterBottom>
                            {t('officiantDuty')}
                        </Typography>
                        <Box
                            component="form"
                            onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'
                        >
                            <Grid container spacing={1.5} item xs={12}>
                                {['teamName', 'shift', 'residenceType', 'dutyStart', 'dutyEnd', 'attendanceMethod'].map((lbl, index) => (
                                    <Grid item
                                        xs={lbl === 'teamName' || lbl === 'attendanceMethod' ? 12 : 6}
                                        key={`lbl${index}`}
                                    >
                                        {lbl === 'attendanceMethod' ? (
                                            <Controller
                                                key={`controller-${lbl}`} // Ensure keys are unique
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        autoComplete="given-name"
                                                        fullWidth
                                                        size="small"
                                                        id={`id${index}`}
                                                        select
                                                        label={t(lbl)}
                                                        onChange={(e) => {
                                                            field.onChange(e);
                                                            handleAttendanceTypeChange(e);
                                                        }}
                                                    >
                                                        {attendanceTypes.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                )}
                                            />
                                        ) : lbl === 'dutyStart' || lbl === 'dutyEnd' ? (
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <Controller
                                                    name={lbl}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TimePicker
                                                            {...field}
                                                            value={field.value ? dayjs(field.value) : null}
                                                            slotProps={{
                                                                textField: {
                                                                    size: 'small', fullWidth: true, required: true,
                                                                    label: t(lbl)
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </LocalizationProvider>

                                        ) : (
                                            <Controller
                                                name={lbl}
                                                control={control}
                                                render={({ field }) => (
                                                    <TextField
                                                        {...field}
                                                        size="small"
                                                        label={t(lbl)}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>
                                ))}

                                {attendanceType.map((lbl, index) => (
                                    <Grid item xs={6} key={lbl}>
                                        <Controller
                                            name={lbl}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    size="small"
                                                    label={t(lbl)}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                ))}

                                {attendanceType?.length > 0 ? (
                                    <Grid item
                                        xs={['finger', 'all', 'manual'].includes(getValues('attendanceMethod')) ? 12 : 6}
                                        key={'userType'}>
                                        <Controller
                                            key={'controller-userType'} // Ensure keys are unique
                                            name={'privilege'}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    autoComplete="given-name"
                                                    fullWidth
                                                    size="small"
                                                    select
                                                    label={t('userType')}
                                                    onChange={(e) => {
                                                        field.onChange(e);
                                                        handleAttendanceTypeChange(e);
                                                    }}
                                                >
                                                    {userTypes.map((option) => (
                                                        <MenuItem key={option.value} value={option.value}>
                                                            {option.label}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                ) : null}

                                {getValues('attendanceMethod') !== 'manual' ?
                                    <Grid item xs={12} key={'deviceIPs'}>
                                        <Controller
                                            key={'controller-deviceIPs'}
                                            name={'deviceIPs'}
                                            control={control}
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    select
                                                    multiple
                                                    autoComplete="given-name"
                                                    fullWidth
                                                    required
                                                    size="small"
                                                    label={t('deviceNames')}
                                                    value={Array.isArray(field.value) ? field.value : []}
                                                    onChange={(e) => {
                                                        field.onChange(e.target.value); // Handle multiple selected values
                                                    }}
                                                    SelectProps={{
                                                        multiple: true,
                                                        renderValue: (selected) =>
                                                            Array.isArray(selected)
                                                                ? selected
                                                                    .map((ipWithPort) => {
                                                                        // Split to get IP and port separately
                                                                        const [ip, port] = ipWithPort.split(':');
                                                                        // Find device by matching both IP and port
                                                                        const device = devices?.find(
                                                                            (device) =>
                                                                                device.ip_address === ip && device.port.toString() === port
                                                                        );
                                                                        // Return only the device name or an empty string if not found
                                                                        return device ? device.device_name : '';
                                                                    })
                                                                    .join(', ')
                                                                : '', // Ensure no fallback with IP or port
                                                    }}
                                                >
                                                    {/* Render only the device name in the dropdown list */}
                                                    {devices?.map((option, index) => (
                                                        <MenuItem key={`${option.device_user}-${index}`} value={`${option.ip_address}:${option.port}`}>
                                                            {option.device_name}
                                                        </MenuItem>
                                                    ))}
                                                </TextField>
                                            )}
                                        />
                                    </Grid>
                                    : null}

                            </Grid>

                            {['finger', 'all'].includes(getValues('attendanceMethod')) ?
                                <Button
                                    variant="contained"
                                    startIcon={<Fingerprint />}
                                    color="success"
                                    type='submit'
                                    sx={{
                                        justifyContent: 'left',
                                        mt: 2
                                    }}
                                >
                                    {t('save')}
                                    {loading && (
                                        <CircularProgress
                                            size={30}
                                            sx={{
                                                color: green[500],
                                                position: 'absolute',
                                                top: 3,
                                                left: 6.8,
                                                zIndex: 1,
                                            }}
                                        />
                                    )}
                                </Button>
                                :
                                <LoadingButton
                                    loading={loading || loadingDuty}
                                    type='submit'
                                    variant="contained"
                                    color="success" sx={{ mt: 2 }}>
                                    {t('save')}
                                </LoadingButton>
                            }
                            <Button variant="contained" color="error" sx={{ mt: 2, marginLeft: '10px' }}>
                                {t('clear')}
                            </Button>
                        </Box>
                    </Paper>
                </Grid>
            </Grid>
        </Container>
    );
}

export default DUTYFORM;
