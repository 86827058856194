// React and hooks
import React, { useEffect } from 'react';

// MUI components and icons
import { Box, Grid, Chip } from '@mui/material';
import { t } from 'i18next'
// Custom utilities
import useAuth from '../../hooks/UseAuth';
import { useDispatch } from 'react-redux';
import { getSettings } from '../../../actions/othersActions';
import { allClass } from '../../../actions/eduActions';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { convertToBengaliDigits } from '../../utils/converter';

export const BALANCECHECK = () => {
    const dispatch = useDispatch();
    const { code, smsBalance } = useAuth();

    useEffect(() => {
        dispatch(allClass(code))
        dispatch(getSettings(code))
    }, [dispatch, code]);


    const handleClick = () => {
        window.open('https://web.whatsapp.com', '_blank');
    };

    const balance = ((parseFloat(smsBalance) * 100) / 45).toFixed(2);

    return (
        <Box
            sx={{
                p: 3,
                borderRadius: 2,
                mx: "auto",
                margin: "auto",
                bgcolor: 'background.paper',
                boxShadow: '0px 0px 15px 0.5px #e0ecff',
                maxWidth: '450px'
            }}
            maxWidth={'none'}
        >
            <Grid container spacing={2}>
                {/* Title */}
                <Grid item xs={12} textAlign="center">
                    <Chip
                        sx={{ fontSize: '1.1rem', mb: 2 }}
                        label={t('balanceInquery')}
                    />
                    <Divider />
                </Grid>

                {/* Receiver Selection */}
                <Grid item xs={6}>
                    <Typography>
                        {t('currentBalance')}
                    </Typography>
                    <Typography>
                        {t('messageRate')}
                    </Typography>
                    <Typography>
                        {t('totalMessage')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography textAlign={'right'}>
                        {convertToBengaliDigits(smsBalance)} {t('moneySymbol')}
                    </Typography>
                    <Typography textAlign={'right'}>
                        {convertToBengaliDigits(0.45)} {t('moneySymbol')}
                    </Typography>
                    <Typography textAlign={'right'}>
                        {convertToBengaliDigits(balance)} {t('the')}
                    </Typography>
                </Grid>
                <Grid item xs={12} textAlign={'center'}>
                    <Divider />
                    <Chip onClick={handleClick} size="small" sx={{ mt: 2 }} color="primary" label={t('newRecharge')} />
                </Grid>
            </Grid>
        </Box>
    );
};