// React and related hooks
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    Grid,
    Button,
    ButtonGroup,
    Box,
    Menu,
    MenuItem,
    Dialog,
    Chip
} from '@mui/material';
import {
    Delete,
    AddCircle,
    Edit,
    ChecklistOutlined,
    FormatListBulletedOutlined,
    FileDownloadOutlined
} from '@mui/icons-material';

// MUI Date & Time related components
import dayjs from 'dayjs';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import CustomPagination from '../layout/Pagination';
import useAuth from '../hooks/UseAuth';
import useDataGridColumns from '../utils/useDataGridColumns';
import { useComponent } from '../hooks/ComponentContext';

// Forms
import NEWFURNITUREFORM from './FurnitureForm';
import GIVINGFURNITURE from './GivingFurnitureForm';

// Actions and constants
import {
    clearErrors,
    deleteFurniture,
    getFurnitures
} from '../../actions/adminisActions';
import { furnitureListPdf } from '../../actions/pdfActions';
import { DELETE_FURNITURE_RESET, NEW_FURNITURE_RESET, NEW_GIVEN_FURNITURE_RESET, UPDATE_FURNITURE_RESET } from '../../constants/adminisConstants';
import { getUserTypes } from '../../constants/commonContstants';
import { DataGrid, GridActionsCellItem, gridFilteredSortedRowIdsSelector, GridToolbarContainer, GridToolbarQuickFilter, useGridApiContext } from '@mui/x-data-grid';

function CustomToolbar({ handleOpen, handleGiving }) {
    const { t } = useTranslation();
    const { changeComponent } = useComponent();
    const apiRef = useGridApiContext();
    const { code, type } = useAuth();
    const dispatch = useDispatch();
    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };

    // for csv export using MUI API
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== 'id' && column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('listOfFurniture'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClosePrint = () => {
        setAnchorEl(null);
    };

    // open list window
    const OpenAnotherWindow = () => {
        changeComponent('givenFurnitureWindow')
    };

    const handlePdf = () => {
        dispatch(furnitureListPdf(currentRows, code));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button
                            onClick={OpenAnotherWindow}
                        ><FormatListBulletedOutlined /></Button>
                        <Button
                            disabled={type !== 'principal'}
                            onClick={handleGiving}
                        >
                            <ChecklistOutlined />
                        </Button>
                        <Button disabled={type !== 'principal'} onClick={handleOpen}><AddCircle /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClosePrint}
                        onClick={handleClosePrint}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'} mt={0.3}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('listOfFurniture')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function FURNITURELIST() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const userTypes = getUserTypes(t)
    const { code, type } = useAuth();

    const [selectedRows, setSelectedRows] = useState([]);
    const [open1, setOpen1] = React.useState(false);

    // import these info from reducers those are bound in store.js
    const { error, furnitures, loading } = useSelector(state => state.allFurniture);
    const { success, isUpdated, isDeleted } = useSelector(state => state.newFurniture);
    const { givingSuccess } = useSelector(state => state.newGivenFurniture);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(getFurnitures(code));
        if (success) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_FURNITURE_RESET })
        }

        if (givingSuccess) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            setOpen1(false);
            dispatch({ type: NEW_GIVEN_FURNITURE_RESET })
        }

        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_FURNITURE_RESET })
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_FURNITURE_RESET })
        }

        if (error) {
            dispatch(clearErrors());
        }

    }, [dispatch, error, t, enqueueSnackbar, success, isDeleted, isUpdated, givingSuccess, code])

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('the')}${count !== 1 ? t('these') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const handleEdit = (singleRowData) => {
        if (selectedRows.length === 0 || selectedRows.length === 1) { // for editing single data
            setSelectedRows([singleRowData])
        }
        setOpen(true);
    };

    const handleDelete = (id) => {
        dispatch(deleteFurniture(id, code));
    }

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), disableColumnMenu: false, minWidth: 80, flex: 0 },
        { field: 'id', headerName: t('idNo'), sortable: true, minWidth: 80, flex: 0 },
        { field: 'name', headerName: t('furnitureName') },
        { field: 'totalHas', headerName: t('totalHas') },
        { field: 'totalGiven', headerName: t('given') },
        { field: 'remains', headerName: t('remain') },
        { field: 'createdAt', headerName: t('addingDate') },
        { field: 'responsible', headerName: t('responsible') },

        {
            field: 'actions',
            headerName: t('actions'),
            type: 'actions',
            minWidth: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'principal'}
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    disabled={parseInt(params.row.totalHas) !== parseInt(params.row.remains) || type !== 'principal' ? true : false}
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.id)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform furnitures array into the desired format
    const rows = furnitures?.map((furniture, index) => {
        // Find the matching user type
        const responsible = userTypes.find(type => type.value === furniture.responsible);

        return {
            serial: index + 1,
            id: furniture.id,
            name: furniture.furniture_name,
            totalHas: `${furniture.total_has} ${t('the')}`,
            totalGiven: `${furniture.total_given} ${t('the')}`,
            remains: `${furniture.total_has - furniture.total_given} ${t('the')}`,
            createdAt: `${dayjs(furniture.created_at).format('YYYY-MM-DD')}`,
            responsible: responsible.label
        };
    });


    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setSelectedRows([])
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    // handle selection model change
    const handleSelectionModelChange = (selectionModel) => {
        const selectedIDs = new Set(selectionModel);
        const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
        setSelectedRows(selectedRowData);
    };

    // handle giving form
    const handleGiving = () => {
        if (selectedRows.length > 0) {
            setOpen1(true);
        } else {
            enqueueSnackbar(t('pleaseSelect'), { variant: 'info' });
        }
    };

    const handleClose1 = () => {
        setOpen1(false);
    };

    return (
        <Box className="globalShapeDesign">

            <MetaData title={'FURNITURE LIST'} />
            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
            >
                <NEWFURNITUREFORM closeDialog={handleClose} selectedItem={selectedRows} />
            </Dialog>
            <Dialog
                open={open1}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
            >
                <GIVINGFURNITURE closeDialog={handleClose1} selectedItem={selectedRows} />
            </Dialog>

            <DataGrid
                rows={rows}
                columns={columns}
                loading={loading}
                density={'compact'}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                localeText={localeText}
                onRowSelectionModelChange={(selectionModel) => {
                    handleSelectionModelChange(selectionModel);
                }}
                selectionModel={selectedRows}

                getRowClassName={(params) => (parseInt(params.row.remains) === 0 ? 'highlight-row' : '')}
                isRowSelectable={(params) => parseInt(params.row.remains) !== 0}

                sx={{
                    '& .highlight-row': {
                        backgroundColor: '#FFFFF0'
                    },
                }}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 }, // Default page size
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            id: false
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} handleGiving={handleGiving} />,
                    pagination: CustomPagination,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}

            />
        </Box>
    );
}
