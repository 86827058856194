// React and Hooks
import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// Material UI components
import { Button, CssBaseline, TextField, Grid, Box, Container } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

// MUI Icons
import CloseIcon from '@mui/icons-material/Close';

// Custom Components and Utils
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';

// Redux Actions
import { clearErrors } from '../../actions/accountActions';
import { newBook, updateBook } from '../../actions/libraryActions';

// Custom Hooks
import useAuth from '../hooks/UseAuth';

export default function ADDNEWBOOKFORM({ closeDialog, bookInfo }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const [book, setBook] = useState(bookInfo);
    const formRef = useRef(null);
    const { code } = useAuth();

    // getting the value from redux according to the history info for using by default
    const { error, user } = useSelector(state => state.auth);
    const { loading } = useSelector(state => state.newBook);
    const { books } = useSelector(state => state.allBooks);

    const defaultValues = {
        bookNo: book?.bookNo || '',
        bookName: book?.bookName || '',
        storageLocation: book?.storageLocation || '',
        price: book?.price || '',
        part: book?.part || '',
        publisher: book?.publisher || '',
        subject: book?.subject || '',
        writer: book?.writer || '',
    };
    const { handleSubmit, control, reset } = useForm({ defaultValues });

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        if (error) {
            dispatch(clearErrors());
        }

    }, [dispatch, error, user, t])

    // handle clear
    const handleClear = () => {
        reset();
        setBook('')
    };

    const lbls = ['bookNo', 'part', 'bookName', 'writer', 'subject', 'publisher', 'price', 'storageLocation'];

    // handle submit
    const onSubmit = (data) => {
        if (!book) {
            const duplicate = books.find(b => b.book_no === parseInt(data.bookNo))
            if (duplicate) return enqueueSnackbar(t('duplicateNotPossible'), { variant: 'error' });
            dispatch(newBook(data, code))
        } else {
            dispatch(updateBook(data, code))
        }
    };

    return (
        <Container component="main" maxWidth="sm" sx={{
            borderRadius: '10px'
        }}>
            <MetaData title={'ADD NEW BOOk'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <CloseIcon fontSize='small' />
            </CustomCrossButton>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '1.5rem'
                }}
            >
                <RECEIPTHEADING text={t('addNewBookForm')} department={t('library')} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data'>
                    <Grid container spacing={1.2}
                        sx={{ mt: 1 }}
                    >
                        {lbls.map((lbl, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={['bookName', 'writer'].includes(lbl) ? 12 : 6}
                                key={`grid-${index}`}
                            >
                                <Controller
                                    key={`controller-${lbl}`} // Ensure keys are unique
                                    name={lbl}
                                    control={control} // pass the control object
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            autoComplete="given-name"
                                            fullWidth
                                            type={lbl === 'bookNo' || lbl === 'part' || lbl === 'price' ? 'number' : 'text'}
                                            size="small"
                                            id={`id${index}`}
                                            autoFocus={lbl === 'bookNo' && !book}
                                            label={t(lbl)}
                                            disabled={(book && lbl === 'bookNo') || (book && lbl === 'part')}
                                            required={lbl === 'bookNo' || lbl === 'part'}
                                        />
                                    )}
                                />
                            </Grid>
                        ))}
                    </Grid>
                    <Grid item container xs={12} columnSpacing='4' mb={2} justifyContent={'right'}>
                        {book ?
                            <Grid item>
                                <Button
                                    fullWidth
                                    type="submit"
                                    variant="contained"
                                    color="success"
                                    sx={{ mt: 2 }}
                                >
                                    {t('update')}
                                </Button>
                            </Grid>
                            :
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('save')}</span>
                                </LoadingButton>
                            </Grid>
                        }
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                sx={{ mt: 2 }}
                                onClick={handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}