import {
    NEW_DONOR_REQUEST,
    NEW_DONOR_SUCCESS,
    NEW_DONOR_FAIL,
    NEW_DONOR_RESET,
    ALL_DONORS_REQUEST,
    ALL_DONORS_SUCCESS,
    ALL_DONORS_FAIL,
    UPDATE_DONOR_REQUEST,
    UPDATE_DONOR_FAIL,
    UPDATE_DONOR_SUCCESS,
    DELETE_DONOR_REQUEST,
    DELETE_DONOR_FAIL,
    DELETE_DONOR_SUCCESS,
    UPDATE_DONOR_RESET,
    DELETE_DONOR_RESET,
    NEW_ACCEPT_DONATION_REQUEST,
    NEW_ACCEPT_DONATION_SUCCESS,
    NEW_ACCEPT_DONATION_FAIL,
    ALL_DONATIONS_REQUEST,
    ALL_DONATIONS_SUCCESS,
    ALL_DONATIONS_FAIL,
    NEW_ACCEPT_DONATION_RESET,
    DELETE_DONATION_REQUEST,
    DELETE_DONATION_FAIL,
    DELETE_DONATION_SUCCESS,
    DELETE_DONATION_RESET,
    CLEAR_ERRORS
} from '../constants/donorContstants'

// for add new donor
export const newDonorReducer = (state = { donor: {} }, action) => {
    switch (action.type) {
        case NEW_DONOR_REQUEST:
            return {
                ...state,
                loading: true
            }

        case NEW_DONOR_SUCCESS:
            return {
                loading: false,
                isSaved: true,
                donor: action.payload
            }

        case NEW_DONOR_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_DONOR_RESET:
            return {
                isSaved: false,
                donor: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all donors
export const allDonorsReducer = (state = { donors: [] }, action) => {
    switch (action.type) {
        case ALL_DONORS_REQUEST:
            return {
                loading: true,
                donors: []
            }

        case ALL_DONORS_SUCCESS:
            return {
                loading: false,
                donors: action.payload.donors
            }

        case ALL_DONORS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const donorReducer = (state = {}, action) => {
    switch (action.type) {
        case UPDATE_DONOR_REQUEST:
        case DELETE_DONOR_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case UPDATE_DONOR_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isUpdated: true
            }

        case UPDATE_DONOR_RESET:
            return {
                ...state,
                isUpdated: false
            }

        case UPDATE_DONOR_FAIL:
        case DELETE_DONOR_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_DONOR_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_DONOR_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// for add new provided donor
export const newDonationReducer = (state = { donation: {} }, action) => {
    switch (action.type) {
        case NEW_ACCEPT_DONATION_REQUEST:
            return {
                ...state,
                loadingDonation: true
            }

        case NEW_ACCEPT_DONATION_SUCCESS:
            return {
                loadingDonation: false,
                isSavedDonation: true,
                donation: action.payload
            }

        case NEW_ACCEPT_DONATION_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case NEW_ACCEPT_DONATION_RESET:
            return {
                isSavedDonation: false,
                donation: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state
    }
}

// For all donations
export const allDonationsReducer = (state = { donations: [] }, action) => {
    switch (action.type) {
        case ALL_DONATIONS_REQUEST:
            return {
                loading: true,
                donations: []
            }

        case ALL_DONATIONS_SUCCESS:
            return {
                loading: false,
                donations: action.payload.donations
            }

        case ALL_DONATIONS_FAIL:
            return {
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

export const donationReducer = (state = {}, action) => {
    switch (action.type) {
        case DELETE_DONATION_REQUEST:
            return {
                ...state,
                loadingAction: true
            }

        case DELETE_DONATION_FAIL:
            return {
                ...state,
                loadingAction: false,
                error: action.payload
            }

        case DELETE_DONATION_SUCCESS:
            return {
                ...state,
                loadingAction: false,
                isDeleted: true
            }

        case DELETE_DONATION_RESET:
            return {
                ...state,
                isDeleted: false
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}