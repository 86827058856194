// React and related hooks
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
    GridActionsCellItem,
    gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';
import { Grid, Button, ButtonGroup, Box, Menu, MenuItem, Dialog, Chip } from '@mui/material';
import { Delete as DeleteIcon, AddCircle as AddCircleIcon, Edit as EditIcon, FileDownloadOutlined } from '@mui/icons-material';

// Custom components and styles
import { MetaData } from '../utils/metaData';
import CustomPagination from '../layout/Pagination';
import OFFICIANTSREPORTFORM from './ReportForm';
import useAuth from '../hooks/UseAuth';
import useDataGridColumns from '../utils/useDataGridColumns';

// Actions
import {
    allStaffReports,
    clearErrors,
    deleteStaffReport
} from '../../actions/adminisActions';
import { staffReport, staffReportListPdf } from '../../actions/pdfActions';

// Constants
import { DELETE_STAFF_REPORT_RESET, UPDATE_STAFF_REPORT_RESET } from '../../constants/adminisConstants';

// Utilities
import dayjs from 'dayjs';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [currentRows, setCurrentRows] = React.useState('');
    const { code, type } = useAuth();
    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // Prepare data for PDF
        const filteredRows = gridFilteredSortedRowIdsSelector(apiRef).map((id) => apiRef.current.getRow(id));

        const excludedFields = ['__check__', 'actions'];
        const headerNames = apiRef.current.getAllColumns()
            .filter(column => !excludedFields.includes(column.field))  // Exclude unwanted fields
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('officiantsReportPannel'),
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    // handle pdf making
    const handlePdf = async (e) => {
        dispatch(staffReportListPdf(currentRows, code));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button disabled={type !== 'principal'} onClick={handleOpen}><AddCircleIcon /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('officiantsReportPannel')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function OFFICIANTREPORT() {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();

    const { code, type } = useAuth();

    // import these info from reducers those are bound in store.js
    const { error, staffReports } = useSelector(state => state.allStaffReports);
    const { isUpdated, isDeleted } = useSelector(state => state.staffReport)
    const [selectedId, setSelectedId] = useState('');

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allStaffReports(code));
    }, [dispatch, code])

    useEffect(() => {
        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_STAFF_REPORT_RESET })
            dispatch(allStaffReports(code));
            handleClose()
        }

        if (error) {
            enqueueSnackbar(error, { variant: 'error' });
            dispatch(clearErrors())
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_STAFF_REPORT_RESET })
            dispatch(allStaffReports(code));
        }
    }, [dispatch, error, enqueueSnackbar, t, isUpdated, isDeleted, code]);

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const handleDownloadFile = (staffId) => {
        const findReport = staffReports?.find(report => report.id === staffId);
        dispatch(staffReport(findReport, code))
    };

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('serialNo'), headerClassName: 'CustomHeader', minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'staffId', headerName: t('idNo'), headerClassName: 'CustomHeader', minWidth: 80, sortable: true, flex: 0 },
        { field: 'name', headerName: t('name') },
        { field: 'designation', headerName: t('designation'), },
        { field: 'reportType', headerName: t('reportType'), },
        { field: 'date', headerName: t('date'), },

        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            minWidth: 110,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'principal'}
                    icon={<EditIcon />}
                    label="edit"
                    onClick={() => handleEdit(params.row.data_id)}
                />,

                <GridActionsCellItem
                    disabled={type !== 'principal'}
                    icon={<FileDownloadOutlined />}
                    label='savePdf'
                    onClick={() => handleDownloadFile(params.row.data_id)}
                />,

                <GridActionsCellItem
                    disabled={type !== 'principal'}
                    icon={<DeleteIcon
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.data_id)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform staff reports array into the desired format
    const rows = staffReports?.map((report, index) => {
        return {
            id: index + 1,
            data_id: report.id,
            staffId: report.staff_id,
            name: report.name,
            designation: report.designation,
            reportType: report.report_type,
            date: `${dayjs(report.date).format('YYYY-MM-DD')}`,
        };
    });

    const handleEdit = (id) => {
        setSelectedId(id);
        setOpen(true);
    };

    const handleDelete = (id) => {
        dispatch(deleteStaffReport({ id: id }, code));
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'OFFICIANT REPORTS'} />
            <Dialog
                open={open}
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md"
            >
                <OFFICIANTSREPORTFORM id={selectedId} closeDialog={handleClose} />
            </Dialog>

            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                localeText={localeText}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 }
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false
                        },
                    },
                }}

                slots={{
                    // toolbar: CustomToolbar,
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />,
                    pagination: CustomPagination,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
