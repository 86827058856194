// React and related hooks
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    Button,
    CssBaseline,
    TextField,
    Grid,
    Box,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    InputAdornment
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Close } from '@mui/icons-material';

// Custom components and styles
import { RECEIPTHEADING } from '../layout/MiniComponents';
import { MetaData } from '../utils/metaData';
import { CustomCrossButton } from '../styles/style';
import useAuth from '../hooks/UseAuth';

// Actions and constants
import {
    getFurnitures,
    newFurniture,
    updateFurniture,
    updateMatchedFurniture
} from '../../actions/adminisActions';
import { CLEAR_ERRORS } from '../../constants/adminisConstants';

// Utilities
import { nanoid } from 'nanoid';
import dayjs from 'dayjs';

export default function NEWFURNITUREFORM({ closeDialog, selectedItem }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar(); // for alert messages
    const [catchItems, setCatchItems] = useState(selectedItem);
    const formRef = useRef(null);
    const { code } = useAuth();

    // getting the value from redux according to the history info for using by default
    const { furnitures } = useSelector(state => state.allFurniture);
    const { loading, error } = useSelector(state => state.newFurniture);
    const { user } = useSelector(state => state.auth);

    // Transform selectedItem into defaultValues
    const updatingDefaultValues = selectedItem.reduce((acc, item, index) => {
        acc[`id${index}`] = item.id || '';
        acc[`totalGiven${index}`] = item.totalGiven || 0;
        acc[`furnitureName${index}`] = item.name || '';
        acc[`amount${index}`] = parseInt(item.totalHas) || '';
        return acc;
    }, {});

    const intialDefaultValues = {
        furnitureName0: '',
        furnitureName1: '',
        furnitureName2: '',
        furnitureName3: '',
        furnitureName4: '',
        furnitureName5: '',
        furnitureName6: '',
        furnitureName7: '',
        furnitureName8: '',
        furnitureName9: '',
        amount0: '',
        amount1: '',
        amount2: '',
        amount3: '',
        amount4: '',
        amount5: '',
        amount6: '',
        amount7: '',
        amount8: '',
        amount9: '',
    };

    const defaultValues = catchItems.length === 0 ? intialDefaultValues : updatingDefaultValues;

    const { handleSubmit, control, reset } = useForm({ defaultValues });

    useEffect(() => {
        if (error) {
            enqueueSnackbar(t('notWorked'), { variant: 'error' });
            dispatch({ type: CLEAR_ERRORS })
        }
    }, [error, t, dispatch, enqueueSnackbar])


    // handle clear
    const handleClear = () => {
        reset('');
        setCatchItems('')
    };

    // handle submit
    const onSubmit = (data) => {

        // Determine the maximum index dynamically if needed
        let items = [];
        let updateItems = [];
        if (catchItems.length === 0) { // thtat means this is for inserting
            const maxIndex = 10;
            for (let i = 0; i < maxIndex; i++) {
                const amountKey = `amount${i}`;
                const furnitureNameKey = `furnitureName${i}`;

                // Find duplicate
                const duplicate = furnitures.find(furniture => furniture.furniture_name === data[furnitureNameKey]);

                if (data[amountKey] && data[furnitureNameKey] && !duplicate) {
                    items.push([nanoid(10), data[furnitureNameKey], data[amountKey]]);
                } else if (duplicate) {
                    updateItems.push([data[amountKey], duplicate.id])
                }

            }
            if (items.length > 0) {
                const today = dayjs().format('YYYY-MM-DD');
                dispatch(newFurniture({ data: items, date: today, responsible: user.type }, code));
                handleClear();
            }
        } else {
            // controling update functionality
            const maxIndex = catchItems.length;
            for (let i = 0; i < maxIndex; i++) {
                const idKey = `id${i}`;
                const totalGivenKey = `totalGiven${i}`;
                const amountKey = `amount${i}`;
                const furnitureNameKey = `furnitureName${i}`;

                if (data[amountKey] && data[furnitureNameKey] && parseInt(data[totalGivenKey]) <= data[amountKey]) {
                    items.push([data[furnitureNameKey], data[amountKey], data[idKey]]);
                } else {
                    const minNotPossible = t('minNotPossible', { furniture: data[furnitureNameKey] });
                    enqueueSnackbar(minNotPossible, { variant: 'error' });
                }
            }
            dispatch(updateFurniture({ data: items }, code));
        }

        // update amount of matching furnitures
        if (updateItems.length > 0) {
            dispatch(updateMatchedFurniture({ data: updateItems }, code));
            handleClear();
            enqueueSnackbar(t('matchedFurniture'), { variant: 'success' });
            dispatch(getFurnitures(code));
        };
    };

    return (
        <Container component="main" maxWidth="md" sx={{ borderRadius: '10px' }}>
            <MetaData title={'FURNITURE FORM'} />
            <CustomCrossButton
                onClick={closeDialog}
                disableElevation
                disableRipple
                disableFocusRipple
            >
                <Close fontSize='small' />
            </CustomCrossButton>
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    marginTop: '1.5rem'
                }}
            >
                <RECEIPTHEADING text={t('newItemForm')} department={t('adminis')} />
                <Box component='form' onSubmit={handleSubmit(onSubmit)} ref={formRef} encType='multipart/form-data' width={'100%'} marginTop={2}>
                    <Grid container
                        sx={{
                            overflow: 'auto',
                            position: 'relative',
                            '&::-webkit-scrollbar-track': {
                                marginTop: '10px',
                            },
                        }}
                    >
                        <TableContainer>
                            <Table aria-label="custom table" sx={{ border: '1px solid #ecc' }}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', width: '4%', padding: '5px' }}>
                                            {t('serialNo1')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', width: '18%', padding: '5px' }}>
                                            {t('furnitureName')}
                                        </TableCell>
                                        <TableCell align="center" sx={{ borderRight: '1px solid #ecc', width: '10%', fontSize: '1rem', padding: '5px' }}>
                                            {t('amount')}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>

                                <TableBody>
                                    {Array.from({ length: selectedItem.length !== 0 ? selectedItem.length : 10 }, (_, index) =>
                                        <TableRow key={`item-${index}`}>
                                            {selectedItem.length !== 0 ?
                                                <>
                                                    <TableCell sx={{ display: 'none' }}>
                                                        <TextField
                                                            name={`id${index}`}
                                                            type="number"
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{ display: 'none' }}>
                                                        <TextField
                                                            name={`totalGiven${index}`}
                                                            type="number"
                                                        />
                                                    </TableCell></>
                                                : null}
                                            <TableCell align="center" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '0px' }}>
                                                {index + 1}
                                            </TableCell>
                                            <TableCell align="left" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '5px' }}>
                                                <Controller
                                                    name={`furnitureName${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            InputProps={{
                                                                sx: { '& input': { textAlign: 'left' } },
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>

                                            <TableCell align="left" sx={{ borderRight: '1px solid #ecc', fontSize: '1rem', padding: '5px' }}>
                                                <Controller
                                                    name={`amount${index}`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <TextField
                                                            {...field}
                                                            size="small"
                                                            fullWidth
                                                            type='number'
                                                            // disabled={catchItems && index !== 0 && index !== 1 ? true : false}
                                                            // required={index === 0 || index === 1 ? true : false}
                                                            InputProps={{
                                                                endAdornment: <InputAdornment position="end">{t('the')}</InputAdornment>,
                                                                sx: { '& input': { textAlign: 'center' } },
                                                                disableUnderline: true
                                                            }}
                                                            variant="standard"
                                                        />
                                                    )}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                    <Grid item container columnSpacing='4' marginBottom={2} justifyContent='right'>
                        <Grid item>
                            <Button
                                fullWidth
                                variant="contained"
                                color='error'
                                sx={{ mt: 2 }}
                                onClick={handleClear}
                            >
                                {t('clear')}
                            </Button>
                        </Grid>
                        {catchItems.length !== 0 ?
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    loading={loading ? true : false}
                                    variant="contained"
                                >
                                    <span>{t('update')}</span>
                                </LoadingButton>
                            </Grid>
                            :
                            <Grid item>
                                <LoadingButton
                                    fullWidth
                                    type="submit"
                                    color="success"
                                    sx={{ mt: 2 }}
                                    loading={loading ? true : false}
                                    // disabled={user.user_type !== t('acntskpr')}
                                    variant="contained"
                                >
                                    <span>{t('save')}</span>
                                </LoadingButton>
                            </Grid>
                        }
                    </Grid>
                </Box>
            </Box>
        </Container >
    );
}