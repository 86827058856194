// React and React Router Imports
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// Material-UI Imports
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Dialog } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Button, Typography, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';
import Avatar from '@mui/material/Avatar';

// Constants and Actions Imports
import { getUserTypes } from '../../constants/commonContstants';
import { DELETE_USER_RESET, REGISTER_USER_RESET, UPDATE_USER_RESET } from '../../constants/authConstants';
import { allUsers, deleteUser } from '../../actions/authActions'

// Utility Imports
import { MetaData } from '../utils/metaData'

// Custom Component Imports
import SignUp from './Signup';
import useAuth from '../hooks/UseAuth';

export default function UsersList() {
    const { t } = useTranslation();
    const userTypes = getUserTypes(t)
    const { enqueueSnackbar } = useSnackbar();
    // const { changeComponent } = useComponent();
    const dispatch = useDispatch();
    const { code, email, type } = useAuth();

    const [userId, setUserId] = useState([])

    // import these info from reducers those are bound in store.js
    const { users } = useSelector(state => state.allUsers);
    const { isDeleted, isUpdated, isSaved } = useSelector(state => state.user)

    const isFirstRender = useRef(true);
    useEffect(() => {
        if (isFirstRender.current || isSaved || isUpdated || isDeleted) {
            isFirstRender.current = false;
            setOpen(false)
            dispatch(allUsers({ email: email }, code));
        }
    }, [dispatch, enqueueSnackbar, code, email, isSaved, isUpdated, isDeleted]);

    // if (error) {
    //     enqueueSnackbar(error, { variant: 'error' });
    //     dispatch(clearErrors());
    // }
    useEffect(() => {
        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_USER_RESET });
        }
        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: REGISTER_USER_RESET });
        }
        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_USER_RESET });
        }
    }, [dispatch, isDeleted, t, enqueueSnackbar, isSaved, isUpdated, code, email]);

    // handle edit 
    const handleEdit = (id) => {
        setUserId(id)
        setOpen(true)
        // changeComponent('registration', id);
    }

    const handleDelete = (id) => {
        dispatch(deleteUser({ id: id }, code));
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        setUserId('')
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (

        <Box>
            <Box display={'flex'} alignItems={'center'} justifyContent={'space-between'} mb={1}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {t('registerdUserList')}
                </Typography>
                <Button disabled={type !== 'admin'} startIcon={<AddIcon />} onClick={handleOpen}>
                    {t('newUser')}
                </Button>
            </Box>
            <MetaData title={'USER LIST'} />
            <Box sx={{ width: '100%' }}>
                <TableContainer component={Paper}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">{t('serialNo')}</TableCell>
                                <TableCell align="center">{t('picture')}</TableCell>
                                <TableCell align="center">{t('type')}</TableCell>
                                <TableCell align="center">{t('firstName')}</TableCell>
                                <TableCell align="center">{t('lastName')}</TableCell>
                                <TableCell align="center">{t('email')}</TableCell>
                                <TableCell align="center">{t('justSign')}</TableCell>
                                <TableCell align="center">{t('actions')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {users?.map((user, index) => {
                                // Find the matching user type
                                const userType = userTypes.find(type => type.value === user.type);

                                // If a match is found, replace type with the corresponding label
                                const userTypeLabel = userType ? userType.label : user.type;

                                return (
                                    <TableRow key={user.id}>
                                        <TableCell align="center" sx={{ paddingTop: 0, paddingBottom: 0 }}>{index + 1}</TableCell>
                                        <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                            <Avatar sx={{ display: 'flex', margin: 'auto' }} alt="User avatar" src={user.avatar_url} />
                                        </TableCell>
                                        <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>{userTypeLabel}</TableCell>
                                        <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>{user.first_name}</TableCell>
                                        <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>{user.last_name}</TableCell>
                                        <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }}>{user.email}</TableCell>
                                        <TableCell sx={{ paddingTop: 0, paddingBottom: 0 }} align="center">
                                            <img
                                                src={user.sign_url}
                                                alt="❌"
                                                style={{ width: '60px', height: '30px', marginTop: '2.5px' }}
                                            />
                                        </TableCell>

                                        <TableCell align="center" sx={{ padding: '10px' }}>
                                            <IconButton disabled={user.type !== type} size='small' onClick={() => handleEdit(user.id)}>
                                                <EditIcon fontSize='small' />
                                            </IconButton>
                                            <IconButton disabled={type !== 'admin'} size='small' onClick={() => handleDelete(user.id)} sx={{ color: 'red', marginLeft: 1 }}>
                                                <DeleteIcon fontSize='small' />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}

                        </TableBody>
                    </Table>
                </TableContainer>

                <Dialog maxWidth="md" open={open} onClose={handleClose}>
                    <SignUp closeDialog={handleClose} id={userId} />
                </Dialog>
            </Box>
        </Box>
    );
}
