export const NEW_STUDENT_REPORT_REQUEST = 'NEW_STUDENT_REPORT_REQUEST'
export const NEW_STUDENT_REPORT_SUCCESS = 'NEW_STUDENT_REPORT_SUCCESS'
export const NEW_STUDENT_REPORT_RESET = 'NEW_STUDENT_REPORT_RESET'
export const NEW_STUDENT_REPORT_FAIL = 'NEW_STUDENT_REPORT_FAIL'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const ALL_STUDENT_REPORT_REQUEST = 'ALL_STUDENT_REPORT_REQUEST'
export const ALL_STUDENT_REPORT_SUCCESS = 'ALL_STUDENT_REPORT_SUCCESS'
export const ALL_STUDENT_REPORT_FAIL = 'ALL_STUDENT_REPORT_FAIL'
export const ALL_STUDENT_REPORT_RESET = 'ALL_STUDENT_REPORT_RESET'

export const DELETE_STUDENT_REPORT_REQUEST = 'DELETE_STUDENT_REPORT_REQUEST'
export const DELETE_STUDENT_REPORT_SUCCESS = 'DELETE_STUDENT_REPORT_SUCCESS'
export const DELETE_STUDENT_REPORT_FAIL = 'DELETE_STUDENT_REPORT_FAIL'
export const DELETE_STUDENT_REPORT_RESET = 'DELETE_STUDENT_REPORT_RESET'

export const UPDATE_STUDENT_REPORT_REQUEST = 'UPDATE_STUDENT_REPORT_REQUEST'
export const UPDATE_STUDENT_REPORT_SUCCESS = 'UPDATE_STUDENT_REPORT_SUCCESS'
export const UPDATE_STUDENT_REPORT_FAIL = 'UPDATE_STUDENT_REPORT_FAIL'
export const UPDATE_STUDENT_REPORT_RESET = 'UPDATE_STUDENT_REPORT_RESET'