import React from "react";
import { Box, Typography, Button, Grid } from '@mui/material';
import { keyframes } from '@emotion/react';
import WindowIcon from '@mui/icons-material/Window';

const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
`;

const HeroSection = () => {
    return (
        <Box
            sx={{
                position: "relative",
                overflow: "hidden",
                // minHeight: "85vh",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
                background: "linear-gradient(to bottom, #6a11cb, #2575fc)",
            }}
        >
            {/* Subtle Animated Waves */}
            <Box
                sx={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    width: "100%",
                    height: "30%",
                }}
            >
                <Box
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        width: "100%",
                        height: "30%",
                    }}
                >
                    <Box>
                        <svg
                            className="waves"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 24 150 28"
                            preserveAspectRatio="none"
                            shapeRendering="auto"
                        >
                            <defs>
                                <path
                                    id="gentle-wave"
                                    d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z"
                                />
                            </defs>
                            <g className="parallax">
                                <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7)" />
                                <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                                <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                                <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
                            </g>
                        </svg>
                    </Box>
                </Box>
            </Box>

            <Box sx={{ zIndex: 1 }}>
                <Box padding={0.5}>
                    <Typography variant="h3" mt={3} color={'white'}>
                        Online Academy Management
                    </Typography>

                    <Typography variant="h3" color={'white'}>
                        Software
                    </Typography>
                    <Typography variant="body" color={'white'}>
                        Now you can manage your school, college, or any educational center with TANZIM ACADEMY
                    </Typography>
                    <Box mt={1}>
                        <a href="https://drive.google.com/file/d/1LAk7tRomUk2IcrlfaWGJzrYtmB8XlWbE/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                            <Button size="large" variant="contained" color="inherit" startIcon={<WindowIcon />}>
                                Download
                            </Button>
                        </a>
                    </Box>
                </Box>
                <Grid container spacing={2} justifyContent="center" alignItems="center" sx={{ padding: 4, position: 'relative' }}>
                    {/* Laptop Mockup */}
                    <Grid item xs={12} sm={6} sx={{ position: 'relative', zIndex: 1, animation: `${bounce} 4s infinite` }}>
                        <Box
                            sx={{
                                // animation: `${bounce} 4s infinite`,
                                borderRadius: 2,
                                overflow: 'hidden',
                                width: '100%',
                                margin: '0 auto',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                            }}
                        >
                            <img
                                src="/images/laptopmockup.png"
                                alt="Laptop Mockup"
                                style={{
                                    width: '80%',
                                    height: '70%',
                                    objectFit: 'contain', // Ensures the image scales proportionally
                                }}
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default HeroSection;
