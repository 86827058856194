import axios from 'axios';
import {
    CLEAR_ERRORS,
    NEW_DONOR_REQUEST,
    NEW_DONOR_SUCCESS,
    NEW_DONOR_FAIL,
    ALL_DONORS_REQUEST,
    ALL_DONORS_SUCCESS,
    ALL_DONORS_FAIL,
    UPDATE_DONOR_REQUEST,
    UPDATE_DONOR_FAIL,
    UPDATE_DONOR_SUCCESS,
    DELETE_DONOR_REQUEST,
    DELETE_DONOR_FAIL,
    NEW_ACCEPT_DONATION_REQUEST,
    NEW_ACCEPT_DONATION_SUCCESS,
    NEW_ACCEPT_DONATION_FAIL,
    ALL_DONATIONS_REQUEST,
    ALL_DONATIONS_SUCCESS,
    ALL_DONATIONS_FAIL,
    DELETE_DONATION_REQUEST,
    DELETE_DONATION_SUCCESS,
    DELETE_DONATION_FAIL,
    DELETE_DONOR_SUCCESS
} from '../constants/donorContstants'


// add new donor 
export const newDonor = (donorData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_DONOR_REQUEST })

        // passing donorData to backend by this route 
        const { data } = await axios.post(`/api/donor/new_member/${code}`, donorData)

        // store data in payload 
        dispatch({
            type: NEW_DONOR_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_DONOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all donors
export const allDonors = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_DONORS_REQUEST })

        // connect with backend to get all donors
        const { data } = await axios.get(`/api/donor/members/${code}`)

        // storing all members in payload
        dispatch({
            type: ALL_DONORS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_DONORS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update donor data
export const updateDonor = (donorData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_DONOR_REQUEST })

        // connect with backend for donor report update
        const { data } = await axios.put(`/api/donor/update_member/${code}`, donorData)

        // showing success status if the donor is updated
        dispatch({
            type: UPDATE_DONOR_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_DONOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete donor
export const deleteDonor = (id, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_DONOR_REQUEST
        })

        // Sending a delete request to the backend to delete the class
        const { data } = await axios.delete(`/api/donor/member/delete/${code}/${id}`)

        // showing success status if the member is deleted
        dispatch({
            type: DELETE_DONOR_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_DONOR_FAIL,
            payload: error.response.data.message
        })
    }
}

// new donation
export const newDonation = (donationData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_ACCEPT_DONATION_REQUEST })

        // passing bookData to backend by this route 
        const { data } = await axios.post(`/api/donor/donation/new/${code}`, donationData)

        // store data in payload 
        dispatch({
            type: NEW_ACCEPT_DONATION_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_ACCEPT_DONATION_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all donations
export const allDonations = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_DONATIONS_REQUEST })

        // connect with backend to get all donations
        const { data } = await axios.get(`/api/donor/donations/${code}`)

        // storing all provided donations in payload
        dispatch({
            type: ALL_DONATIONS_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_DONATIONS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Delete donation
export const deleteDonation = (id, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_DONATION_REQUEST
        })

        // Sending a delete request to the backend to delete the donation
        const { data } = await axios.delete(`/api/donor/donation/delete/${code}/${id}`)

        // showing success status if the member is deleted
        dispatch({
            type: DELETE_DONATION_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_DONATION_FAIL,
            payload: error.response.data.message
        })
    }
}

// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}