// React and related hooks
import React, { useEffect } from 'react';

// Redux hooks and actions
import { useDispatch, useSelector } from 'react-redux';
import { allReceipt, deleteReceipt } from '../../actions/accountActions';
import { allUsers } from '../../actions/authActions';
import { receiptListPdf, receiptsPdf } from '../../actions/pdfActions';

// MUI components and icons
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
    GridActionsCellItem,
    gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';
import {
    Grid,
    Button,
    ButtonGroup,
    Chip,
    Box,
    Dialog,
    Menu,
    MenuItem
} from '@mui/material';
import {
    Delete,
    AddCircle,
    Edit,
    CheckCircle,
    Cancel,
    FileDownloadOutlined
} from '@mui/icons-material';

// Third-party libraries
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';
import dayjs from 'dayjs';

// Custom components and hooks
import RECEIPTFORM from './ReciptForm';
import CustomPagination from '../layout/Pagination';
import useAuth from '../hooks/UseAuth';
import useDataGridColumns from '../utils/useDataGridColumns';

// Utilities and constants
import { MetaData } from '../utils/metaData';
import {
    UPDATE_RECEIPT_RESET,
    DELETE_RECEIPT_RESET,
    NEW_RECEIPT_RESET
} from '../../constants/accountConstants';
import { GETCODE } from '../layout/MiniComponents';

function CustomToolbar({ handleOpen, departType, handleReceiptPdf, typeOfDepartment }) {
    const { t } = useTranslation();
    const { type } = useAuth();
    const apiRef = useGridApiContext();
    const dispatch = useDispatch();

    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // Prepare data for PDF
        const filteredRows = gridFilteredSortedRowIdsSelector(apiRef).map((id) => apiRef.current.getRow(id));

        // Get the current visibility model
        const excludedFields = ['__check__', 'actions', 'receiverSign'];
        const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel;
        const headerNames = apiRef.current.getAllColumns()
            .filter(column => !excludedFields.includes(column.field) && columnVisibilityModel[column.field] !== false)  // Exclude unwanted fields and hidden columns
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            department: typeOfDepartment,
            heading: departType,
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // for csv export using MUI API
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    const handlePdf = () => {
        dispatch(receiptListPdf(currentRows));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button disabled={type !== 'accountant' && type !== 'boardingSuper'} aria-label="Add icon" onClick={handleOpen}><AddCircle /></Button>
                        <Button
                            aria-label="Print icon"
                            onClick={handleDownloadFile}
                        >
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handleReceiptPdf}>{t('recieptDownload')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={departType} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default function RECEIPTLIST({ departmentType }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { code, email } = useAuth();

    // for storing basic data according to the departmentType
    let type;
    let receiptDepartment;
    if (departmentType === 'boarding') {
        type = t('receiptListBoarding');
        receiptDepartment = 'boarding';
    } else if (departmentType === 'caravan') {
        type = t('receiptListCaravan');
        receiptDepartment = 'caravan';
    } else {
        type = t('receiptListAccounts');
        receiptDepartment = 'accounts';
    }

    // fetch data from redux store
    const { receipts, loading } = useSelector(state => state.allReceipt);
    const { isUpdated, isDeleted } = useSelector(state => state.receipt);
    const { isSaved } = useSelector(state => state.newReceipt);
    const { users } = useSelector(state => state.allUsers);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        // dispatch(approvedBooks({ department: receiptDepartment }, code));
        dispatch(allReceipt({ department: receiptDepartment }, code));
        dispatch(allUsers({ email: email }, code));

        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_RECEIPT_RESET })
        }

        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_RECEIPT_RESET });
            setOpen(false);
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_RECEIPT_RESET })
        }
    }, [dispatch, t, isUpdated, isDeleted, enqueueSnackbar, receiptDepartment, departmentType, code, isSaved, email])

    // handle edit 
    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);
    const [selectedInfo, setSelectedInfo] = React.useState(null);
    const [rowData, setRowData] = React.useState(null);

    const handleOpen = () => {
        setOpen(true);
        setSelectedInfo(null)
    };

    const handleEdit = (rowData) => {
        if (rowData.countCondition === 'saved') { // prevent form editing if the monthly count is saved of this receipt
            enqueueSnackbar(t('sorryMonthlyCountDone'), { variant: 'error' });
        } else {
            setSelectedInfo({
                bookNo: rowData.bookNo,
                receiptTill: rowData.receiptTill
            });
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    // for handling principal sign
    const [password, setPassword] = React.useState('');
    const [openGetCode, setGetCodeOpen] = React.useState(false);
    const handleOpenGetCode = (data) => {
        setRowData(data)
        setGetCodeOpen(true);
    };

    const handlePrincipalSign = async (e) => {
        e.preventDefault();
        const userType = users.find(user => user.password === password);
        if (userType && userType.type === 'principal') {
            setGetCodeOpen(false);
            if (rowData.countCondition === 'saved') {
                enqueueSnackbar(t('sorryMonthlyCountDone'), { variant: 'error' });
            } else {
                const data = {
                    bookNo: rowData.bookNo,
                    receiptTill: rowData.receiptTill
                }
                dispatch(deleteReceipt(data, code));
            }
        } else {
            enqueueSnackbar(t('authorRequired'), { variant: 'error' });
        }
    };

    const handleCloseGetCode = () => {
        setGetCodeOpen(false);
    };

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('serialNo'), minWidth: 80, width: 80, disableColumnMenu: false, flex: 0 },
        { field: 'donorName', headerName: t('donorName') },
        { field: 'address', headerName: t('address') },
        { field: 'bookNo', headerName: t('bookNo'), minWidth: 80, width: 80, sortable: true, flex: 0 },
        { field: 'receiptFrom', headerName: t('receiptFrom') },
        { field: 'receiptTill', headerName: t('receiptTill') },
        { field: 'amount', headerName: t('amount') },
        { field: 'inWords', headerName: t('inWords') },
        { field: 'fund', headerName: t('fund') },
        { field: 'regarding', headerName: t('regarding') },
        { field: 'date', headerName: t('date') },

        {
            field: 'receiverSign',
            headerName: t('receiverSign'),

            minWidth: 110,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="User"
                    style={{ width: '60px', height: '30px', marginTop: '2.5px' }}
                />
            ),
        },

        {
            field: 'principalSign',
            headerName: t('principalSign'),

            minWidth: 110,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="❌"
                    style={{ width: '60px', height: '30px', marginTop: '2.5px' }}
                />
            ),
        },

        { field: 'type', headerName: t('type') },
        { field: 'department', headerName: t('department') },
        {
            field: 'countCondition',
            headerName: t('monthlyCount'),

            minWidth: 150,
            renderCell: (params) =>
                params.value === 'unsaved' ?
                    <GridActionsCellItem
                        icon={<Cancel />}
                        color="error"
                        label="not saved"
                    /> :
                    <GridActionsCellItem
                        icon={<CheckCircle />}
                        label="saved"
                        color="success"
                    />
        },
        {
            field: 'actions',
            headerName: t('actions'),

            type: 'actions',
            minWidth: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleOpenGetCode(params.row)}
                    showInMenu
                />,
            ],
        },
    ]);

    // Transform receipts array into the desired format
    const rows = receipts?.map((receipt, index) => {
        return {
            id: index + 1,
            donorName: receipt.name,
            address: receipt.address,
            bookNo: receipt.book_no,
            receiptFrom: receipt.receipt_no_from,
            receiptTill: receipt.receipt_no_till,
            amount: receipt.amount,
            inWords: receipt.in_words,
            fund: t(receipt.fund),
            regarding: receipt.regarding,
            date: dayjs(receipt.date).format('YYYY-MM-DD'),
            receiverSign: receipt.receiver_sign,
            principalSign: receipt.principal_sign,
            type: t(receipt.type),
            department: t(receipt.department),
            countCondition: receipt.condition
        };
    });

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlaycibel: t('sorryNotFound'),
    };

    const [selectionModel, setSelectionModel] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
        // Find the selected row data
        const pdfRows = rows.filter(row => newSelectionModel.includes(row.id));
        setSelectedRows(pdfRows)
    };

    const handleReceiptPdf = () => {
        const data = {
            department: departmentType,
            selectedRows: selectedRows
        }
        dispatch(receiptsPdf(data))
    };

    // Define the getRowId function to use 'serialNo' as the unique id for each row
    return (
        <Box className="globalShapeDesign">
            <MetaData title={'RECEIPT LIST'} />
            <Dialog
                open={open}
                aria-describedby="alert-dialog-slide-description"
            >
                <RECEIPTFORM history={selectedInfo} text={t('receipt')} receiptDepartment={receiptDepartment} closeDialog={handleClose} />
            </Dialog>

            <GETCODE
                headingText={t('principalCode')}
                handleOpen={openGetCode}
                handleClose={handleCloseGetCode}
                handleSign={handlePrincipalSign}
                password={password}
                setPassword={setPassword}
            />

            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'} // for rowHeight
                loading={loading ? true : false}
                // rowHeight={35}
                pageSizeOptions={[5, 10, 20, 50, 100]} // Custom rows per page options
                checkboxSelection
                scrollbarSize={0} // it's remove the empty space of toolbar
                localeText={localeText} // Add custom locale text
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                // onRowClick={handleRowClick} // Add the onRowClick event handler

                selectionModel={selectionModel}
                onRowSelectionModelChange={handleSelectionModelChange}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 }, // Default page size
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            inWords: false,
                            receiverSign: false,
                            principalSign: false,
                            type: false,
                            address: false,
                            countCondition: false
                        },
                    },
                }}

                slots={{
                    // toolbar: CustomToolbar,
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} departType={type} handleReceiptPdf={handleReceiptPdf} typeOfDepartment={departmentType} />,
                    pagination: CustomPagination
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
