import {
    LOGIN_REQUEST,
    LOGIN_SUCCESS,
    // LOGIN_RESET,
    LOGIN_FAIL,
    REGISTER_USER_REQUEST,
    REGISTER_USER_SUCCESS,
    REGISTER_USER_RESET,
    REGISTER_USER_FAIL,
    LOAD_USER_REQUEST,
    LOAD_USER_SUCCESS,
    LOAD_USER_FAIL,
    LOGOUT_SUCCESS,
    LOGOUT_FAIL,
    // UPDATE_PROFILE_REQUEST,
    // UPDATE_PROFILE_SUCCESS,
    // UPDATE_PROFILE_RESET,
    // UPDATE_PROFILE_FAIL,
    // UPDATE_PASSWORD_REQUEST,
    // UPDATE_PASSWORD_SUCCESS,
    // UPDATE_PASSWORD_RESET,
    // UPDATE_PASSWORD_FAIL,
    // FORGOT_PASSWORD_REQUEST,
    // FORGOT_PASSWORD_SUCCESS,
    // FORGOT_PASSWORD_FAIL,
    // NEW_PASSWORD_REQUEST,
    // NEW_PASSWORD_SUCCESS,
    // NEW_PASSWORD_FAIL,
    ALL_USERS_REQUEST,
    ALL_USERS_SUCCESS,
    ALL_USERS_FAIL,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_RESET,
    UPDATE_USER_FAIL,
    // USER_DETAILS_REQUEST,
    // USER_DETAILS_SUCCESS,
    // USER_DETAILS_FAIL,
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAIL,
    DELETE_USER_RESET,

    CLEAR_ERRORS
} from '../constants/authConstants'

// For Register user and Login and Load logged in user
export const authReducer = (state = {}, action) => {
    switch (action.type) {
        case LOGIN_REQUEST: // for login
        case LOAD_USER_REQUEST: // for loading logged in user
            return {
                loading: true
            }

        case LOGIN_SUCCESS:
        case LOAD_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isAuthenticated: true,
                user: action.payload.user,
                devices: action.payload.devices
            }

        case LOGOUT_SUCCESS:
            return {
                loading: false,
                isAuthenticated: false,
                user: null,
                devices: null
            }

        case LOGIN_FAIL:
            return {
                ...state,
                loading: false,
                isAuthenticated: false,
                user: null,
                devices: null,
                error: action.payload
            }

        case LOAD_USER_FAIL:
            return {
                loading: false,
                isAuthenticated: false,
                user: null,
                devices: null,
                error: action.payload
            }

        case LOGOUT_FAIL:
            return {
                ...state,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                loading: false,
                error: null
            }

        default:
            return state
    }
}


export const userReducer = (state = {}, action) => {
    switch (action.type) {
        // case UPDATE_PROFILE_REQUEST:
        // case UPDATE_PASSWORD_REQUEST:
        case REGISTER_USER_REQUEST:
        case UPDATE_USER_REQUEST:
        case DELETE_USER_REQUEST:
            return {
                ...state,
                loading: true
            }

        case REGISTER_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isSaved: true
            }

        case REGISTER_USER_RESET:
            return {
                isSaved: false
            }

        // case UPDATE_PROFILE_SUCCESS:
        // case UPDATE_PASSWORD_SUCCESS:
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isUpdated: true
            }

        case DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                isDeleted: true
            }

        case DELETE_USER_RESET:
            return {
                ...state,
                isDeleted: false
            }

        // case UPDATE_PROFILE_RESET:
        // case UPDATE_PASSWORD_RESET:
        case UPDATE_USER_RESET:
            return {
                ...state,
                isUpdated: false
            }

        // case UPDATE_PROFILE_FAIL:
        // case UPDATE_PASSWORD_FAIL:
        case REGISTER_USER_FAIL:
        case UPDATE_USER_FAIL:
        case DELETE_USER_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}

// export const userDetailsReducer = (state = { user: {} }, action) => {
//     switch (action.type) {

//         case USER_DETAILS_REQUEST:
//             return {
//                 ...state,
//                 loading: true,
//             }

//         case USER_DETAILS_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 user: action.payload
//             }

//         case USER_DETAILS_FAIL:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload
//             }

//         case CLEAR_ERRORS:
//             return {
//                 ...state,
//                 error: null
//             }

//         default:
//             return state;
//     }
// }

// export const forgotPasswordReducer = (state = {}, action) => {
//     switch (action.type) {

//         case FORGOT_PASSWORD_REQUEST:
//         case NEW_PASSWORD_REQUEST:
//             return {
//                 ...state,
//                 loading: true,
//                 error: null
//             }

//         case FORGOT_PASSWORD_SUCCESS:
//             return {
//                 ...state,
//                 loading: false,
//                 message: action.payload
//             }

//         case NEW_PASSWORD_SUCCESS:
//             return {
//                 ...state,
//                 success: action.payload
//             }

//         case FORGOT_PASSWORD_FAIL:
//         case NEW_PASSWORD_FAIL:
//             return {
//                 ...state,
//                 loading: false,
//                 error: action.payload
//             }

//         case CLEAR_ERRORS:
//             return {
//                 ...state,
//                 error: null
//             }

//         default:
//             return state;
//     }
// }

export const allUsersReducer = (state = { users: [] }, action) => {
    switch (action.type) {
        case ALL_USERS_REQUEST:
            return {
                ...state,
                loading: true,
            }

        case ALL_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload
            }

        case ALL_USERS_FAIL:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case CLEAR_ERRORS:
            return {
                ...state,
                error: null
            }

        default:
            return state;
    }
}