import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ThemeProvider } from '@emotion/react';
import './App.css';
import { ComponentProvider } from './components/hooks/ComponentContext';
import { SnackbarProvider } from 'notistack'; // for showing alert messages
import LOGIN from './components/auth/Login';
import store from './store';
import { loadUser } from './actions/authActions';
import CssBaseline from '@mui/material/CssBaseline';
import REGISTERNEWACADEMY from './components/superAdmin/AcademyForm';
import SETTINGS from './components/settings/Settings';
import { theme as themeConfig } from './components/styles/theme';
import ProtectedRoute from './components/hooks/ProtectedRoutes';
import rtlPlugin from 'stylis-plugin-rtl'; // Import RTL plugin
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { prefixer } from 'stylis';
import ACADEMICWEBSITE from './components/website/Website';
import ONLINEADMISSIONFORM from './components/website/OnlineAdmitForm';
import MAIN from './components/Main';
import LANDINGPAGE from './components/Landing';
import SUPERADMINLOGIN from './components/superAdmin/SuperAdminLogin';
import TermsAndConditions from './components/superAdmin/TemsAndConditions';

// Create a custom cache for RTL AND LTR
const ltrCache = createCache({
  key: 'mui',
});

export const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin],
});

function App() {
  useEffect(() => {
    store.dispatch(loadUser())
  }, []);

  // Step 1: Get initial settings from localStorage or default values
  const { language, themeMode } = JSON.parse(localStorage.getItem('basics')) || {};
  const [mode, setMode] = useState(themeMode || 'light');

  // Toggle the mode and update state
  const handleToggleMode = () => {
    const newMode = mode === 'light' ? 'dark' : 'light';
    setMode(newMode);

    // Update the basics object with new mode and save to localStorage
    const basics = JSON.parse(localStorage.getItem('basics')) || {};
    basics.themeMode = newMode;
    localStorage.setItem('basics', JSON.stringify(basics));
  };

  // Step 3: Apply the theme based on the current mode
  const appliedTheme = themeConfig(mode);

  return (
    <Router future={{ v7_startTransition: true, v7_relativeSplatPath: true }}>
      <CacheProvider value={language === 'ar' ? cacheRtl : ltrCache}>
        <ThemeProvider theme={appliedTheme}>
          <CssBaseline enableColorScheme />
          <SnackbarProvider maxSnack={3} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
            <ComponentProvider>
              <Routes>
                <Route
                  path="/:code"
                  element={
                    <ProtectedRoute>
                      <MAIN handleToggleMode={handleToggleMode} />
                    </ProtectedRoute>
                  }
                />
                <Route path='/' element={<LANDINGPAGE />} />
                <Route path='/login' element={<LOGIN />} />
                <Route path='/new_academy' element={<REGISTERNEWACADEMY />} />
                <Route path='/terms' element={<TermsAndConditions />} />
                <Route path='/super_admin' element={<SUPERADMINLOGIN />} />
                <Route path={`/:code/settings`} element={<SETTINGS />} />
                <Route path={`/:code/wb/:lang`} element={<ACADEMICWEBSITE handleToggleMode={handleToggleMode} />} />
                <Route path={`/:code/online_admission`} element={<ONLINEADMISSIONFORM />} />
              </Routes>
            </ComponentProvider>
          </SnackbarProvider>
        </ThemeProvider>
      </CacheProvider>
    </Router>
  );
}

export default App;

