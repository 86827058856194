import React from 'react';
import { AppBar, Drawer, List, ListItem, ListItemButton, ListItemText, Toolbar, Divider, Grid, Box, Container, MenuItem, IconButton, Button, Card, CardContent, Typography, Paper, Menu, Chip, Avatar, Dialog } from '@mui/material';
import { Menu as MenuIcon, LightModeOutlined as LightModeOutlinedIcon, Language } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { getSettings, getSingleAcademy } from '../../actions/othersActions';
import { allStaff } from '../../actions/adminisActions';
import { getResult } from '../../actions/eduActions';
import { changeLanguage } from '../hooks/i18n';

import ONLINEADMISSIONFORM from './OnlineAdmitForm';
import TopPadHeading from './TopPadHeading';
import { MetaData } from '../utils/metaData';

const drawerWidth = 240;

export default function ACADEMICWEBSITE({ handleToggleMode }) {
    // const { window } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { code, lang } = useParams();
    const isFirstRender = React.useRef(true);
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [openAdmission, setOpenAdmission] = React.useState(false);

    const { academy } = useSelector(state => state.singleAcademy);
    const { staff } = useSelector(state => state.allStaff);
    const { allSettings } = useSelector(state => state.settings);
    const academicInfo = academy[0];

    React.useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            changeLanguage(lang, academicInfo?.academy_type);
            dispatch(getSingleAcademy(code))
            dispatch(allStaff(code))
            dispatch(getResult(code))
            dispatch(getSettings(code))
        }
    }, [dispatch, code, lang, academicInfo?.academy_type]);

    const handleDrawerToggle = () => {
        setMobileOpen((prevState) => !prevState);
    };

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const changeLang = (lng) => {
        changeLanguage(lng);
        setAnchorEl(null);
    };

    // for open the new admission dialouge
    const handleCloseAdmissionFrom = () => {
        setOpenAdmission(false);
    };

    // for focusing section
    const scrollToSection = (sectionId) => {
        const element = document.getElementById(sectionId);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    // handle menus
    const handleClickMenu = (key) => {
        if (key === 'online_admission') {
            setOpenAdmission(true);
        } else if (key === 'officiants') {
            scrollToSection('officiants')
        }
    };

    const navItems = ['officiants', 'online_admission'];
    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Typography variant="h6" sx={{ my: 2 }}>
                {'TANZIMACADEMY'}
            </Typography>
            <Divider />
            <List>
                {navItems.map((item) => (
                    <ListItem key={item} disablePadding>
                        <ListItemButton onClick={() => handleClickMenu(`${item}`)} sx={{ textAlign: 'left' }}>
                            <ListItemText primary={t(item)} />
                        </ListItemButton>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <Box>
            <MetaData title={'WEBSITE'} />
            {allSettings.online_admission ?
                <Dialog
                    open={openAdmission}
                    aria-describedby="alert-dialog-slide-description"
                    maxWidth="md"
                >
                    <ONLINEADMISSIONFORM closeDialog={handleCloseAdmissionFrom} academicInfo={academicInfo} />
                </Dialog>
                : null}

            <Box sx={{ alignItems: 'center' }}>
                <TopPadHeading academicInfo={academicInfo} />
            </Box>
            <Box>
                <AppBar
                    // elevation={2}
                    position="sticky"
                    color='success'
                >
                    <Toolbar>
                        <Grid container>
                            <Grid item xs={3} display={'flex'} textAlign={'left'} alignItems={'center'}>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}
                                    sx={{ mr: 1, display: { sm: 'none' } }}
                                >
                                    <MenuIcon />
                                </IconButton>

                                <Box sx={{ display: 'flex', alignItems: 'baseline' }}>
                                    <Typography>
                                        TANZIM
                                    </Typography>
                                    <Typography variant='caption' marginLeft={'0.5px'}>
                                        ACADEMY
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={6} textAlign={'center'}>
                                <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
                                    {navItems.map((item) => (
                                        <Button onClick={() => handleClickMenu(`${item}`)} key={item} sx={{ color: '#fff', fontSize: '1rem' }}>
                                            {t(item)}
                                        </Button>
                                    ))}
                                </Box>
                            </Grid>
                            <Grid item xs={3} textAlign={'right'}>
                                <IconButton color="inherit" onClick={handleToggleMode} aria-label="show new mails">
                                    <LightModeOutlinedIcon />
                                </IconButton>

                                <IconButton color="inherit" aria-label="add an alarm"
                                    onClick={handleClick}
                                >
                                    <Language />
                                </IconButton>
                                <Menu
                                    id="basic-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'basic-button',
                                    }}
                                >
                                    <MenuItem onClick={() => changeLang('en')}>ENGLISH</MenuItem>
                                    <MenuItem onClick={() => changeLang('bn')}>BENGALI</MenuItem>
                                    <MenuItem onClick={() => changeLang('ar')}>ARABIC</MenuItem>
                                </Menu>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box>
                        <Drawer
                            variant="temporary"
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            sx={{
                                display: { xs: 'block', sm: 'none' },
                                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Box>
                    <Box component={'main'} width={'100%'}>
                        <Box
                            sx={{
                                position: "relative",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                height: "65vh",
                                width: '100%',
                                backgroundColor: "#f5f5f5",
                                overflow: "hidden",
                            }}
                        >
                            {/* Blurred Image */}
                            {/* <Box
                                component="img"
                                src={academicInfo?.academy_photo_url} // Replace with your image URL
                                alt="Academic"
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    filter: "blur(8px)",
                                    zIndex: -1,
                                }}
                            /> */}

                            <Box
                                sx={{
                                    position: "absolute",
                                    top: 0,
                                    left: 0,
                                    width: "100%",
                                    height: "100%",
                                    backgroundImage: `url(${academicInfo?.academy_photo_url})`,
                                    backgroundSize: "cover",
                                    backgroundPosition: "center",
                                    zIndex: 1,
                                }}
                            />

                            {/* Text Overlay */}
                            <Box
                                sx={{
                                    textAlign: "center",
                                    color: "#fff",
                                    // backgroundColor: "rgba(188, 228, 127, 0.5)",
                                    padding: 0.5,
                                    borderRadius: 4,
                                    zIndex: 2,
                                    width: { xs: '100%', md: "60%" },
                                    display: allSettings?.notice ? 'flex' : 'none'
                                    // position: "absolute",
                                    // bottom: 50,
                                }}
                            >
                                <Paper
                                    elevation={3}
                                    sx={{
                                        padding: 3,
                                        width: "100%",
                                        borderRadius: "10px",
                                        backgroundColor: "rgba(255, 255, 255, 0.9)",
                                        transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                        "&:hover": {
                                            transform: "translateY(-10px)",
                                        },
                                    }}
                                >
                                    <Typography
                                        variant="h6"
                                        sx={{
                                            fontWeight: "bold",
                                            color: "#333",
                                            marginBottom: 1,
                                        }}
                                    >
                                        {t('notice')}
                                    </Typography>
                                    <Typography
                                        textAlign={'left'}
                                        variant="body1"
                                        sx={{
                                            whiteSpace: "pre-wrap",
                                            wordBreak: "break-word",
                                            color: "#333",
                                        }}
                                    >
                                        {allSettings.notice_text}
                                    </Typography>
                                </Paper>
                            </Box>
                        </Box>

                        <Container
                            id="officiants"
                            sx={{
                                marginTop: 5,
                                textAlign: "center",
                                display: allSettings?.officiants ? 'block' : 'none'
                            }}
                        >
                            <Chip
                                label={t('ourOfficiants')}
                                sx={{ fontSize: '1.7rem', marginBottom: '1rem', padding: 3, borderRadius: '50px' }}
                            />
                            <Grid container spacing={4} justifyContent="center">
                                {staff.map((teacher, index) => (
                                    <Grid item key={index} xs={12} sm={6} md={4}>
                                        <Card
                                            sx={{
                                                maxWidth: 300,
                                                margin: "auto",
                                                display: "flex",
                                                flexDirection: "column",
                                                alignItems: "center",
                                                transition: "transform 0.3s ease, box-shadow 0.3s ease",
                                                "&:hover": {
                                                    transform: "translateY(-10px)",
                                                    boxShadow: "0 8px 20px rgba(0, 0, 0, 0.2)",
                                                },
                                            }}
                                        >
                                            <Avatar sx={{ width: 90, height: 90, marginTop: 2 }} alt={teacher.name} src={teacher.photo_url} />
                                            <CardContent>
                                                <Typography
                                                    gutterBottom
                                                    variant="h6"
                                                    component="div"
                                                    textAlign="center"
                                                >
                                                    {teacher.name}
                                                </Typography>
                                                <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    textAlign="center"
                                                >
                                                    {teacher.designation}
                                                </Typography>
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Container >
                    </Box>
                </Box>
            </Box>

            {/* <Box sx={{
                padding: 5,
                marginTop: 5,
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
                display: settings?.result ? 'flex' : 'none',
            }}
                className='bgColor'
            >
                <RESULTMANAGEMENT publicUser={academicInfo} />
            </Box> */}


            <Box color='inherit' elevation={0} sx={{ padding: 1, marginTop: 4 }}>
                <Toolbar variant='dense' sx={{ justifyContent: 'center' }}>
                    COPYRIGHT 2024
                </Toolbar>
            </Box>
        </Box>
    );
}