// React and related hooks
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    DataGrid,
    GridToolbarContainer,
    GridToolbarQuickFilter,
    useGridApiContext,
    GridActionsCellItem,
    gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';
import {
    Grid,
    Button,
    ButtonGroup,
    Box,
    Menu,
    MenuItem,
    Dialog,
    Chip
} from '@mui/material';
import {
    Delete,
    AddCircle,
    Edit,
    CheckCircle,
    Cancel,
    FileDownloadOutlined
} from '@mui/icons-material';

// Custom components and utilities
import { MetaData } from '../utils/metaData';
import CustomPagination from '../layout/Pagination';
import VOUCHERFORM from './VoucherFrom';
import useAuth from '../hooks/UseAuth';
import useDataGridColumns from '../utils/useDataGridColumns';

// Actions and constants
import {
    allRegardings,
    allVouchers,
    deleteVoucher
} from '../../actions/accountActions';
import { allUsers } from '../../actions/authActions';
import { voucherListPdf, vouchersPdf } from '../../actions/pdfActions';
import {
    UPDATE_VOUCHER_RESET,
    DELETE_VOUCHER_RESET,
    NEW_VOUCHER_FAIL
} from '../../constants/accountConstants';

// Utilities
import dayjs from 'dayjs';
import { GETCODE } from '../layout/MiniComponents';

function CustomToolbar({ handleOpen, departType, handleVoucherPdf, typeOfDepartment }) {
    const { t } = useTranslation();
    const { type } = useAuth();
    const apiRef = useGridApiContext();
    const dispatch = useDispatch();
    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };

    // for csv export using MUI API
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // Prepare data for PDF
        const filteredRows = gridFilteredSortedRowIdsSelector(apiRef).map((id) => apiRef.current.getRow(id));

        // Get the current visibility model
        const excludedFields = ['__check__', 'actions', 'receiverSign'];
        const columnVisibilityModel = apiRef.current.state.columns.columnVisibilityModel;
        const headerNames = apiRef.current.getAllColumns()
            .filter(column => !excludedFields.includes(column.field) && columnVisibilityModel[column.field] !== false)  // Exclude unwanted fields and hidden columns
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            department: typeOfDepartment,
            heading: departType,
            columns: headerNames,
            rows: filteredRows
        }
        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePdf = () => {
        dispatch(voucherListPdf(currentRows));
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button disabled={type !== 'accountant' && type !== 'boardingSuper'} aria-label="Add icon" onClick={handleOpen}><AddCircle /></Button>
                        <Button aria-label="Print icon"
                            onClick={handleDownloadFile}
                        >
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handleVoucherPdf}>{t('voucherDownload')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={departType} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer>
    );
}

export default function VOUCHERLIST({ departmentType }) {
    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const { code, email } = useAuth();
    const [rowData, setRowData] = React.useState(null);

    // for storing basic data according to the departmentType
    let type;
    let voucherDepartment;
    if (departmentType === 'boarding') {
        type = t('voucherListBoarding');
        voucherDepartment = 'boarding';
    } else {
        type = t('voucherListAccounts');
        voucherDepartment = 'accounts';
    }

    // fetch data from redux store
    const { vouchers, loading } = useSelector(state => state.allVoucher);
    const { isUpdated, isDeleted } = useSelector(state => state.voucher);
    const { isSaved } = useSelector(state => state.newVoucher);
    const { users } = useSelector(state => state.allUsers);

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allRegardings(code));
        dispatch(allUsers({ email: email }, code));
    }, [dispatch, email, code]);

    useEffect(() => {
        dispatch(allVouchers({ department: voucherDepartment }, code));
        if (isSaved) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: NEW_VOUCHER_FAIL })
        }

        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: UPDATE_VOUCHER_RESET })
            setOpen(false);
        }

        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch({ type: DELETE_VOUCHER_RESET })
        }
    }, [dispatch, t, isUpdated, isDeleted, enqueueSnackbar, voucherDepartment, code, isSaved])

    // handle edit 
    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);
    const [selectedInfo, setSelectedInfo] = React.useState(null);

    const handleOpen = () => {
        setOpen(true);
        setSelectedInfo('')
    };

    const handleEdit = (rowData) => {
        if (rowData.countCondition === 'saved') {
            enqueueSnackbar(t('sorryMonthlyVoucherCountDone'), { variant: 'error' });
        } else {
            setSelectedInfo({
                id: rowData.id,
                voucherNo: rowData.voucher_no
            });
            setOpen(true);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const columns = useDataGridColumns([
        { field: 'id', headerName: t('dataId'), minWidth: 80, disableColumnMenu: false, flex: 0 },
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, sortable: true, flex: 0 },
        { field: 'name', headerName: t('name') },
        { field: 'voucher_no', headerName: t('voucherNo') },
        { field: 'fund', headerName: t('fund') },
        { field: 'total', headerName: t('allTotal') },
        { field: 'inWords', headerName: t('inWords') },
        { field: 'date', headerName: t('date') },

        {
            field: 'receiverSign',
            headerName: t('receiverSign'),
            headerClassName: 'CustomHeader',
            minWidth: 110,
            flex: 1,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="User"
                    style={{ width: '60px', height: '30px', marginTop: '2.5px' }}
                />
            ),
        },

        {
            field: 'principalSign',
            headerName: t('principalSign'),
            headerClassName: 'CustomHeader',
            minWidth: 110,
            flex: 1,
            renderCell: (params) => (
                <img
                    src={params.value}
                    alt="❌"
                    style={{ width: '60px', height: '30px', marginTop: '2.5px' }}
                />
            ),
        },

        { field: 'department', headerName: t('department'), width: 100 },
        {
            field: 'countCondition',
            headerName: t('monthlyCount'),
            headerClassName: 'CustomHeader',
            minWidth: 150,
            renderCell: (params) =>
                params.value === 'unsaved' ?
                    <GridActionsCellItem
                        icon={<Cancel />}
                        color="error"
                        label="not saved"
                    /> :
                    <GridActionsCellItem
                        icon={<CheckCircle />}
                        label="saved"
                        color="success"
                    />
        },
        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            minWidth: 80,
            getActions: (params) => [
                <GridActionsCellItem
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleOpenGetCode(params.row)}
                    showInMenu
                />,
            ],
        },
    ]);

    // Transform vouchers array into the desired format
    const rows = vouchers.map((voucher, index) => {
        return {
            id: voucher.id,
            serial: index + 1,
            name: voucher.name,
            voucher_no: voucher.voucher_no,
            fund: t(voucher.fund),
            total: voucher.total,
            inWords: voucher.in_words,
            date: `${dayjs(voucher.date).format('YYYY-MM-DD')}`,
            receiverSign: voucher.receiver_sign,
            principalSign: voucher.principal_sign,
            department: t(voucher.department),
            countCondition: voucher.condition // Store condition directly
        };
    });

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlaycibel: t('sorryNotFound'),
    };

    // handle pdf
    const [selectionModel, setSelectionModel] = React.useState([]);
    const [selectedRows, setSelectedRows] = React.useState([]);
    const handleSelectionModelChange = (newSelectionModel) => {
        setSelectionModel(newSelectionModel);
        // Find the selected row data
        const pdfRows = vouchers.filter(row => newSelectionModel.includes(row.id) && row.code === code);
        setSelectedRows(pdfRows)
    };

    const handleVoucherPdf = () => {
        const data = {
            department: departmentType,
            selectedRows: selectedRows
        }
        dispatch(vouchersPdf(data));
    };

    // for handling principal sign
    const [password, setPassword] = React.useState('');
    const [openGetCode, setGetCodeOpen] = React.useState(false);
    const handleOpenGetCode = (data) => {
        setRowData(data)
        setGetCodeOpen(true);
    };

    const handlePrincipalSign = async (e) => {
        e.preventDefault();
        const userType = users.find(user => user.password === password);
        if (userType && userType.type === 'principal') {
            setGetCodeOpen(false);
            if (rowData.countCondition === 'saved') {
                enqueueSnackbar(t('sorryMonthlyVoucherCountDone'), { variant: 'error' });
            } else {
                const data = {
                    id: rowData.id
                }
                dispatch(deleteVoucher(data, code))
            }
        } else {
            enqueueSnackbar(t('authorRequired'), { variant: 'error' });
        }
    };

    const handleCloseGetCode = () => {
        setGetCodeOpen(false);
    };

    // Define the getRowId function to use 'serialNo' as the unique id for each row
    return (
        <Box className="globalShapeDesign">
            <MetaData title={'VOUCHER LIST'} />
            <Dialog
                open={open}
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <VOUCHERFORM history={selectedInfo} department={voucherDepartment} closeDialog={handleClose} />
            </Dialog>

            <GETCODE
                headingText={t('principalCode')}
                handleOpen={openGetCode}
                handleClose={handleCloseGetCode}
                handleSign={handlePrincipalSign}
                password={password}
                setPassword={setPassword}
            />

            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                loading={loading ? true : false}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                localeText={localeText}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                selectionModel={selectionModel}
                onRowSelectionModelChange={handleSelectionModelChange}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 },
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            id: false,
                            inWords: false,
                            principalSign: false,
                            countCondition: false
                        },
                    },
                }}

                slots={{
                    // toolbar: CustomToolbar,
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} departType={type} handleVoucherPdf={handleVoucherPdf} typeOfDepartment={departmentType} />,
                    pagination: CustomPagination
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
