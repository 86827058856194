export const NEW_STAFF_REQUEST = 'NEW_STAFF_REQUEST'
export const NEW_STAFF_SUCCESS = 'NEW_STAFF_SUCCESS'
export const NEW_STAFF_RESET = 'NEW_STAFF_RESET'
export const NEW_STAFF_FAIL = 'NEW_STAFF_FAIL'
export const CLEAR_ERRORS = 'CLEAR_ERRORS'

export const ALL_STAFF_REQUEST = 'ALL_STAFF_REQUEST'
export const ALL_STAFF_SUCCESS = 'ALL_STAFF_SUCCESS'
export const ALL_STAFF_RESET = 'ALL_STAFF_RESET'
export const ALL_STAFF_FAIL = 'ALL_STAFF_FAIL'

export const UPDATE_STAFF_REQUEST = 'UPDATE_STAFF_REQUEST'
export const UPDATE_STAFF_SUCCESS = 'UPDATE_STAFF_SUCCESS'
export const UPDATE_STAFF_RESET = 'UPDATE_STAFF_RESET'
export const UPDATE_STAFF_FAIL = 'UPDATE_STAFF_FAIL'

export const DELETE_STAFF_REQUEST = 'DELETE_STAFF_REQUEST'
export const DELETE_STAFF_SUCCESS = 'DELETE_STAFF_SUCCESS'
export const DELETE_STAFF_RESET = 'DELETE_STAFF_RESET'
export const DELETE_STAFF_FAIL = 'DELETE_STAFF_FAIL'

// for staff reports
export const NEW_STAFF_REPORT_REQUEST = 'NEW_STAFF_REPORT_REQUEST'
export const NEW_STAFF_REPORT_SUCCESS = 'NEW_STAFF_REPORT_SUCCESS'
export const NEW_STAFF_REPORT_RESET = 'NEW_STAFF_REPORT_RESET'
export const NEW_STAFF_REPORT_FAIL = 'NEW_STAFF_REPORT_FAIL'

export const ALL_STAFF_REPORT_REQUEST = 'ALL_STAFF_REPORT_REQUEST'
export const ALL_STAFF_REPORT_SUCCESS = 'ALL_STAFF_REPORT_SUCCESS'
export const ALL_STAFF_REPORT_RESET = 'ALL_STAFF_REPORT_RESET'
export const ALL_STAFF_REPORT_FAIL = 'ALL_STAFF_REPORT_FAIL'

export const UPDATE_STAFF_REPORT_REQUEST = 'UPDATE_STAFF_REPORT_REQUEST'
export const UPDATE_STAFF_REPORT_SUCCESS = 'UPDATE_STAFF_REPORT_SUCCESS'
export const UPDATE_STAFF_REPORT_RESET = 'UPDATE_STAFF_REPORT_RESET'
export const UPDATE_STAFF_REPORT_FAIL = 'UPDATE_STAFF_REPORT_FAIL'

export const DELETE_STAFF_REPORT_REQUEST = 'DELETE_STAFF_REPORT_REQUEST'
export const DELETE_STAFF_REPORT_SUCCESS = 'DELETE_STAFF_REPORT_SUCCESS'
export const DELETE_STAFF_REPORT_RESET = 'DELETE_STAFF_REPORT_RESET'
export const DELETE_STAFF_REPORT_FAIL = 'DELETE_STAFF_REPORT_FAIL'

// for store management
export const NEW_FURNITURE_REQUEST = 'NEW_FURNITURE_REQUEST'
export const NEW_FURNITURE_SUCCESS = 'NEW_FURNITURE_SUCCESS'
export const NEW_FURNITURE_FAIL = 'NEW_FURNITURE_FAIL'
export const NEW_FURNITURE_RESET = 'NEW_FURNITURE_RESET'

export const ALL_FURNITURE_REQUEST = 'ALL_FURNITURE_REQUEST'
export const ALL_FURNITURE_SUCCESS = 'ALL_FURNITURE_SUCCESS'
export const ALL_FURNITURE_RESET = 'ALL_FURNITURE_RESET'
export const ALL_FURNITURE_FAIL = 'ALL_FURNITURE_FAIL'

export const UPDATE_FURNITURE_REQUEST = 'UPDATE_FURNITURE_REQUEST'
export const UPDATE_FURNITURE_SUCCESS = 'UPDATE_FURNITURE_SUCCESS'
export const UPDATE_FURNITURE_RESET = 'UPDATE_FURNITURE_RESET'
export const UPDATE_FURNITURE_FAIL = 'UPDATE_FURNITURE_FAIL'

export const DELETE_FURNITURE_REQUEST = 'DELETE_FURNITURE_REQUEST'
export const DELETE_FURNITURE_SUCCESS = 'DELETE_FURNITURE_SUCCESS'
export const DELETE_FURNITURE_RESET = 'DELETE_FURNITURE_RESET'
export const DELETE_FURNITURE_FAIL = 'DELETE_FURNITURE_FAIL'

export const NEW_GIVEN_FURNITURE_REQUEST = 'NEW_GIVEN_FURNITURE_REQUEST'
export const NEW_GIVEN_FURNITURE_SUCCESS = 'NEW_GIVEN_FURNITURE_SUCCESS'
export const NEW_GIVEN_FURNITURE_FAIL = 'NEW_GIVEN_FURNITURE_FAIL'
export const NEW_GIVEN_FURNITURE_RESET = 'NEW_GIVEN_FURNITURE_RESET'

export const ALL_GIVEN_FURNITURE_REQUEST = 'ALL_GIVEN_FURNITURE_REQUEST'
export const ALL_GIVEN_FURNITURE_SUCCESS = 'ALL_GIVEN_FURNITURE_SUCCESS'
export const ALL_GIVEN_FURNITURE_RESET = 'ALL_GIVEN_FURNITURE_RESET'
export const ALL_GIVEN_FURNITURE_FAIL = 'ALL_GIVEN_FURNITURE_FAIL'

export const UPDATE_GIVEN_FURNITURE_REQUEST = 'UPDATE_GIVEN_FURNITURE_REQUEST'
export const UPDATE_GIVEN_FURNITURE_SUCCESS = 'UPDATE_GIVEN_FURNITURE_SUCCESS'
export const UPDATE_GIVEN_FURNITURE_RESET = 'UPDATE_GIVEN_FURNITURE_RESET'
export const UPDATE_GIVEN_FURNITURE_FAIL = 'UPDATE_GIVEN_FURNITURE_FAIL'

export const DELETE_GIVEN_FURNITURE_REQUEST = 'DELETE_GIVEN_FURNITURE_REQUEST'
export const DELETE_GIVEN_FURNITURE_SUCCESS = 'DELETE_GIVEN_FURNITURE_SUCCESS'
export const DELETE_GIVEN_FURNITURE_RESET = 'DELETE_GIVEN_FURNITURE_RESET'
export const DELETE_GIVEN_FURNITURE_FAIL = 'DELETE_GIVEN_FURNITURE_FAIL'

export const ACCEPT_ALL_FURNITURE_REQUEST = 'ACCEPT_ALL_FURNITURE_REQUEST'
export const ACCEPT_ALL_FURNITURE_SUCCESS = 'ACCEPT_ALL_FURNITURE_SUCCESS'
export const ACCEPT_ALL_FURNITURE_RESET = 'ACCEPT_ALL_FURNITURE_RESET'
export const ACCEPT_ALL_FURNITURE_FAIL = 'ACCEPT_ALL_FURNITURE_FAIL'

export const UPDATE_MATCHING_FURNITURE_REQUEST = 'UPDATE_MATCHING_FURNITURE_REQUEST'
export const UPDATE_MATCHING_FURNITURE_SUCCESS = 'UPDATE_MATCHING_FURNITURE_SUCCESS'
export const UPDATE_MATCHING_FURNITURE_RESET = 'UPDATE_MATCHING_FURNITURE_RESET'
export const UPDATE_MATCHING_FURNITURE_FAIL = 'UPDATE_MATCHING_FURNITURE_FAIL'

export const ADD_OFFICIANT_ATTENDANCE_REQUEST = 'ADD_OFFICIANT_ATTENDANCE_REQUEST'
export const ADD_OFFICIANT_ATTENDANCE_SUCCESS = 'ADD_OFFICIANT_ATTENDANCE_SUCCESS'
export const ADD_OFFICIANT_ATTENDANCE_FAIL = 'ADD_OFFICIANT_ATTENDANCE_FAIL'
export const ADD_OFFICIANT_ATTENDANCE_RESET = 'ADD_OFFICIANT_ATTENDANCE_RESET'

export const ALL_OFFICIANT_ATTENDANCE_REQUEST = 'ALL_OFFICIANT_ATTENDANCE_REQUEST'
export const ALL_OFFICIANT_ATTENDANCE_SUCCESS = 'ALL_OFFICIANT_ATTENDANCE_SUCCESS'
export const ALL_OFFICIANT_ATTENDANCE_FAIL = 'ALL_OFFICIANT_ATTENDANCE_FAIL'
export const ALL_OFFICIANT_ATTENDANCE_RESET = 'ALL_OFFICIANT_ATTENDANCE_RESET'

export const DELETE_OFFICIANT_ATTENDANCE_REQUEST = 'DELETE_OFFICIANT_ATTENDANCE_REQUEST'
export const DELETE_OFFICIANT_ATTENDANCE_SUCCESS = 'DELETE_OFFICIANT_ATTENDANCE_SUCCESS'
export const DELETE_OFFICIANT_ATTENDANCE_FAIL = 'DELETE_OFFICIANT_ATTENDANCE_FAIL'
export const DELETE_OFFICIANT_ATTENDANCE_RESET = 'DELETE_OFFICIANT_ATTENDANCE_RESET'

// constants
export const NEW_STAFF_LEAVE_REQUEST = 'NEW_STAFF_LEAVE_REQUEST'
export const NEW_STAFF_LEAVE_SUCCESS = 'NEW_STAFF_LEAVE_SUCCESS'
export const NEW_STAFF_LEAVE_FAIL = 'NEW_STAFF_LEAVE_FAIL'
export const NEW_STAFF_LEAVE_RESET = 'NEW_STAFF_LEAVE_RESET'

export const ALL_STAFF_LEAVE_REQUEST = 'ALL_STAFF_LEAVE_REQUEST'
export const ALL_STAFF_LEAVE_SUCCESS = 'ALL_STAFF_LEAVE_SUCCESS'
export const ALL_STAFF_LEAVE_FAIL = 'ALL_STAFF_LEAVE_FAIL'

export const UPDATE_STAFF_LEAVE_REQUEST = 'UPDATE_STAFF_LEAVE_REQUEST'
export const UPDATE_STAFF_LEAVE_SUCCESS = 'UPDATE_STAFF_LEAVE_SUCCESS'
export const UPDATE_STAFF_LEAVE_FAIL = 'UPDATE_STAFF_LEAVE_FAIL'

export const DELETE_STAFF_LEAVE_REQUEST = 'DELETE_STAFF_LEAVE_REQUEST'
export const DELETE_STAFF_LEAVE_SUCCESS = 'DELETE_STAFF_LEAVE_SUCCESS'
export const DELETE_STAFF_LEAVE_FAIL = 'DELETE_STAFF_LEAVE_FAIL'
export const UPDATE_STAFF_LEAVE_RESET = 'UPDATE_STAFF_LEAVE_RESET'
export const DELETE_STAFF_LEAVE_RESET = 'DELETE_STAFF_LEAVE_RESET'

export const ALL_DUTIES_REQUEST = 'ALL_DUTIES_REQUEST'
export const ALL_DUTIES_SUCCESS = 'ALL_DUTIES_SUCCESS'
export const ALL_DUTIES_FAIL = 'ALL_DUTIES_FAIL'

export const ADD_STAFF_DUTY_REQUEST = 'ADD_STAFF_DUTY_REQUEST'
export const ADD_STAFF_DUTY_SUCCESS = 'ADD_STAFF_DUTY_SUCCESS'
export const ADD_STAFF_DUTY_FAIL = 'ADD_STAFF_DUTY_FAIL'
export const ADD_STAFF_DUTY_RESET = 'ADD_STAFF_DUTY_RESET'

export const DELETE_STAFF_DUTY_REQUEST = 'DELETE_STAFF_DUTY_REQUEST'
export const DELETE_STAFF_DUTY_SUCCESS = 'DELETE_STAFF_DUTY_SUCCESS'
export const DELETE_STAFF_DUTY_FAIL = 'DELETE_STAFF_DUTY_FAIL'
export const DELETE_STAFF_DUTY_RESET = 'DELETE_STAFF_DUTY_RESET'

export const getAttendanceTypes = (t) => [
    { value: 'manual', label: t('manual') },
    { value: 'cardNumber', label: t('idCard') },
    { value: 'finger', label: t('fingerPrint') },
    { value: 'pin', label: t('pinCode') },
    { value: 'all', label: `${t('idCard')}/${t('fingerPrint')}/${t('pinCode')}` }
];

export const getZktecoUserTypes = (t) => [
    { value: 'admin', label: t('admin') },
    { value: 'general', label: t('general') }
];