// React and related hooks
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSnackbar } from 'notistack';

// MUI components and icons
import {
    Grid, Button, ButtonGroup, Box, Chip, Dialog, Menu, MenuItem
} from '@mui/material';
import {
    Delete, AddCircle, Edit,
    FileDownloadOutlined
} from '@mui/icons-material';

import {
    DataGrid, GridToolbarContainer, GridToolbarQuickFilter,
    useGridApiContext, GridActionsCellItem, gridFilteredSortedRowIdsSelector
} from '@mui/x-data-grid';

// Custom components and utilities
import CustomPagination from '../layout/Pagination';
import { MetaData } from '../utils/metaData';
import useAuth from '../hooks/UseAuth';
import useDataGridColumns from '../utils/useDataGridColumns';

// Actions and Constants
import { allDonors, deleteDonor } from '../../actions/donorActions';
import { DELETE_DONOR_RESET, UPDATE_DONOR_RESET } from '../../constants/donorContstants';
import { donorListPdf } from '../../actions/pdfActions';

// Forms and Modals
import DONORFORM from './DonorForm';
import dayjs from 'dayjs';

function CustomToolbar({ handleOpen }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code } = useAuth();
    const [currentRows, setCurrentRows] = React.useState('');

    // for changing local text of mui dataGrid
    const localeText = {
        toolbarQuickFilterPlaceholder: t('typeHere'),
    };
    // for csv export using MUI API
    const apiRef = useGridApiContext();
    const handleExportCsv = () => {
        const csvOptions = {
            fileName: 'data',
            delimiter: ',',
            utf8WithBom: true
        };
        apiRef.current.exportDataAsCsv(csvOptions);
    };

    // for prints option selection
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleDownloadFile = (event) => {
        setAnchorEl(event.currentTarget);

        // for prepare data to handle pdf
        const rows = apiRef.current.getAllRowIds().map((id) => apiRef.current.getRow(id));
        const rowIds = gridFilteredSortedRowIdsSelector(apiRef);
        const filteredRows = rows.filter(row => rowIds.includes(row.id));

        const headerNames = apiRef.current.getAllColumns()
            .filter(column => column.field !== '__check__' && column.field !== 'actions')  // Filter out the 'id' field
            .map(column => column.headerName || column.field);  // Map to headerName or field

        const data = {
            heading: t('lifeTimeDonors'),
            columns: headerNames,
            rows: filteredRows
        }

        setCurrentRows(data)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handlePdf = () => {
        dispatch(donorListPdf(currentRows, code))
    };

    return (
        <GridToolbarContainer>
            <Grid container alignItems='center'>
                <Grid xs={6} sm={3} order={{ sm: 1, xs: 2 }} item display={'flex'} justifyContent={'left'}>
                    <ButtonGroup size="small" variant="outlined" aria-label="Basic button group">
                        <Button onClick={handleOpen}><AddCircle /></Button>
                        <Button onClick={handleDownloadFile}>
                            <FileDownloadOutlined />
                        </Button>
                    </ButtonGroup>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0,
                            sx: {
                                overflow: 'visible', filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))', mt: 1.5, '& .MuiAvatar-root': { width: 32, height: 30, ml: -0.5, mr: 1 }, '&::before': { content: '""', display: 'block', position: 'absolute', top: 0, right: 14, width: 10, height: 10, bgcolor: 'background.paper', transform: 'translateY(-50%) rotate(45deg)', zIndex: 0 },
                            },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleExportCsv}>{t('saveExcel')}</MenuItem>
                        <MenuItem onClick={handlePdf}>{t('savePdf')}</MenuItem>
                    </Menu>
                </Grid>
                <Grid xs={12} sm={6} order={{ sm: 2, xs: 1 }} item display={'flex'} justifyContent={'center'}>
                    <Chip sx={{
                        fontSize: '1rem',
                    }} label={t('lifeTimeDonors')} />
                </Grid>
                <Grid xs={6} sm={3} order={{ sm: 3, xs: 3 }} item display='flex' justifyContent='end'>
                    <GridToolbarQuickFilter placeholder={localeText.toolbarQuickFilterPlaceholder} />
                </Grid>
            </Grid>
        </GridToolbarContainer >
    );
}

export default function DONORLIST() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const { code, type } = useAuth();

    const { donors, loading } = useSelector(state => state.allDonors);
    const { isDeleted, isUpdated } = useSelector(state => state.donor);
    const [donorInfo, setDonorInfo] = useState('')

    // useEffect is used to work these functionality in one time
    useEffect(() => {
        dispatch(allDonors(code))
    }, [dispatch, t, code])

    useEffect(() => {
        if (isUpdated) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(allDonors(code));
            dispatch({ type: UPDATE_DONOR_RESET })
            setOpen(false);
        }
        if (isDeleted) {
            enqueueSnackbar(t('successMessage'), { variant: 'success' });
            dispatch(allDonors(code));
            dispatch({ type: DELETE_DONOR_RESET })
        }
    }, [dispatch, t, enqueueSnackbar, isDeleted, isUpdated, code])

    // for change mui localtext selection
    const localeText = {
        footerRowSelected: (count) => `${count} ${t('line')}${count !== 1 ? t('lines') : ''} ${t('selectedLine')}`,
        noRowsLabel: t('sorryNotFound'),
        noResultsOverlayLabel: t('sorryNotFound'),
    };

    const columns = useDataGridColumns([
        { field: 'serial', headerName: t('serialNo'), minWidth: 80, width: 80, disableColumnMenu: false, flex: 0 },
        { field: 'id', headerName: t('memberNo'), minWidth: 80, width: 80, sortable: true, flex: 0 },
        { field: 'donorName', headerName: t('donorName') },
        { field: 'fatherName', headerName: t('fatherName') },
        { field: 'address', headerName: t('address') },
        { field: 'donationAmount', headerName: t('donationAmount'), minWidth: 120, width: 120, flex: 0 },
        { field: 'referrerId', headerName: t('referrerId') },
        { field: 'referrer', headerName: t('referrer') },
        { field: 'mobile', headerName: t('mobile'), minWidth: 120, width: 120, flex: 0 },
        { field: 'date', headerName: t('date'), minWidth: 100, width: 100, flex: 0 },
        { field: 'status', headerName: t('status'), minWidth: 100, width: 100, flex: 0 },
        {
            field: 'actions',
            headerName: t('actions'),
            headerClassName: 'CustomHeader',
            type: 'actions',
            minWidth: 80,
            width: 80,
            flex: 0,
            getActions: (params) => [
                <GridActionsCellItem
                    disabled={type !== 'principal'}
                    icon={<Edit />}
                    label="edit"
                    onClick={() => handleEdit(params.row)}
                />,
                <GridActionsCellItem
                    disabled={type !== 'principal'}
                    icon={<Delete
                        sx={{
                            color: 'red'
                        }}
                    />}
                    label={t('delete')}
                    onClick={() => handleDelete(params.row.id)}
                    showInMenu
                />,
            ],
        },

    ]);

    // Transform books array into the desired format
    const rows = donors?.map((d, index) => {
        return {
            serial: index + 1,
            id: d.member_no,
            donationAmount: d.donation_amount,
            donorName: d.donor_name,
            fatherName: d.father_name,
            address: `${d.village}, ${d.sub_district}, ${d.district}`,
            village: d.village,
            postOffice: d.post_office,
            subDistrict: d.sub_district,
            district: d.district,
            referrerId: d.referrer_id,
            referrer: d.referrer_name,
            mobile: d.mobile,
            date: `${dayjs(d.created_at).format('YYYY-MM-DD')}`,
            status: ''
        };
    });

    const handleEdit = (rowData) => {
        setDonorInfo(rowData)
        setOpen(true);
    };

    const handleDelete = (id) => {
        dispatch(deleteDonor(id, code))
    }

    // for open the edit dialouge
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Box className="globalShapeDesign">
            <MetaData title={'DONOR LIST'} />
            <Dialog
                open={open}
                // onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DONORFORM closeDialog={handleClose} history={donorInfo} />
            </Dialog>
            <DataGrid
                rows={rows}
                columns={columns}
                density={'compact'}
                pageSizeOptions={[5, 10, 20, 50, 100]}
                checkboxSelection
                scrollbarSize={0}
                loading={loading}
                showCellVerticalBorder={true}
                showColumnVerticalBorder={true}
                localeText={localeText}

                initialState={{
                    pagination: {
                        paginationModel: { pageSize: 100 },
                    },

                    columns: {
                        columnVisibilityModel: {
                            __check__: false,
                            fatherName: false,
                            referrer: false,
                            referrerId: false,
                        },
                    },
                }}

                slots={{
                    toolbar: () => <CustomToolbar handleOpen={handleOpen} />,
                    pagination: CustomPagination,
                }}

                slotProps={{
                    toolbar: {
                        showQuickFilter: true,
                    },
                }}
            />
        </Box>
    );
}
