// React and Hooks
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

// Material UI Components
import { Box, Paper, Typography, List, ListItem, ListItemButton, ListItemText } from '@mui/material';

// Custom Actions and Utilities
import { allApprovedBooks } from '../../actions/accountActions';
import useAuth from '../hooks/UseAuth';
import { changeLanguage } from '../hooks/i18n';

export default function LANGUAGESETTINGS() {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { code, academyType } = useAuth();

    useEffect(() => {
        const data = { department: 'all' };
        dispatch(allApprovedBooks(data, code));
    }, [dispatch, code]);

    const changeLang = (lng) => {
        changeLanguage(lng, academyType);
    };

    return (
        <>
            <Box display={'flex'} sx={{ width: '40%' }} alignItems={'center'} justifyContent={'space-between'} mb={1}>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {t('languages')}
                </Typography>
            </Box>
            <Box component={Paper} sx={{ width: '40%', padding: '1rem' }}>
                <nav aria-label="secondary mailbox folders">
                    <List>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => changeLang('en')}>
                                <ListItemText primary="English" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => changeLang('bn')}>
                                <ListItemText primary="বাংলা" />
                            </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                            <ListItemButton onClick={() => changeLang('ar')}>
                                <ListItemText primary="عربي" />
                            </ListItemButton>
                        </ListItem>
                    </List>
                </nav>
            </Box>
        </>
    );
}