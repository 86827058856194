import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    TextField,
    Grid,
    IconButton,
    Chip,
    ToggleButton,
    ToggleButtonGroup
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { t } from "i18next";
import { getSettings, updateSettings } from "../../actions/othersActions";
import useAuth from "../hooks/UseAuth";
import { useDispatch, useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import CircularProgress from '@mui/material/CircularProgress';

const CustomizeGrading = () => {
    const { code } = useAuth();
    const dispatch = useDispatch();
    const [rows, setRows] = useState([]);

    const { allSettings, loading } = useSelector(state => state.settings);

    useEffect(() => {
        dispatch(getSettings(code))
    }, [dispatch, code])


    const addRow = () => {
        setRows([
            ...rows,
            { grade: "", from: "", to: "", status: "" },
        ]);
    };

    const removeRow = (index) => {
        setRows(rows.filter((_, i) => i !== index));
    };

    const handleChange = (index, field, value) => {
        const updatedRows = [...rows];
        updatedRows[index][field] = value;
        setRows(updatedRows);
    };

    // for handling results system
    const [resultSystem, setResultSystem] = React.useState(allSettings.result_system || 'parcentageSystem');

    // Optimized toggle handler using useCallback
    const handleChangeResultSystem = React.useCallback((event, newresultSystem) => {
        setResultSystem(newresultSystem)
        if (newresultSystem === allSettings.result_system && allSettings?.grades?.length) {
            // Use grades from allSettings if available
            setRows(
                allSettings.grades.map((grade) => ({
                    grade: grade.grade,
                    from: grade.from,
                    to: grade.to,
                    status: grade.status,
                }))
            );
        } else if (newresultSystem === 'parcentageSystem') {
            setRows([
                { grade: "A+", from: "80", to: "100", status: t('passResult') },
                { grade: "A", from: "70", to: "79", status: t('passResult') },
                { grade: "A-", from: "60", to: "69", status: t('passResult') },
                { grade: "B", from: "50", to: "59", status: t('passResult') },
                { grade: "C", from: "40", to: "49", status: t('passResult') },
                { grade: "D", from: "33", to: "39", status: t('passResult') },
                { grade: "F", from: "0", to: "32", status: t('fail') },
            ]);
        } else if (newresultSystem === 'gradeSystem') {
            setRows([
                { grade: "A+", from: "4.5", to: "5.0", status: t('passResult') },
                { grade: "A", from: "3.75", to: "4.49", status: t('passResult') },
                { grade: "A-", from: "3.25", to: "3.74", status: t('passResult') },
                { grade: "B", from: "2.50", to: "3.24", status: t('passResult') },
                { grade: "C", from: "1.50", to: "2.49", status: t('passResult') },
                { grade: "D", from: "0.75", to: "1.49", status: t('passResult') },
                { grade: "F", from: "0.00", to: "0.74", status: t('fail') },
            ]);
        }
    }, [allSettings.result_system, allSettings.grades]);

    useEffect(() => {
        if (allSettings?.grades?.length) {
            // setResultSystem(allSettings.result_system)
            // Use grades from allSettings if available
            setRows(
                allSettings.grades.map((grade) => ({
                    grade: grade.grade,
                    from: grade.from,
                    to: grade.to,
                    status: grade.status,
                }))
            );
        }
    }, [allSettings?.grades]);

    const handleUpdate = () => {
        const finalData = {
            isGrade: true,
            result_system: resultSystem,
            grades: rows
        }

        dispatch(updateSettings(finalData, code))
        enqueueSnackbar(t('successMessage'), { variant: 'success' });

    };


    return (
        <Box
            sx={{
                p: 4,
                borderRadius: 2,
                maxWidth: 800,
                mx: "auto",
                margin: "auto",
                bgcolor: 'background.paper',
                boxShadow: '0px 0px 15px 1px #e0ecff',
            }}
        >
            {!loading ?
                <>
                    <Grid container>
                        <Grid item xs={3}>
                            <ToggleButtonGroup
                                color="primary"
                                value={resultSystem}
                                exclusive
                                onChange={handleChangeResultSystem}
                                aria-label="Result_system"
                            >
                                <ToggleButton size='small' value='parcentageSystem'>{t('parcentageSystem')}</ToggleButton>
                                <ToggleButton size='small' value="gradeSystem">{t('gradeSystem')}</ToggleButton>
                            </ToggleButtonGroup>
                        </Grid>
                        <Grid item xs={6} textAlign={'center'}>
                            <Chip sx={{
                                fontSize: '1.1rem',
                            }} label={t('customizeGrading')} />
                        </Grid>
                        <Grid item xs={3}>

                        </Grid>
                    </Grid>

                    <Grid container spacing={1} mt={2}>
                        {rows.map((row, index) => (
                            <React.Fragment key={index}>
                                <Grid item xs={3}>
                                    <TextField
                                        label={t('grade')}
                                        variant="outlined"
                                        value={row.grade}
                                        size="small"
                                        onChange={(e) =>
                                            handleChange(index, "grade", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={`${resultSystem === 'parcentageSystem' ? '%' : ''} ${t('from')}`}
                                        variant="outlined"
                                        size="small"
                                        type="number"
                                        value={row.from}
                                        onChange={(e) =>
                                            handleChange(index, "from", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={`${resultSystem === 'parcentageSystem' ? '%' : ''} ${t('upto')}`}
                                        variant="outlined"
                                        type="number"
                                        size="small"
                                        value={row.to}
                                        onChange={(e) =>
                                            handleChange(index, "to", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={3}>
                                    <TextField
                                        label={t('status')}
                                        variant="outlined"
                                        size="small"
                                        value={row.status}
                                        onChange={(e) =>
                                            handleChange(index, "status", e.target.value)
                                        }
                                        fullWidth
                                    />
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    {index === rows.length - 1 && (
                                        <Box mt={1} display={'flex'} justifyContent={'space-between'}>
                                            <Box>
                                                <IconButton
                                                    color="primary"
                                                    onClick={addRow}
                                                    aria-label="add"
                                                    sx={{
                                                        background: 'rgba(244,247,254,255)'
                                                    }}
                                                >
                                                    <AddIcon />
                                                </IconButton>
                                                <IconButton
                                                    color="error"
                                                    onClick={() => removeRow(index)}
                                                    aria-label="remove"
                                                    sx={{
                                                        background: 'rgba(244,247,254,255)',
                                                        marginLeft: 1
                                                    }}
                                                >
                                                    <RemoveIcon />
                                                </IconButton>
                                            </Box>

                                            <Button onClick={handleUpdate} disableElevation variant="contained" color="success">
                                                {t('update')}
                                            </Button>
                                        </Box>
                                    )}
                                </Grid>
                            </React.Fragment>
                        ))}
                    </Grid>
                </>
                : <CircularProgress />}
        </Box>
    );
};

export default CustomizeGrading;
