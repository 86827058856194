import axios from 'axios';

import {
    NEW_STAFF_REQUEST,
    NEW_STAFF_SUCCESS,
    NEW_STAFF_FAIL,
    ALL_STAFF_REQUEST,
    ALL_STAFF_SUCCESS,
    ALL_STAFF_FAIL,
    UPDATE_STAFF_REQUEST,
    UPDATE_STAFF_SUCCESS,
    UPDATE_STAFF_FAIL,
    DELETE_STAFF_REQUEST,
    DELETE_STAFF_SUCCESS,
    DELETE_STAFF_FAIL,
    NEW_STAFF_REPORT_REQUEST,
    NEW_STAFF_REPORT_SUCCESS,
    NEW_STAFF_REPORT_FAIL,
    ALL_STAFF_REPORT_REQUEST,
    ALL_STAFF_REPORT_SUCCESS,
    ALL_STAFF_REPORT_FAIL,
    UPDATE_STAFF_REPORT_REQUEST,
    UPDATE_STAFF_REPORT_SUCCESS,
    UPDATE_STAFF_REPORT_FAIL,
    DELETE_STAFF_REPORT_REQUEST,
    DELETE_STAFF_REPORT_SUCCESS,
    DELETE_STAFF_REPORT_FAIL,
    NEW_FURNITURE_REQUEST,
    NEW_FURNITURE_SUCCESS,
    NEW_FURNITURE_FAIL,
    ALL_FURNITURE_REQUEST,
    ALL_FURNITURE_SUCCESS,
    ALL_FURNITURE_FAIL,
    UPDATE_FURNITURE_REQUEST,
    UPDATE_FURNITURE_SUCCESS,
    UPDATE_FURNITURE_FAIL,
    DELETE_FURNITURE_REQUEST,
    DELETE_FURNITURE_SUCCESS,
    DELETE_FURNITURE_FAIL,
    NEW_GIVEN_FURNITURE_REQUEST,
    NEW_GIVEN_FURNITURE_SUCCESS,
    NEW_GIVEN_FURNITURE_FAIL,
    ALL_GIVEN_FURNITURE_REQUEST,
    ALL_GIVEN_FURNITURE_SUCCESS,
    ALL_GIVEN_FURNITURE_FAIL,
    UPDATE_GIVEN_FURNITURE_REQUEST,
    UPDATE_GIVEN_FURNITURE_SUCCESS,
    UPDATE_GIVEN_FURNITURE_FAIL,
    UPDATE_MATCHING_FURNITURE_REQUEST,
    UPDATE_MATCHING_FURNITURE_SUCCESS,
    UPDATE_MATCHING_FURNITURE_FAIL,
    DELETE_GIVEN_FURNITURE_REQUEST,
    DELETE_GIVEN_FURNITURE_SUCCESS,
    DELETE_GIVEN_FURNITURE_FAIL,
    ACCEPT_ALL_FURNITURE_REQUEST,
    ACCEPT_ALL_FURNITURE_SUCCESS,
    CLEAR_ERRORS,
    ADD_OFFICIANT_ATTENDANCE_REQUEST,
    ADD_OFFICIANT_ATTENDANCE_SUCCESS,
    ADD_OFFICIANT_ATTENDANCE_FAIL,
    ALL_OFFICIANT_ATTENDANCE_REQUEST,
    ALL_OFFICIANT_ATTENDANCE_SUCCESS,
    ALL_OFFICIANT_ATTENDANCE_FAIL,
    DELETE_OFFICIANT_ATTENDANCE_REQUEST,
    DELETE_OFFICIANT_ATTENDANCE_SUCCESS,
    DELETE_OFFICIANT_ATTENDANCE_FAIL,
    ACCEPT_ALL_FURNITURE_FAIL,
    NEW_STAFF_LEAVE_REQUEST,
    NEW_STAFF_LEAVE_SUCCESS,
    NEW_STAFF_LEAVE_FAIL,
    ALL_STAFF_LEAVE_REQUEST,
    ALL_STAFF_LEAVE_SUCCESS,
    ALL_STAFF_LEAVE_FAIL,
    UPDATE_STAFF_LEAVE_REQUEST,
    UPDATE_STAFF_LEAVE_SUCCESS,
    UPDATE_STAFF_LEAVE_FAIL,
    DELETE_STAFF_LEAVE_REQUEST,
    DELETE_STAFF_LEAVE_SUCCESS,
    DELETE_STAFF_LEAVE_FAIL,
    ALL_DUTIES_REQUEST,
    ALL_DUTIES_SUCCESS,
    ALL_DUTIES_FAIL,
    ADD_STAFF_DUTY_REQUEST,
    ADD_STAFF_DUTY_SUCCESS,
    ADD_STAFF_DUTY_FAIL,
    DELETE_STAFF_DUTY_REQUEST,
    DELETE_STAFF_DUTY_SUCCESS,
    DELETE_STAFF_DUTY_FAIL
} from '../constants/adminisConstants'


// add new staff by adminis 
export const newStaff = (formData, code) => async (dispatch) => {
    try {
        dispatch({ type: NEW_STAFF_REQUEST })

        // configuration for register a user 
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        // passing staffData to backend by this route 
        const { data } = await axios.post(`/api/adminis/staff/new/${code}`, formData, config)

        // store data in payload 
        dispatch({
            type: NEW_STAFF_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_STAFF_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all staff
export const allStaff = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_STAFF_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/adminis/staff/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_STAFF_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_STAFF_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update staff
export const updateStaff = (staffData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_STAFF_REQUEST })

        // configuration for register a user 
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }

        // connect with backend for staff update
        const { data } = await axios.put(`/api/adminis/staff/update/${code}`, staffData, config)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_STAFF_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_STAFF_FAIL,
            payload: error.response.data.message
        })
    }
}

// Add staff duty
export const addStaffDuty = (staffData, code) => async (dispatch) => {
    try {

        dispatch({ type: ADD_STAFF_DUTY_REQUEST })

        // connect with backend for add staff duty
        const { data } = await axios.put(`/api/adminis/staff/add_duty/${code}`, staffData)

        // showing success status if the profile is updated
        dispatch({
            type: ADD_STAFF_DUTY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_STAFF_DUTY_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete staff duty
export const deleteDuty = (id, code) => async (dispatch) => { // id is nid which is sent from stafflsit

    try {
        dispatch({
            type: DELETE_STAFF_DUTY_REQUEST
        })

        // Sending a delete request to the backend to delete the staff
        const { data } = await axios.post(`/api/adminis/staff/delete_duty/${code}/${id}`)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_STAFF_DUTY_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_STAFF_DUTY_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete staff
export const deleteStaff = (staffData, code) => async (dispatch) => { // id is nid which is sent from stafflsit
    try {
        dispatch({
            type: DELETE_STAFF_REQUEST
        })

        // Sending a delete request to the backend to delete the staff
        const { data } = await axios.post(`/api/adminis/staff/delete/${code}`, staffData)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_STAFF_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_STAFF_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new staff report by adminis 
export const newStaffReport = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_STAFF_REPORT_REQUEST })

        // passing _STAFF_REPORT_Data to backend by this route 
        const { data } = await axios.post(`/api/adminis/staff/report/new/${code}`, formData)

        // store data in payload 
        dispatch({
            type: NEW_STAFF_REPORT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_STAFF_REPORT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all staff reports 
export const allStaffReports = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_STAFF_REPORT_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/adminis/staff/reports/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_STAFF_REPORT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_STAFF_REPORT_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update staff report
export const updateStaffReport = (staffReportData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_STAFF_REPORT_REQUEST })

        // connect with backend for staff report update
        const { data } = await axios.put(`/api/adminis/staff/report/update/${code}`, staffReportData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_STAFF_REPORT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_STAFF_REPORT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Delete staff report
export const deleteStaffReport = (reportData, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_STAFF_REPORT_REQUEST
        })

        // Sending a delete request to the backend to delete the _STAFF_REPORT_
        const { data } = await axios.post(`/api/adminis/staff/report/delete/${code}`, reportData)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_STAFF_REPORT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_STAFF_REPORT_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new furniture by adminis 
export const newFurniture = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_FURNITURE_REQUEST })


        // passing formData to backend by this route 
        const { data } = await axios.post(`/api/adminis/furniture/new/${code}`, formData)

        // store data in payload 
        dispatch({
            type: NEW_FURNITURE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_FURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all furnitures
export const getFurnitures = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_FURNITURE_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/adminis/furnitures/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_FURNITURE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_FURNITURE_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update furniture
export const updateFurniture = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_FURNITURE_REQUEST })

        // connect with backend for furniture update
        const { data } = await axios.put(`/api/adminis/furniture/update/${code}`, formData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_FURNITURE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_FURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update matching furniture
export const updateMatchedFurniture = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_MATCHING_FURNITURE_REQUEST })

        // connect with backend for furniture update
        const { data } = await axios.put(`/api/adminis/furniture/matched/update/${code}`, formData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_MATCHING_FURNITURE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_MATCHING_FURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}


// Delete furniture
export const deleteFurniture = (id, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_FURNITURE_REQUEST
        })

        // Sending a delete request to the backend to delete the furniture
        const { data } = await axios.delete(`/api/adminis/furniture/delete/${code}/${id}`)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_FURNITURE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_FURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new given furniture by adminis 
export const newGivenFurniture = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_GIVEN_FURNITURE_REQUEST })


        // passing formData to backend by this route 
        const { data } = await axios.post(`/api/adminis/furniture/giving/new/${code}`, formData)

        // store data in payload 
        dispatch({
            type: NEW_GIVEN_FURNITURE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_GIVEN_FURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all furnitures
export const getGivenFurnitures = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_GIVEN_FURNITURE_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/adminis/given/furnitures/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_GIVEN_FURNITURE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_GIVEN_FURNITURE_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update furniture
export const updateGivenFurniture = (formData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_GIVEN_FURNITURE_REQUEST })

        // connect with backend for furniture update
        const { data } = await axios.put(`/api/adminis/furniture/update/${code}`, formData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_GIVEN_FURNITURE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_GIVEN_FURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}


// Delete furniture
export const deleteGivenFurniture = (id, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_GIVEN_FURNITURE_REQUEST
        })

        // Sending a delete request to the backend to delete the furniture
        const { data } = await axios.delete(`/api/adminis/furniture/delete//${code}`, id)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_GIVEN_FURNITURE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_GIVEN_FURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Accept furniture
export const acceptFurniture = (rowData, code) => async (dispatch) => {
    try {
        dispatch({
            type: ACCEPT_ALL_FURNITURE_REQUEST
        })

        // Sending a delete request to the backend to delete the furniture
        const { data } = await axios.post(`/api/adminis/furniture/accept/${code}`, rowData)

        // showing success status
        dispatch({
            type: ACCEPT_ALL_FURNITURE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ACCEPT_ALL_FURNITURE_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new staff report by adminis 
export const addOfficiantAttendance = (code, logData) => async (dispatch) => {

    try {

        dispatch({ type: ADD_OFFICIANT_ATTENDANCE_REQUEST })

        // passing _STAFF_REPORT_Data to backend by this route 
        const { data } = await axios.post(`/api/adminis/officiant/add_attendance/${code}`, logData)

        // store data in payload 
        dispatch({
            type: ADD_OFFICIANT_ATTENDANCE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ADD_OFFICIANT_ATTENDANCE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all officiant attendance
export const getAllOffciantAttend = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_OFFICIANT_ATTENDANCE_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/adminis/officiant/all_attendance/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_OFFICIANT_ATTENDANCE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_OFFICIANT_ATTENDANCE_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Delete officiant Attendance
export const deleteOffciantAttend = (info, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_OFFICIANT_ATTENDANCE_REQUEST
        })

        // Sending a delete request to the backend to delete the furniture
        const { data } = await axios.post(`/api/adminis/officiant/delete_attendance//${code}`, info)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_OFFICIANT_ATTENDANCE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_OFFICIANT_ATTENDANCE_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new staff leave
export const newStaffLeave = (formData, code) => async (dispatch) => {

    try {

        dispatch({ type: NEW_STAFF_LEAVE_REQUEST })

        // passing staffData to backend by this route 
        const { data } = await axios.post(`/api/adminis/staff/leave/new/${code}`, formData)

        // store data in payload 
        dispatch({
            type: NEW_STAFF_LEAVE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_STAFF_LEAVE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const allStaffLeaves = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_STAFF_LEAVE_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/adminis/staff/leaves/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_STAFF_LEAVE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_STAFF_LEAVE_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

export const updateStaffLeave = (staffData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_STAFF_LEAVE_REQUEST })

        // connect with backend for staff update
        const { data } = await axios.post(`/api/adminis/staff/leave/update/${code}`, staffData)

        // showing success status if the profile is updated
        dispatch({
            type: UPDATE_STAFF_LEAVE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_STAFF_LEAVE_FAIL,
            payload: error.response.data.message
        })
    }
}

export const deleteStaffLeave = (id, code) => async (dispatch) => { // id is nid which is sent from stafflsit
    try {
        dispatch({
            type: DELETE_STAFF_LEAVE_REQUEST
        })

        // Sending a delete request to the backend to delete the staff
        const { data } = await axios.post(`/api/adminis/staff/leave/delete/${code}`, id)

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_STAFF_LEAVE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: DELETE_STAFF_LEAVE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all staff duties
export const allDuties = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_DUTIES_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/adminis/staff/duties/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_DUTIES_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_DUTIES_FAIL,
            payload: error.response.data.message
        })
        return
    }
}


// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}