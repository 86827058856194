import axios from 'axios';

import {
    NEW_REGARDING_REQUEST,
    NEW_REGARDING_SUCCESS,
    NEW_REGARDING_FAIL,
    NEW_RECEIPT_REQUEST,
    NEW_RECEIPT_SUCCESS,
    NEW_RECEIPT_FAIL,
    ALL_REGARDING_REQUEST,
    ALL_REGARDING_SUCCESS,
    ALL_REGARDING_FAIL,
    ALL_RECEIPT_REQUEST,
    ALL_RECEIPT_SUCCESS,
    ALL_RECEIPT_FAIL,
    ALL_VOUCHER_REQUEST,
    ALL_VOUCHER_SUCCESS,
    ALL_VOUCHER_FAIL,
    UPDATE_RECEIPT_REQUEST,
    UPDATE_RECEIPT_SUCCESS,
    UPDATE_RECEIPT_FAIL,
    UPDATE_VOUCHER_REQUEST,
    UPDATE_VOUCHER_SUCCESS,
    UPDATE_VOUCHER_FAIL,
    DELETE_RECEIPT_REQUEST,
    DELETE_RECEIPT_SUCCESS,
    DELETE_RECEIPT_FAIL,
    DELETE_VOUCHER_REQUEST,
    DELETE_VOUCHER_SUCCESS,
    DELETE_VOUCHER_FAIL,
    COUNTING_DATA_REQUEST,
    COUNTING_DATA_SUCCESS,
    COUNTING_DATA_FAIL,
    SEARCHED_COUNTING_DATA_REQUEST,
    SEARCHED_COUNTING_DATA_SUCCESS,
    SEARCHED_COUNTING_DATA_FAIL,
    DELETE_COUNTING_DATA_REQUEST,
    DELETE_COUNTING_DATA_SUCCESS,
    DELETE_COUNTING_DATA_FAIL,
    SAVE_COUNTING_DATA_REQUEST,
    SAVE_COUNTING_DATA_SUCCESS,
    SAVE_COUNTING_DATA_FAIL,
    NEW_VOUCHER_REQUEST,
    NEW_VOUCHER_SUCCESS,
    NEW_VOUCHER_FAIL,
    NEW_SALARY_REQUEST,
    NEW_SALARY_SUCCESS,
    NEW_SALARY_FAIL,
    ALL_SALARY_REQUEST,
    ALL_SALARY_SUCCESS,
    ALL_SALARY_FAIL,
    UPDATE_SALARY_REQUEST,
    UPDATE_SALARY_SUCCESS,
    UPDATE_SALARY_FAIL,
    DELETE_SALARY_REQUEST,
    DELETE_SALARY_SUCCESS,
    DELETE_SALARY_FAIL,
    NEW_RECEIPT_BOOK_REQUEST,
    NEW_RECEIPT_BOOK_SUCCESS,
    NEW_RECEIPT_BOOK_FAIL,
    ALL_RECEIPT_BOOKS_REQUEST,
    ALL_RECEIPT_BOOKS_SUCCESS,
    ALL_RECEIPT_BOOKS_FAIL,
    UPDATE_RECEIPT_BOOK_REQUEST,
    UPDATE_RECEIPT_BOOK_SUCCESS,
    UPDATE_RECEIPT_BOOK_FAIL,
    DELETE_RECEIPT_BOOK_REQUEST,
    DELETE_RECEIPT_BOOK_SUCCESS,
    DELETE_RECEIPT_BOOK_FAIL,
    NEW_FEE_REQUEST,
    NEW_FEE_SUCCESS,
    NEW_FEE_FAIL,
    ALL_FEES_REQUEST,
    ALL_FEES_SUCCESS,
    ALL_FEES_FAIL,
    APPROVED_BOOKS_REQUEST,
    APPROVED_BOOKS_SUCCESS,
    APPROVED_BOOKS_FAIL,
    DELETE_FEE_REQUEST,
    DELETE_FEE_SUCCESS,
    DELETE_FEE_FAIL,
    UPDATE_FEE_REQUEST,
    UPDATE_FEE_SUCCESS,
    UPDATE_FEE_FAIL,
    NEW_REPORT_REQUEST,
    NEW_REPORT_SUCCESS,
    NEW_REPORT_FAIL,
    REPORT_BALANCE_REQUEST,
    REPORT_BALANCE_SUCCESS,
    REPORT_BALANCE_FAIL,
    DELETE_REPORT_REQUEST,
    DELETE_REPORT_SUCCESS,
    DELETE_REPORT_FAIL,
    CLEAR_ERRORS

} from '../constants/accountConstants'


// add new regarding by admin 
export const newRegarding = (regardingData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_REGARDING_REQUEST })

        // configuration for register a user 
        const config = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        // passing regardingData to backend by this route 
        const { data } = await axios.post(`/api/account/regarding/new/${code}`, regardingData, config)

        // store data in payload 
        dispatch({
            type: NEW_REGARDING_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_REGARDING_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all reagardings
export const allRegardings = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_REGARDING_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/account/regardings/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_REGARDING_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: ALL_REGARDING_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// add new receipt
export const newReceipt = (code, ReceiptData) => async (dispatch) => {
    try {

        dispatch({ type: NEW_RECEIPT_REQUEST })

        // passing receiptData to backend by this route 
        const { data } = await axios.post(`/api/account/receipt/new/${code}`, ReceiptData)

        // store data in payload 
        dispatch({
            type: NEW_RECEIPT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_RECEIPT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all receipt
export const allReceipt = (department, code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_RECEIPT_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/account/receipts/${code}`, department)

        // storing all users in payload
        dispatch({
            type: ALL_RECEIPT_SUCCESS,
            payload: data.receipts
        })

    } catch (error) {
        dispatch({
            type: ALL_RECEIPT_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update receipt
export const updateReceipt = (receiptData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_RECEIPT_REQUEST })

        // connect with backend for receipt update by userData that will be sent from frontend
        const { data } = await axios.put(`/api/account/receipt/update/${code}`, receiptData)

        // showing success status if the receipt is updated
        dispatch({
            type: UPDATE_RECEIPT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_RECEIPT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete receipt
export const deleteReceipt = (rowData, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_RECEIPT_REQUEST
        })

        // Sending a delete request to the backend to delete the receipt
        const { data } = await axios.delete(`/api/account/receipt/delete/${code}`, { data: rowData }); // rowData is passed as the request body

        // showing success status if the receipt is deleted
        dispatch({
            type: DELETE_RECEIPT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_RECEIPT_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all receipt
export const getCountingData = (basicData, code) => async (dispatch) => {
    try {

        dispatch({ type: COUNTING_DATA_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/account/counting/${code}`, basicData)

        // storing all users in payload
        dispatch({
            type: COUNTING_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: COUNTING_DATA_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// add new counting 
export const newCounting = (countingData, code) => async (dispatch) => {
    try {

        dispatch({ type: SAVE_COUNTING_DATA_REQUEST })

        // passing countingData to backend by this route 
        const { data } = await axios.post(`/api/account/counting/new/${code}`, countingData)

        // store data in payload 
        dispatch({
            type: SAVE_COUNTING_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SAVE_COUNTING_DATA_FAIL,
            payload: error.response.data.message
        })
    }
}

// get counting  data
export const searchCountingData = (basicInfo, code) => async (dispatch) => {
    try {

        dispatch({ type: SEARCHED_COUNTING_DATA_REQUEST })

        // passing basicInfo to backend by this route 
        const { data } = await axios.post(`/api/account/counting/search/${code}`, basicInfo)

        // store data in payload 
        dispatch({
            type: SEARCHED_COUNTING_DATA_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: SEARCHED_COUNTING_DATA_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete counting
export const deleteCounting = (passingData, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_COUNTING_DATA_REQUEST
        })

        // Sending a delete request to the backend to delete the counting
        const { data } = await axios.delete(`/api/account/counting/delete/${code}`, { data: passingData }); // passingData is passed as the request body

        // showing success status if the counting is deleted
        dispatch({
            type: DELETE_COUNTING_DATA_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_COUNTING_DATA_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new receipt
export const newVoucher = (VoucherData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_VOUCHER_REQUEST })

        // passing regardingData to backend by this route 
        const { data } = await axios.post(`/api/account/voucher/new/${code}`, VoucherData)

        // store data in payload 
        dispatch({
            type: NEW_VOUCHER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_VOUCHER_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all voucher
export const allVouchers = (voucherDepertment, code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_VOUCHER_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/account/vouchers/${code}`, voucherDepertment)

        // storing all users in payload
        dispatch({
            type: ALL_VOUCHER_SUCCESS,
            payload: data.vouchers
        })

    } catch (error) {
        dispatch({
            type: ALL_VOUCHER_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update voucher
export const updateVoucher = (voucherData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_VOUCHER_REQUEST })

        // connect with backend for voucher update by userData that will be sent from frontend
        const { data } = await axios.put(`/api/account/voucher/update/${code}`, voucherData)

        // showing success status if the voucher is updated
        dispatch({
            type: UPDATE_VOUCHER_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_VOUCHER_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete voucher
export const deleteVoucher = (voucherData, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_VOUCHER_REQUEST
        })

        // Sending a delete request to the backend to delete the voucher
        const { data } = await axios.delete(`/api/account/voucher/delete/${code}`, { data: voucherData }); // rowData is passed as the request body

        // showing success status if the voucher is deleted
        dispatch({
            type: DELETE_VOUCHER_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_VOUCHER_FAIL,
            payload: error.response.data.message
        })
    }
}


// add new salary
export const newSalary = (salaryData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_SALARY_REQUEST })

        // passing salaryData to backend by this route 
        const { data } = await axios.post(`/api/account/salary/new/${code}`, salaryData)

        // store data in payload 
        dispatch({
            type: NEW_SALARY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_SALARY_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all salaries
export const allSalaries = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_SALARY_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.get(`/api/account/salaries/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_SALARY_SUCCESS,
            payload: data.salaries
        })

    } catch (error) {
        dispatch({
            type: ALL_SALARY_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update salary
export const updateSalary = (salaryData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_SALARY_REQUEST })

        // connect with backend for salary update by userData that will be sent from frontend
        const { data } = await axios.put(`/api/account/salary/update/${code}`, salaryData)

        // showing success status if the salary is updated
        dispatch({
            type: UPDATE_SALARY_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_SALARY_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete salary
export const deleteSalary = (salaryData, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_SALARY_REQUEST
        })

        // Sending a delete request to the backend to delete the salary
        const { data } = await axios.post(`/api/account/salary/delete/${code}`, salaryData); // rowData is passed as the request body

        // showing success status if the salary is deleted
        dispatch({
            type: DELETE_SALARY_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_SALARY_FAIL,
            payload: error.response.data.message
        })
    }
}


// add new receipt book
export const newReceiptBook = (booksData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_RECEIPT_BOOK_REQUEST })

        // passing BookData to backend by this route 
        const { data } = await axios.post(`/api/account/receipt_book/new/${code}`, booksData)

        // store data in payload 
        dispatch({
            type: NEW_RECEIPT_BOOK_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_RECEIPT_BOOK_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all approved books
export const allApprovedBooks = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_RECEIPT_BOOKS_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/account/all_receipt_books/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_RECEIPT_BOOKS_SUCCESS,
            payload: data.all_approved_books
        })

    } catch (error) {
        dispatch({
            type: ALL_RECEIPT_BOOKS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Get approved books
export const approvedBooks = (department, code) => async (dispatch) => {
    try {

        dispatch({ type: APPROVED_BOOKS_REQUEST })

        // connect with backend to get all APPROVED books
        const { data } = await axios.post(`/api/account/receipt_books/${code}`, department)

        // storing APPROVED books in payload
        dispatch({
            type: APPROVED_BOOKS_SUCCESS,
            payload: data.approved_books
        })

    } catch (error) {
        dispatch({
            type: APPROVED_BOOKS_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Update approved books
export const updateApprovedBooks = (booksData, code) => async (dispatch) => {
    try {

        dispatch({ type: UPDATE_RECEIPT_BOOK_REQUEST })

        // connect with backend for receipt update by userData that will be sent from frontend
        const { data } = await axios.post(`/api/account/update_receipt_book/${code}`, booksData)

        // showing success status if the receipt is updated
        dispatch({
            type: UPDATE_RECEIPT_BOOK_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: UPDATE_RECEIPT_BOOK_FAIL,
            payload: error.response.data.message
        })
    }
}

// Delete approved books
export const deleteApprovedBooks = (id, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_RECEIPT_BOOK_REQUEST
        })

        // Sending a delete request to the backend to delete the receipt book
        const { data } = await axios.delete(`/api/account/receipt_book/delete/${id}/${code}`);

        // showing success status if the receipt book is deleted
        dispatch({
            type: DELETE_RECEIPT_BOOK_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_RECEIPT_BOOK_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new fee
export const newFee = (studentInfo, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_FEE_REQUEST })

        // passing BookData to backend by this route 
        const { data } = await axios.post(`/api/account/fees/new/${code}`, studentInfo)

        // store data in payload 
        dispatch({
            type: NEW_FEE_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_FEE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Get all fees
export const allFees = (code) => async (dispatch) => {
    try {

        dispatch({ type: ALL_FEES_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/account/fees/${code}`)

        // storing all users in payload
        dispatch({
            type: ALL_FEES_SUCCESS,
            payload: data.fees
        })

    } catch (error) {
        dispatch({
            type: ALL_FEES_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Delete fee
export const deleteFee = (info, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_FEE_REQUEST
        })

        // Sending a delete request to the backend to delete the receipt book
        const { data } = await axios.post(`/api/account/fee/delete/${code}`, info); // rowData is passed as the request body

        // showing success status if the receipt book is deleted
        dispatch({
            type: DELETE_FEE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_FEE_FAIL,
            payload: error.response.data.message
        })
    }
}

// Update fee
export const updateFee = (updateData, code) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_FEE_REQUEST
        })

        // Sending a update request to the backend to update the fee
        const { data } = await axios.put(`/api/account/fee/update/${code}`, updateData); // rowData is passed as the request body

        // showing success status if the fee is updated
        dispatch({
            type: UPDATE_FEE_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: UPDATE_FEE_FAIL,
            payload: error.response.data.message
        })
    }
}

// add new counting report by admin 
export const newReport = (reportData, code) => async (dispatch) => {
    try {

        dispatch({ type: NEW_REPORT_REQUEST })

        // passing regardingData to backend by this route 
        const { data } = await axios.post(`/api/account/report/new/${code}`, reportData)

        // store data in payload 
        dispatch({
            type: NEW_REPORT_SUCCESS,
            payload: data
        })

    } catch (error) {
        dispatch({
            type: NEW_REPORT_FAIL,
            payload: error.response.data.message
        })
    }
}

export const getReportBalance = (reportData, code) => async (dispatch) => {
    try {

        dispatch({ type: REPORT_BALANCE_REQUEST })

        // connect with backend to get all users
        const { data } = await axios.post(`/api/account/report/balance/${code}`, reportData)

        // storing all users in payload
        dispatch({
            type: REPORT_BALANCE_SUCCESS,
            payload: data.balances
        })

    } catch (error) {
        dispatch({
            type: REPORT_BALANCE_FAIL,
            payload: error.response.data.message
        })
        return
    }
}

// Delete counting report
export const deleteReport = (reportData, code) => async (dispatch) => {
    try {
        dispatch({
            type: DELETE_REPORT_REQUEST
        })

        // Sending a delete request to the backend to delete the receipt book
        const { data } = await axios.post(`/api/account/report/delete/${code}`, reportData);

        // showing success status if the receipt book is deleted
        dispatch({
            type: DELETE_REPORT_SUCCESS,
            payload: data.success
        })

    } catch (error) {
        dispatch({
            type: DELETE_REPORT_FAIL,
            payload: error.response.data.message
        })
    }
}


// Clear Errors
export const clearErrors = () => async (dispatch) => {
    dispatch({
        type: CLEAR_ERRORS
    })
}
